<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" width="48" height="48" class="fill-segment-light">
  <g>
    <g>
      <g>
        <path d="M348.51,387.008c-6.075,0-11-4.925-11-11V71.996c0-6.075,4.925-11,11-11s11,4.925,11,11v304.012     C359.51,382.083,354.585,387.008,348.51,387.008z"/>
        <path d="M420.506,336.701V71.996C420.506,32.297,388.209,0,348.51,0s-71.996,32.297-71.996,71.996v264.705     c-19.442,19.227-30.498,45.482-30.498,72.805C246.017,466.021,291.995,512,348.51,512s102.494-45.979,102.494-102.494     C451.004,382.184,439.948,355.928,420.506,336.701z M348.51,490c-44.384,0-80.494-36.109-80.494-80.494     c0-22.749,9.784-44.57,26.842-59.869c2.327-2.087,3.656-5.064,3.656-8.189V71.996C298.515,44.428,320.942,22,348.51,22     s49.996,22.428,49.996,49.996v269.451c0,3.125,1.329,6.103,3.656,8.189c17.058,15.298,26.842,37.12,26.842,59.869     C429.004,453.891,392.895,490,348.51,490z"/>
        <path d="M348.51,451.004c-22.882,0-41.498-18.616-41.498-41.498s18.616-41.498,41.498-41.498s41.498,18.616,41.498,41.498     S371.393,451.004,348.51,451.004z M348.51,390.008c-10.751,0-19.498,8.747-19.498,19.498s8.747,19.498,19.498,19.498     s19.498-8.747,19.498-19.498S359.262,390.008,348.51,390.008z"/>
      </g>
      <path d="M501,82.996h-30.498c-6.075,0-11-4.925-11-11s4.925-11,11-11H501c6.075,0,11,4.925,11,11    S507.075,82.996,501,82.996z"/>
      <path d="M485.751,143.992h-15.249c-6.075,0-11-4.925-11-11s4.925-11,11-11h15.249c6.075,0,11,4.925,11,11    S491.826,143.992,485.751,143.992z"/>
      <path d="M501,204.987h-30.498c-6.075,0-11-4.925-11-11s4.925-11,11-11H501c6.075,0,11,4.925,11,11    S507.075,204.987,501,204.987z"/>
      <path d="M485.751,265.983h-15.249c-6.075,0-11-4.925-11-11s4.925-11,11-11h15.249c6.075,0,11,4.925,11,11    S491.826,265.983,485.751,265.983z"/>
      <path d="M501,326.979h-30.498c-6.075,0-11-4.925-11-11s4.925-11,11-11H501c6.075,0,11,4.925,11,11    S507.075,326.979,501,326.979z"/>
    </g>
    <g>
      <path d="M122.826,174.489c-28.488,0-51.664-23.176-51.664-51.663s23.176-51.664,51.664-51.664    s51.664,23.177,51.664,51.664S151.313,174.489,122.826,174.489z M122.826,93.162c-16.357,0-29.664,13.308-29.664,29.664    s13.307,29.663,29.664,29.663s29.664-13.307,29.664-29.663S139.183,93.162,122.826,93.162z"/>
      <path d="M31.332,133.826H11c-6.075,0-11-4.925-11-11s4.925-11,11-11h20.332c6.075,0,11,4.925,11,11    S37.407,133.826,31.332,133.826z"/>
      <path d="M234.651,133.826h-20.332c-6.075,0-11-4.925-11-11s4.925-11,11-11h20.332c6.075,0,11,4.925,11,11    S240.727,133.826,234.651,133.826z"/>
      <path d="M43.753,212.898c-2.815,0-5.63-1.074-7.778-3.222c-4.295-4.296-4.295-11.261,0-15.557    l14.377-14.377c4.296-4.295,11.261-4.295,15.557,0c4.295,4.296,4.295,11.261,0,15.557l-14.377,14.377    C49.383,211.824,46.568,212.898,43.753,212.898z"/>
      <path d="M187.521,69.13c-2.815,0-5.63-1.074-7.778-3.222c-4.295-4.296-4.295-11.261,0-15.557l14.377-14.377    c4.296-4.295,11.261-4.295,15.557,0c4.295,4.296,4.295,11.261,0,15.557L195.3,65.908C193.152,68.056,190.336,69.13,187.521,69.13z    "/>
      <path d="M122.826,245.651c-6.075,0-11-4.925-11-11v-20.332c0-6.075,4.925-11,11-11s11,4.925,11,11v20.332    C133.826,240.727,128.901,245.651,122.826,245.651z"/>
      <path d="M122.826,42.332c-6.075,0-11-4.925-11-11V11c0-6.075,4.925-11,11-11s11,4.925,11,11v20.332    C133.826,37.407,128.901,42.332,122.826,42.332z"/>
      <path d="M201.898,212.898c-2.815,0-5.63-1.074-7.778-3.222L179.743,195.3    c-4.295-4.296-4.295-11.261,0-15.557c4.296-4.295,11.261-4.295,15.557,0l14.377,14.377c4.295,4.296,4.295,11.261,0,15.557    C207.529,211.824,204.713,212.898,201.898,212.898z"/>
      <path d="M58.13,69.13c-2.815,0-5.63-1.074-7.778-3.222L35.975,51.531c-4.295-4.296-4.295-11.261,0-15.557    c4.296-4.295,11.261-4.295,15.557,0l14.377,14.377c4.295,4.296,4.295,11.261,0,15.557C63.76,68.056,60.945,69.13,58.13,69.13z"/>
    </g>
  </g>
  </svg>
</template>

<script>
  export default {
    //
  }
</script>
