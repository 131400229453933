<template>
  <svg width="36" height="40" viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.173 7.78737L27.5738 30.9894L13.3741 19.8043L18.173 7.78737ZM34.8733 36.3343L20.413 1.5347C20.0002 0.531068 19.1748 0 18.173 0C17.1694 0 16.2839 0.531068 15.8711 1.5347L0 39.7055H5.42925L11.712 23.9676L30.4609 39.1145C31.215 39.7242 31.7591 40 32.4664 40C33.8828 40 35.1208 38.9381 35.1208 37.4054C35.1208 37.156 35.0327 36.7601 34.8733 36.3343Z" fill="white"/>
    <path d="M18.173 7.78737L27.5738 30.9894L13.3741 19.8043L18.173 7.78737ZM34.8733 36.3343L20.413 1.5347C20.0002 0.531068 19.1748 0 18.173 0C17.1694 0 16.2839 0.531068 15.8711 1.5347L0 39.7055H5.42925L11.712 23.9676L30.4609 39.1145C31.215 39.7242 31.7591 40 32.4664 40C33.8828 40 35.1208 38.9381 35.1208 37.4054C35.1208 37.156 35.0327 36.7601 34.8733 36.3343Z" fill="#232634"/>
    <path d="M18.173 7.78737L27.5738 30.9894L13.3741 19.8043L18.173 7.78737ZM34.8733 36.3343L20.413 1.5347C20.0002 0.531068 19.1748 0 18.173 0C17.1694 0 16.2839 0.531068 15.8711 1.5347L0 39.7055H5.42925L11.712 23.9676L30.4609 39.1145C31.215 39.7242 31.7591 40 32.4664 40C33.8828 40 35.1208 38.9381 35.1208 37.4054C35.1208 37.156 35.0327 36.7601 34.8733 36.3343Z" fill="url(#icon_customiso_linear)"/>
    <defs>
      <linearGradient id="icon_customiso_linear" x1="17.5604" y1="0" x2="17.5604" y2="40" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>
</template>



<script>
  export default {
    //
  }
</script>
