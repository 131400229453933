<script>
  export default {
    props: {
      x: {
        type: Number,
        required: true
      },
      y: {
        type: Number,
        required: true
      },
      disabled: Boolean
    }
  }
</script>



<style scoped>
  mark {
    @apply transform scale-150;
    @apply text-gray-lightest bg-transparent;
  }

  mark[disabled="true"] figure {
    @apply bg-gray-lightest bg-opacity-40;
  }

  mark[disabled="false"] figure {
    @apply bg-brand-matte bg-opacity-40;
  }

  mark[disabled="true"] figure span {
    @apply bg-gray-lightest border-gray-darkest;
  }

  mark[disabled="false"] figure span {
    @apply bg-brand border-gray-darkest;
  }
</style>



<template>
  <mark class="absolute flex items-center font-bold whitespace-nowrap"
    :disabled="disabled"
    :style="'top: ' + y + 'px; left: ' + x + 'px;'">
    <figure class="flex items-center justify-center h-10 w-10 rounded-full mr-2">
      <span class="h-4 w-4 border-4 rounded-full"></span>
    </figure>

    <div class="hidden lg:block">
      <slot></slot>
    </div>
  </mark>
</template>
