<script setup>
  import { ref, computed, onMounted, watch, nextTick } from 'vue';
  import Atropos from 'atropos';
  import 'atropos/css';

  const props = defineProps({
    disabled: Boolean,
  });

  const effect = ref(null);
  const instance = ref(null);
  const disabled = computed(() => !! props.disabled);
  const enabled = computed(() => ! disabled.value);

  watch(enabled, (value) => {
    if (value === false) {
      effect.value.classList.remove('atropos-rotate-touch', 'atropos-active');
      effect.value.querySelector('.atropos-scale').removeAttribute('style');
      effect.value.querySelector('.atropos-rotate').removeAttribute('style');
      effect.value.querySelector('.atropos-highlight').remove();
      effect.value.querySelector('.atropos-shadow').remove();

      nextTick(() => {
        instance.value.destroy();
        instance.value = null;
      });
    }

    if (value === true) {
      instance.value = Atropos({ el: effect.value });
    }
  });

  onMounted(() => {
    if (enabled.value) {
      instance.value = Atropos({ el: effect.value });
    }
  });
</script>



<template>
  <div ref="effect" class="atropos">
    <div class="atropos-scale">
      <div class="atropos-rotate">
        <div class="atropos-inner">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
