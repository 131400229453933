<script setup>
  import { computed } from 'vue';

  const suffix = Math.random().toString(36).substr(2, 9);
  const generate = (range, callback) => ([...Array(range)].map(callback));

  const gradients = generate(43, (v, k) => `url(#memory-optimized-${suffix}-${k})`);
  const slugs = generate(43, (v, k) => `memory-optimized-${suffix}-${k}`);
</script>



<style scoped>
  .memory-optimized-dark { @apply hidden; }
  .memory-optimized-light { @apply block; }

  /*
  .memory-optimized-dark { @apply transition opacity-100; }
  .memory-optimized-light { @apply transition opacity-0; }

  svg:hover .memory-optimized-dark { @apply opacity-0; }
  svg:hover .memory-optimized-light { @apply opacity-100; }
  */

	.memory-optimized-0 { fill: rgba(0, 0, 0, .15); }
	.memory-optimized-1 { fill: #20B785; }
	.memory-optimized-2 { fill: #53DCAB; }
	.memory-optimized-3 { fill: #198C5D; }
	.memory-optimized-4 { fill: v-bind(gradients[1]); }
	.memory-optimized-5 { fill: v-bind(gradients[2]); }
	.memory-optimized-6 { fill: v-bind(gradients[3]); }
	.memory-optimized-7 { fill: v-bind(gradients[4]); }
	.memory-optimized-8 { fill: v-bind(gradients[5]); }
	.memory-optimized-9 { fill: v-bind(gradients[6]); }
	.memory-optimized-10 { fill: v-bind(gradients[7]); }
	.memory-optimized-11 { fill: v-bind(gradients[8]); }
	.memory-optimized-12 { fill: v-bind(gradients[9]); }
	.memory-optimized-13 { fill: v-bind(gradients[10]); }
	.memory-optimized-14 { fill: v-bind(gradients[11]); }
	.memory-optimized-15 { fill: v-bind(gradients[12]); }
	.memory-optimized-16 { fill: v-bind(gradients[13]); }
	.memory-optimized-17 { fill: v-bind(gradients[14]); }
	.memory-optimized-18 { fill: v-bind(gradients[15]); }
	.memory-optimized-19 { fill: v-bind(gradients[16]); }
	.memory-optimized-20 { fill: v-bind(gradients[17]); }
	.memory-optimized-21 { fill: v-bind(gradients[18]); }
	.memory-optimized-22 { fill: v-bind(gradients[19]); }
	.memory-optimized-23 { fill: v-bind(gradients[20]); }
	.memory-optimized-24 { fill: v-bind(gradients[21]); }
	.memory-optimized-25 { fill: #9E7238; }
	.memory-optimized-26 { fill: #4B4D59; }
	.memory-optimized-27 { fill: #292C35; }
	.memory-optimized-28 { fill: #323542; }
	.memory-optimized-29 { fill: #2C2F3A; }
	.memory-optimized-30 { fill: #383C49; }
	.memory-optimized-31 { fill: #474956; }
	.memory-optimized-32 { fill: #161924; }
	.memory-optimized-33 { fill: #242835; }
	.memory-optimized-34 { fill: #191D26; }
	.memory-optimized-35 { fill: v-bind(gradients[22]); }
	.memory-optimized-36 { fill: v-bind(gradients[23]); }
	.memory-optimized-37 { fill: v-bind(gradients[24]); }
	.memory-optimized-38 { fill: v-bind(gradients[25]); }
	.memory-optimized-39 { fill: v-bind(gradients[26]); }
	.memory-optimized-40 { fill: v-bind(gradients[27]); }
	.memory-optimized-41 { fill: v-bind(gradients[28]); }
	.memory-optimized-42 { fill: v-bind(gradients[29]); }
	.memory-optimized-43 { fill: v-bind(gradients[30]); }
	.memory-optimized-44 { fill: v-bind(gradients[31]); }
	.memory-optimized-45 { fill: v-bind(gradients[32]); }
	.memory-optimized-46 { fill: v-bind(gradients[33]); }
	.memory-optimized-47 { fill: v-bind(gradients[34]); }
	.memory-optimized-48 { fill: v-bind(gradients[35]); }
	.memory-optimized-49 { fill: v-bind(gradients[36]); }
	.memory-optimized-50 { fill: v-bind(gradients[37]); }
	.memory-optimized-51 { fill: v-bind(gradients[38]); }
	.memory-optimized-52 { fill: v-bind(gradients[39]); }
	.memory-optimized-53 { fill: v-bind(gradients[40]); }
	.memory-optimized-54 { fill: v-bind(gradients[41]); }
	.memory-optimized-55 { fill: v-bind(gradients[42]); }
	.memory-optimized-56 { fill: #A3ABC3; }
	.memory-optimized-57 { fill: #595F68; }
	.memory-optimized-58 { fill: #69717F; }
	.memory-optimized-59 { fill: #767F91; }
	.memory-optimized-60 { fill: #68707A; }
	.memory-optimized-61 { fill: #7C85A6; }
	.memory-optimized-62 { fill: #979FAF; }
	.memory-optimized-63 { fill: #363F4C; }
	.memory-optimized-64 { fill: #282D38; }
</style>

<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 375.47 339.43" style="enable-background:new 0 0 375.47 339.43;" xml:space="preserve">
    <g class="memory-optimized-dark">
      <path class="memory-optimized-0" d="M358.77,229.38l-157.17-90.74c-7.63-4.4-20.11-4.4-27.74,0l-23.11,13.34c10.17,5.87,10.17,15.48,0,21.35 c-10.17,5.87-26.81,5.87-36.98,0l-96.01,54.56c-4.04,2.33-6.27,5.33-6.69,8.4c-0.12,0.72-0.12,1.45,0,2.18 c0.43,3.07,2.65,6.07,6.69,8.4l153.53,88.64c4.04,2.33,9.24,3.62,14.55,3.86c1.25,0.07,2.52,0.07,3.77,0 c5.32-0.25,10.51-1.53,14.55-3.86l94.5-55.43c0,0,0,0,0,0c-10.17-5.87-10.17-15.48,0-21.35c10.17-5.87,26.81-5.87,36.98,0 c0,0,0,0,0,0l23.11-13.34C366.4,240.99,366.4,233.78,358.77,229.38z"/>
      <g>
        <g>
          <path class="memory-optimized-1" d="M357.71,190.19l-5.82,3.36v9.9l5.82-3.36c4.52-2.61,6.78-6.05,6.78-9.49l0,0v-9.9 C364.49,184.14,362.23,187.58,357.71,190.19z"/>
          <path class="memory-optimized-1" d="M17.76,190.19l5.82,3.36v9.9l-5.82-3.36c-4.52-2.61-6.78-6.05-6.78-9.49l0,0v-9.9 C10.98,184.14,13.24,187.58,17.76,190.19z"/>
          <polygon class="memory-optimized-1" points="204.18,278.83 204.18,288.73 357.71,200.09 357.71,190.19"/>
          <polygon class="memory-optimized-1" points="171.3,278.83 171.3,288.73 17.76,200.09 17.76,190.19"/>
          <path class="memory-optimized-1" d="M171.3,278.83v9.9c9.04,5.22,23.84,5.22,32.88,0v-9.9C195.14,284.05,180.34,284.05,171.3,278.83z"/>
        </g>
        <path class="memory-optimized-2" d="M357.71,171.2L204.18,82.56c-9.04-5.22-23.84-5.22-32.88,0L17.76,171.2c-9.04,5.22-9.04,13.76,0,18.98 l153.53,88.64c9.04,5.22,23.84,5.22,32.88,0l153.53-88.64C366.75,184.97,366.75,176.42,357.71,171.2z M343.49,185.69L196.4,270.61 c-4.76,2.75-12.56,2.75-17.32,0L31.99,185.69c-4.76-2.75-4.76-7.25,0-10l147.09-84.92c4.76-2.75,12.56-2.75,17.32,0l147.09,84.92 C348.25,178.44,348.25,182.94,343.49,185.69z"/>
        <path class="memory-optimized-1" d="M31.93,185.65c0.02-0.01,0.04-0.03,0.06-0.04l147.09-84.92c4.76-2.75,12.56-2.75,17.32,0l147.09,84.92 c0.02,0.01,0.04,0.03,0.06,0.04c4.7-2.75,4.68-7.22-0.06-9.96L196.4,90.78c-4.76-2.75-12.56-2.75-17.32,0L31.99,175.69 C27.25,178.43,27.23,182.9,31.93,185.65z"/>
      </g>
      <path class="memory-optimized-3" d="M6.08,128.23l4.91,2.83v-22.68l-4.91,2.84C-2.03,115.9-2.03,123.55,6.08,128.23z M369.4,111.22L202.47,14.85 c-8.1-4.68-21.36-4.68-29.46,0l-24.55,14.17h0c10.8,6.24,10.8,16.44,0,22.68s-28.48,6.24-39.28,0l-67.37,38.9l5.15-2.39 l154.77,95.1l-4.17,4.45l11.56,8.51l-11.56,8.5l-29.45,17v0.01l4.9,2.83c8.1,4.68,21.36,4.68,29.46,0l103.11-59.53c0,0,0,0,0,0 c-10.8-6.24-10.8-16.44,0-22.68c10.8-6.24,28.48-6.24,39.28,0c0,0,0,0,0,0l24.55-14.17C377.5,123.55,377.5,115.9,369.4,111.22z"/>
      <g>
        <g>
          <linearGradient :id="slugs[1]" gradientUnits="userSpaceOnUse" x1="37.9892" y1="140.987" x2="52.7185" y2="115.4752">
            <stop offset="0" style="stop-color:#F7CD66"/>
            <stop offset="0.6117" style="stop-color:#F0C964"/>
            <stop offset="1" style="stop-color:#E8C562"/>
          </linearGradient>
          <polygon class="memory-optimized-4" points="50.26,114.06 20.8,131.07 40.44,142.4 69.9,125.4"/>
          <linearGradient :id="slugs[2]" gradientUnits="userSpaceOnUse" x1="67.4489" y1="157.995" x2="82.178" y2="132.4834">
            <stop offset="0" style="stop-color:#F7CD66"/>
            <stop offset="0.6117" style="stop-color:#F0C964"/>
            <stop offset="1" style="stop-color:#E8C562"/>
          </linearGradient>
          <polygon class="memory-optimized-5" points="79.72,131.07 50.26,148.07 69.9,159.41 99.36,142.41"/>
          <linearGradient :id="slugs[3]" gradientUnits="userSpaceOnUse" x1="96.9084" y1="175.0028" x2="111.6375" y2="149.4912">
            <stop offset="0" style="stop-color:#F7CD66"/>
            <stop offset="0.6117" style="stop-color:#F0C964"/>
            <stop offset="1" style="stop-color:#E8C562"/>
          </linearGradient>
          <polygon class="memory-optimized-6" points="109.18,148.07 79.72,165.08 99.36,176.42 128.82,159.41"/>
          <linearGradient :id="slugs[4]" gradientUnits="userSpaceOnUse" x1="126.3679" y1="192.0105" x2="141.0969" y2="166.499">
            <stop offset="0" style="stop-color:#F7CD66"/>
            <stop offset="0.6117" style="stop-color:#F0C964"/>
            <stop offset="1" style="stop-color:#E8C562"/>
          </linearGradient>
          <polygon class="memory-optimized-7" points="138.64,165.08 109.18,182.09 128.82,193.43 158.28,176.42"/>
          <linearGradient :id="slugs[5]" gradientUnits="userSpaceOnUse" x1="155.8273" y1="209.0183" x2="170.5564" y2="183.5068">
            <stop offset="0" style="stop-color:#F7CD66"/>
            <stop offset="0.6117" style="stop-color:#F0C964"/>
            <stop offset="1" style="stop-color:#E8C562"/>
          </linearGradient>
          <polygon class="memory-optimized-8" points="168.1,182.09 138.64,199.1 158.28,210.43 187.74,193.43"/>
        </g>
        <g>
          <linearGradient :id="slugs[6]" gradientUnits="userSpaceOnUse" x1="23.2595" y1="132.483" x2="37.9887" y2="106.9713">
            <stop offset="0" style="stop-color:#F7CD66"/>
            <stop offset="0.6117" style="stop-color:#F0C964"/>
            <stop offset="1" style="stop-color:#E8C562"/>
          </linearGradient>
          <polygon class="memory-optimized-9" points="40.44,108.39 50.26,114.06 20.8,131.07 10.99,125.4"/>
          <linearGradient :id="slugs[7]" gradientUnits="userSpaceOnUse" x1="52.719" y1="149.4908" x2="67.4482" y2="123.9791">
            <stop offset="0" style="stop-color:#F7CD66"/>
            <stop offset="0.6117" style="stop-color:#F0C964"/>
            <stop offset="1" style="stop-color:#E8C562"/>
          </linearGradient>
          <polygon class="memory-optimized-10" points="69.9,125.4 79.72,131.07 50.26,148.07 40.44,142.4"/>
          <linearGradient :id="slugs[8]" gradientUnits="userSpaceOnUse" x1="82.1785" y1="166.4986" x2="96.9077" y2="140.987">
            <stop offset="0" style="stop-color:#F7CD66"/>
            <stop offset="0.6117" style="stop-color:#F0C964"/>
            <stop offset="1" style="stop-color:#E8C562"/>
          </linearGradient>
          <polygon class="memory-optimized-11" points="99.36,142.4 109.18,148.07 79.72,165.08 69.9,159.41"/>
          <linearGradient :id="slugs[9]" gradientUnits="userSpaceOnUse" x1="111.638" y1="183.5065" x2="126.3672" y2="157.9948">
            <stop offset="0" style="stop-color:#F7CD66"/>
            <stop offset="0.6117" style="stop-color:#F0C964"/>
            <stop offset="1" style="stop-color:#E8C562"/>
          </linearGradient>
          <polygon class="memory-optimized-12" points="128.82,159.41 138.64,165.08 109.18,182.09 99.36,176.42"/>
          <linearGradient :id="slugs[10]" gradientUnits="userSpaceOnUse" x1="141.0974" y1="200.5143" x2="155.8266" y2="175.0025">
            <stop offset="0" style="stop-color:#F7CD66"/>
            <stop offset="0.6117" style="stop-color:#F0C964"/>
            <stop offset="1" style="stop-color:#E8C562"/>
          </linearGradient>
          <polygon class="memory-optimized-13" points="158.28,176.42 168.1,182.09 138.64,199.1 128.82,193.43"/>
          <linearGradient :id="slugs[11]" gradientUnits="userSpaceOnUse" x1="170.5567" y1="217.522" x2="185.2858" y2="192.0105">
            <stop offset="0" style="stop-color:#F7CD66"/>
            <stop offset="0.6117" style="stop-color:#F0C964"/>
            <stop offset="1" style="stop-color:#E8C562"/>
          </linearGradient>
          <polygon class="memory-optimized-14" points="187.74,193.43 197.56,199.1 168.1,216.1 158.28,210.44"/>
        </g>
      </g>
      <g>
        <linearGradient :id="slugs[12]" gradientUnits="userSpaceOnUse" x1="29.3962" y1="136.0264" x2="44.1259" y2="110.5139">
          <stop offset="0" style="stop-color:#CC973A"/>
          <stop offset="0.2076" style="stop-color:#C59139"/>
          <stop offset="0.5179" style="stop-color:#B08236"/>
          <stop offset="0.8909" style="stop-color:#8F6931"/>
          <stop offset="1" style="stop-color:#84612F"/>
        </linearGradient>
        <polygon class="memory-optimized-15" points="52.72,115.47 50.26,114.06 20.8,131.07 23.26,132.48"/>
        <linearGradient :id="slugs[13]" gradientUnits="userSpaceOnUse" x1="46.5809" y1="145.9482" x2="61.3105" y2="120.4357">
          <stop offset="0" style="stop-color:#CC973A"/>
          <stop offset="0.2076" style="stop-color:#C59139"/>
          <stop offset="0.5179" style="stop-color:#B08236"/>
          <stop offset="0.8909" style="stop-color:#8F6931"/>
          <stop offset="1" style="stop-color:#84612F"/>
        </linearGradient>
        <polygon class="memory-optimized-16" points="69.9,125.4 67.45,123.98 37.99,140.99 40.44,142.41"/>
        <linearGradient :id="slugs[14]" gradientUnits="userSpaceOnUse" x1="58.8558" y1="153.0351" x2="73.5855" y2="127.5225">
          <stop offset="0" style="stop-color:#CC973A"/>
          <stop offset="0.2076" style="stop-color:#C59139"/>
          <stop offset="0.5179" style="stop-color:#B08236"/>
          <stop offset="0.8909" style="stop-color:#8F6931"/>
          <stop offset="1" style="stop-color:#84612F"/>
        </linearGradient>
        <polygon class="memory-optimized-17" points="82.18,132.48 79.72,131.07 50.26,148.07 52.72,149.49"/>
        <linearGradient :id="slugs[15]" gradientUnits="userSpaceOnUse" x1="76.0403" y1="162.9571" x2="90.77" y2="137.4445">
          <stop offset="0" style="stop-color:#CC973A"/>
          <stop offset="0.2076" style="stop-color:#C59139"/>
          <stop offset="0.5179" style="stop-color:#B08236"/>
          <stop offset="0.8909" style="stop-color:#8F6931"/>
          <stop offset="1" style="stop-color:#84612F"/>
        </linearGradient>
        <polygon class="memory-optimized-18" points="99.36,142.41 96.91,140.99 67.45,158 69.9,159.41"/>
        <linearGradient :id="slugs[16]" gradientUnits="userSpaceOnUse" x1="88.3152" y1="170.044" x2="103.045" y2="144.5312">
          <stop offset="0" style="stop-color:#CC973A"/>
          <stop offset="0.2076" style="stop-color:#C59139"/>
          <stop offset="0.5179" style="stop-color:#B08236"/>
          <stop offset="0.8909" style="stop-color:#8F6931"/>
          <stop offset="1" style="stop-color:#84612F"/>
        </linearGradient>
        <polygon class="memory-optimized-19" points="111.64,149.49 109.18,148.07 79.72,165.08 82.18,166.5"/>
        <linearGradient :id="slugs[17]" gradientUnits="userSpaceOnUse" x1="105.4998" y1="179.9659" x2="120.2295" y2="154.4533">
          <stop offset="0" style="stop-color:#CC973A"/>
          <stop offset="0.2076" style="stop-color:#C59139"/>
          <stop offset="0.5179" style="stop-color:#B08236"/>
          <stop offset="0.8909" style="stop-color:#8F6931"/>
          <stop offset="1" style="stop-color:#84612F"/>
        </linearGradient>
        <polygon class="memory-optimized-20" points="128.82,159.41 126.37,158 96.91,175 99.36,176.42"/>
        <linearGradient :id="slugs[18]" gradientUnits="userSpaceOnUse" x1="117.7747" y1="187.0528" x2="132.5045" y2="161.54">
          <stop offset="0" style="stop-color:#CC973A"/>
          <stop offset="0.2076" style="stop-color:#C59139"/>
          <stop offset="0.5179" style="stop-color:#B08236"/>
          <stop offset="0.8909" style="stop-color:#8F6931"/>
          <stop offset="1" style="stop-color:#84612F"/>
        </linearGradient>
        <polygon class="memory-optimized-21" points="141.1,166.5 138.64,165.08 109.18,182.09 111.64,183.51"/>
        <linearGradient :id="slugs[19]" gradientUnits="userSpaceOnUse" x1="134.9593" y1="196.9746" x2="149.6889" y2="171.4622">
          <stop offset="0" style="stop-color:#CC973A"/>
          <stop offset="0.2076" style="stop-color:#C59139"/>
          <stop offset="0.5179" style="stop-color:#B08236"/>
          <stop offset="0.8909" style="stop-color:#8F6931"/>
          <stop offset="1" style="stop-color:#84612F"/>
        </linearGradient>
        <polygon class="memory-optimized-22" points="158.28,176.42 155.83,175.01 126.37,192.01 128.82,193.43"/>
        <linearGradient :id="slugs[20]" gradientUnits="userSpaceOnUse" x1="147.2341" y1="204.0615" x2="161.9639" y2="178.5488">
          <stop offset="0" style="stop-color:#CC973A"/>
          <stop offset="0.2076" style="stop-color:#C59139"/>
          <stop offset="0.5179" style="stop-color:#B08236"/>
          <stop offset="0.8909" style="stop-color:#8F6931"/>
          <stop offset="1" style="stop-color:#84612F"/>
        </linearGradient>
        <polygon class="memory-optimized-23" points="170.56,183.51 168.1,182.09 138.64,199.1 141.1,200.52"/>
        <linearGradient :id="slugs[21]" gradientUnits="userSpaceOnUse" x1="164.4187" y1="213.9834" x2="179.1484" y2="188.4709">
          <stop offset="0" style="stop-color:#CC973A"/>
          <stop offset="0.2076" style="stop-color:#C59139"/>
          <stop offset="0.5179" style="stop-color:#B08236"/>
          <stop offset="0.8909" style="stop-color:#8F6931"/>
          <stop offset="1" style="stop-color:#84612F"/>
        </linearGradient>
        <polygon class="memory-optimized-24" points="187.74,193.43 185.29,192.01 155.83,209.02 158.28,210.44"/>
      </g>
      <path class="memory-optimized-25" d="M128.82,193.43l0,5.67l9.82,5.67l0-5.67L128.82,193.43z M99.36,176.42l0,5.67l9.82,5.67l0-5.67L99.36,176.42z M158.28,210.44l0,5.67l9.82,5.67v-5.67L158.28,210.44z M10.99,131.07l9.82,5.67v-5.67l-9.82-5.67V131.07z M69.9,165.08l9.82,5.67 v-5.67l-9.82-5.67V165.08z M40.44,142.4l0,5.67l9.82,5.67l0-5.67L40.44,142.4z"/>
      <path class="memory-optimized-25" d="M158.28,210.43l-19.64-11.34l0,5.67l19.64,11.34L158.28,210.43z M109.18,182.09l0,5.67l19.64,11.34l0-5.67 L109.18,182.09z M79.72,165.08l0,5.67l19.64,11.34l0-5.67L79.72,165.08z M50.26,148.08l0,5.67l19.64,11.34l0-5.67L50.26,148.08z M20.8,131.07l0,5.67l19.64,11.34l0-5.67L20.8,131.07z"/>
      <path class="memory-optimized-25" d="M138.65,204.77l2.46,1.42v-5.67l-2.46-1.42V204.77z M126.37,197.68l2.46,1.42v-5.67l-2.46-1.42L126.37,197.68 z M109.19,187.76l2.46,1.42v-5.67l-2.46-1.42L109.19,187.76z M155.83,214.69l2.46,1.42v-5.68l-2.46-1.42L155.83,214.69z M96.91,180.67l2.46,1.42v-5.67L96.91,175L96.91,180.67z M20.8,136.74l2.46,1.42v-5.67l-2.46-1.42L20.8,136.74z M37.99,146.66 l2.46,1.42v-5.67l-2.46-1.42L37.99,146.66z M79.72,170.75l2.46,1.42v-5.67l-2.46-1.42V170.75z M67.45,163.67l2.46,1.42v-5.67 l-2.46-1.42L67.45,163.67z M50.26,153.74l2.46,1.42v-5.67l-2.46-1.42V153.74z"/>
      <path class="memory-optimized-26" d="M369.4,99.89L202.47,3.51c-8.1-4.68-21.36-4.68-29.46,0l-24.55,14.17h0c10.8,6.24,10.8,16.44,0,22.68 c-10.8,6.24-28.48,6.24-39.28,0L6.08,99.89c-8.1,4.68-8.1,12.33,0,17.01l4.91,2.83l29.46-17.01l157.11,90.71l-29.46,17.01 l4.91,2.83c8.1,4.68,21.36,4.68,29.46,0l103.11-59.53c0,0,0,0,0,0c-10.8-6.24-10.8-16.44,0-22.68c10.8-6.24,28.48-6.24,39.28,0 c0,0,0,0,0,0l24.55-14.17C377.5,112.22,377.5,104.56,369.4,99.89z"/>
      <polygon class="memory-optimized-27" points="40.44,102.72 40.44,108.39 10.99,125.4 10.99,119.73"/>
      <polygon class="memory-optimized-28" points="40.44,102.72 40.44,108.39 192.65,196.26 197.56,193.43"/>
      <g>
        <polygon class="memory-optimized-28" points="305.57,153.74 305.57,165.08 202.47,224.61 202.47,213.27"/>
        <path class="memory-optimized-28" d="M6.08,116.89c-3.87-2.23-5.87-5.15-6.04-8.09v10.26l0.02,0.03c-0.3,3.29,1.69,6.64,6.02,9.14l4.91,2.83 v-11.34L6.08,116.89z"/>
        <path class="memory-optimized-28" d="M202.47,213.27c-8.1,4.68-21.36,4.68-29.46,0l-4.9-2.83l-0.01,0v11.33l4.91,2.83 c8.1,4.68,21.36,4.68,29.46,0V213.27z"/>
        <path class="memory-optimized-28" d="M375.42,119.09l0.02-0.03V108.8c-0.17,2.94-2.17,5.85-6.04,8.09v11.34 C373.73,125.73,375.72,122.38,375.42,119.09z"/>
        <polygon class="memory-optimized-28" points="344.85,131.06 369.4,116.89 369.4,128.23 344.85,142.4"/>
        <path class="memory-optimized-28" d="M154.77,34.69c3.72-5.72,1.62-12.43-6.31-17.01h0v11.34h0C151.33,30.68,153.43,32.62,154.77,34.69z"/>
        <path class="memory-optimized-29" d="M305.57,131.06c-7.93,4.58-10.03,11.3-6.31,17.01c1.34-2.07,3.44-4.02,6.31-5.68 c10.8-6.24,28.48-6.24,39.28,0v-11.34C334.05,124.83,316.37,124.83,305.57,131.06z"/>
      </g>
      <g>
        <g>
          <path class="memory-optimized-30" d="M207.38,187.76c-5.4-3.12-5.4-8.22,0-11.34c5.4-3.12,14.24-3.12,19.64,0c5.4,3.12,5.4,8.22,0,11.34 C221.62,190.88,212.78,190.88,207.38,187.76z"/>
        </g>
        <g>
          <path class="memory-optimized-30" d="M51.63,96.26c-5.4-3.12-5.4-8.22,0-11.34c5.4-3.12,14.24-3.12,19.64,0c5.4,3.12,5.4,8.22,0,11.34 C65.87,99.38,57.03,99.38,51.63,96.26z"/>
        </g>
      </g>
      <path class="memory-optimized-31" d="M301.61,95.85l-91.74-52.97c-6.77-3.91-17.86-3.91-24.63,0L93.5,95.85c-6.77,3.91-6.77,10.31,0,14.22 l91.74,52.96h24.63l91.74-52.96C308.38,106.16,308.38,99.76,301.61,95.85z"/>
      <g>
        <g>
          <path class="memory-optimized-32" d="M288.8,94.29L288.8,94.29L288.8,94.29l-80.45,46.45v14.73l80.45-46.45c2.97-1.72,4.45-3.98,4.46-6.24V88.05 C293.26,90.31,291.77,92.57,288.8,94.29z"/>
          <path class="memory-optimized-32" d="M186.76,140.73l-80.45-46.45v0l0,0c-2.97-1.71-4.45-3.97-4.45-6.24v14.73c0,2.26,1.49,4.52,4.45,6.24 l80.45,46.45V140.73z"/>
          <g>
            <path class="memory-optimized-33" d="M186.76,140.73c5.94,3.43,15.66,3.43,21.6,0l80.45-46.45c5.94-3.43,5.94-9.04,0-12.47l-80.45-46.45 c-5.94-3.43-15.66-3.43-21.6,0l-80.45,46.45c-5.94,3.43-5.94,9.04,0,12.47L186.76,140.73z"/>
          </g>
          <rect x="186.76" y="140.73" class="memory-optimized-34" width="21.6" height="14.73"/>
          <g>
            <path class="memory-optimized-2" d="M192.69,61.29c2.68,1.55,7.06,1.55,9.73,0v0c2.68-1.54,2.67-4.07,0-5.62v0c-2.68-1.55-7.06-1.55-9.73,0 C190.01,57.22,190.01,59.75,192.69,61.29z M226.83,81c2.67,1.54,7.05,1.54,9.73,0v0c2.68-1.54,2.68-4.07,0-5.62 c-2.67-1.54-7.05-1.54-9.73,0C224.15,76.93,224.15,79.46,226.83,81z M253.63,85.24c-2.67-1.54-7.05-1.54-9.73,0 c-2.68,1.54-2.68,4.07,0,5.62c2.68,1.55,7.05,1.55,9.73,0v0C256.31,89.31,256.31,86.78,253.63,85.24z M192.69,81 c2.68,1.55,7.05,1.55,9.73,0v0c2.68-1.54,2.67-4.07,0-5.62v0c-2.68-1.54-7.06-1.54-9.73,0C190.01,76.93,190.01,79.46,192.69,81z M219.49,85.24L219.49,85.24c-2.68-1.55-7.05-1.55-9.73,0c-2.67,1.54-2.67,4.07,0,5.62l0,0c2.68,1.54,7.05,1.54,9.73,0 C222.17,89.31,222.17,86.79,219.49,85.24z M236.56,95.1L236.56,95.1c-2.68-1.55-7.05-1.55-9.73,0c-2.67,1.54-2.67,4.07,0,5.62 l0,0c2.68,1.54,7.05,1.54,9.73,0C239.24,99.17,239.24,96.64,236.56,95.1z M158.55,81c2.67,1.54,7.05,1.54,9.73,0l0,0 c2.68-1.54,2.68-4.07,0-5.62c-2.68-1.54-7.06-1.54-9.73,0C155.87,76.93,155.87,79.46,158.55,81z M185.35,85.24L185.35,85.24 c-2.68-1.55-7.06-1.55-9.73,0c-2.67,1.54-2.67,4.07,0,5.62c2.68,1.54,7.06,1.54,9.73,0C188.03,89.31,188.03,86.79,185.35,85.24z M202.42,95.1L202.42,95.1c-2.68-1.54-7.06-1.54-9.73,0c-2.68,1.55-2.68,4.07,0,5.62c2.68,1.54,7.05,1.54,9.73,0 C205.1,99.17,205.1,96.64,202.42,95.1z M151.21,85.24c-2.68-1.54-7.06-1.54-9.73,0c-2.68,1.54-2.68,4.07,0,5.62 c2.67,1.54,7.05,1.54,9.73,0l0,0C153.89,89.31,153.89,86.78,151.21,85.24z M168.28,95.1L168.28,95.1 c-2.68-1.55-7.06-1.55-9.73,0c-2.67,1.54-2.67,4.07,0,5.62c2.68,1.54,7.06,1.54,9.73,0C170.96,99.17,170.96,96.64,168.28,95.1z" />
            <path class="memory-optimized-1" d="M209.76,71.15L209.76,71.15c2.68,1.55,7.05,1.55,9.73,0l0,0c2.67-1.55,2.67-4.07,0-5.62 c-2.68-1.55-7.05-1.55-9.73,0C207.09,67.07,207.09,69.6,209.76,71.15z M175.62,71.15c2.67,1.55,7.05,1.55,9.73,0 c2.68-1.55,2.68-4.07,0-5.62v0c-2.68-1.54-7.06-1.54-9.73,0C172.94,67.07,172.94,69.6,175.62,71.15z M219.49,104.95 L219.49,104.95c-2.68-1.55-7.06-1.55-9.73,0c-2.68,1.55-2.68,4.07,0,5.62c2.68,1.54,7.05,1.54,9.73,0 C222.17,109.03,222.17,106.5,219.49,104.95z M185.35,104.95L185.35,104.95c-2.68-1.55-7.06-1.55-9.73,0 c-2.68,1.55-2.68,4.07,0,5.62c2.67,1.54,7.05,1.54,9.73,0C188.03,109.02,188.03,106.5,185.35,104.95z M202.43,114.81 L202.43,114.81c-2.68-1.54-7.06-1.54-9.73,0c-2.68,1.54-2.68,4.07,0,5.62c2.67,1.54,7.05,1.54,9.73,0 C205.1,118.88,205.1,116.35,202.43,114.81z"/>
          </g>
        </g>
      </g>
    </g>

    <g class="memory-optimized-light">
      <path class="memory-optimized-0" d="M358.77,229.38l-157.17-90.74c-7.63-4.4-20.11-4.4-27.74,0l-23.11,13.34c10.17,5.87,10.17,15.48,0,21.35 c-10.17,5.87-26.81,5.87-36.98,0l-96.01,54.56c-4.04,2.33-6.27,5.33-6.69,8.4c-0.12,0.72-0.12,1.45,0,2.18 c0.43,3.07,2.65,6.07,6.69,8.4l153.53,88.64c4.04,2.33,9.24,3.62,14.55,3.86c1.25,0.07,2.52,0.07,3.77,0 c5.32-0.25,10.51-1.53,14.55-3.86l94.5-55.43c0,0,0,0,0,0c-10.17-5.87-10.17-15.48,0-21.35c10.17-5.87,26.81-5.87,36.98,0 c0,0,0,0,0,0l23.11-13.34C366.4,240.99,366.4,233.78,358.77,229.38z"/>
      <g>
        <g>
          <path class="memory-optimized-1" d="M357.71,190.19l-5.82,3.36v9.9l5.82-3.36c4.52-2.61,6.78-6.05,6.78-9.49l0,0v-9.9 C364.49,184.14,362.23,187.58,357.71,190.19z"/>
          <path class="memory-optimized-1" d="M17.76,190.19l5.82,3.36v9.9l-5.82-3.36c-4.52-2.61-6.78-6.05-6.78-9.49l0,0v-9.9 C10.98,184.14,13.24,187.58,17.76,190.19z"/>
          <polygon class="memory-optimized-1" points="204.18,278.83 204.18,288.73 357.71,200.09 357.71,190.19"/>
          <polygon class="memory-optimized-1" points="171.3,278.83 171.3,288.73 17.76,200.09 17.76,190.19"/>
          <path class="memory-optimized-1" d="M171.3,278.83v9.9c9.04,5.22,23.84,5.22,32.88,0v-9.9C195.14,284.05,180.34,284.05,171.3,278.83z"/>
        </g>
        <path class="memory-optimized-2" d="M357.71,171.2L204.18,82.56c-9.04-5.22-23.84-5.22-32.88,0L17.76,171.2c-9.04,5.22-9.04,13.76,0,18.98 l153.53,88.64c9.04,5.22,23.84,5.22,32.88,0l153.53-88.64C366.75,184.97,366.75,176.42,357.71,171.2z M343.49,185.69L196.4,270.61 c-4.76,2.75-12.56,2.75-17.32,0L31.99,185.69c-4.76-2.75-4.76-7.25,0-10l147.09-84.92c4.76-2.75,12.56-2.75,17.32,0l147.09,84.92 C348.25,178.44,348.25,182.94,343.49,185.69z"/>
        <path class="memory-optimized-1" d="M31.93,185.65c0.02-0.01,0.04-0.03,0.06-0.04l147.09-84.92c4.76-2.75,12.56-2.75,17.32,0l147.09,84.92 c0.02,0.01,0.04,0.03,0.06,0.04c4.7-2.75,4.68-7.22-0.06-9.96L196.4,90.78c-4.76-2.75-12.56-2.75-17.32,0L31.99,175.69 C27.25,178.43,27.23,182.9,31.93,185.65z"/>
      </g>
      <path class="memory-optimized-3" d="M6.08,128.23l4.91,2.83v-22.68l-4.91,2.84C-2.03,115.9-2.03,123.55,6.08,128.23z M369.4,111.22L202.47,14.85 c-8.1-4.68-21.36-4.68-29.46,0l-24.55,14.17h0c10.8,6.24,10.8,16.44,0,22.68s-28.48,6.24-39.28,0l-67.37,38.9l5.15-2.39 l154.77,95.1l-4.17,4.45l11.56,8.51l-11.56,8.5l-29.45,17v0.01l4.9,2.83c8.1,4.68,21.36,4.68,29.46,0l103.11-59.53c0,0,0,0,0,0 c-10.8-6.24-10.8-16.44,0-22.68c10.8-6.24,28.48-6.24,39.28,0c0,0,0,0,0,0l24.55-14.17C377.5,123.55,377.5,115.9,369.4,111.22z"/>
      <g>
        <g>
          <linearGradient :id="slugs[22]" gradientUnits="userSpaceOnUse" x1="37.9892" y1="140.987" x2="52.7185" y2="115.4752">
            <stop offset="0" style="stop-color:#F7CD66"/>
            <stop offset="0.6117" style="stop-color:#F0C964"/>
            <stop offset="1" style="stop-color:#E8C562"/>
          </linearGradient>
          <polygon class="memory-optimized-35" points="50.26,114.06 20.8,131.07 40.44,142.4 69.9,125.4"/>
          <linearGradient :id="slugs[23]" gradientUnits="userSpaceOnUse" x1="67.4489" y1="157.995" x2="82.178" y2="132.4834">
            <stop offset="0" style="stop-color:#F7CD66"/>
            <stop offset="0.6117" style="stop-color:#F0C964"/>
            <stop offset="1" style="stop-color:#E8C562"/>
          </linearGradient>
          <polygon class="memory-optimized-36" points="79.72,131.07 50.26,148.07 69.9,159.41 99.36,142.41"/>
          <linearGradient :id="slugs[24]" gradientUnits="userSpaceOnUse" x1="96.9084" y1="175.0028" x2="111.6375" y2="149.4912">
            <stop offset="0" style="stop-color:#F7CD66"/>
            <stop offset="0.6117" style="stop-color:#F0C964"/>
            <stop offset="1" style="stop-color:#E8C562"/>
          </linearGradient>
          <polygon class="memory-optimized-37" points="109.18,148.07 79.72,165.08 99.36,176.42 128.82,159.41"/>
          <linearGradient :id="slugs[25]" gradientUnits="userSpaceOnUse" x1="126.3679" y1="192.0105" x2="141.0969" y2="166.499">
            <stop offset="0" style="stop-color:#F7CD66"/>
            <stop offset="0.6117" style="stop-color:#F0C964"/>
            <stop offset="1" style="stop-color:#E8C562"/>
          </linearGradient>
          <polygon class="memory-optimized-38" points="138.64,165.08 109.18,182.09 128.82,193.43 158.28,176.42"/>
          <linearGradient :id="slugs[26]" gradientUnits="userSpaceOnUse" x1="155.8273" y1="209.0183" x2="170.5564" y2="183.5068">
            <stop offset="0" style="stop-color:#F7CD66"/>
            <stop offset="0.6117" style="stop-color:#F0C964"/>
            <stop offset="1" style="stop-color:#E8C562"/>
          </linearGradient>
          <polygon class="memory-optimized-39" points="168.1,182.09 138.64,199.1 158.28,210.43 187.74,193.43"/>
        </g>
        <g>
          <linearGradient :id="slugs[27]" gradientUnits="userSpaceOnUse" x1="23.2595" y1="132.483" x2="37.9887" y2="106.9713">
            <stop offset="0" style="stop-color:#F7CD66"/>
            <stop offset="0.6117" style="stop-color:#F0C964"/>
            <stop offset="1" style="stop-color:#E8C562"/>
          </linearGradient>
          <polygon class="memory-optimized-40" points="40.44,108.39 50.26,114.06 20.8,131.07 10.99,125.4"/>
          <linearGradient :id="slugs[28]" gradientUnits="userSpaceOnUse" x1="52.719" y1="149.4908" x2="67.4482" y2="123.9791">
            <stop offset="0" style="stop-color:#F7CD66"/>
            <stop offset="0.6117" style="stop-color:#F0C964"/>
            <stop offset="1" style="stop-color:#E8C562"/>
          </linearGradient>
          <polygon class="memory-optimized-41" points="69.9,125.4 79.72,131.07 50.26,148.07 40.44,142.4"/>
          <linearGradient :id="slugs[29]" gradientUnits="userSpaceOnUse" x1="82.1785" y1="166.4986" x2="96.9077" y2="140.987">
            <stop offset="0" style="stop-color:#F7CD66"/>
            <stop offset="0.6117" style="stop-color:#F0C964"/>
            <stop offset="1" style="stop-color:#E8C562"/>
          </linearGradient>
          <polygon class="memory-optimized-42" points="99.36,142.4 109.18,148.07 79.72,165.08 69.9,159.41"/>
          <linearGradient :id="slugs[30]" gradientUnits="userSpaceOnUse" x1="111.638" y1="183.5065" x2="126.3672" y2="157.9948">
            <stop offset="0" style="stop-color:#F7CD66"/>
            <stop offset="0.6117" style="stop-color:#F0C964"/>
            <stop offset="1" style="stop-color:#E8C562"/>
          </linearGradient>
          <polygon class="memory-optimized-43" points="128.82,159.41 138.64,165.08 109.18,182.09 99.36,176.42"/>
          <linearGradient :id="slugs[31]" gradientUnits="userSpaceOnUse" x1="141.0974" y1="200.5143" x2="155.8266" y2="175.0025">
            <stop offset="0" style="stop-color:#F7CD66"/>
            <stop offset="0.6117" style="stop-color:#F0C964"/>
            <stop offset="1" style="stop-color:#E8C562"/>
          </linearGradient>
          <polygon class="memory-optimized-44" points="158.28,176.42 168.1,182.09 138.64,199.1 128.82,193.43"/>
          <linearGradient :id="slugs[32]" gradientUnits="userSpaceOnUse" x1="170.5567" y1="217.522" x2="185.2858" y2="192.0105">
            <stop offset="0" style="stop-color:#F7CD66"/>
            <stop offset="0.6117" style="stop-color:#F0C964"/>
            <stop offset="1" style="stop-color:#E8C562"/>
          </linearGradient>
          <polygon class="memory-optimized-45" points="187.74,193.43 197.56,199.1 168.1,216.1 158.28,210.44"/>
        </g>
      </g>
      <g>
        <linearGradient :id="slugs[33]" gradientUnits="userSpaceOnUse" x1="29.3962" y1="136.0264" x2="44.1259" y2="110.5139">
          <stop offset="0" style="stop-color:#CC973A"/>
          <stop offset="0.2076" style="stop-color:#C59139"/>
          <stop offset="0.5179" style="stop-color:#B08236"/>
          <stop offset="0.8909" style="stop-color:#8F6931"/>
          <stop offset="1" style="stop-color:#84612F"/>
        </linearGradient>
        <polygon class="memory-optimized-46" points="52.72,115.47 50.26,114.06 20.8,131.07 23.26,132.48"/>
        <linearGradient :id="slugs[34]" gradientUnits="userSpaceOnUse" x1="46.5809" y1="145.9482" x2="61.3105" y2="120.4357">
          <stop offset="0" style="stop-color:#CC973A"/>
          <stop offset="0.2076" style="stop-color:#C59139"/>
          <stop offset="0.5179" style="stop-color:#B08236"/>
          <stop offset="0.8909" style="stop-color:#8F6931"/>
          <stop offset="1" style="stop-color:#84612F"/>
        </linearGradient>
        <polygon class="memory-optimized-47" points="69.9,125.4 67.45,123.98 37.99,140.99 40.44,142.41"/>
        <linearGradient :id="slugs[35]" gradientUnits="userSpaceOnUse" x1="58.8558" y1="153.0351" x2="73.5855" y2="127.5225">
          <stop offset="0" style="stop-color:#CC973A"/>
          <stop offset="0.2076" style="stop-color:#C59139"/>
          <stop offset="0.5179" style="stop-color:#B08236"/>
          <stop offset="0.8909" style="stop-color:#8F6931"/>
          <stop offset="1" style="stop-color:#84612F"/>
        </linearGradient>
        <polygon class="memory-optimized-48" points="82.18,132.48 79.72,131.07 50.26,148.07 52.72,149.49"/>
        <linearGradient :id="slugs[36]" gradientUnits="userSpaceOnUse" x1="76.0403" y1="162.9571" x2="90.77" y2="137.4445">
          <stop offset="0" style="stop-color:#CC973A"/>
          <stop offset="0.2076" style="stop-color:#C59139"/>
          <stop offset="0.5179" style="stop-color:#B08236"/>
          <stop offset="0.8909" style="stop-color:#8F6931"/>
          <stop offset="1" style="stop-color:#84612F"/>
        </linearGradient>
        <polygon class="memory-optimized-49" points="99.36,142.41 96.91,140.99 67.45,158 69.9,159.41"/>
        <linearGradient :id="slugs[37]" gradientUnits="userSpaceOnUse" x1="88.3152" y1="170.044" x2="103.045" y2="144.5312">
          <stop offset="0" style="stop-color:#CC973A"/>
          <stop offset="0.2076" style="stop-color:#C59139"/>
          <stop offset="0.5179" style="stop-color:#B08236"/>
          <stop offset="0.8909" style="stop-color:#8F6931"/>
          <stop offset="1" style="stop-color:#84612F"/>
        </linearGradient>
        <polygon class="memory-optimized-50" points="111.64,149.49 109.18,148.07 79.72,165.08 82.18,166.5"/>
        <linearGradient :id="slugs[38]" gradientUnits="userSpaceOnUse" x1="105.4998" y1="179.9659" x2="120.2295" y2="154.4533">
          <stop offset="0" style="stop-color:#CC973A"/>
          <stop offset="0.2076" style="stop-color:#C59139"/>
          <stop offset="0.5179" style="stop-color:#B08236"/>
          <stop offset="0.8909" style="stop-color:#8F6931"/>
          <stop offset="1" style="stop-color:#84612F"/>
        </linearGradient>
        <polygon class="memory-optimized-51" points="128.82,159.41 126.37,158 96.91,175 99.36,176.42"/>
        <linearGradient :id="slugs[39]" gradientUnits="userSpaceOnUse" x1="117.7747" y1="187.0528" x2="132.5045" y2="161.54">
          <stop offset="0" style="stop-color:#CC973A"/>
          <stop offset="0.2076" style="stop-color:#C59139"/>
          <stop offset="0.5179" style="stop-color:#B08236"/>
          <stop offset="0.8909" style="stop-color:#8F6931"/>
          <stop offset="1" style="stop-color:#84612F"/>
        </linearGradient>
        <polygon class="memory-optimized-52" points="141.1,166.5 138.64,165.08 109.18,182.09 111.64,183.51"/>
        <linearGradient :id="slugs[40]" gradientUnits="userSpaceOnUse" x1="134.9593" y1="196.9746" x2="149.6889" y2="171.4622">
          <stop offset="0" style="stop-color:#CC973A"/>
          <stop offset="0.2076" style="stop-color:#C59139"/>
          <stop offset="0.5179" style="stop-color:#B08236"/>
          <stop offset="0.8909" style="stop-color:#8F6931"/>
          <stop offset="1" style="stop-color:#84612F"/>
        </linearGradient>
        <polygon class="memory-optimized-53" points="158.28,176.42 155.83,175.01 126.37,192.01 128.82,193.43"/>
        <linearGradient :id="slugs[41]" gradientUnits="userSpaceOnUse" x1="147.2341" y1="204.0615" x2="161.9639" y2="178.5488">
          <stop offset="0" style="stop-color:#CC973A"/>
          <stop offset="0.2076" style="stop-color:#C59139"/>
          <stop offset="0.5179" style="stop-color:#B08236"/>
          <stop offset="0.8909" style="stop-color:#8F6931"/>
          <stop offset="1" style="stop-color:#84612F"/>
        </linearGradient>
        <polygon class="memory-optimized-54" points="170.56,183.51 168.1,182.09 138.64,199.1 141.1,200.52"/>
        <linearGradient :id="slugs[42]" gradientUnits="userSpaceOnUse" x1="164.4187" y1="213.9834" x2="179.1484" y2="188.4709">
          <stop offset="0" style="stop-color:#CC973A"/>
          <stop offset="0.2076" style="stop-color:#C59139"/>
          <stop offset="0.5179" style="stop-color:#B08236"/>
          <stop offset="0.8909" style="stop-color:#8F6931"/>
          <stop offset="1" style="stop-color:#84612F"/>
        </linearGradient>
        <polygon class="memory-optimized-55" points="187.74,193.43 185.29,192.01 155.83,209.02 158.28,210.44"/>
      </g>
      <path class="memory-optimized-25" d="M128.82,193.43l0,5.67l9.82,5.67l0-5.67L128.82,193.43z M99.36,176.42l0,5.67l9.82,5.67l0-5.67L99.36,176.42z M158.28,210.44l0,5.67l9.82,5.67v-5.67L158.28,210.44z M10.99,131.07l9.82,5.67v-5.67l-9.82-5.67V131.07z M69.9,165.08l9.82,5.67 v-5.67l-9.82-5.67V165.08z M40.44,142.4l0,5.67l9.82,5.67l0-5.67L40.44,142.4z"/>
      <path class="memory-optimized-25" d="M158.28,210.43l-19.64-11.34l0,5.67l19.64,11.34L158.28,210.43z M109.18,182.09l0,5.67l19.64,11.34l0-5.67 L109.18,182.09z M79.72,165.08l0,5.67l19.64,11.34l0-5.67L79.72,165.08z M50.26,148.08l0,5.67l19.64,11.34l0-5.67L50.26,148.08z M20.8,131.07l0,5.67l19.64,11.34l0-5.67L20.8,131.07z"/>
      <path class="memory-optimized-25" d="M138.65,204.77l2.46,1.42v-5.67l-2.46-1.42V204.77z M126.37,197.68l2.46,1.42v-5.67l-2.46-1.42L126.37,197.68 z M109.19,187.76l2.46,1.42v-5.67l-2.46-1.42L109.19,187.76z M155.83,214.69l2.46,1.42v-5.68l-2.46-1.42L155.83,214.69z M96.91,180.67l2.46,1.42v-5.67L96.91,175L96.91,180.67z M20.8,136.74l2.46,1.42v-5.67l-2.46-1.42L20.8,136.74z M37.99,146.66 l2.46,1.42v-5.67l-2.46-1.42L37.99,146.66z M79.72,170.75l2.46,1.42v-5.67l-2.46-1.42V170.75z M67.45,163.67l2.46,1.42v-5.67 l-2.46-1.42L67.45,163.67z M50.26,153.74l2.46,1.42v-5.67l-2.46-1.42V153.74z"/>
      <path class="memory-optimized-56" d="M369.4,99.89L202.47,3.51c-8.1-4.68-21.36-4.68-29.46,0l-24.55,14.17h0c10.8,6.24,10.8,16.44,0,22.68 c-10.8,6.24-28.48,6.24-39.28,0L6.08,99.89c-8.1,4.68-8.1,12.33,0,17.01l4.91,2.83l29.46-17.01l157.11,90.71l-29.46,17.01 l4.91,2.83c8.1,4.68,21.36,4.68,29.46,0l103.11-59.53c0,0,0,0,0,0c-10.8-6.24-10.8-16.44,0-22.68c10.8-6.24,28.48-6.24,39.28,0 c0,0,0,0,0,0l24.55-14.17C377.5,112.22,377.5,104.56,369.4,99.89z"/>
      <polygon class="memory-optimized-57" points="40.44,102.72 40.44,108.39 10.99,125.4 10.99,119.73"/>
      <polygon class="memory-optimized-58" points="40.44,102.72 40.44,108.39 192.65,196.26 197.56,193.43"/>
      <g>
        <polygon class="memory-optimized-59" points="305.57,153.74 305.57,165.08 202.47,224.61 202.47,213.27"/>
        <path class="memory-optimized-59" d="M6.08,116.89c-3.87-2.23-5.87-5.15-6.04-8.09v10.26l0.02,0.03c-0.3,3.29,1.69,6.64,6.02,9.14l4.91,2.83 v-11.34L6.08,116.89z"/>
        <path class="memory-optimized-59" d="M202.47,213.27c-8.1,4.68-21.36,4.68-29.46,0l-4.9-2.83l-0.01,0v11.33l4.91,2.83 c8.1,4.68,21.36,4.68,29.46,0V213.27z"/>
        <path class="memory-optimized-59" d="M375.42,119.09l0.02-0.03V108.8c-0.17,2.94-2.17,5.85-6.04,8.09v11.34 C373.73,125.73,375.72,122.38,375.42,119.09z"/>
        <polygon class="memory-optimized-59" points="344.85,131.06 369.4,116.89 369.4,128.23 344.85,142.4"/>
        <path class="memory-optimized-59" d="M154.77,34.69c3.72-5.72,1.62-12.43-6.31-17.01h0v11.34h0C151.33,30.68,153.43,32.62,154.77,34.69z"/>
        <path class="memory-optimized-60" d="M305.57,131.06c-7.93,4.58-10.03,11.3-6.31,17.01c1.34-2.07,3.44-4.02,6.31-5.68 c10.8-6.24,28.48-6.24,39.28,0v-11.34C334.05,124.83,316.37,124.83,305.57,131.06z"/>
      </g>
      <g>
        <g>
          <path class="memory-optimized-61" d="M207.38,187.76c-5.4-3.12-5.4-8.22,0-11.34c5.4-3.12,14.24-3.12,19.64,0c5.4,3.12,5.4,8.22,0,11.34 C221.62,190.88,212.78,190.88,207.38,187.76z"/>
        </g>
        <g>
          <path class="memory-optimized-61" d="M51.63,96.26c-5.4-3.12-5.4-8.22,0-11.34c5.4-3.12,14.24-3.12,19.64,0c5.4,3.12,5.4,8.22,0,11.34 C65.87,99.38,57.03,99.38,51.63,96.26z"/>
        </g>
      </g>
      <path class="memory-optimized-62" d="M301.61,95.85l-91.74-52.97c-6.77-3.91-17.86-3.91-24.63,0L93.5,95.85c-6.77,3.91-6.77,10.31,0,14.22 l91.74,52.96h24.63l91.74-52.96C308.38,106.16,308.38,99.76,301.61,95.85z"/>
      <g>
        <g>
          <path class="memory-optimized-33" d="M288.8,94.29L288.8,94.29L288.8,94.29l-80.45,46.45v14.73l80.45-46.45c2.97-1.72,4.45-3.98,4.46-6.24V88.05 C293.26,90.31,291.77,92.57,288.8,94.29z"/>
          <path class="memory-optimized-33" d="M186.76,140.73l-80.45-46.45v0l0,0c-2.97-1.71-4.45-3.97-4.45-6.24v14.73c0,2.26,1.49,4.52,4.45,6.24 l80.45,46.45V140.73z"/>
          <g>
            <path class="memory-optimized-63" d="M186.76,140.73c5.94,3.43,15.66,3.43,21.6,0l80.45-46.45c5.94-3.43,5.94-9.04,0-12.47l-80.45-46.45 c-5.94-3.43-15.66-3.43-21.6,0l-80.45,46.45c-5.94,3.43-5.94,9.04,0,12.47L186.76,140.73z"/>
          </g>
          <rect x="186.76" y="140.73" class="memory-optimized-64" width="21.6" height="14.73"/>
          <g>
            <path class="memory-optimized-2" d="M192.69,61.29c2.68,1.55,7.06,1.55,9.73,0v0c2.68-1.54,2.67-4.07,0-5.62v0c-2.68-1.55-7.06-1.55-9.73,0 C190.01,57.22,190.01,59.75,192.69,61.29z M226.83,81c2.67,1.54,7.05,1.54,9.73,0v0c2.68-1.54,2.68-4.07,0-5.62 c-2.67-1.54-7.05-1.54-9.73,0C224.15,76.93,224.15,79.46,226.83,81z M253.63,85.24c-2.67-1.54-7.05-1.54-9.73,0 c-2.68,1.54-2.68,4.07,0,5.62c2.68,1.55,7.05,1.55,9.73,0v0C256.31,89.31,256.31,86.78,253.63,85.24z M192.69,81 c2.68,1.55,7.05,1.55,9.73,0v0c2.68-1.54,2.67-4.07,0-5.62v0c-2.68-1.54-7.06-1.54-9.73,0C190.01,76.93,190.01,79.46,192.69,81z M219.49,85.24L219.49,85.24c-2.68-1.55-7.05-1.55-9.73,0c-2.67,1.54-2.67,4.07,0,5.62l0,0c2.68,1.54,7.05,1.54,9.73,0 C222.17,89.31,222.17,86.79,219.49,85.24z M236.56,95.1L236.56,95.1c-2.68-1.55-7.05-1.55-9.73,0c-2.67,1.54-2.67,4.07,0,5.62 l0,0c2.68,1.54,7.05,1.54,9.73,0C239.24,99.17,239.24,96.64,236.56,95.1z M158.55,81c2.67,1.54,7.05,1.54,9.73,0l0,0 c2.68-1.54,2.68-4.07,0-5.62c-2.68-1.54-7.06-1.54-9.73,0C155.87,76.93,155.87,79.46,158.55,81z M185.35,85.24L185.35,85.24 c-2.68-1.55-7.06-1.55-9.73,0c-2.67,1.54-2.67,4.07,0,5.62c2.68,1.54,7.06,1.54,9.73,0C188.03,89.31,188.03,86.79,185.35,85.24z M202.42,95.1L202.42,95.1c-2.68-1.54-7.06-1.54-9.73,0c-2.68,1.55-2.68,4.07,0,5.62c2.68,1.54,7.05,1.54,9.73,0 C205.1,99.17,205.1,96.64,202.42,95.1z M151.21,85.24c-2.68-1.54-7.06-1.54-9.73,0c-2.68,1.54-2.68,4.07,0,5.62 c2.67,1.54,7.05,1.54,9.73,0l0,0C153.89,89.31,153.89,86.78,151.21,85.24z M168.28,95.1L168.28,95.1 c-2.68-1.55-7.06-1.55-9.73,0c-2.67,1.54-2.67,4.07,0,5.62c2.68,1.54,7.06,1.54,9.73,0C170.96,99.17,170.96,96.64,168.28,95.1z"/>
            <path class="memory-optimized-1" d="M209.76,71.15L209.76,71.15c2.68,1.55,7.05,1.55,9.73,0l0,0c2.67-1.55,2.67-4.07,0-5.62 c-2.68-1.55-7.05-1.55-9.73,0C207.09,67.07,207.09,69.6,209.76,71.15z M175.62,71.15c2.67,1.55,7.05,1.55,9.73,0 c2.68-1.55,2.68-4.07,0-5.62v0c-2.68-1.54-7.06-1.54-9.73,0C172.94,67.07,172.94,69.6,175.62,71.15z M219.49,104.95 L219.49,104.95c-2.68-1.55-7.06-1.55-9.73,0c-2.68,1.55-2.68,4.07,0,5.62c2.68,1.54,7.05,1.54,9.73,0 C222.17,109.03,222.17,106.5,219.49,104.95z M185.35,104.95L185.35,104.95c-2.68-1.55-7.06-1.55-9.73,0 c-2.68,1.55-2.68,4.07,0,5.62c2.67,1.54,7.05,1.54,9.73,0C188.03,109.02,188.03,106.5,185.35,104.95z M202.43,114.81 L202.43,114.81c-2.68-1.54-7.06-1.54-9.73,0c-2.68,1.54-2.68,4.07,0,5.62c2.67,1.54,7.05,1.54,9.73,0 C205.1,118.88,205.1,116.35,202.43,114.81z"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>

export default {
  //
}

</script>
