<template>
    <svg id="Layer_1" class="fill-segment-light" enable-background="new 0 0 66 66" height="48" viewBox="0 0 66 66" width="48" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="m44.35 62.75h-2.35l-1.33-24.8c-.02-.41-.4-.73-.79-.71-.41.02-.73.38-.71.79l1.33 24.72h-5.3l1.87-34.75c.25.05.51.08.78.08s.53-.03.78-.08l.37 6.81c.02.4.35.71.75.71h.04c.41-.02.73-.38.71-.79l-.36-6.64.61 1.12c.08.15.21.27.37.33l14.33 5.87c.19.08.38.11.56.11.49 0 .95-.25 1.22-.71.37-.63.23-1.39-.34-1.84l-12.06-9.7c-.13-.11-.3-.17-.47-.17h-2.95c-.25-.74-.72-1.38-1.34-1.83l1.33-2.25c.09-.15.12-.32.1-.49l-2.23-15.32c-.1-.72-.69-1.23-1.42-1.23s-1.31.5-1.42 1.23l-2.23 15.32c-.02.17.01.34.1.49l1.33 2.25c-.62.45-1.09 1.09-1.34 1.83h-2.95c-.17 0-.34.06-.47.17l-12.07 9.7c-.57.46-.71 1.22-.33 1.84.27.46.73.71 1.22.71.19 0 .38-.04.56-.11l14.33-5.87c.16-.06.29-.18.37-.33l.62-1.12-1.87 34.66h-2.36c-.41 0-.75.34-.75.75s.34.75.75.75h13.01c.41 0 .75-.34.75-.75s-.33-.75-.75-.75zm-7.65-36.49c-.17-.1-.32-.21-.46-.34-.01-.01-.01-.01-.02-.02-.4-.41-.65-.97-.65-1.59 0-.15.02-.3.05-.45.21-1.04 1.13-1.82 2.23-1.82s2.02.78 2.23 1.82c.03.15.05.3.05.45 0 .62-.25 1.18-.65 1.59-.01.01-.01.01-.02.02-.41.41-.98.67-1.61.67-.42-.01-.81-.13-1.15-.33zm18.89 7.59-13.65-5.59-.99-1.8c.37-.53.59-1.16.64-1.84h2.51zm-19.86-15.37 2.12-14.6 2.12 14.6-1.28 2.15c-.27-.06-.55-.1-.85-.1s-.57.04-.85.1zm-1.97 9.77-13.66 5.6 11.5-9.25h2.51c.05.68.27 1.31.64 1.84z"/>
            <path d="m19.34 40.24c-.13-.11-.3-.17-.47-.17h-1.71c-.16-.38-.41-.71-.72-.98l.74-1.26c.09-.15.12-.32.1-.49l-1.44-9.9c-.09-.59-.58-1.02-1.18-1.02s-1.09.43-1.18 1.02l-1.44 9.9c-.02.17.01.34.1.49l.74 1.26c-.31.27-.56.6-.72.98h-1.71c-.17 0-.34.06-.47.17l-7.78 6.27c-.47.37-.58 1.02-.28 1.53.22.38.61.59 1.02.59.15 0 .31-.03.46-.09l9.25-3.79c.07-.03.14-.07.2-.13l-.27 4.34c-.03.41.29.77.7.79.4.03.77-.29.79-.7l.32-5.24c.05.01.1 0 .15 0 .04 0 .07.01.11.01s.08-.01.11-.01c.05 0 .1.01.15 0l1.16 18.94h-2.85l.7-11.48c.03-.41-.29-.77-.7-.79-.42-.03-.77.29-.79.7l-.71 11.57h-1.28c-.41 0-.75.34-.75.75s.34.75.75.75h8.41c.41 0 .75-.34.75-.75s-.34-.75-.75-.75h-1.25l-1.11-18.12c.06.05.13.09.2.12l9.26 3.79c.15.06.3.09.46.09.41 0 .8-.21 1.02-.59.3-.51.19-1.16-.28-1.53zm-4.28 2c-.12.04-.25.08-.39.08s-.27-.04-.39-.08c-.05-.02-.1-.04-.15-.06-.39-.2-.67-.59-.67-1.06 0-.66.54-1.2 1.2-1.2s1.2.54 1.2 1.2c0 .47-.28.87-.67 1.06-.04.03-.08.05-.13.06zm-1.49-4.93 1.09-7.53 1.09 7.53-.68 1.15c-.13-.02-.27-.04-.41-.04s-.28.02-.41.04zm-8.78 9.04 5.93-4.77h1.29c.06.34.18.65.35.93l-.52.95zm12.71-2.89-.52-.95c.17-.28.29-.59.35-.93h1.29l5.93 4.77z"/>
            <path d="m18.27 15.92c.4-.79.61-1.67.61-2.55 0-3.11-2.53-5.63-5.63-5.63-3.11 0-5.63 2.53-5.63 5.63 0 3.11 2.53 5.63 5.63 5.63 1.19 0 2.32-.37 3.28-1.06.34-.24.41-.71.17-1.05s-.71-.41-1.05-.17c-.71.51-1.54.78-2.41.78-2.28 0-4.13-1.85-4.13-4.13s1.85-4.13 4.13-4.13 4.13 1.85 4.13 4.13c0 .66-.15 1.29-.45 1.87-.19.37-.04.82.33 1.01.38.19.83.04 1.02-.33z"/>
            <path d="m13.26 6.43c.41 0 .74-.33.74-.74v-3.2c0-.41-.33-.74-.74-.74s-.74.33-.74.74v3.2c0 .41.33.74.74.74z"/>
            <path d="m9.63 6.55c.12.29.39.46.69.46.09 0 .19-.02.28-.06.38-.16.56-.59.4-.97l-.7-1.68c-.16-.38-.59-.56-.97-.4s-.56.59-.4.97z"/>
            <path d="m4.18 10.41 1.69.7c.09.04.19.06.28.06.29 0 .57-.17.69-.46.16-.38-.02-.81-.4-.97l-1.69-.7c-.38-.16-.81.02-.97.4s.02.81.4.97z"/>
            <path d="m6.83 16.02c-.16-.38-.59-.56-.97-.4l-1.69.7c-.38.16-.56.59-.4.97.12.29.39.46.68.46.09 0 .19-.02.28-.06l1.69-.7c.39-.16.57-.59.41-.97z"/>
            <path d="m9.61 20.19-.7 1.69c-.16.38.02.81.4.97.1.03.19.05.29.05.29 0 .57-.17.68-.46l.7-1.69c.16-.38-.02-.81-.4-.97-.38-.15-.81.03-.97.41z"/>
            <path d="m15.89 19.79c-.38.16-.56.59-.4.97l.7 1.69c.12.29.39.46.69.46.09 0 .19-.02.28-.06.38-.16.56-.59.4-.97l-.7-1.69c-.15-.37-.59-.55-.97-.4z"/>
            <path d="m22.03 17.76c.29 0 .57-.17.68-.46.16-.38-.02-.81-.4-.97l-1.69-.7c-.38-.16-.81.02-.97.4s.02.81.4.97l1.69.7c.1.05.2.06.29.06z"/>
            <path d="m19.67 10.73c.12.29.39.46.69.46.09 0 .19-.02.28-.06l1.69-.7c.38-.16.56-.59.4-.97s-.59-.56-.97-.4l-1.69.7c-.38.15-.56.59-.4.97z"/>
            <path d="m15.91 6.96c.1.04.19.06.29.06.29 0 .57-.17.68-.46l.7-1.69c.16-.38-.02-.81-.4-.97s-.81.02-.97.4l-.7 1.69c-.15.38.02.82.4.97z"/>
            <path d="m7.3 8.46c.14.14.33.21.52.21s.38-.07.52-.22c.29-.29.29-.76 0-1.05l-2.26-2.25c-.29-.29-.76-.29-1.05 0s-.29.76 0 1.05z"/>
            <path d="m2.37 14.1h3.2c.41 0 .74-.33.74-.74s-.33-.74-.74-.74h-3.2c-.41 0-.74.33-.74.74s.33.74.74.74z"/>
            <path d="m5.55 21.8c.19 0 .38-.07.52-.22l2.26-2.26c.29-.29.29-.76 0-1.05s-.76-.29-1.05 0l-2.26 2.26c-.29.29-.29.76 0 1.05.15.15.34.22.53.22z"/>
            <path d="m12.5 21.06v3.2c0 .41.33.74.74.74s.74-.33.74-.74v-3.2c0-.41-.33-.74-.74-.74s-.74.33-.74.74z"/>
            <path d="m20.41 21.6c.14.14.33.22.52.22s.38-.07.52-.22c.29-.29.29-.76 0-1.05l-2.26-2.26c-.29-.29-.76-.29-1.05 0s-.29.76 0 1.05z"/>
            <path d="m20.19 13.38c0 .41.33.74.74.74h3.2c.41 0 .74-.33.74-.74s-.33-.74-.74-.74h-3.2c-.41 0-.74.33-.74.74z"/>
            <path d="m18.69 8.69c.19 0 .38-.07.52-.22l2.26-2.26c.29-.29.29-.76 0-1.05s-.76-.29-1.05 0l-2.26 2.26c-.29.29-.29.76 0 1.05.15.15.34.22.53.22z"/>
            <path d="m64.36 58.73-1.97-13.58c-.12-.85-.86-1.48-1.71-1.48h-13.73c-.85 0-1.59.64-1.71 1.48l-1.97 13.58c-.07.5.08 1 .4 1.38s.81.6 1.31.6h4.3s0 0 0 0h1.7v2.04h-2.37c-.41 0-.75.34-.75.75s.34.75.75.75h10.39c.41 0 .75-.34.75-.75s-.34-.75-.75-.75h-2.36v-2.04h1.7s0 0 0 0h1.14c.41 0 .75-.34.75-.75s-.34-.75-.75-.75h-.38v-2.27h3.49l.29 2.01c.01.09-.03.15-.05.18-.03.03-.08.08-.18.08h-.85c-.41 0-.75.34-.75.75s.34.75.75.75h.84c.5 0 .98-.22 1.31-.6s.48-.89.41-1.38zm-14.33.48v-2.27h3.03v2.27zm0-3.78v-2.5h3.03v2.5zm3.03-7.99h-3.03v-2.28h3.03zm1.5-2.28h3.04v2.28h-3.04zm-1.5 3.78v2.5h-3.03v-2.5zm-4.53 2.49h-2.7l.36-2.5h2.33v2.5zm-2.91 1.5h2.91v2.5h-3.27zm8.94 0h3.04v2.5h-3.04zm0-1.5v-2.5h3.04v2.5zm4.54-2.49h2.33l.36 2.5h-2.69zm1.8-3.58.3 2.08h-2.1v-2.28h1.58c.11 0 .21.09.22.2zm-13.95-.2h1.58v2.28h-2.12l.3-2.08c.02-.11.12-.2.24-.2zm-2.15 13.97c-.03-.03-.07-.09-.05-.19l.29-2.01h3.49v2.27h-3.55c-.09.01-.15-.04-.18-.07zm7.68 3.62v-2.04h2.66v2.04zm5.12-3.54h-3.04v-2.27h3.04zm1.5-3.78v-2.5h2.9l.36 2.5z"/>
            <path d="m49.13 8.51h11.73c.41 0 .75-.34.75-.75s-.34-.75-.75-.75h-11.73c-.97 0-1.77-.79-1.77-1.77 0-.97.79-1.77 1.77-1.77.41 0 .75-.34.75-.75s-.34-.75-.75-.75c-1.8 0-3.27 1.46-3.27 3.27s1.47 3.27 3.27 3.27z"/>
            <path d="m49.61 14c0 1.8 1.46 3.27 3.26 3.27.41 0 .75-.34.75-.75s-.34-.75-.75-.75c-.97 0-1.76-.79-1.76-1.77 0-.97.79-1.77 1.76-1.77h9.73c.41 0 .75-.34.75-.75s-.34-.75-.75-.75h-9.73c-1.8 0-3.26 1.47-3.26 3.27z"/>
        </g>
    </svg>
</template>

<script>
  export default {
    //
  }
</script>
