<template>
  <svg width="43" height="43" class="fill-segment-light"  viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg">
    <path d="M41.7646 7.43104C41.9112 6.70964 41.2029 6.10803 40.5139 6.37065L39.4669 6.77046C36.3023 2.79924 30.0038 3.85049 28.2837 8.60595C28.1598 8.94829 28.3369 9.32612 28.6793 9.45005C29.0214 9.57388 29.3995 9.39688 29.5234 9.05437C30.825 5.45633 35.4971 4.44727 38.1554 7.27135L37.4456 7.5424C36.7577 7.80501 36.6293 8.72592 37.2199 9.16652L39.6781 11.0006C40.2289 11.4112 41.0182 11.1085 41.1548 10.4369L41.7646 7.43104ZM39.9826 9.58294L38.5657 8.5258L40.3342 7.85045L39.9826 9.58294Z"/>
    <path d="M41.7646 7.43104C41.9112 6.70964 41.2029 6.10803 40.5139 6.37065L39.4669 6.77046C36.3023 2.79924 30.0038 3.85049 28.2837 8.60595C28.1598 8.94829 28.3369 9.32612 28.6793 9.45005C29.0214 9.57388 29.3995 9.39688 29.5234 9.05437C30.825 5.45633 35.4971 4.44727 38.1554 7.27135L37.4456 7.5424C36.7577 7.80501 36.6293 8.72592 37.2199 9.16652L39.6781 11.0006C40.2289 11.4112 41.0182 11.1085 41.1548 10.4369L41.7646 7.43104ZM39.9826 9.58294L38.5657 8.5258L40.3342 7.85045L39.9826 9.58294Z"/>
    <path d="M40.0994 12.1762C39.757 12.0527 39.3792 12.2296 39.2554 12.5722C37.932 16.236 33.1461 17.1835 30.5375 14.2683L31.2368 14.0012C31.9244 13.7385 32.0533 12.8176 31.4626 12.3771C31.4625 12.3771 29.0041 10.5428 29.004 10.5428C28.4539 10.1323 27.6641 10.4349 27.5277 11.1068L26.9177 14.1123C26.771 14.8341 27.4803 15.4357 28.1684 15.1728L29.2381 14.7643C32.3573 18.8357 38.7582 17.8291 40.4953 13.02C40.619 12.6778 40.4418 12.3 40.0994 12.1762ZM28.3481 13.6932L28.6998 11.9606L30.1166 13.0178L28.3481 13.6932Z"/>
    <path d="M40.0994 12.1762C39.757 12.0527 39.3792 12.2296 39.2554 12.5722C37.932 16.236 33.1461 17.1835 30.5375 14.2683L31.2368 14.0012C31.9244 13.7385 32.0533 12.8176 31.4626 12.3771C31.4625 12.3771 29.0041 10.5428 29.004 10.5428C28.4539 10.1323 27.6641 10.4349 27.5277 11.1068L26.9177 14.1123C26.771 14.8341 27.4803 15.4357 28.1684 15.1728L29.2381 14.7643C32.3573 18.8357 38.7582 17.8291 40.4953 13.02C40.619 12.6778 40.4418 12.3 40.0994 12.1762ZM28.3481 13.6932L28.6998 11.9606L30.1166 13.0178L28.3481 13.6932Z"/>
    <path d="M29.1051 1.61515C18.4799 -1.43217 0 0.642558 0 6.13164V38.8806C0 42.7312 9.02885 44.8088 17.5242 44.8088C26.0196 44.8088 35.0484 42.7311 35.0484 38.8806C35.0484 37.4713 35.0484 30.578 35.0484 29.3134C35.0484 28.9493 34.7533 28.6542 34.3892 28.6542C34.0251 28.6542 33.73 28.9493 33.73 29.3134V30.6934C33.73 32.8004 27.1994 35.3033 17.5242 35.3033C7.84927 35.3033 1.31835 32.8004 1.31835 30.6934V24.8784C7.02241 29.5978 28.0282 29.596 33.73 24.8784V26.2373C33.73 26.6014 34.025 26.8964 34.3891 26.8964C34.7533 26.8964 35.0483 26.6014 35.0483 26.2373C35.0483 25.2787 35.0483 22.4153 35.0483 21.4042C36.6671 21.3036 38.2461 20.834 39.6509 20.0302C39.9669 19.8494 40.0765 19.4467 39.8957 19.1307C39.7149 18.8148 39.3122 18.7051 38.9962 18.886C32.8334 22.4118 25.1856 17.9339 25.0982 10.9325C25.0333 5.75011 29.2304 1.52172 34.3891 1.52172C39.513 1.52172 43.6816 5.69034 43.6816 10.8142C43.6816 13.1452 42.8146 15.3745 41.2403 17.0916C40.9943 17.3599 41.0123 17.777 41.2808 18.023C41.549 18.269 41.9659 18.2509 42.2121 17.9825C44.0099 16.0215 45.0001 13.4759 45.0001 10.8142C45 2.67844 36.1549 -2.4502 29.1051 1.61515ZM33.73 33.0667V38.8806C33.73 40.9876 27.1993 43.4905 17.5241 43.4905C7.84927 43.4906 1.31835 40.9876 1.31835 38.8806V33.0667C7.02267 37.7843 28.027 37.7833 33.73 33.0667ZM27.8411 19.1649C29.5308 20.495 31.5722 21.2735 33.73 21.405V22.5061C33.73 24.6136 27.1976 27.1161 17.5241 27.1161C7.85068 27.1161 1.31835 24.6136 1.31835 22.5061V16.6923C5.48354 20.1371 18.7508 21.3163 27.8411 19.1649ZM26.6536 18.0761C15.2734 20.4109 1.31835 17.6108 1.31835 14.3189V8.50493C5.26179 11.7676 16.2638 12.6225 23.8143 11.6863C24.0077 14.0811 25 16.3172 26.6536 18.0761ZM23.7878 10.3592C12.5043 11.7965 1.31835 9.01574 1.31835 6.13164C1.31835 2.66693 16.4903 -0.102924 27.6692 2.59952C25.3801 4.46392 23.9203 7.23263 23.7878 10.3592Z" />
    <path d="M29.1051 1.61515C18.4799 -1.43217 0 0.642558 0 6.13164V38.8806C0 42.7312 9.02885 44.8088 17.5242 44.8088C26.0196 44.8088 35.0484 42.7311 35.0484 38.8806C35.0484 37.4713 35.0484 30.578 35.0484 29.3134C35.0484 28.9493 34.7533 28.6542 34.3892 28.6542C34.0251 28.6542 33.73 28.9493 33.73 29.3134V30.6934C33.73 32.8004 27.1994 35.3033 17.5242 35.3033C7.84927 35.3033 1.31835 32.8004 1.31835 30.6934V24.8784C7.02241 29.5978 28.0282 29.596 33.73 24.8784V26.2373C33.73 26.6014 34.025 26.8964 34.3891 26.8964C34.7533 26.8964 35.0483 26.6014 35.0483 26.2373C35.0483 25.2787 35.0483 22.4153 35.0483 21.4042C36.6671 21.3036 38.2461 20.834 39.6509 20.0302C39.9669 19.8494 40.0765 19.4467 39.8957 19.1307C39.7149 18.8148 39.3122 18.7051 38.9962 18.886C32.8334 22.4118 25.1856 17.9339 25.0982 10.9325C25.0333 5.75011 29.2304 1.52172 34.3891 1.52172C39.513 1.52172 43.6816 5.69034 43.6816 10.8142C43.6816 13.1452 42.8146 15.3745 41.2403 17.0916C40.9943 17.3599 41.0123 17.777 41.2808 18.023C41.549 18.269 41.9659 18.2509 42.2121 17.9825C44.0099 16.0215 45.0001 13.4759 45.0001 10.8142C45 2.67844 36.1549 -2.4502 29.1051 1.61515ZM33.73 33.0667V38.8806C33.73 40.9876 27.1993 43.4905 17.5241 43.4905C7.84927 43.4906 1.31835 40.9876 1.31835 38.8806V33.0667C7.02267 37.7843 28.027 37.7833 33.73 33.0667ZM27.8411 19.1649C29.5308 20.495 31.5722 21.2735 33.73 21.405V22.5061C33.73 24.6136 27.1976 27.1161 17.5241 27.1161C7.85068 27.1161 1.31835 24.6136 1.31835 22.5061V16.6923C5.48354 20.1371 18.7508 21.3163 27.8411 19.1649ZM26.6536 18.0761C15.2734 20.4109 1.31835 17.6108 1.31835 14.3189V8.50493C5.26179 11.7676 16.2638 12.6225 23.8143 11.6863C24.0077 14.0811 25 16.3172 26.6536 18.0761ZM23.7878 10.3592C12.5043 11.7965 1.31835 9.01574 1.31835 6.13164C1.31835 2.66693 16.4903 -0.102924 27.6692 2.59952C25.3801 4.46392 23.9203 7.23263 23.7878 10.3592Z" />
  </svg>
</template>



<script>
  export default {
    //
  }
</script>
