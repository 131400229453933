export default {

  /**
   * About Us
   */
  "heading": "About Us",
  "meta": [
    { "name": "description", "content": "We fill the big gap of cost-effective and high-performing computing (HPC) resources in the public cloud market." }
  ],
  "preamble": [
    "Siberfy is the brain child of two entrepreneurs with the dream to revolutionize the Pakistani internet. There are simply no transparently priced cloud providers inside Pakistan. People are forced to buy from international players, but that means your users get a much worse experience due to the traffic crossing borders. We've resolved that problem for you. Whether you are a user with a small website, a university on a budget, a bank with huge compute needs, or a government institution that needs to store data inside the borders - we have an on-demand, monthly billed solution for you, with no upfront expensive hardware costs. Welcome to the future of cloud in Pakistan. Welcome to Siberfy.",
    "We have been doing this since 2011. We've spent hundreds of thousands of dollars and hours in R&D, and built our own control panel and monitoring systems from the ground up. While Siberfy as a company is new, our team on the other hand has had a long history in the public cloud industry, from enthusiast companies in the low-cost market, to market leaders such as Google Cloud."
  ],

  /**
   * Our Team
   */
  "team": {
    "heading": "Our Team",
    "preamble": [
      ""
    ],
    "recruit": {
      "heading": "You?",
      "role": "Engineer",
      "description": [
        "Are you looking for new opportunities? We're always looking for talent. Read more about which positions we currently need filling below!"
      ]
    },
    "employees": [
      {
        "name": "Emil Jönsson",
        "role": "Co-owner",
        "avatar": "/images/avatars/emil.jpeg",
        "description": [
          "An original co-founder and an ex-Google engineer who left Google to come back and build his own cloud computing company. He is the head of our technical operations. Having made multiple trips to Pakistan, he understood the clear gap in the market for reliable cloud compute services in the country.",
        ]
      },
      {
        "name": "Able Rafiq",
        "role": "Co-owner",
        "avatar": "/images/avatars/able.png",
        "description": [
          "One of the original co-founders, he has been the dreamer behind Siberfy's existence. As someone who grew up in the internet age, he noticed the clear problems prevailing in Pakistan's internet infrastructure, and set out to right the wrongs with Siberfy.",
        ]
      },
      {
        "name": "Christopher Eklund",
        "role": "Head of Development",
        "description": [
          "The head of our development team - and responsible for making our clean and easy to use user interface called Peregrine. When he is not spending time with his family, you will find him trying to figure out the best-est solution to the next big problem.",
        ]
      },
      {
        "name": "Lara Gatchalian",
        "role": "Head of Support",
        "description": [
          "She heads our support team. With vast experience in working for web hosting companies earlier, we brought Lara in to make sure our customers are provided with the best user experience, and that is something she strives to accomplish everyday.",
        ]
      },
      {
        "name": "Shakeb Afzal",
        "role": "Customer Support",
        "description": [
          "Being the newest addition to the team, Shakeb lives for delivering true customer happiness. You will usually find yourself communicating to him at our helpdesk - making sure your problems are being resolved, yesterday.",
        ]
      },
    ],
  },

  /**
   * Jobs
   */
  "jobs": {
    "heading": "Work with us",
    "preamble": [
      "We're growing! Join our amazing team and work from home in a flexible startup environment."
    ],
    "points": [
      {
        "heading": "Flexible Schedules",
        "icon": "x-icon-flexible-schedule",
        "description": "We're a flexible company who operates 24/7/365, so we'll always find a schedule that fits into your personal life, not the other way around."
      },
      {
        "heading": "100% Remote",
        "icon": "x-icon-flaticon-wfh",
        "description": "Our team is remote, so we don't have to pass down costs of fancy offices to our customers. Depending on your preference, we'll help you setup a home office or rent a coworking space local to you."
      },
      {
        "heading": "Personal Growth",
        "icon": "x-icon-personal-growth",
        "description": "We'll grow together. Yearly conferences of your choice, courses, litterature and time reserved for L&D is money we consider well spent."
      }
    ],
    "positions": {
      "heading": "Available Positions",
      "preamble": [
        "Good news! We're currently hiring. Are you a talented individual with a background from the IaaS or hosting industry in Pakistan? Send us your resume with a few details to <a href=\"mailto:careers@siberfy.com\" class=\"transition text-brand hover:text-black\">careers@siberfy.com</a>",
        "We're looking for both technical and non-technical roles so don't be shy :)"
      ],
      "role": "Role",
      "location": "Location",
      "contract": "Contract",
      "remote": "Remote",
      "apply": "Read more",
      "empty": "There are currently no available positions. Please come back later."
    }
  },

  /**
   * Brands
   */
  "brands": {
    "palettes": [
      {
        "name": "On Brand",
        "darkOnLight": true,
        "description": "This is the preferred logotype",
        "foreground": "#096C09",
        "background": "#FFFFFF",
        "download": "/images/logos/siberfy.svg",
      },
      {
        "name": "Light on Dark",
        "description": "Alt. logotype for dark settings",
        "foreground": "#FFFFFF",
        "background": "#222222",
        "download": "/images/logos/siberfy-white.svg",
      },
      {
        "name": "Dark on Light",
        "darkOnLight": true,
        "description": "Alt. logotype for light settings",
        "foreground": "#000000",
        "background": "#FFFFFF",
        "download": "/images/logos/siberfy-black.svg",
      },
    ]
  },

  /**
   * Contact
   */
  "contact": {
    "heading": "Contact",
    "meta": {
      "description": "SEO description of the contact page"
    },
    "preamble": [
      "Interested in taking your computing to a whole new level in terms of performance and cost effectiveness? We'd love to hear from you!"
    ],
    "address": {
      "label": "Mailing Address",
      "lines": [
        "67 - Block K1, Wapda Town,",
        "Lahore, 54000"
      ]
    },
    "email": {
      "label": "Email Address",
      "value": "hello@siberfy.com"
    },
    "phone": {
      "label": "Phone Number",
      "value": "Currently support via phone is only provided to enterprise customers with SLAs"
    },
    "customer": {
      "guest": {
        "heading": "Already a customer?",
        "instructions": [
          "The quickest and best way for us to help you with your queries, is if you log in to your account and open up a support ticket within our portal."
        ],
        "action": {
          "label": "Log In",
          "url": "https://cloud.siberfy.com/login"
        }
      },
      "auth": {
        "heading": "What's up, {name}?",
        "instructions": [
          "The quickest and best way for us to help you with your queries, is if you open up a support ticket within our portal."
        ],
        "action": {
          "label": "Open Ticket",
          "url": "https://cloud.siberfy.com/tickets/create"
        }
      }
    }
  },

  /**
   * Service Level Agreement
   */
  "sla": {
    "heading": "Service Level Agreement",
    "meta": {
      "description": "SEO description of the SLA"
    },
    "preamble": [
      "This Service Legal Agreement (the \"SLA\") describes a legally binding agreement between Siberfy Private Limited, (\"Siberfy\", \"us\", \"our\") and Siberfy's customers.",
    ],
    "content": [
      {
        "type": "paragraph",
        "value": "The SLA applies to services that Siberfy itself provides, i,e the unmanaged cloud compute virtual machines, and does not apply to any software or OS side issues that are under the responsibility of the customer.",
      },
      {
        "type": "paragraph",
        "value": "The SLA does not apply in case of force majeure, acts of God, or any other cases outside the control of Siberfy. It also does not apply in the cases of scheduled maintenance that is announced at least 24 hours before.",
      },
      {
        "type": "paragraph",
        "value": "We provide an uptime guarantee of 99.9% uptime, counted per month, meaning that up to ~40 minutes of approximate downtime is uncovered by the SLA, unless otherwise negotiated and agreed upon in a separate contract. In any given month, the SLA provides, counting from after the initial 40 minutes.",
      },
      {
        "type": "list",
        "values": [
          "1 hour of downtime: 20% of the monthly cost",
          "2 hours of downtime: 40% of the monthly cost",
          "3 hours of downtime: 60% of the monthly cost",
          "4 hours of downtime or higher: 100% of the monthly cost",
        ],
      },
      {
        "type": "paragraph",
        "value": "A request must be made by opening a ticket with Siberfy, either through our control panel at <a href=\"https://cloud.siberfy.com\" target=\"_blank\" class=\"transition text-brand hover:text-black\">cloud.siberfy.com</a>, or by emailing <a href=\"mailto:support@siberfy.com\" class=\"transition text-brand hover:text-black\">support@siberfy.com</a> with the exact outage details. Note that this must be done within 30 days of the said outage.",
      },
      {
        "type": "paragraph",
        "value": "A credit will be issued to your Siberfy account, that will be usable for any future invoices at Siberfy."
      },
    ]
  },

  /**
   * Legal Documents
   */
  "legal": {
    "heading": "Legal Documents",
    "meta": {
      "description": "SEO description of the legal documents"
    },
    "preamble": [
      "Praesent aliquet quam mi, vel porta magna molestie nec. In hac habitasse platea dictumst. Donec fermentum rhoncus orci vel interdum. Donec vehicula placerat sem eget commodo."
    ],
    "subterms": [
      {
        "heading": "Terms of Service",
        "route": "legal.tos",
        "description": "Pellentesque ligula est, finibus vel urna ac, dictum suscipit leo. Sed vel erat id lectus tincidunt rutrum ac vel lorem. Mauris non cursus ligula."
      },
      {
        "heading": "Acceptable Use Policy",
        "route": "legal.policy",
        "description": "Pellentesque ligula est, finibus vel urna ac, dictum suscipit leo. Sed vel erat id lectus tincidunt rutrum ac vel lorem. Mauris non cursus ligula."
      },
      {
        "heading": "Privacy Policy",
        "route": "legal.privacy",
        "description": "Pellentesque ligula est, finibus vel urna ac, dictum suscipit leo. Sed vel erat id lectus tincidunt rutrum ac vel lorem. Mauris non cursus ligula."
      }
    ],
    "support": {
      "heading": "Got any questions?",
      "preamble": [
        "Feel free to contact us."
      ],
      "actions": [
        {
          "label": "Send us an email!",
          "value": "mailto:support@siberfy.com",
          "primary": true
        },
        {
          "label": "all +92-321-1517175",
          "value": "tel:00923211517175",
          "primary": false
        }
      ]
    }
  }

};
