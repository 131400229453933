<script>
  import Switcher from './Switcher.vue';

  export default {
    components: {
      'switcher': Switcher,
    },

    props: {
      packages: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        selected: null
      };
    },

    computed: {
      allProducts () {
        const products = this.packages;

        let combined = { };

        for (const group in products) {
          for (const product in products[group]) {
            let object = products[group][product];

            combined[`${group}.${product}`] = object;
          }
        }

        return combined;
      },

      group () {
        const parts = this.selected.split('.');

        return parts[0] || null;
      },

      subgroup () {
        const parts = this.selected.split('.');

        return parts[1] || null;
      },
    },

    created () {
      this.selected = this.findMatch(this.$route.params.group);
      
      this.$watch(
        () => this.$route.params,
        (toParams, previousParams) => {
          if (toParams?.group && ! toParams?.subgroup) {
            this.selected = this.findMatch(toParams.group);
          }
        },
      );
    },

    methods: {
      findMatch (group) {
        const keys = Object.keys(this.allProducts);

        const match = keys.find(value => new RegExp('\.' + group + '$', 'i').test(value));

        return match || keys[0];
      },

      isSelected (group, identifier) {
        return this.selected === `${group}.${identifier}`;
      },

      switchTo (group, identifier, subgroup) {
        this.selected = `${group}.${identifier}`;

        this.$router.push({ name: 'pricing', params: { group: identifier, subgroup } })
      },
      
      findOptimizationMatch (product, subgroup) {
        const keys = Object.keys(product.optimizations);

        const match = keys.find(value => new RegExp('^' + subgroup + '$', 'i').test(value));

        return match || keys[0];
      },

      getOptimization (product) {
        if (! product?.optimizations) {
          return null;
        }

        const subgroup = this.findOptimizationMatch(product, this.$route.params.subgroup);

        return product.optimizations[subgroup];
      },

      getPackages (product) {
        if (! product?.optimizations) {
          return product.packages;
        }

        const optimization = this.getOptimization(product);

        return optimization.packages;
      }
    }
  }
</script>



<style scoped>
  /* */
</style>



<template>
  <switcher :packages="packages"></switcher>
  
  <section>
    <x-effect-fade :active-slot="selected">
      <template v-for="(product, identifier) in allProducts" :key="identifier" v-slot:[identifier]>
        <slot v-bind="{
          group,
          subgroup,
          product,
          packages: getPackages(product),
          optimization: {
            object: getOptimization(product),
            carousel: this,
          },
        }" />
      </template>
    </x-effect-fade>
  </section>
</template>
