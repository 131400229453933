<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="fill-segment-light" id="Icons" viewBox="0 0 60 60" width="44" height="44">
    <path d="M55,4H53V3a3,3,0,0,0-6,0V4H43V3a3,3,0,0,0-6,0V4H33V3a3,3,0,0,0-6,0V4H23V3a3,3,0,0,0-6,0V4H13V3A3,3,0,0,0,7,3V4H5A5.006,5.006,0,0,0,0,9V55a5.006,5.006,0,0,0,5,5H55a5.006,5.006,0,0,0,5-5V9A5.006,5.006,0,0,0,55,4ZM49,3a1,1,0,0,1,2,0V7a1,1,0,0,1-2,0ZM39,3a1,1,0,0,1,2,0V7a1,1,0,0,1-2,0ZM29,3a1,1,0,0,1,2,0V7a1,1,0,0,1-2,0ZM19,3a1,1,0,0,1,2,0V7a1,1,0,0,1-2,0ZM9,3a1,1,0,0,1,2,0V7A1,1,0,0,1,9,7ZM58,55a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V14H58Zm0-43H2V9A3,3,0,0,1,5,6H7V7a3,3,0,0,0,6,0V6h4V7a3,3,0,0,0,6,0V6h4V7a3,3,0,0,0,6,0V6h4V7a3,3,0,0,0,6,0V6h4V7a3,3,0,0,0,6,0V6h2a3,3,0,0,1,3,3Z"/><path d="M18,49A13.015,13.015,0,0,0,31,36,11,11,0,1,1,42.436,46.978l1.271-1.271a1,1,0,0,0-1.414-1.414l-3,3a1,1,0,0,0,0,1.414l3,3a1,1,0,0,0,1.414-1.414L42.394,48.98A12.993,12.993,0,1,0,29,36,11,11,0,1,1,17.564,25.022l-1.271,1.271a1,1,0,1,0,1.414,1.414l3-3a1,1,0,0,0,0-1.414l-3-3a1,1,0,0,0-1.414,1.414l1.313,1.313A12.993,12.993,0,0,0,18,49Z"/>
  </svg>
</template>

<script>
  export default {
    //
  }
</script>
