<template>
  <svg width="61" height="30" viewBox="0 0 61 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M59.04 25.02H49.3036C48.8008 23.5224 47.3848 22.44 45.72 22.44C44.0552 22.44 42.6392 23.5224 42.1364 25.02H18.1036C17.7274 23.8997 16.8403 23.0125 15.72 22.6364V17.76H22.7236C23.3862 17.76 23.9236 17.2226 23.9236 16.56V1.2C23.9236 0.53736 23.3862 0 22.7236 0H17.6617C17.2726 0 16.9075 0.18876 16.6825 0.5064L14.0738 4.18908H6.31644C5.6538 4.18908 5.11644 4.72644 5.11644 5.38908V16.56C5.11644 17.2226 5.6538 17.76 6.31644 17.76H13.32V22.6364C12.1997 23.0126 11.3125 23.8997 10.9364 25.02H7.8C7.13736 25.02 6.6 25.5574 6.6 26.22C6.6 26.8826 7.13736 27.42 7.8 27.42H10.9364C11.4392 28.9176 12.8552 30 14.52 30C16.1848 30 17.6008 28.9176 18.1036 27.42H42.1363C42.6391 28.9176 44.0551 30 45.7199 30C47.3846 30 48.8006 28.9176 49.3034 27.42H59.04C59.7026 27.42 60.24 26.8826 60.24 26.22C60.24 25.5574 59.7026 25.02 59.04 25.02ZM18.2824 2.4H21.5237V4.18908H17.0152L18.2824 2.4ZM7.51644 15.36V6.58908H21.5236V15.36H7.51644ZM14.52 27.6C13.7591 27.6 13.14 26.9809 13.14 26.22C13.14 25.4591 13.7591 24.84 14.52 24.84C15.2809 24.84 15.9 25.4591 15.9 26.22C15.9 26.9809 15.2809 27.6 14.52 27.6ZM45.72 27.6C44.9591 27.6 44.34 26.9809 44.34 26.22C44.34 25.4591 44.9591 24.84 45.72 24.84C46.4809 24.84 47.1 25.4591 47.1 26.22C47.1 26.9809 46.4809 27.6 45.72 27.6Z" fill="black"/>
    <path d="M59.04 25.02H49.3036C48.8008 23.5224 47.3848 22.44 45.72 22.44C44.0552 22.44 42.6392 23.5224 42.1364 25.02H18.1036C17.7274 23.8997 16.8403 23.0125 15.72 22.6364V17.76H22.7236C23.3862 17.76 23.9236 17.2226 23.9236 16.56V1.2C23.9236 0.53736 23.3862 0 22.7236 0H17.6617C17.2726 0 16.9075 0.18876 16.6825 0.5064L14.0738 4.18908H6.31644C5.6538 4.18908 5.11644 4.72644 5.11644 5.38908V16.56C5.11644 17.2226 5.6538 17.76 6.31644 17.76H13.32V22.6364C12.1997 23.0126 11.3125 23.8997 10.9364 25.02H7.8C7.13736 25.02 6.6 25.5574 6.6 26.22C6.6 26.8826 7.13736 27.42 7.8 27.42H10.9364C11.4392 28.9176 12.8552 30 14.52 30C16.1848 30 17.6008 28.9176 18.1036 27.42H42.1363C42.6391 28.9176 44.0551 30 45.7199 30C47.3846 30 48.8006 28.9176 49.3034 27.42H59.04C59.7026 27.42 60.24 26.8826 60.24 26.22C60.24 25.5574 59.7026 25.02 59.04 25.02ZM18.2824 2.4H21.5237V4.18908H17.0152L18.2824 2.4ZM7.51644 15.36V6.58908H21.5236V15.36H7.51644ZM14.52 27.6C13.7591 27.6 13.14 26.9809 13.14 26.22C13.14 25.4591 13.7591 24.84 14.52 24.84C15.2809 24.84 15.9 25.4591 15.9 26.22C15.9 26.9809 15.2809 27.6 14.52 27.6ZM45.72 27.6C44.9591 27.6 44.34 26.9809 44.34 26.22C44.34 25.4591 44.9591 24.84 45.72 24.84C46.4809 24.84 47.1 25.4591 47.1 26.22C47.1 26.9809 46.4809 27.6 45.72 27.6Z" fill="#232634"/>
    <path d="M59.04 25.02H49.3036C48.8008 23.5224 47.3848 22.44 45.72 22.44C44.0552 22.44 42.6392 23.5224 42.1364 25.02H18.1036C17.7274 23.8997 16.8403 23.0125 15.72 22.6364V17.76H22.7236C23.3862 17.76 23.9236 17.2226 23.9236 16.56V1.2C23.9236 0.53736 23.3862 0 22.7236 0H17.6617C17.2726 0 16.9075 0.18876 16.6825 0.5064L14.0738 4.18908H6.31644C5.6538 4.18908 5.11644 4.72644 5.11644 5.38908V16.56C5.11644 17.2226 5.6538 17.76 6.31644 17.76H13.32V22.6364C12.1997 23.0126 11.3125 23.8997 10.9364 25.02H7.8C7.13736 25.02 6.6 25.5574 6.6 26.22C6.6 26.8826 7.13736 27.42 7.8 27.42H10.9364C11.4392 28.9176 12.8552 30 14.52 30C16.1848 30 17.6008 28.9176 18.1036 27.42H42.1363C42.6391 28.9176 44.0551 30 45.7199 30C47.3846 30 48.8006 28.9176 49.3034 27.42H59.04C59.7026 27.42 60.24 26.8826 60.24 26.22C60.24 25.5574 59.7026 25.02 59.04 25.02ZM18.2824 2.4H21.5237V4.18908H17.0152L18.2824 2.4ZM7.51644 15.36V6.58908H21.5236V15.36H7.51644ZM14.52 27.6C13.7591 27.6 13.14 26.9809 13.14 26.22C13.14 25.4591 13.7591 24.84 14.52 24.84C15.2809 24.84 15.9 25.4591 15.9 26.22C15.9 26.9809 15.2809 27.6 14.52 27.6ZM45.72 27.6C44.9591 27.6 44.34 26.9809 44.34 26.22C44.34 25.4591 44.9591 24.84 45.72 24.84C46.4809 24.84 47.1 25.4591 47.1 26.22C47.1 26.9809 46.4809 27.6 45.72 27.6Z" fill="url(#icon_privatenetworking0_linear)"/>
    <path d="M3.24 25.02H1.2C0.53736 25.02 0 25.5574 0 26.22C0 26.8826 0.53736 27.42 1.2 27.42H3.24C3.90264 27.42 4.44 26.8826 4.44 26.22C4.44 25.5574 3.90264 25.02 3.24 25.02Z" fill="black"/>
    <path d="M3.24 25.02H1.2C0.53736 25.02 0 25.5574 0 26.22C0 26.8826 0.53736 27.42 1.2 27.42H3.24C3.90264 27.42 4.44 26.8826 4.44 26.22C4.44 25.5574 3.90264 25.02 3.24 25.02Z" fill="#232634"/>
    <path d="M3.24 25.02H1.2C0.53736 25.02 0 25.5574 0 26.22C0 26.8826 0.53736 27.42 1.2 27.42H3.24C3.90264 27.42 4.44 26.8826 4.44 26.22C4.44 25.5574 3.90264 25.02 3.24 25.02Z" fill="url(#icon_privatenetworking1_linear)"/>
    <path d="M37.56 17.76H44.52V20.04C44.52 20.7026 45.0574 21.24 45.72 21.24C46.3826 21.24 46.92 20.7026 46.92 20.04V17.76H53.9674C54.63 17.76 55.1674 17.2226 55.1674 16.56V1.2C55.1674 0.53736 54.63 0 53.9674 0H48.9055C48.5164 0 48.1513 0.18876 47.9263 0.5064L45.3176 4.18908H37.56C36.8974 4.18908 36.36 4.72644 36.36 5.38908V16.56C36.36 17.2226 36.8974 17.76 37.56 17.76ZM49.526 2.4H52.7674V4.18908H48.2588L49.526 2.4ZM38.76 6.58908H52.7672V15.36H38.76V6.58908Z" fill="black"/>
    <path d="M37.56 17.76H44.52V20.04C44.52 20.7026 45.0574 21.24 45.72 21.24C46.3826 21.24 46.92 20.7026 46.92 20.04V17.76H53.9674C54.63 17.76 55.1674 17.2226 55.1674 16.56V1.2C55.1674 0.53736 54.63 0 53.9674 0H48.9055C48.5164 0 48.1513 0.18876 47.9263 0.5064L45.3176 4.18908H37.56C36.8974 4.18908 36.36 4.72644 36.36 5.38908V16.56C36.36 17.2226 36.8974 17.76 37.56 17.76ZM49.526 2.4H52.7674V4.18908H48.2588L49.526 2.4ZM38.76 6.58908H52.7672V15.36H38.76V6.58908Z" fill="#232634"/>
    <path d="M37.56 17.76H44.52V20.04C44.52 20.7026 45.0574 21.24 45.72 21.24C46.3826 21.24 46.92 20.7026 46.92 20.04V17.76H53.9674C54.63 17.76 55.1674 17.2226 55.1674 16.56V1.2C55.1674 0.53736 54.63 0 53.9674 0H48.9055C48.5164 0 48.1513 0.18876 47.9263 0.5064L45.3176 4.18908H37.56C36.8974 4.18908 36.36 4.72644 36.36 5.38908V16.56C36.36 17.2226 36.8974 17.76 37.56 17.76ZM49.526 2.4H52.7674V4.18908H48.2588L49.526 2.4ZM38.76 6.58908H52.7672V15.36H38.76V6.58908Z" fill="url(#icon_privatenetworking2_linear)"/>
    <defs>
      <linearGradient id="icon_privatenetworking0_linear" x1="30.12" y1="0" x2="30.12" y2="30" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="icon_privatenetworking1_linear" x1="30.12" y1="0" x2="30.12" y2="30" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="icon_privatenetworking2_linear" x1="30.12" y1="0" x2="30.12" y2="30" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>
</template>



<script>
  export default {
    //
  }
</script>
