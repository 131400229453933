<script>
  import { h } from 'vue';

  export default {
    props: {
      level: {
        type: Number,
        required: false,
        default: 1
      }
    },

    render() {
      return h('h' + this.level, { }, this.$slots.default())
    }
  }
</script>



<style scoped>
  h1, h2, h3, h4, h5, h6 {
    @apply text-white;
  }

  h1 {
    @apply text-3xl lg:text-4xl mb-8;
  }

  h2 {
    @apply text-xl lg:text-2xl mb-6;
  }
</style>
