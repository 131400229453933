<style scoped>
  .cloud-compute-dark { @apply hidden; }
  .cloud-compute-light { @apply block; }

  /*
  .cloud-compute-dark { @apply transition opacity-100; }
  .cloud-compute-light { @apply transition opacity-0; }

  svg:hover .cloud-compute-dark { @apply opacity-0; }
  svg:hover .cloud-compute-light { @apply opacity-100; }
  */
  
  .cloud-compute-1 { fill: rgba(0, 0, 0, .15); }
  .cloud-compute-2 { fill: #20b785; }
  .cloud-compute-3 { fill: #53dcab; }
  .cloud-compute-4 { fill: #1faa78; }
  .cloud-compute-5 { fill: #4d5470; }
  .cloud-compute-6 { fill: #767f91; }
  .cloud-compute-7 { fill: #a3abc3; }
  .cloud-compute-8 { fill: #f2f2f2; }
  .cloud-compute-9 { fill: #979faf; }
  .cloud-compute-10 { fill: #242835; }
  .cloud-compute-11 { fill: #282d38; }
  .cloud-compute-12 { fill: #363f4c; }
</style>

<template>
  <svg id="Both" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 372.43 338.55">
    <path class="cloud-compute-1" d="M815.07,624c9,5.22,23.84,5.22,32.88,0l153.54-88.64c9-5.22,9-13.77,0-19L848,427.73c-9-5.22-23.83-5.22-32.88,0L661.54,516.36c-9,5.22-9,13.77,0,19Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-2" d="M1001.49,481.49l-5.83,3.36v9.9l5.83-3.36c4.52-2.61,6.78-6.05,6.78-9.49h0V472C1008.27,475.44,1006,478.88,1001.49,481.49Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-2" d="M661.54,481.49l5.82,3.36v9.9l-5.82-3.36c-4.52-2.61-6.78-6.05-6.78-9.49h0V472C654.76,475.44,657,478.88,661.54,481.49Z" transform="translate(-645.3 -289.35)"/>
    <polygon class="cloud-compute-2" points="202.66 280.78 202.66 290.68 356.19 202.04 356.19 192.14 202.66 280.78"/>
    <polygon class="cloud-compute-2" points="169.78 280.78 169.78 290.68 16.24 202.04 16.24 192.14 169.78 280.78"/>
    <path class="cloud-compute-2" d="M815.07,570.13V580c9,5.22,23.84,5.22,32.88,0v-9.9C838.91,575.35,824.12,575.35,815.07,570.13Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-3" d="M1001.49,462.51,848,373.87c-9-5.22-23.83-5.22-32.88,0L661.54,462.51c-9,5.22-9,13.76,0,19l153.53,88.64c9,5.22,23.84,5.22,32.88,0l153.54-88.64C1010.53,476.27,1010.53,467.73,1001.49,462.51ZM987.26,477,840.17,561.92c-4.76,2.75-12.55,2.75-17.32,0L675.76,477c-4.76-2.75-4.76-7.25,0-10l147.09-84.92c4.77-2.75,12.56-2.75,17.32,0L987.26,467C992,469.75,992,474.25,987.26,477Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-4" d="M675.7,477l.06,0L822.85,392c4.77-2.75,12.56-2.75,17.32,0l147.09,84.92.07,0c4.69-2.75,4.67-7.22-.07-10L840.17,382.08c-4.76-2.75-12.55-2.75-17.32,0L675.76,467C671,469.74,671,474.21,675.7,477Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-5" d="M814.19,528.59c9.53,5.5,25.12,5.5,34.64,0l161.75-93.38c9.53-5.5,9.53-14.5,0-20L848.83,321.82c-9.52-5.5-25.11-5.5-34.64,0L652.44,415.21c-9.52,5.5-9.52,14.5,0,20Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-6" d="M1010.58,406.86l-6.13,3.54v28.35l6.13-3.54c4.76-2.75,7.15-6.37,7.15-10h0V396.86C1017.73,400.49,1015.35,404.11,1010.58,406.86Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-6" d="M652.44,406.86l6.14,3.54v28.35l-6.14-3.54c-4.76-2.75-7.14-6.37-7.14-10h0V396.86C645.3,400.49,647.68,404.11,652.44,406.86Z" transform="translate(-645.3 -289.35)"/>
    <polygon class="cloud-compute-6" points="203.53 210.9 203.53 239.24 365.29 145.86 365.29 117.51 203.53 210.9"/>
    <polygon class="cloud-compute-6" points="168.9 210.9 168.9 239.24 7.14 145.86 7.14 117.51 168.9 210.9"/>
    <path class="cloud-compute-6" d="M814.19,500.25v28.34c9.53,5.5,25.12,5.5,34.64,0V500.25C839.31,505.75,823.72,505.75,814.19,500.25Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-7" d="M814.19,500.25c9.53,5.5,25.12,5.5,34.64,0l161.75-93.39c9.53-5.5,9.53-14.5,0-20L848.83,293.47c-9.52-5.5-25.11-5.5-34.64,0L652.44,386.86c-9.52,5.5-9.52,14.5,0,20Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-8" d="M985.87,393a14.74,14.74,0,0,1,13.26,0c3.64,2.11,3.64,5.55,0,7.65a14.71,14.71,0,0,1-13.26,0C982.23,398.58,982.23,395.14,985.87,393Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-8" d="M824.89,300.09a14.66,14.66,0,0,1,13.25,0c3.65,2.1,3.65,5.55,0,7.65a14.66,14.66,0,0,1-13.25,0C821.24,305.64,821.24,302.19,824.89,300.09Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-8" d="M824.88,486a14.68,14.68,0,0,1,13.26,0c3.65,2.1,3.65,5.55,0,7.65a14.68,14.68,0,0,1-13.26,0C821.24,491.53,821.24,488.08,824.88,486Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-8" d="M663.9,393a14.74,14.74,0,0,1,13.26,0c3.64,2.11,3.64,5.55,0,7.66a14.74,14.74,0,0,1-13.26,0C660.26,398.58,660.26,395.14,663.9,393Z" transform="translate(-645.3 -289.35)"/>
    <polygon class="cloud-compute-9" points="132.38 199.84 132.38 209.56 128.35 207.23 128.35 197.51 132.38 199.84"/>
    <polygon class="cloud-compute-9" points="120.28 192.85 120.28 202.57 116.25 200.24 116.25 190.52 120.28 192.85"/>
    <polygon class="cloud-compute-9" points="108.19 185.87 108.19 195.59 104.15 193.26 104.15 183.54 108.19 185.87"/>
    <polygon class="cloud-compute-9" points="96.08 178.88 96.08 188.6 92.05 186.27 92.05 176.55 96.08 178.88"/>
    <polygon class="cloud-compute-9" points="83.99 171.9 83.99 181.61 79.95 179.29 79.95 169.56 83.99 171.9"/>
    <polygon class="cloud-compute-9" points="71.89 164.91 71.89 174.63 67.85 172.3 67.85 162.58 71.89 164.91"/>
    <polygon class="cloud-compute-9" points="59.79 157.93 59.79 167.64 55.75 165.31 55.75 155.59 59.79 157.93"/>
    <polygon class="cloud-compute-9" points="47.69 150.94 47.69 160.66 43.66 158.33 43.65 148.61 47.69 150.94"/>
    <polygon class="cloud-compute-9" points="240.05 199.84 240.05 209.56 244.08 207.23 244.08 197.51 240.05 199.84"/>
    <polygon class="cloud-compute-9" points="252.15 192.85 252.15 202.57 256.18 200.24 256.18 190.52 252.15 192.85"/>
    <polygon class="cloud-compute-9" points="264.25 185.87 264.25 195.59 268.28 193.26 268.28 183.54 264.25 185.87"/>
    <polygon class="cloud-compute-9" points="276.35 178.88 276.35 188.6 280.38 186.27 280.38 176.55 276.35 178.88"/>
    <polygon class="cloud-compute-9" points="288.44 171.9 288.44 181.61 292.48 179.29 292.48 169.56 288.44 171.9"/>
    <polygon class="cloud-compute-9" points="300.54 164.91 300.54 174.63 304.58 172.3 304.58 162.58 300.54 164.91"/>
    <polygon class="cloud-compute-9" points="312.64 157.93 312.64 167.64 316.68 165.31 316.68 155.59 312.64 157.93"/>
    <polygon class="cloud-compute-9" points="324.74 150.94 324.74 160.66 328.78 158.33 328.78 148.61 324.74 150.94"/>
    <path class="cloud-compute-9" d="M958.91,393.66l-51.16-29.54a12.31,12.31,0,0,0-11.09,0l-51.15,29.54c-3,1.76-3,4.64,0,6.4l49.21,28.41h15l49.21-28.41C962,398.3,962,395.42,958.91,393.66Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-10" d="M953.55,387.94l-44.5,25.69h0v10.89l44.5-25.69a2.4,2.4,0,0,0,1.41-2V386A2.41,2.41,0,0,1,953.55,387.94Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-10" d="M895.37,413.63h0l-44.5-25.69a2.41,2.41,0,0,1-1.41-2v10.89a2.4,2.4,0,0,0,1.41,2l44.5,25.69Z" transform="translate(-645.3 -289.35)"/>
    <rect class="cloud-compute-11" x="250.07" y="124.28" width="13.69" height="10.89"/>
    <path class="cloud-compute-12" d="M953.55,384l-47.92-27.66a7.58,7.58,0,0,0-6.85,0L850.87,384c-1.89,1.09-1.89,2.87,0,4l44.5,25.69h13.68l44.5-25.69C955.44,386.85,955.44,385.07,953.55,384Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-3" d="M899.7,372.18a5.53,5.53,0,0,0,5,0h0c1.38-.79,1.38-2.1,0-2.89h0a5.59,5.59,0,0,0-5,0C898.33,370.08,898.33,371.39,899.7,372.18Zm17.58,10.15a5.53,5.53,0,0,0,5,0h0c1.38-.79,1.38-2.1,0-2.89a5.59,5.59,0,0,0-5,0C915.9,380.23,915.9,381.54,917.28,382.33Zm13.8,2.18a5.59,5.59,0,0,0-5,0c-1.37.8-1.37,2.1,0,2.9a5.59,5.59,0,0,0,5,0h0C932.46,386.61,932.46,385.31,931.08,384.51Zm-31.38-2.18a5.53,5.53,0,0,0,5,0h0c1.38-.79,1.38-2.1,0-2.89h0a5.59,5.59,0,0,0-5,0C898.33,380.23,898.33,381.54,899.7,382.33Zm13.8,2.19h0a5.53,5.53,0,0,0-5,0c-1.37.79-1.37,2.09,0,2.89h0a5.59,5.59,0,0,0,5,0C914.88,386.61,914.88,385.31,913.5,384.52Zm8.79,5.07h0a5.53,5.53,0,0,0-5,0c-1.37.8-1.37,2.1,0,2.89h0a5.53,5.53,0,0,0,5,0C923.67,391.69,923.67,390.39,922.29,389.59Zm-40.17-7.26a5.53,5.53,0,0,0,5,0h0c1.37-.79,1.37-2.1,0-2.89a5.61,5.61,0,0,0-5,0C880.75,380.23,880.75,381.54,882.12,382.33Zm13.8,2.19h0a5.56,5.56,0,0,0-5,0c-1.37.79-1.37,2.09,0,2.89a5.59,5.59,0,0,0,5,0C897.3,386.61,897.3,385.31,895.92,384.52Zm8.79,5.07h0a5.53,5.53,0,0,0-5,0c-1.37.8-1.37,2.1,0,2.89a5.53,5.53,0,0,0,5,0C906.09,391.69,906.09,390.39,904.71,389.59Zm-26.36-5.08a5.59,5.59,0,0,0-5,0c-1.38.8-1.38,2.1,0,2.9a5.57,5.57,0,0,0,5,0h0C879.72,386.61,879.72,385.31,878.35,384.51Zm8.79,5.08h0a5.55,5.55,0,0,0-5,0c-1.37.79-1.37,2.1,0,2.89a5.55,5.55,0,0,0,5,0C888.51,391.69,888.51,390.38,887.14,389.59Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-2" d="M908.49,377.26h0a5.59,5.59,0,0,0,5,0h0c1.38-.8,1.38-2.1,0-2.9a5.59,5.59,0,0,0-5,0C907.12,375.16,907.12,376.46,908.49,377.26Zm-17.58,0a5.59,5.59,0,0,0,5,0c1.38-.8,1.38-2.1,0-2.9h0a5.59,5.59,0,0,0-5,0C889.54,375.16,889.54,376.46,890.91,377.26Zm22.6,17.4h0a5.61,5.61,0,0,0-5,0c-1.37.8-1.37,2.1,0,2.9a5.61,5.61,0,0,0,5,0C914.88,396.76,914.88,395.46,913.51,394.66Zm-17.59,0h0a5.59,5.59,0,0,0-5,0c-1.37.8-1.37,2.1,0,2.89a5.53,5.53,0,0,0,5,0C897.31,396.76,897.31,395.46,895.92,394.66Zm8.8,5.08h0a5.53,5.53,0,0,0-5,0c-1.38.79-1.38,2.09,0,2.89a5.59,5.59,0,0,0,5,0C906.09,401.83,906.09,400.53,904.72,399.74Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-9" d="M888.28,352.88l-51.16-29.54a12.31,12.31,0,0,0-11.09,0l-51.15,29.54c-3,1.76-3,4.64,0,6.4l49.21,28.41h15l49.22-28.41C891.33,357.52,891.33,354.64,888.28,352.88Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-10" d="M882.92,347.16l-44.5,25.69h0v10.89l44.5-25.68a2.4,2.4,0,0,0,1.41-2V345.19A2.4,2.4,0,0,1,882.92,347.16Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-10" d="M824.73,372.85h0l-44.5-25.69a2.4,2.4,0,0,1-1.41-2v10.89a2.4,2.4,0,0,0,1.41,2l44.49,25.68Z" transform="translate(-645.3 -289.35)"/>
    <rect class="cloud-compute-11" x="179.44" y="83.5" width="13.69" height="10.89"/>
    <path class="cloud-compute-12" d="M882.92,343.21,835,315.54a7.58,7.58,0,0,0-6.85,0l-47.91,27.67c-1.89,1.09-1.89,2.86,0,4l44.5,25.69h13.68l44.5-25.69C884.81,346.07,884.81,344.3,882.92,343.21Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-3" d="M829.07,331.4a5.56,5.56,0,0,0,5,0h0c1.38-.79,1.38-2.09,0-2.89h0a5.59,5.59,0,0,0-5,0C827.7,329.31,827.7,330.61,829.07,331.4Zm17.58,10.16a5.59,5.59,0,0,0,5,0h0c1.38-.79,1.38-2.09,0-2.89a5.59,5.59,0,0,0-5,0C845.28,339.46,845.28,340.76,846.65,341.56Zm13.8,2.18a5.51,5.51,0,0,0-5,0c-1.38.79-1.38,2.09,0,2.89a5.51,5.51,0,0,0,5,0h0C861.83,345.83,861.83,344.53,860.45,343.74Zm-31.38-2.18a5.59,5.59,0,0,0,5,0h0c1.38-.8,1.38-2.1,0-2.9h0a5.59,5.59,0,0,0-5,0C827.7,339.46,827.7,340.76,829.07,341.56Zm13.8,2.18h0a5.53,5.53,0,0,0-5,0c-1.37.79-1.37,2.09,0,2.89h0a5.53,5.53,0,0,0,5,0C844.25,345.83,844.25,344.53,842.87,343.74Zm8.79,5.07h0a5.59,5.59,0,0,0-5,0c-1.37.8-1.37,2.1,0,2.9h0a5.59,5.59,0,0,0,5,0C853,350.91,853,349.61,851.66,348.81Zm-40.17-7.25a5.59,5.59,0,0,0,5,0h0c1.37-.79,1.37-2.09,0-2.89a5.61,5.61,0,0,0-5,0C810.12,339.46,810.12,340.76,811.49,341.56Zm13.8,2.18h0a5.53,5.53,0,0,0-5,0c-1.38.79-1.38,2.1,0,2.89a5.53,5.53,0,0,0,5,0C826.67,345.84,826.67,344.53,825.29,343.74Zm8.79,5.07h0a5.59,5.59,0,0,0-5,0c-1.37.8-1.37,2.1,0,2.9a5.59,5.59,0,0,0,5,0C835.46,350.91,835.46,349.61,834.08,348.81Zm-26.36-5.07a5.53,5.53,0,0,0-5,0c-1.38.79-1.38,2.09,0,2.89a5.57,5.57,0,0,0,5,0h0C809.09,345.83,809.09,344.53,807.72,343.74Zm8.79,5.07h0a5.61,5.61,0,0,0-5,0c-1.37.8-1.37,2.1,0,2.9a5.61,5.61,0,0,0,5,0C817.88,350.91,817.88,349.61,816.51,348.81Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-2" d="M837.86,336.48h0a5.53,5.53,0,0,0,5,0h0c1.38-.79,1.38-2.1,0-2.89a5.53,5.53,0,0,0-5,0C836.49,334.38,836.49,335.69,837.86,336.48Zm-17.58,0a5.53,5.53,0,0,0,5,0c1.38-.79,1.38-2.1,0-2.89h0a5.53,5.53,0,0,0-5,0C818.9,334.38,818.9,335.69,820.28,336.48Zm22.6,17.41h0a5.55,5.55,0,0,0-5,0c-1.37.79-1.37,2.09,0,2.89a5.61,5.61,0,0,0,5,0C844.25,356,844.25,354.68,842.88,353.89Zm-17.59,0h0a5.53,5.53,0,0,0-5,0c-1.37.79-1.37,2.09,0,2.89a5.59,5.59,0,0,0,5,0C826.68,356,826.68,354.68,825.29,353.89Zm8.8,5.07h0a5.59,5.59,0,0,0-5,0c-1.38.8-1.38,2.1,0,2.89a5.53,5.53,0,0,0,5,0C835.46,361.06,835.46,359.76,834.09,359Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-9" d="M888.28,434.43,837.12,404.9a12.31,12.31,0,0,0-11.09,0l-51.15,29.53c-3,1.76-3,4.65,0,6.41l49.21,28.41h15l49.22-28.41C891.33,439.08,891.33,436.19,888.28,434.43Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-10" d="M882.92,428.72l-44.5,25.68h0v10.9l44.5-25.69a2.4,2.4,0,0,0,1.41-2v-10.9A2.41,2.41,0,0,1,882.92,428.72Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-10" d="M824.73,454.4h0l-44.5-25.68a2.41,2.41,0,0,1-1.41-2v10.9a2.4,2.4,0,0,0,1.41,2l44.49,25.69Z" transform="translate(-645.3 -289.35)"/>
    <rect class="cloud-compute-11" x="179.44" y="165.05" width="13.69" height="10.89"/>
    <path class="cloud-compute-12" d="M882.92,424.76,835,397.09a7.65,7.65,0,0,0-6.85,0l-47.91,27.67c-1.89,1.09-1.89,2.87,0,4l44.5,25.68h13.68l44.5-25.69C884.81,427.63,884.81,425.85,882.92,424.76Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-3" d="M829.07,413a5.53,5.53,0,0,0,5,0h0c1.38-.79,1.38-2.1,0-2.89h0a5.53,5.53,0,0,0-5,0C827.7,410.86,827.7,412.17,829.07,413Zm17.58,10.15a5.59,5.59,0,0,0,5,0h0c1.38-.8,1.38-2.1,0-2.89a5.53,5.53,0,0,0-5,0C845.28,421,845.28,422.31,846.65,423.11Zm13.8,2.18a5.57,5.57,0,0,0-5,0c-1.38.8-1.38,2.1,0,2.89a5.51,5.51,0,0,0,5,0h0C861.83,427.39,861.83,426.09,860.45,425.29Zm-31.38-2.18a5.59,5.59,0,0,0,5,0h0c1.38-.8,1.38-2.1,0-2.89h0a5.53,5.53,0,0,0-5,0C827.7,421,827.7,422.31,829.07,423.11Zm13.8,2.18h0a5.59,5.59,0,0,0-5,0c-1.37.8-1.37,2.1,0,2.9h0a5.59,5.59,0,0,0,5,0C844.25,427.39,844.25,426.09,842.87,425.29Zm8.79,5.08h0a5.53,5.53,0,0,0-5,0c-1.37.8-1.37,2.09,0,2.89h0a5.53,5.53,0,0,0,5,0C853,432.46,853,431.17,851.66,430.37Zm-40.17-7.26a5.59,5.59,0,0,0,5,0h0c1.37-.8,1.37-2.1,0-2.89a5.55,5.55,0,0,0-5,0C810.12,421,810.12,422.31,811.49,423.11Zm13.8,2.18h0a5.59,5.59,0,0,0-5,0c-1.38.8-1.38,2.1,0,2.9a5.59,5.59,0,0,0,5,0C826.67,427.39,826.67,426.09,825.29,425.29Zm8.79,5.08h0a5.53,5.53,0,0,0-5,0c-1.37.8-1.37,2.09,0,2.89a5.59,5.59,0,0,0,5,0C835.46,432.46,835.46,431.17,834.08,430.37Zm-26.36-5.08a5.59,5.59,0,0,0-5,0c-1.38.8-1.38,2.1,0,2.89a5.51,5.51,0,0,0,5,0h0C809.09,427.39,809.09,426.09,807.72,425.29Zm8.79,5.08h0a5.55,5.55,0,0,0-5,0c-1.37.79-1.37,2.09,0,2.89a5.61,5.61,0,0,0,5,0C817.88,432.46,817.88,431.16,816.51,430.37Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-2" d="M837.86,418h0a5.59,5.59,0,0,0,5,0h0c1.38-.8,1.38-2.1,0-2.9a5.59,5.59,0,0,0-5,0C836.49,415.94,836.49,417.24,837.86,418Zm-17.58,0a5.59,5.59,0,0,0,5,0c1.38-.8,1.38-2.1,0-2.9h0a5.59,5.59,0,0,0-5,0C818.9,415.94,818.9,417.24,820.28,418Zm22.6,17.4h0a5.61,5.61,0,0,0-5,0c-1.37.8-1.37,2.1,0,2.89a5.55,5.55,0,0,0,5,0C844.25,437.54,844.25,436.24,842.88,435.44Zm-17.59,0h0a5.59,5.59,0,0,0-5,0c-1.37.8-1.37,2.1,0,2.89a5.53,5.53,0,0,0,5,0C826.68,437.54,826.68,436.24,825.29,435.44Zm8.8,5.08h0a5.56,5.56,0,0,0-5,0c-1.38.79-1.38,2.09,0,2.89a5.59,5.59,0,0,0,5,0C835.46,442.61,835.46,441.31,834.09,440.52Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-9" d="M817.65,393.66l-51.16-29.54a12.31,12.31,0,0,0-11.09,0l-51.15,29.54c-3,1.76-3,4.64,0,6.4l49.22,28.41h15l49.21-28.41C820.7,398.3,820.7,395.42,817.65,393.66Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-10" d="M812.29,387.94,767.8,413.63h0v10.89l44.49-25.69a2.41,2.41,0,0,0,1.42-2V386A2.42,2.42,0,0,1,812.29,387.94Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-10" d="M754.11,413.63h0l-44.5-25.69a2.41,2.41,0,0,1-1.41-2v10.89a2.4,2.4,0,0,0,1.41,2l44.5,25.69Z" transform="translate(-645.3 -289.35)"/>
    <rect class="cloud-compute-11" x="108.81" y="124.28" width="13.69" height="10.89"/>
    <path class="cloud-compute-12" d="M812.29,384l-47.91-27.66a7.58,7.58,0,0,0-6.85,0L709.61,384c-1.89,1.08-1.89,2.86,0,3.95l44.5,25.69H767.8l44.49-25.69C814.18,386.85,814.18,385.07,812.29,384Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-3" d="M758.44,372.18a5.55,5.55,0,0,0,5,0h0c1.37-.79,1.37-2.09,0-2.89h0a5.61,5.61,0,0,0-5,0C757.07,370.09,757.07,371.39,758.44,372.18ZM776,382.33a5.55,5.55,0,0,0,5,0h0c1.37-.79,1.37-2.1,0-2.89a5.61,5.61,0,0,0-5,0C774.65,380.23,774.65,381.54,776,382.33Zm13.8,2.19a5.51,5.51,0,0,0-5,0c-1.38.79-1.38,2.09,0,2.89a5.57,5.57,0,0,0,5,0h0C791.2,386.61,791.2,385.31,789.82,384.52Zm-31.38-2.19a5.55,5.55,0,0,0,5,0h0c1.37-.79,1.37-2.1,0-2.89h0a5.61,5.61,0,0,0-5,0C757.07,380.23,757.07,381.54,758.44,382.33Zm13.81,2.19h0a5.55,5.55,0,0,0-5,0c-1.37.79-1.37,2.09,0,2.89h0a5.61,5.61,0,0,0,5,0C773.62,386.61,773.62,385.31,772.25,384.52Zm8.79,5.07h0a5.55,5.55,0,0,0-5,0c-1.37.8-1.37,2.1,0,2.89h0a5.55,5.55,0,0,0,5,0C782.41,391.69,782.41,390.39,781,389.59Zm-40.17-7.26a5.53,5.53,0,0,0,5,0h0c1.37-.79,1.37-2.1,0-2.89a5.59,5.59,0,0,0-5,0C739.49,380.23,739.49,381.54,740.87,382.33Zm13.8,2.19h0a5.55,5.55,0,0,0-5,0c-1.37.79-1.37,2.09,0,2.89a5.61,5.61,0,0,0,5,0C756,386.61,756,385.31,754.67,384.52Zm8.79,5.07h0a5.55,5.55,0,0,0-5,0c-1.37.8-1.37,2.1,0,2.89a5.55,5.55,0,0,0,5,0C764.83,391.69,764.83,390.39,763.46,389.59Zm-26.37-5.08a5.59,5.59,0,0,0-5,0c-1.38.8-1.38,2.1,0,2.9a5.59,5.59,0,0,0,5,0h0C738.47,386.61,738.47,385.31,737.09,384.51Zm8.79,5.08h0a5.53,5.53,0,0,0-5,0c-1.38.79-1.38,2.1,0,2.89a5.53,5.53,0,0,0,5,0C747.26,391.69,747.26,390.38,745.88,389.59Z" transform="translate(-645.3 -289.35)"/>
    <path class="cloud-compute-2" d="M767.23,377.26h0a5.61,5.61,0,0,0,5,0h0c1.37-.8,1.37-2.1,0-2.9a5.61,5.61,0,0,0-5,0C765.86,375.16,765.86,376.46,767.23,377.26Zm-17.57,0a5.59,5.59,0,0,0,5,0c1.37-.8,1.37-2.1,0-2.9h0a5.59,5.59,0,0,0-5,0C748.28,375.16,748.28,376.46,749.66,377.26Zm22.59,17.4h0a5.59,5.59,0,0,0-5,0c-1.38.8-1.38,2.1,0,2.9a5.59,5.59,0,0,0,5,0C773.63,396.76,773.63,395.46,772.25,394.66Zm-17.58,0h0a5.59,5.59,0,0,0-5,0c-1.38.8-1.38,2.1,0,2.89a5.53,5.53,0,0,0,5,0C756.05,396.76,756.05,395.46,754.67,394.66Zm8.79,5.08h0a5.53,5.53,0,0,0-5,0c-1.38.79-1.38,2.09,0,2.89a5.59,5.59,0,0,0,5,0C764.83,401.83,764.83,400.53,763.46,399.74Z" transform="translate(-645.3 -289.35)"/>
  </svg>
</template>

<script>

export default {
  //
}

</script>
