<script>
  export default {
    //
  }
</script>



<style scoped>
  .slide-fade-enter-active {
    transition: all .15s ease-out;
  }

  .slide-fade-leave-active {
    transition: all .15s ease-in;
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }
</style>



<template>
  <transition tag="div" class="relative" name="slide-fade" mode="out-in">
    <slot></slot>
  </transition>
</template>
