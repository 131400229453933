<script setup>
  import { computed } from 'vue';

  const suffix = Math.random().toString(36).substr(2, 9);
  const generate = (range, callback) => ([...Array(range)].map(callback));

  const gradients = generate(14, (v, k) => `url(#snapshots-backup-${suffix}-${k})`);
  const slugs = generate(14, (v, k) => `snapshots-backup-${suffix}-${k}`);
</script>



<style scoped>
  .snapshots-backup-dark { @apply hidden; }
  .snapshots-backup-light { @apply block; }

  /*
  .snapshots-backup-dark { @apply transition opacity-100; }
  .snapshots-backup-light { @apply transition opacity-0; }

  svg:hover .snapshots-backup-dark { @apply opacity-0; }
  svg:hover .snapshots-backup-light { @apply opacity-100; }
  */

  .snapshots-backup-1 { fill: none; }
  .snapshots-backup-2 { fill: rgba(0, 0, 0, .15); }
  .snapshots-backup-3 { fill: #394051; }
  .snapshots-backup-4 { fill: #2b303d; }
  .snapshots-backup-5 { fill: #20b785; }
  .snapshots-backup-6 { fill: #a3abc3; }
  .snapshots-backup-7 { fill: #767f91; }
  .snapshots-backup-8 { fill: #979faf; }
  .snapshots-backup-9 { clip-path: v-bind(gradients[0]); }
  .snapshots-backup-10 { fill: #ccc; }
  .snapshots-backup-11 { fill: #cfd1d3; }
  .snapshots-backup-12 { fill: #cdd0d3; }
  .snapshots-backup-13 { fill: #cbced2; }
  .snapshots-backup-14 { fill: #c9ccd1; }
  .snapshots-backup-15 { fill: #c7cad0; }
  .snapshots-backup-16 { fill: #c5c9d0; }
  .snapshots-backup-17 { fill: #c3c7cf; }
  .snapshots-backup-18 { fill: #c1c5ce; }
  .snapshots-backup-19 { fill: #bfc3cd; }
  .snapshots-backup-20 { fill: #bdc2cd; }
  .snapshots-backup-21 { fill: #bbc0cc; }
  .snapshots-backup-22 { fill: #b9becb; }
  .snapshots-backup-23 { fill: #b7bcca; }
  .snapshots-backup-24 { fill: #b5bbca; }
  .snapshots-backup-25 { fill: #b3b9c9; }
  .snapshots-backup-26 { fill: #b1b7c8; }
  .snapshots-backup-27 { fill: #afb5c7; }
  .snapshots-backup-28 { fill: #adb4c7; }
  .snapshots-backup-29 { fill: #abb2c6; }
  .snapshots-backup-30 { fill: #a9b0c5; }
  .snapshots-backup-31 { fill: #a7aec4; }
  .snapshots-backup-32 { fill: #a5adc4; }
  .snapshots-backup-33 { fill: #f2f2f2; }
  .snapshots-backup-34 { fill: #86939b; }
  .snapshots-backup-35 { fill: #404956; }
  .snapshots-backup-36 { fill: #303847; }
  .snapshots-backup-37 { fill: v-bind(gradients[1]); }
  .snapshots-backup-38 { fill: v-bind(gradients[2]); }
  .snapshots-backup-39 { fill: v-bind(gradients[3]); }
  .snapshots-backup-40 { fill: v-bind(gradients[4]); }
  .snapshots-backup-41 { fill: #4b4d59; }
  .snapshots-backup-42 { fill: v-bind(gradients[5]); }
  .snapshots-backup-43 { fill: v-bind(gradients[6]); }
  .snapshots-backup-44 { fill: v-bind(gradients[7]); }
  .snapshots-backup-45 { fill: v-bind(gradients[8]); }
  .snapshots-backup-46 { fill: v-bind(gradients[9]); }
  .snapshots-backup-47 { fill: v-bind(gradients[10]); }
  .snapshots-backup-48 { fill: v-bind(gradients[11]); }
  .snapshots-backup-49 { fill: v-bind(gradients[12]); }
  .snapshots-backup-50 { fill: v-bind(gradients[13]); }
</style>

<template>
  <svg id="Both" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 372.43 365.48">
    <defs>
      <clipPath :id="slugs[0]" transform="translate(-628.06 -242.46)">
        <path class="snapshots-backup-1" d="M831.6,246.59c-9.53-5.5-25.12-5.5-34.64,0L699.32,303V350h0a3.45,3.45,0,0,0,2,2.79l108,62.37a10.87,10.87,0,0,0,9.81,0l108-62.37a3.43,3.43,0,0,0,2-2.79h0V303Z"/>
      </clipPath>
      <linearGradient :id="slugs[1]" x1="758.79" y1="391.67" x2="869.76" y2="391.67" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#404b5b"/>
        <stop offset="0.4" stop-color="#4d5470"/>
        <stop offset="1" stop-color="#3b3e49"/>
      </linearGradient>
      <linearGradient :id="slugs[2]" x1="814.27" y1="334.95" x2="814.27" y2="399.01" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#515c70"/>
        <stop offset="0.37" stop-color="#535e73"/>
        <stop offset="0.6" stop-color="#5a647b"/>
        <stop offset="0.78" stop-color="#656f89"/>
        <stop offset="0.95" stop-color="#757e9d"/>
        <stop offset="1" stop-color="#7c85a6"/>
      </linearGradient>
      <linearGradient :id="slugs[3]" x1="814.28" y1="334.95" x2="814.28" y2="397.31" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#515c70"/>
        <stop offset="0.38" stop-color="#545f73"/>
        <stop offset="0.66" stop-color="#5d687b"/>
        <stop offset="0.9" stop-color="#6d7689"/>
        <stop offset="1" stop-color="#767f91"/>
      </linearGradient>
      <linearGradient :id="slugs[4]" x1="769.2" y1="371.51" x2="859.35" y2="371.51" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#20b785"/>
        <stop offset="0.27" stop-color="#6bedc8"/>
        <stop offset="0.76" stop-color="#53dcab"/>
        <stop offset="1" stop-color="#23ad7b"/>
      </linearGradient>
      <linearGradient :id="slugs[5]" x1="758.79" y1="360.34" x2="869.76" y2="360.34" :xlink:href="`#${slugs[0]}`"/>
      <linearGradient :id="slugs[6]" x1="814.27" y1="303.76" x2="814.27" y2="367.83" :xlink:href="`#${slugs[2]}`"/>
      <linearGradient :id="slugs[7]" x1="814.28" y1="303.76" x2="814.28" y2="366.13" :xlink:href="`#${slugs[3]}`"/>
      <linearGradient :id="slugs[8]" x1="769.2" y1="340.33" x2="859.35" y2="340.33" :xlink:href="`#${slugs[4]}`"/>
      <linearGradient :id="slugs[9]" x1="758.79" y1="329.16" x2="869.76" y2="329.16" :xlink:href="`#${slugs[0]}`"/>
      <linearGradient :id="slugs[10]" x1="814.27" y1="272.58" x2="814.27" y2="336.65" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#6a7284"/>
        <stop offset="0.43" stop-color="#6b7387"/>
        <stop offset="0.69" stop-color="#70788f"/>
        <stop offset="0.91" stop-color="#77809d"/>
        <stop offset="1" stop-color="#7c85a6"/>
      </linearGradient>
      <linearGradient :id="slugs[11]" x1="814.28" y1="272.58" x2="814.28" y2="334.94" :xlink:href="`#${slugs[3]}`"/>
      <linearGradient :id="slugs[12]" x1="814.27" y1="279.67" x2="814.27" y2="325.02" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#28c984"/>
        <stop offset="0.07" stop-color="#2fcd8b"/>
        <stop offset="0.46" stop-color="#50deac"/>
        <stop offset="0.78" stop-color="#64e9c1"/>
        <stop offset="1" stop-color="#6bedc8"/>
      </linearGradient>
      <linearGradient :id="slugs[13]" x1="814.27" y1="285.16" x2="814.27" y2="319.53" :xlink:href="`#${slugs[3]}`"/>
    </defs>
    <path class="snapshots-backup-2" d="M797.84,604c9,5.22,23.83,5.22,32.87,0l153.52-88.63c9-5.22,9-13.77,0-19L830.71,407.76c-9-5.22-23.83-5.22-32.87,0L644.32,496.4c-9,5.22-9,13.77,0,19Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-3" d="M993.35,382.64l-6.14,3.54v62.37l6.14-3.54c4.76-2.75,7.14-6.37,7.14-10h0V372.64C1000.49,376.27,998.11,379.89,993.35,382.64Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-3" d="M635.21,382.64l6.13,3.54v62.37L635.21,445c-4.76-2.75-7.15-6.37-7.15-10h0V372.64C628.06,376.27,630.44,379.89,635.21,382.64Z" transform="translate(-628.06 -242.46)"/>
    <polygon class="snapshots-backup-3" points="203.53 233.57 203.53 295.93 365.29 202.55 365.29 140.18 203.53 233.57"/>
    <polygon class="snapshots-backup-3" points="168.9 233.57 168.9 295.93 7.14 202.55 7.14 140.18 168.9 233.57"/>
    <path class="snapshots-backup-3" d="M797,476v62.36c9.52,5.5,25.11,5.5,34.63,0V476C822.07,481.53,806.48,481.53,797,476Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-4" d="M797,538.4c9.53,5.5,25.12,5.5,34.64,0L993.35,445c9.52-5.5,9.52-14.5,0-20L831.6,331.62c-9.53-5.5-25.12-5.5-34.64,0L635.21,425c-9.53,5.5-9.53,14.5,0,20Z" transform="translate(-628.06 -242.46)"/>
    <polygon class="snapshots-backup-5" points="256.68 221.2 256.68 227.17 349.26 173.72 349.26 167.75 256.68 221.2"/>
    <polygon class="snapshots-backup-5" points="256.68 236.61 256.68 242.58 349.26 189.13 349.26 183.15 256.68 236.61"/>
    <path class="snapshots-backup-5" d="M861.59,475c6-3.49,11-.64,11,6.34s-5,15.54-11,19-11,.63-11-6.35S855.55,478.52,861.59,475Z" transform="translate(-628.06 -242.46)"/>
    <polygon class="snapshots-backup-5" points="152.87 242.74 152.87 248.72 60.29 195.26 60.29 189.29 152.87 242.74"/>
    <polygon class="snapshots-backup-5" points="152.87 258.15 152.87 264.12 60.29 210.67 60.29 204.7 152.87 258.15"/>
    <path class="snapshots-backup-5" d="M665.2,416.39c-6-3.49-11-.63-11,6.34s4.94,15.54,11,19,11,.63,11-6.34S671.24,419.88,665.2,416.39Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-6" d="M797,453.36c9.53,5.5,25.12,5.5,34.64,0L993.35,360c9.52-5.5,9.52-14.5,0-20L831.6,246.59c-9.53-5.5-25.12-5.5-34.64,0L635.21,340c-9.53,5.5-9.53,14.5,0,20Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-7" d="M993.35,360l-6.14,3.54v22.68l6.14-3.54c4.76-2.75,7.14-6.37,7.14-10h0V350C1000.49,353.6,998.11,357.22,993.35,360Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-7" d="M635.21,360l6.13,3.54v22.68l-6.13-3.54c-4.76-2.75-7.15-6.37-7.15-10h0V350C628.06,353.6,630.44,357.22,635.21,360Z" transform="translate(-628.06 -242.46)"/>
    <polygon class="snapshots-backup-7" points="203.53 210.9 203.53 233.57 365.29 140.19 365.29 117.51 203.53 210.9"/>
    <polygon class="snapshots-backup-7" points="168.9 210.9 168.9 233.57 7.14 140.19 7.14 117.51 168.9 210.9"/>
    <path class="snapshots-backup-7" d="M797,453.36V476c9.52,5.5,25.11,5.5,34.63,0V453.36C822.07,458.86,806.48,458.86,797,453.36Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-8" d="M993.35,440.76l-6.14,3.54L831.59,534.15c-9.52,5.5-25.11,5.5-34.63,0h0L641.34,444.3l-6.13-3.54c-4.76-2.75-7.15-6.37-7.15-10V435c0,3.62,2.39,7.24,7.15,10l6.13,3.54L797,538.4h0c9.52,5.5,25.11,5.5,34.63,0l155.62-89.85,6.14-3.54c4.76-2.75,7.14-6.37,7.14-10v-4.25C1000.49,434.39,998.11,438,993.35,440.76Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-7" d="M993.35,445l-6.14,3.54v22.68l6.14-3.54c4.76-2.75,7.14-6.37,7.14-10h0V435C1000.49,438.64,998.11,442.26,993.35,445Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-7" d="M635.21,445l6.13,3.54v22.68l-6.13-3.54c-4.76-2.75-7.15-6.37-7.15-10h0V435C628.06,438.64,630.44,442.26,635.21,445Z" transform="translate(-628.06 -242.46)"/>
    <polygon class="snapshots-backup-7" points="203.53 295.94 203.53 318.62 365.29 225.23 365.29 202.55 203.53 295.94"/>
    <polygon class="snapshots-backup-7" points="168.9 295.94 168.9 318.62 7.14 225.23 7.14 202.55 168.9 295.94"/>
    <path class="snapshots-backup-7" d="M797,538.4v22.68c9.52,5.5,25.11,5.5,34.63,0V538.4C822.07,543.9,806.48,543.9,797,538.4Z" transform="translate(-628.06 -242.46)"/>
    <g class="snapshots-backup-9">
      <path class="snapshots-backup-10" d="M831.6,291.94c-9.53-5.5-25.12-5.5-34.64,0l-97.64,56.37v11.33L797,303.28c9.52-5.5,25.11-5.5,34.64,0l97.63,56.36V348.31Z" transform="translate(-628.06 -242.46)"/>
      <path class="snapshots-backup-11" d="M831.6,290c-9.53-5.5-25.12-5.5-34.64,0l-97.64,56.36v11.34L797,301.3c9.52-5.5,25.11-5.5,34.64,0l97.63,56.37V346.33Z" transform="translate(-628.06 -242.46)"/>
      <path class="snapshots-backup-12" d="M831.6,288c-9.53-5.5-25.12-5.5-34.64,0l-97.64,56.37V355.7L797,299.33c9.52-5.5,25.11-5.5,34.64,0l97.63,56.37V344.36Z" transform="translate(-628.06 -242.46)"/>
      <path class="snapshots-backup-13" d="M831.6,286c-9.53-5.5-25.12-5.5-34.64,0l-97.64,56.37v11.34L797,297.36c9.52-5.5,25.11-5.5,34.64,0l97.63,56.37V342.39Z" transform="translate(-628.06 -242.46)"/>
      <path class="snapshots-backup-14" d="M831.6,284.05c-9.53-5.5-25.12-5.5-34.64,0l-97.64,56.37v11.34L797,295.39c9.52-5.5,25.11-5.5,34.64,0l97.63,56.37V340.42Z" transform="translate(-628.06 -242.46)"/>
      <path class="snapshots-backup-15" d="M831.6,282.08c-9.53-5.5-25.12-5.5-34.64,0l-97.64,56.37v11.34L797,293.42c9.52-5.5,25.11-5.5,34.64,0l97.63,56.37V338.45Z" transform="translate(-628.06 -242.46)"/>
      <path class="snapshots-backup-16" d="M831.6,280.11c-9.53-5.5-25.12-5.5-34.64,0l-97.64,56.36v11.34L797,291.45c9.52-5.5,25.11-5.5,34.64,0l97.63,56.36V336.47Z" transform="translate(-628.06 -242.46)"/>
      <path class="snapshots-backup-17" d="M831.6,278.13c-9.53-5.5-25.12-5.5-34.64,0L699.32,334.5v11.34L797,289.47c9.52-5.5,25.11-5.5,34.64,0l97.63,56.37V334.5Z" transform="translate(-628.06 -242.46)"/>
      <path class="snapshots-backup-18" d="M831.6,276.16c-9.53-5.5-25.12-5.5-34.64,0l-97.64,56.37v11.34L797,287.5c9.52-5.5,25.11-5.5,34.64,0l97.63,56.37V332.53Z" transform="translate(-628.06 -242.46)"/>
      <path class="snapshots-backup-19" d="M831.6,274.19c-9.53-5.5-25.12-5.5-34.64,0l-97.64,56.37V341.9L797,285.53c9.52-5.5,25.11-5.5,34.64,0l97.63,56.37V330.56Z" transform="translate(-628.06 -242.46)"/>
      <path class="snapshots-backup-20" d="M831.6,272.22c-9.53-5.5-25.12-5.5-34.64,0l-97.64,56.37v11.33L797,283.56c9.52-5.5,25.11-5.5,34.64,0l97.63,56.36V328.59Z" transform="translate(-628.06 -242.46)"/>
      <path class="snapshots-backup-21" d="M831.6,270.25c-9.53-5.5-25.12-5.5-34.64,0l-97.64,56.36V338L797,281.59c9.52-5.5,25.11-5.5,34.64,0L929.23,338V326.61Z" transform="translate(-628.06 -242.46)"/>
      <path class="snapshots-backup-22" d="M831.6,268.28c-9.53-5.5-25.12-5.5-34.64,0l-97.64,56.36V336L797,279.61c9.52-5.5,25.11-5.5,34.64,0L929.23,336V324.64Z" transform="translate(-628.06 -242.46)"/>
      <path class="snapshots-backup-23" d="M831.6,266.3c-9.53-5.5-25.12-5.5-34.64,0l-97.64,56.37V334L797,277.64c9.52-5.5,25.11-5.5,34.64,0L929.23,334V322.67Z" transform="translate(-628.06 -242.46)"/>
      <path class="snapshots-backup-24" d="M831.6,264.33c-9.53-5.5-25.12-5.5-34.64,0L699.32,320.7V332L797,275.67c9.52-5.5,25.11-5.5,34.64,0L929.23,332V320.7Z" transform="translate(-628.06 -242.46)"/>
      <path class="snapshots-backup-25" d="M831.6,262.36c-9.53-5.5-25.12-5.5-34.64,0l-97.64,56.37v11.34L797,273.7c9.52-5.5,25.11-5.5,34.64,0l97.63,56.37V318.73Z" transform="translate(-628.06 -242.46)"/>
      <path class="snapshots-backup-26" d="M831.6,260.39c-9.53-5.5-25.12-5.5-34.64,0l-97.64,56.36v11.34L797,271.73c9.52-5.5,25.11-5.5,34.64,0l97.63,56.36V316.75Z" transform="translate(-628.06 -242.46)"/>
      <path class="snapshots-backup-27" d="M831.6,258.42c-9.53-5.5-25.12-5.5-34.64,0l-97.64,56.36v11.34L797,269.75c9.52-5.5,25.11-5.5,34.64,0l97.63,56.37V314.78Z" transform="translate(-628.06 -242.46)"/>
      <path class="snapshots-backup-28" d="M831.6,256.44c-9.53-5.5-25.12-5.5-34.64,0l-97.64,56.37v11.34L797,267.78c9.52-5.5,25.11-5.5,34.64,0l97.63,56.37V312.81Z" transform="translate(-628.06 -242.46)"/>
      <path class="snapshots-backup-29" d="M831.6,254.47c-9.53-5.5-25.12-5.5-34.64,0l-97.64,56.37v11.34L797,265.81c9.52-5.5,25.11-5.5,34.64,0l97.63,56.37V310.84Z" transform="translate(-628.06 -242.46)"/>
      <path class="snapshots-backup-30" d="M831.6,252.5c-9.53-5.5-25.12-5.5-34.64,0l-97.64,56.37v11.34L797,263.84c9.52-5.5,25.11-5.5,34.64,0l97.63,56.37V308.87Z" transform="translate(-628.06 -242.46)"/>
      <path class="snapshots-backup-31" d="M831.6,250.53c-9.53-5.5-25.12-5.5-34.64,0L699.32,306.9v11.33L797,261.87c9.52-5.5,25.11-5.5,34.64,0l97.63,56.36V306.9Z" transform="translate(-628.06 -242.46)"/>
      <path class="snapshots-backup-32" d="M831.6,248.56c-9.53-5.5-25.12-5.5-34.64,0l-97.64,56.36v11.34L797,259.89c9.52-5.5,25.11-5.5,34.64,0l97.63,56.37V304.92Z" transform="translate(-628.06 -242.46)"/>
      <path class="snapshots-backup-6" d="M831.6,246.59c-9.53-5.5-25.12-5.5-34.64,0L699.32,303v11.34L797,257.92c9.52-5.5,25.11-5.5,34.64,0l97.63,56.37V303Z" transform="translate(-628.06 -242.46)"/>
    </g>
    <path class="snapshots-backup-33" d="M968.63,346.14a14.74,14.74,0,0,1,13.26,0c3.64,2.11,3.64,5.55,0,7.65a14.71,14.71,0,0,1-13.26,0C965,351.69,965,348.25,968.63,346.14Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-33" d="M807.65,253.2a14.66,14.66,0,0,1,13.25,0c3.65,2.1,3.65,5.55,0,7.65a14.66,14.66,0,0,1-13.25,0C804,258.75,804,255.3,807.65,253.2Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-33" d="M807.65,439.09a14.66,14.66,0,0,1,13.25,0c3.65,2.1,3.65,5.55,0,7.65a14.66,14.66,0,0,1-13.25,0C804,444.64,804,441.19,807.65,439.09Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-33" d="M646.66,346.14a14.74,14.74,0,0,1,13.26,0c3.64,2.11,3.64,5.55,0,7.66a14.74,14.74,0,0,1-13.26,0C643,351.69,643,348.25,646.66,346.14Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-34" d="M809.37,415.17a10.87,10.87,0,0,0,9.81,0l108-62.37c2.7-1.55,2.7-4.11,0-5.66l-108-62.37a10.89,10.89,0,0,0-9.82,0l-108,62.37c-2.7,1.55-2.7,4.11,0,5.66Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-35" d="M809.37,296.11,706.26,355.64l103.11,59.53a10.87,10.87,0,0,0,9.81,0l103.11-59.53-103.1-59.53A10.89,10.89,0,0,0,809.37,296.11Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-36" d="M831.6,331.62c-9.53-5.5-25.12-5.5-34.64,0l-66.15,38.19,78.56,45.36a10.87,10.87,0,0,0,9.81,0l78.56-45.36Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-37" d="M869.75,367c0,8.21-5.42,16.41-16.2,22.63-21.6,12.47-56.95,12.47-78.55,0-10.79-6.22-16.19-14.42-16.2-22.63h0v17a20.16,20.16,0,0,0,.13,2.06l44,25.41h0l6.45,3.72a6.81,6.81,0,0,0,1,.46c.34.13.7.24,1.06.34h0a11.74,11.74,0,0,0,5.71,0h0a9.15,9.15,0,0,0,1.05-.33,7.35,7.35,0,0,0,1-.46l6.45-3.72h0l44-25.41a20.16,20.16,0,0,0,.12-2.06h0V367Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-38" d="M775,344.3c-21.61,12.47-21.61,32.88,0,45.35s57,12.47,78.55,0,21.61-32.88,0-45.35S796.6,331.83,775,344.3Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-39" d="M776,344.05c-21,12.14-21,32,0,44.15s55.44,12.14,76.47,0,21-32,0-44.15S797.07,331.91,776,344.05Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-40" d="M859.35,350c0,6.66-4.41,13.32-13.16,18.37-17.55,10.14-46.28,10.14-63.83,0-8.75-5.05-13.14-11.71-13.16-18.37h0v17c0,6.68,4.38,13.36,13.16,18.43,17.55,10.13,46.28,10.13,63.83,0,8.77-5.07,13.16-11.74,13.16-18.42h0V350Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-41" d="M775,330.13c-21.61,12.47-21.61,32.88,0,45.35s57,12.48,78.55,0,21.61-32.88,0-45.35S796.6,317.65,775,330.13Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-42" d="M869.75,335.84c0,8.21-5.42,16.41-16.2,22.63C832,371,796.6,371,775,358.47c-10.79-6.22-16.19-14.42-16.2-22.63h0v17c0,8.22,5.4,16.45,16.21,22.68,21.6,12.48,57,12.48,78.55,0,10.81-6.24,16.21-14.46,16.2-22.68h0v-17Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-43" d="M775,313.12c-21.61,12.47-21.61,32.88,0,45.35s57,12.48,78.55,0,21.61-32.88,0-45.35S796.6,300.65,775,313.12Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-44" d="M776,312.87c-21,12.14-21,32,0,44.15s55.44,12.14,76.47,0,21-32,0-44.15S797.07,300.73,776,312.87Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-45" d="M859.35,318.84c0,6.66-4.41,13.32-13.16,18.37-17.55,10.14-46.28,10.14-63.83,0-8.75-5-13.14-11.71-13.16-18.37h0v17c0,6.68,4.38,13.37,13.16,18.43,17.55,10.14,46.28,10.14,63.83,0,8.77-5.06,13.16-11.74,13.16-18.42h0v-17Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-41" d="M775,299c-21.61,12.47-21.61,32.88,0,45.35s57,12.48,78.55,0,21.61-32.88,0-45.35S796.6,286.47,775,299Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-46" d="M869.75,304.66c0,8.2-5.42,16.41-16.2,22.63-21.6,12.48-56.95,12.48-78.55,0-10.79-6.22-16.19-14.43-16.2-22.63h0v17c0,8.22,5.4,16.44,16.21,22.68,21.6,12.48,57,12.48,78.55,0,10.81-6.24,16.21-14.46,16.2-22.68h0v-17Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-47" d="M775,281.94c-21.61,12.47-21.61,32.88,0,45.35s57,12.48,78.55,0,21.61-32.88,0-45.35S796.6,269.46,775,281.94Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-48" d="M776,281.69c-21,12.14-21,32,0,44.15s55.44,12.14,76.47,0,21-32,0-44.15S797.07,269.55,776,281.69Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-49" d="M786.47,286.29c-15.3,8.83-15.3,23.28,0,32.11s40.32,8.83,55.61,0,15.3-23.28,0-32.11S801.76,277.46,786.47,286.29Z" transform="translate(-628.06 -242.46)"/>
    <path class="snapshots-backup-50" d="M793.2,290.18c-11.59,6.69-11.59,17.64,0,24.33s30.56,6.7,42.15,0,11.59-17.64,0-24.33S804.79,283.49,793.2,290.18Z" transform="translate(-628.06 -242.46)"/>
  </svg>
</template>
