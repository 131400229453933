<script>
  export default {
    props: {
      url: {
        type: [String, Object],
        required: true
      }
    },

    computed: {
      isExternal () {
        return RegExp('^https?://|^//').test(this.url);
      },

      tag () {
        return this.isExternal ? 'a' : 'router-link';
      },

      attributes () {
        return this.isExternal
          ? { href: this.url, role: 'button', 'target': '_blank' }
          : { to: this.url, role: 'button' };
      },
    }
  }
</script>



<template>
  <li class="-mx-1">
    <component :is="tag" v-bind="attributes" class="p-1 inline-block transition hover:text-brand">
      <slot></slot>
    </component>
  </li>
</template>
