<script>
  export default {
    props: {
      orientation: {
        type: String,
        required: true,
        default: 'slope',
      }
    },

    computed: {
      getOrientationClasses () {
        const orientations = {
          hill: 'transform -scale-x-1',
          slope: 'transform scale-x-1',
        }

        return orientations[this.orientation.toLowerCase()]
      }
    }
  }
</script>



<template>
  <div class="block relative pb-[25px] lg:pb-[50px] 2xl:pb-[77px]">
    <svg class="w-full h-[25px] lg:h-[50px] 2xl:h-[77px] absolute bottom-full"
      :class="getOrientationClasses"
      viewBox="0 0 2000 77" fill="none" preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M2000 77L2000 75.5828L0 0L6.10352e-05 77H2000Z" fill="currentColor"/>
    </svg>

    <slot></slot>
  </div>
</template>
