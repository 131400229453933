export default {
  
  /**
   * Features
   */
  "heading": "Features",
  "meta": [
    { "name": "description", "content": "SEO description for the products page" }
  ],
  "preamble": [
    "Simple and cost-effective cloud computing resources without billing surprises. We've put together a bunch of different product groups to suit most workloads, but don't hesitate to reach out if you need something customized!"
  ],
  "pricing": "See Pricing",
  "readmore": "Click for more details",

  /**
   * Products
   */
  "products": {
    "general-compute": {
      "heading": "General Compute VMs",
      "icon": "x-icon-product-cpu-compute",
      "preamble": [
        "The traditional general-purpose cloud VM."
      ],
      "information": {
        "left": [
          {
            "type": "heading",
            "value": "About the product"
          },
          {
            "type": "paragraph",
            "value": "The traditional general-purpose cloud VM. It comes with shared, yet powerful, Intel vCPUs with DDR4 ECC memory. Along with all-flash enterprise NVMe storage, the performance is truly unbeatable. It allows you to have burstable capacity whenever needed without paying for resources you don't use constantly."
          },
        ],
        "right": [
          {
            "type": "heading",
            "value": "Hardware"
          },
          {
            "type": "paragraph",
            "value": "Running on enterprise grade servers with:"
          },
          {
            "type": "list",
            "value": [
              "CPU: Intel E5 v4 Series Processors",
              "Memory: DDR4 ECC memory",
              "NVMe: Samsung Enterprise in RAID-10",
              "Network: 2x 10 GbE",
            ]
          }
        ]
      }
    },
    "storage-vm": {
      "heading": "Storage VMs",
      "icon": "x-icon-product-object-storage",
      "preamble": [
        "High storage VM, with large amounts of disk storage."
      ],
      "information": {
        "left": [
          {
            "type": "heading",
            "value": "About the product"
          },
          {
            "type": "paragraph",
            "value": "This is perfect for storing large files and backups that do not need constant disk I/O."
          }
        ],
        "right": [
          {
            "type": "heading",
            "value": "Hardware"
          },
          {
            "type": "paragraph",
            "value": "Running on enterprise grade servers with:"
          },
          {
            "type": "list",
            "value": [
              "CPU: Intel E5 v2 Series Processors",
              "Memory: DDR3 ECC memory",
              "NVMe: Western Digital Enterprise SAS drives in RAID-5",
              "Network: 2x 10 GbE",
            ]
          }
        ]
      }
    },
    "high-cpu-compute": {
      "heading": "High-CPU Compute VMs",
      "icon": "x-icon-product-dedicated-cpu",
      "preamble": [
        "If you need 100% dedicated CPU resources without any neighbors, this is it."
      ],
      "information": {
        "left": [
          {
            "type": "heading",
            "value": "About the product"
          },
          {
            "type": "paragraph",
            "value": "You'll get your own AMD EPYC or Ryzen cores (up to 4.0 GHz boost clock). Feel free to run them at full load 24/7. We'll make sure to cool them for you."
          },
          {
            "type": "heading",
            "value": "When to Use"
          },
          {
            "type": "paragraph",
            "value": "Apps that needs a lot of CPU, Memory and/or NVMe resources such as high-traffic web servers and databases, video encoding, monitoring software, etc."
          }
        ],
        "right": [
          {
            "type": "heading",
            "value": "Hardware"
          },
          {
            "type": "paragraph",
            "value": "Running on enterprise grade servers with:"
          },
          {
            "type": "list",
            "value": [
              "CPU: AMD EPYC 7443 or equivalent",
              "2.85 GHz Base / 4.0 GHz Boost",
              "Memory: DDR4 3200MHz ECC",
              "NVMe: Samsung Enterprise Gen4 in RAID-10",
              "Network: 4x 10 GbE",
            ]
          }
        ]
      }
    },
  },


  /**
   * The Siberfy Difference
   */
  "difference": {
    "heading": "The Siberfy <span class='text-brand'>Difference</span>",
    "preamble": [],
    "button": "Get started for free today!",
    "points": [
      {
        "heading": "Edge Locations",
        "icon": "x-icon-flaticon-edge-locations",
        "description": "Bring your compute closer to the edge to decrease latency and improve the experience for your end users. Never seen before mix of quality, performance and pricing in Pakistan."
      },
      {
        "heading": "High Performance Networking",
        "icon": "x-icon-flaticon-fast-cloud",
        "description": "Our network is powered by redundant low-latency 10GbE Cisco Catalyst switches. All sites have redundant uplinks to the internet from high quality transit providers. 1GbE internet in Pakistan, first time on a public cloud offering."
      },
      {
        "heading": "All flash NVMe storage",
        "icon": "x-icon-flaticon-failover",
        "description": "Welcome to the future. No more spinning rust for your production data. Only true performance, and nothing less."
      },
      {
        "heading": "Based in Pakistan",
        "icon": "x-icon-flaticon-firewall",
        "description": "No more international round-trip to Europe for your users inside Pakistan. Your traffic remains local, and in most cases, under a few ms RTT rather than a few hundred."
      },
      {
        "heading": "Industry Leading Hardware",
        "icon": "x-icon-flaticon-cpu",
        "description": "We use the latest Supermicro and Dell hardware. Nothing less than the best. Then we combine that with enterprise grade NVMe drives."
      },
      {
        "heading": "24/7 Support",
        "icon": "x-icon-flaticon-helpdesk",
        "description": "Our technical support is always staffed to provide you with quick and friendly responses."
      }
    ]
  },

  /**
   * Control Panel
   */
  "controlpanel": {
    "heading": "Control Panel",
    "preamble": [
      "Nearly 5 years in the making, re-written from scratch for scalability, our cloud control panel known as Peregrine, will allow you to manage your VMs with ease of mind and peace. No clutter or mumbo jumbo. Just the right information and control over your servers that you need, right at your fingertips.",
    ],
  },

  /**
   * Network
   */
  "network": {
    "heading": "Network",
    "preamble": [
      "We offer 1GbE public networking, along with 10GbE private network, secured by VLANs. You can add a second network interface to your servers to communicate between them in the same location, away from the public internet, extremely fast, and secure. Our public internet connectivity is second to none, with extremely good paths inside and outside of Pakistan.",
    ],
  },

  /**
   * Images
   */
  "images": {
    "heading": "Deploy all major Linux Distros - or anything at all!",
    "preamble": [
      "We offer pre-ready cloud templates for all major Linux distributions. But that does not stop you from running any operating system out there on your very own virtual machine. You can upload any ISO using our control panel, and deploy it in your VMs with us, just like you would on a bare-metal or a physical server.",
    ],
  },
  
  /**
   * Data Centers
   */
  "data-centers": {
    "heading": "Data Centers",
    "preamble": [
      "Our datacenter in Lahore is located in the Gulberg area. Fed by 2 redundant power feeds, we run on true A+B power, coming from two separate UPS and generator sets. There are 3 redundant fiber paths for the fiber to enter the datacenter premises, and the datacenter itself is staffed 24/7/365. Mission critical is what we are built for.",
      "We connect with redundant 10GbE uplinks to our upstreams, ensuring congestion free and downtime-proof internet connectivity. Our network is based completely on enterprise grade Cisco equipment. In the near future, we plan to add more internet transit capacity, along with peerings at local IXPs to deliver even better domestic network performance."
    ]
  }

};
