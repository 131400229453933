<template>
  <svg version="1.1" class="fill-segment-light" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="44" height="44" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
    <g>
      <g>
        <path d="M256,106c-5.52,0-10,4.48-10,10s4.48,10,10,10s10-4.48,10-10S261.52,106,256,106z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M396,106H296c-5.522,0-10,4.477-10,10s4.478,10,10,10h90v260H126V126h90c5.523,0,10-4.477,10-10s-4.477-10-10-10H116
          c-5.523,0-10,4.477-10,10v280c0,5.523,4.477,10,10,10h280c5.522,0,10-4.477,10-10V116C406,110.477,401.522,106,396,106z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M502,206c5.522,0,10-4.477,10-10s-4.478-10-10-10h-56v-40h56c5.522,0,10-4.477,10-10s-4.478-10-10-10h-56V96
          c0-16.542-13.458-30-30-30h-30V10c0-5.523-4.478-10-10-10c-5.522,0-10,4.477-10,10v56h-40V10c0-5.523-4.478-10-10-10
          c-5.522,0-10,4.477-10,10v56h-40V10c0-5.523-4.478-10-10-10c-5.522,0-10,4.477-10,10v56h-40V10c0-5.523-4.477-10-10-10
          s-10,4.477-10,10v56h-40V10c0-5.523-4.477-10-10-10s-10,4.477-10,10v56H96c-16.542,0-30,13.458-30,30v30H10
          c-5.523,0-10,4.477-10,10s4.477,10,10,10h56v40H10c-5.523,0-10,4.477-10,10s4.477,10,10,10h56v40H10c-5.523,0-10,4.477-10,10
          s4.477,10,10,10h56v40H10c-5.523,0-10,4.477-10,10s4.477,10,10,10h56v40H10c-5.523,0-10,4.477-10,10s4.477,10,10,10h56v30
          c0,16.542,13.458,30,30,30h30v56c0,5.523,4.477,10,10,10s10-4.477,10-10v-56h40v56c0,5.523,4.477,10,10,10s10-4.477,10-10v-56h40
          v56c0,5.523,4.478,10,10,10c5.522,0,10-4.477,10-10v-56h40v56c0,5.523,4.478,10,10,10c5.522,0,10-4.477,10-10v-56h40v56
          c0,5.523,4.478,10,10,10c5.522,0,10-4.477,10-10v-56h30c16.542,0,30-13.458,30-30v-30h56c5.522,0,10-4.477,10-10s-4.478-10-10-10
          h-56v-40h56c5.522,0,10-4.477,10-10s-4.478-10-10-10h-56v-40h56c5.522,0,10-4.477,10-10s-4.478-10-10-10h-56v-40H502z M426,416
          c0,5.514-4.486,10-10,10H96c-5.514,0-10-4.486-10-10V96c0-5.514,4.486-10,10-10h320c5.514,0,10,4.486,10,10V416z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M349.325,293.593c-2.296-5.024-8.228-7.234-13.252-4.938c-5.022,2.296-7.233,8.229-4.938,13.252
          c1.423,3.115,1.16,6.582-0.723,9.512c-1.87,2.912-4.934,4.582-8.403,4.582h-71.867l2.929-2.929c3.905-3.905,3.905-10.237,0-14.143
          c-3.906-3.905-10.237-3.905-14.143,0l-20,20c-0.022,0.022-0.04,0.047-0.062,0.069c-3.863,3.934-3.757,10.178,0,14.005
          c0.022,0.022,0.04,0.047,0.062,0.069l20,20c3.905,3.905,10.236,3.905,14.143,0c3.905-3.905,3.905-10.237,0-14.143L250.142,336
          h71.867c10.26,0,19.691-5.148,25.23-13.771C352.791,313.587,353.571,302.882,349.325,293.593z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M329.113,225.127c-5.371-1.245-10.75,2.111-11.994,7.492l-0.456,1.974l-34.439-61.98
          c-4.871-8.767-13.729-14.497-23.697-15.328c-10.01-0.835-19.706,3.311-25.911,11.088c-3.447,4.317-2.74,10.609,1.578,14.054
          c4.316,3.444,10.608,2.737,14.053-1.581c2.036-2.552,5.252-3.91,8.616-3.629c3.356,0.279,6.227,2.142,7.876,5.11l36.315,65.355
          l-5.875-1.356c-5.378-1.241-10.751,2.113-11.993,7.495c-1.241,5.381,2.114,10.75,7.495,11.992l27.561,6.361
          c2.401,0.553,4.894,0.21,7.056-0.976c0.005-0.003,0.011-0.005,0.016-0.007c3.159-1.67,5.045-4.794,5.291-8.113l6-25.956
          C337.848,231.741,334.495,226.371,329.113,225.127z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M237.133,235.692l-7.767-27.07c-1.496-5.384-7.068-8.493-12.4-6.965l-27.19,7.79c-5.309,1.521-8.38,7.058-6.859,12.368
          s7.058,8.383,12.368,6.859l3.988-1.143l-35.502,63.9c-4.466,8.033-5.027,17.493-1.539,25.956
          c3.522,8.546,10.576,14.903,19.352,17.44c5.307,1.536,10.851-1.527,12.384-6.829c1.534-5.305-1.523-10.85-6.829-12.384
          c-2.89-0.835-5.228-2.967-6.416-5.848c-0.678-1.645-1.51-4.95,0.529-8.619l35.514-63.921l1.143,3.982
          c1.528,5.326,7.078,8.373,12.37,6.854C235.588,246.539,238.656,241,237.133,235.692z"/>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    //
  }
</script>
