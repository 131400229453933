<template>
  <svg width="39" height="45" viewBox="0 0 39 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M37.6799 7.7705C31.4884 6.07404 25.2606 3.43249 19.6696 0.131506C19.3726 -0.0438353 19.0041 -0.0438353 18.7072 0.131506C12.955 3.52733 7.06366 6.02597 0.696444 7.7705C0.285116 7.88309 0 8.25698 0 8.68351V18.3555C0 28.3155 4.59482 34.9083 8.44952 38.6851C12.5994 42.7513 17.4333 45 19.1884 45C20.9433 45 25.7773 42.7513 29.9271 38.6851C33.7817 34.9084 38.3763 28.3156 38.3763 18.3555V8.68342C38.3763 8.25698 38.0912 7.88309 37.6799 7.7705ZM36.4831 18.3554C36.4831 27.658 32.1975 33.8099 28.6021 37.3327C24.5212 41.3313 20.1581 43.1067 19.1884 43.1067C18.2187 43.1067 13.8553 41.3313 9.77438 37.3327C6.17905 33.8098 1.89325 27.658 1.89325 18.3554V9.40201C7.98212 7.67321 13.6526 5.26053 19.1886 2.04347C24.5863 5.17519 30.5397 7.70784 36.4831 9.40166V18.3554Z" fill="black"/>
    <path d="M37.6799 7.7705C31.4884 6.07404 25.2606 3.43249 19.6696 0.131506C19.3726 -0.0438353 19.0041 -0.0438353 18.7072 0.131506C12.955 3.52733 7.06366 6.02597 0.696444 7.7705C0.285116 7.88309 0 8.25698 0 8.68351V18.3555C0 28.3155 4.59482 34.9083 8.44952 38.6851C12.5994 42.7513 17.4333 45 19.1884 45C20.9433 45 25.7773 42.7513 29.9271 38.6851C33.7817 34.9084 38.3763 28.3156 38.3763 18.3555V8.68342C38.3763 8.25698 38.0912 7.88309 37.6799 7.7705ZM36.4831 18.3554C36.4831 27.658 32.1975 33.8099 28.6021 37.3327C24.5212 41.3313 20.1581 43.1067 19.1884 43.1067C18.2187 43.1067 13.8553 41.3313 9.77438 37.3327C6.17905 33.8098 1.89325 27.658 1.89325 18.3554V9.40201C7.98212 7.67321 13.6526 5.26053 19.1886 2.04347C24.5863 5.17519 30.5397 7.70784 36.4831 9.40166V18.3554Z" fill="#232634"/>
    <path d="M37.6799 7.7705C31.4884 6.07404 25.2606 3.43249 19.6696 0.131506C19.3726 -0.0438353 19.0041 -0.0438353 18.7072 0.131506C12.955 3.52733 7.06366 6.02597 0.696444 7.7705C0.285116 7.88309 0 8.25698 0 8.68351V18.3555C0 28.3155 4.59482 34.9083 8.44952 38.6851C12.5994 42.7513 17.4333 45 19.1884 45C20.9433 45 25.7773 42.7513 29.9271 38.6851C33.7817 34.9084 38.3763 28.3156 38.3763 18.3555V8.68342C38.3763 8.25698 38.0912 7.88309 37.6799 7.7705ZM36.4831 18.3554C36.4831 27.658 32.1975 33.8099 28.6021 37.3327C24.5212 41.3313 20.1581 43.1067 19.1884 43.1067C18.2187 43.1067 13.8553 41.3313 9.77438 37.3327C6.17905 33.8098 1.89325 27.658 1.89325 18.3554V9.40201C7.98212 7.67321 13.6526 5.26053 19.1886 2.04347C24.5863 5.17519 30.5397 7.70784 36.4831 9.40166V18.3554Z" fill="url(#icon_ddosprotection0_linear)"/>
    <path d="M10.798 9.2961C10.6064 8.80963 10.0568 8.57066 9.57021 8.76235C7.8792 9.42864 6.14354 10.0419 4.41148 10.5853C4.01677 10.7092 3.74817 11.0748 3.74817 11.4884V15.1439C3.74817 15.6667 4.17207 16.0905 4.69475 16.0905C5.21744 16.0905 5.64133 15.6667 5.64133 15.1439V12.1802C7.19515 11.6775 8.74729 11.1214 10.2642 10.5237C10.7507 10.3322 10.9896 9.78258 10.798 9.2961Z" fill="black"/>
    <path d="M10.798 9.2961C10.6064 8.80963 10.0568 8.57066 9.57021 8.76235C7.8792 9.42864 6.14354 10.0419 4.41148 10.5853C4.01677 10.7092 3.74817 11.0748 3.74817 11.4884V15.1439C3.74817 15.6667 4.17207 16.0905 4.69475 16.0905C5.21744 16.0905 5.64133 15.6667 5.64133 15.1439V12.1802C7.19515 11.6775 8.74729 11.1214 10.2642 10.5237C10.7507 10.3322 10.9896 9.78258 10.798 9.2961Z" fill="#232634"/>
    <path d="M10.798 9.2961C10.6064 8.80963 10.0568 8.57066 9.57021 8.76235C7.8792 9.42864 6.14354 10.0419 4.41148 10.5853C4.01677 10.7092 3.74817 11.0748 3.74817 11.4884V15.1439C3.74817 15.6667 4.17207 16.0905 4.69475 16.0905C5.21744 16.0905 5.64133 15.6667 5.64133 15.1439V12.1802C7.19515 11.6775 8.74729 11.1214 10.2642 10.5237C10.7507 10.3322 10.9896 9.78258 10.798 9.2961Z" fill="url(#icon_ddosprotection1_linear)"/>
    <path d="M12.5961 9.47566C12.7231 9.47566 12.852 9.45009 12.9757 9.39612L12.9931 9.38856C13.472 9.17868 13.6877 8.62119 13.4778 8.14245C13.2678 7.66354 12.7071 7.44689 12.2287 7.6566L12.2134 7.66319C11.7343 7.87245 11.5179 8.4295 11.7271 8.90851C11.8824 9.26411 12.2309 9.47566 12.5961 9.47566Z" fill="black"/>
    <path d="M12.5961 9.47566C12.7231 9.47566 12.852 9.45009 12.9757 9.39612L12.9931 9.38856C13.472 9.17868 13.6877 8.62119 13.4778 8.14245C13.2678 7.66354 12.7071 7.44689 12.2287 7.6566L12.2134 7.66319C11.7343 7.87245 11.5179 8.4295 11.7271 8.90851C11.8824 9.26411 12.2309 9.47566 12.5961 9.47566Z" fill="#232634"/>
    <path d="M12.5961 9.47566C12.7231 9.47566 12.852 9.45009 12.9757 9.39612L12.9931 9.38856C13.472 9.17868 13.6877 8.62119 13.4778 8.14245C13.2678 7.66354 12.7071 7.44689 12.2287 7.6566L12.2134 7.66319C11.7343 7.87245 11.5179 8.4295 11.7271 8.90851C11.8824 9.26411 12.2309 9.47566 12.5961 9.47566Z" fill="url(#icon_ddosprotection2_linear)"/>
    <path d="M30.4664 30.5229C30.029 30.237 29.4424 30.3599 29.1562 30.7975C28.3761 31.9914 27.461 33.1347 26.4359 34.1953C25.5928 35.0673 24.6793 35.8801 23.7207 36.611C23.305 36.9279 23.225 37.5218 23.5419 37.9377C23.7283 38.1819 24.0102 38.3104 24.2953 38.3104C24.4955 38.3104 24.6972 38.2472 24.8686 38.1164C25.9026 37.328 26.8879 36.4514 27.7968 35.5112C28.9052 34.3647 29.8957 33.1272 30.7411 31.8331C31.0272 31.3954 30.904 30.8088 30.4664 30.5229Z" fill="black"/>
    <path d="M30.4664 30.5229C30.029 30.237 29.4424 30.3599 29.1562 30.7975C28.3761 31.9914 27.461 33.1347 26.4359 34.1953C25.5928 35.0673 24.6793 35.8801 23.7207 36.611C23.305 36.9279 23.225 37.5218 23.5419 37.9377C23.7283 38.1819 24.0102 38.3104 24.2953 38.3104C24.4955 38.3104 24.6972 38.2472 24.8686 38.1164C25.9026 37.328 26.8879 36.4514 27.7968 35.5112C28.9052 34.3647 29.8957 33.1272 30.7411 31.8331C31.0272 31.3954 30.904 30.8088 30.4664 30.5229Z" fill="#232634"/>
    <path d="M30.4664 30.5229C30.029 30.237 29.4424 30.3599 29.1562 30.7975C28.3761 31.9914 27.461 33.1347 26.4359 34.1953C25.5928 35.0673 24.6793 35.8801 23.7207 36.611C23.305 36.9279 23.225 37.5218 23.5419 37.9377C23.7283 38.1819 24.0102 38.3104 24.2953 38.3104C24.4955 38.3104 24.6972 38.2472 24.8686 38.1164C25.9026 37.328 26.8879 36.4514 27.7968 35.5112C28.9052 34.3647 29.8957 33.1272 30.7411 31.8331C31.0272 31.3954 30.904 30.8088 30.4664 30.5229Z" fill="url(#icon_ddosprotection3_linear)"/>
    <path d="M21.5223 38.0955L21.468 38.1275C21.0157 38.3895 20.8611 38.9685 21.123 39.4209C21.2985 39.7241 21.6162 39.8934 21.9431 39.8934C22.1039 39.8934 22.2672 39.8523 22.4164 39.7658L22.479 39.729C22.9302 39.4648 23.0817 38.8849 22.8175 38.4338C22.5531 37.9828 21.9732 37.8316 21.5223 38.0955Z" fill="black"/>
    <path d="M21.5223 38.0955L21.468 38.1275C21.0157 38.3895 20.8611 38.9685 21.123 39.4209C21.2985 39.7241 21.6162 39.8934 21.9431 39.8934C22.1039 39.8934 22.2672 39.8523 22.4164 39.7658L22.479 39.729C22.9302 39.4648 23.0817 38.8849 22.8175 38.4338C22.5531 37.9828 21.9732 37.8316 21.5223 38.0955Z" fill="#232634"/>
    <path d="M21.5223 38.0955L21.468 38.1275C21.0157 38.3895 20.8611 38.9685 21.123 39.4209C21.2985 39.7241 21.6162 39.8934 21.9431 39.8934C22.1039 39.8934 22.2672 39.8523 22.4164 39.7658L22.479 39.729C22.9302 39.4648 23.0817 38.8849 22.8175 38.4338C22.5531 37.9828 21.9732 37.8316 21.5223 38.0955Z" fill="url(#icon_ddosprotection4_linear)"/>
    <path d="M12.736 20.568C12.1548 19.9867 11.3818 19.6668 10.5597 19.6668C9.73755 19.6668 8.96447 19.9868 8.38299 20.568C7.18302 21.7683 7.18302 23.7212 8.38299 24.9213L13.9725 30.5107C14.5538 31.0918 15.3268 31.4118 16.149 31.4118C16.9712 31.4118 17.7442 31.0918 18.3256 30.5105L29.994 18.842C31.1939 17.6415 31.1939 15.6888 29.9938 14.489C29.4125 13.9077 28.6395 13.5878 27.8172 13.5878C26.995 13.5878 26.222 13.9078 25.6406 14.489L16.1489 23.9807L12.736 20.568ZM26.9794 15.8278C27.2031 15.6041 27.5007 15.481 27.8173 15.481C28.1341 15.481 28.4315 15.6041 28.6554 15.8278C29.1172 16.2896 29.1172 17.0414 28.6552 17.5035L16.9869 29.1718C16.7633 29.3955 16.4656 29.5186 16.149 29.5186C15.8324 29.5186 15.5347 29.3955 15.311 29.1718L9.72165 23.5827C9.25961 23.1206 9.25961 22.3688 9.72147 21.907C9.94524 21.6833 10.2429 21.56 10.5596 21.56C10.8763 21.56 11.1738 21.6831 11.3975 21.9068L15.4795 25.989C15.6572 26.1664 15.8978 26.2662 16.1489 26.2662C16.4 26.2662 16.6406 26.1665 16.8181 25.989L26.9794 15.8278Z" fill="black"/>
    <path d="M12.736 20.568C12.1548 19.9867 11.3818 19.6668 10.5597 19.6668C9.73755 19.6668 8.96447 19.9868 8.38299 20.568C7.18302 21.7683 7.18302 23.7212 8.38299 24.9213L13.9725 30.5107C14.5538 31.0918 15.3268 31.4118 16.149 31.4118C16.9712 31.4118 17.7442 31.0918 18.3256 30.5105L29.994 18.842C31.1939 17.6415 31.1939 15.6888 29.9938 14.489C29.4125 13.9077 28.6395 13.5878 27.8172 13.5878C26.995 13.5878 26.222 13.9078 25.6406 14.489L16.1489 23.9807L12.736 20.568ZM26.9794 15.8278C27.2031 15.6041 27.5007 15.481 27.8173 15.481C28.1341 15.481 28.4315 15.6041 28.6554 15.8278C29.1172 16.2896 29.1172 17.0414 28.6552 17.5035L16.9869 29.1718C16.7633 29.3955 16.4656 29.5186 16.149 29.5186C15.8324 29.5186 15.5347 29.3955 15.311 29.1718L9.72165 23.5827C9.25961 23.1206 9.25961 22.3688 9.72147 21.907C9.94524 21.6833 10.2429 21.56 10.5596 21.56C10.8763 21.56 11.1738 21.6831 11.3975 21.9068L15.4795 25.989C15.6572 26.1664 15.8978 26.2662 16.1489 26.2662C16.4 26.2662 16.6406 26.1665 16.8181 25.989L26.9794 15.8278Z" fill="#232634"/>
    <path d="M12.736 20.568C12.1548 19.9867 11.3818 19.6668 10.5597 19.6668C9.73755 19.6668 8.96447 19.9868 8.38299 20.568C7.18302 21.7683 7.18302 23.7212 8.38299 24.9213L13.9725 30.5107C14.5538 31.0918 15.3268 31.4118 16.149 31.4118C16.9712 31.4118 17.7442 31.0918 18.3256 30.5105L29.994 18.842C31.1939 17.6415 31.1939 15.6888 29.9938 14.489C29.4125 13.9077 28.6395 13.5878 27.8172 13.5878C26.995 13.5878 26.222 13.9078 25.6406 14.489L16.1489 23.9807L12.736 20.568ZM26.9794 15.8278C27.2031 15.6041 27.5007 15.481 27.8173 15.481C28.1341 15.481 28.4315 15.6041 28.6554 15.8278C29.1172 16.2896 29.1172 17.0414 28.6552 17.5035L16.9869 29.1718C16.7633 29.3955 16.4656 29.5186 16.149 29.5186C15.8324 29.5186 15.5347 29.3955 15.311 29.1718L9.72165 23.5827C9.25961 23.1206 9.25961 22.3688 9.72147 21.907C9.94524 21.6833 10.2429 21.56 10.5596 21.56C10.8763 21.56 11.1738 21.6831 11.3975 21.9068L15.4795 25.989C15.6572 26.1664 15.8978 26.2662 16.1489 26.2662C16.4 26.2662 16.6406 26.1665 16.8181 25.989L26.9794 15.8278Z" fill="url(#icon_ddosprotection5_linear)"/>
    <defs>
      <linearGradient id="icon_ddosprotection0_linear" x1="19.1881" y1="0" x2="19.1881" y2="45" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="icon_ddosprotection1_linear" x1="19.1881" y1="0" x2="19.1881" y2="45" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="icon_ddosprotection2_linear" x1="19.1881" y1="0" x2="19.1881" y2="45" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="icon_ddosprotection3_linear" x1="19.1881" y1="0" x2="19.1881" y2="45" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="icon_ddosprotection4_linear" x1="19.1881" y1="0" x2="19.1881" y2="45" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="icon_ddosprotection5_linear" x1="19.1881" y1="0" x2="19.1881" y2="45" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>
</template>



<script>
  export default {
    //
  }
</script>
