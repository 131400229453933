export default {

  /**
   * Pricing
   */
  "heading": "Pricing",
  "meta": [
    { "name": "description", "content": "SEO description for the pricing page" }
  ],
  "preamble": [
    "Simple and cost-effective cloud computing resources without billing surprises. We've put together a bunch of different product groups to suit most workloads, but don't hesitate to reach out if you need something customized!"
  ],
  "readmore": "Read more...",
  "optimizations": "Optimizations",
  "pricing": "Pricing",
  "month": "Month",
  "empty": "There are currently no packages available for this product.",
  "packages": {
    "compute": {

      /**
       * General Compute VMs
       */
      "general-compute": {
        "heading": "General Compute VMs",
        "icon": "x-icon-product-cpu-compute",
        "description": "The traditional general-purpose cloud VM. It comes with shared, yet powerful, Intel vCPUs with DDR4 ECC memory. Along with all-flash enterprise NVMe storage, the performance is truly unbeatable. It allows you to have burstable capacity whenever needed without paying for resources you don't use constantly.",
        "headers": {
          "alias": { "label": "ALIAS", "width": "25%" },
          "cpu": { "label": "vCPU" },
          "memory": { "label": "RAM" },
          "storage": { "label": "STORAGE" },
          "bandwidth": { "label": "BANDWIDTH", "width": "20%" }
        },
        "packages": [
          {
            "alias": "general-compute-1",
            "cpu": "1",
            "memory": "2 GB",
            "storage": "20 GB NVMe",
            "bandwidth": "1 TB",
            "url": "#",
            "pricing": {
              "USD": 5
            }
          },
          {
            "alias": "general-compute-2",
            "cpu": "2",
            "memory": "4 GB",
            "storage": "40 GB NVMe",
            "bandwidth": "2 TB",
            "url": "#",
            "pricing": {
              "USD": 10
            }
          },
          {
            "alias": "general-compute-3",
            "cpu": "2",
            "memory": "8 GB",
            "storage": "60 GB NVMe",
            "bandwidth": "3 TB",
            "url": "#",
            "pricing": {
              "USD": 15
            }
          },
          {
            "alias": "general-compute-4",
            "cpu": "4",
            "memory": "16 GB",
            "storage": "80 GB NVMe",
            "bandwidth": "4 TB",
            "url": "#",
            "pricing": {
              "USD": 25
            }
          },
          {
            "alias": "general-compute-5",
            "cpu": "4",
            "memory": "32 GB",
            "storage": "120 GB NVMe",
            "bandwidth": "4 TB",
            "url": "#",
            "pricing": {
              "USD": 45
            }
          },
          {
            "alias": "general-compute-6",
            "cpu": "6",
            "memory": "48 GB",
            "storage": "150 GB NVMe",
            "bandwidth": "5 TB",
            "url": "#",
            "pricing": {
              "USD": 80
            }
          },
          {
            "alias": "general-compute-7",
            "cpu": "6",
            "memory": "64 GB",
            "storage": "200 GB NVMe",
            "bandwidth": "5 TB",
            "url": "#",
            "pricing": {
              "USD": 150
            }
          },
          {
            "alias": "general-compute-8",
            "cpu": "8",
            "memory": "96 GB",
            "storage": "250 GB NVMe",
            "bandwidth": "10 TB",
            "url": "#",
            "pricing": {
              "USD": 275
            }
          },
          {
            "alias": "general-compute-9",
            "cpu": "8",
            "memory": "128 GB",
            "storage": "480 GB NVMe",
            "bandwidth": "15 TB",
            "url": "#",
            "pricing": {
              "USD": 400
            }
          },
        ]
      },

      /**
       * Storage VMs
       */
      "storage-vms": {
        "heading": "Storage VMs",
        "icon": "x-icon-product-object-storage",
        "description": "High storage VM, with large amounts of disk storage. This is perfect for storing large files and backups that do not need constant disk I/O.",
        "headers": {
          "alias": { "label": "ALIAS", "width": "25%" },
          "cpu": { "label": "vCPU" },
          "memory": { "label": "RAM" },
          "storage": { "label": "STORAGE" },
          "bandwidth": { "label": "BANDWIDTH", "width": "20%" }
        },
        "extra": "Additional increments available. For large (50+ TB) deployments, please contact us.",
        "packages": [
          {
            "alias": "storage-vm-1",
            "cpu": "1",
            "memory": "1 GB",
            "storage": "1 TB",
            "bandwidth": "1 TB",
            "url": "#",
            "pricing": {
              "USD": 5
            }
          },
          {
            "alias": "storage-vm-2",
            "cpu": "1",
            "memory": "1 GB",
            "storage": "2 TB",
            "bandwidth": "2 TB",
            "url": "#",
            "pricing": {
              "USD": 10
            }
          },
          {
            "alias": "storage-vm-3",
            "cpu": "1",
            "memory": "2 GB",
            "storage": "3 TB",
            "bandwidth": "3 TB",
            "url": "#",
            "pricing": {
              "USD": 15
            }
          },
          {
            "alias": "storage-vm-4",
            "cpu": "1",
            "memory": "2 GB",
            "storage": "4 TB",
            "bandwidth": "4 TB",
            "url": "#",
            "pricing": {
              "USD": 20
            }
          },
          {
            "alias": "storage-vm-5",
            "cpu": "1",
            "memory": "4 GB",
            "storage": "5 TB",
            "bandwidth": "5 TB",
            "url": "#",
            "pricing": {
              "USD": 25
            }
          },
        ]
      },

      /**
       * High-CPU Compute VMs
       */
      "high-cpu-compute": {
        "heading": "High-CPU Compute VMs",
        "icon": "x-icon-product-dedicated-cpu",
        "description": "If you need 100% dedicated CPU resources without any neighbors, this is it. You'll get your own AMD EPYC or Ryzen cores (up to 4.0 GHz boost clock). Feel free to run them at full load 24/7. We'll make sure to cool them for you.",
        "headers": {
          "alias": { "label": "ALIAS", "width": "25%" },
          "cpu": { "label": "vCPU" },
          "memory": { "label": "RAM" },
          "storage": { "label": "STORAGE" },
          "bandwidth": { "label": "BANDWIDTH", "width": "20%" }
        },
        "packages": [
          {
            "alias": "high-cpu-compute-1",
            "cpu": "1 dedicated",
            "memory": "4 GB",
            "storage": "25 GB NVMe",
            "bandwidth": "1 TB",
            "url": "#",
            "pricing": {
              "USD": 15
            }
          },
          {
            "alias": "high-cpu-compute-2",
            "cpu": "2 dedicated",
            "memory": "8 GB",
            "storage": "50 GB NVMe",
            "bandwidth": "2 TB",
            "url": "#",
            "pricing": {
              "USD": 30
            }
          },
          {
            "alias": "high-cpu-compute-3",
            "cpu": "3 dedicated",
            "memory": "12 GB",
            "storage": "75 GB NVMe",
            "bandwidth": "3 TB",
            "url": "#",
            "pricing": {
              "USD": 45
            }
          },
          {
            "alias": "high-cpu-compute-4",
            "cpu": "4 dedicated",
            "memory": "16 GB",
            "storage": "100 GB NVMe",
            "bandwidth": "4 TB",
            "url": "#",
            "pricing": {
              "USD": 60
            }
          },
          {
            "alias": "high-cpu-compute-5",
            "cpu": "5 dedicated",
            "memory": "24 GB",
            "storage": "125 GB NVMe",
            "bandwidth": "4 TB",
            "url": "#",
            "pricing": {
              "USD": 75
            }
          },
          {
            "alias": "high-cpu-compute-6",
            "cpu": "6 dedicated",
            "memory": "32 GB",
            "storage": "150 GB NVMe",
            "bandwidth": "5 TB",
            "url": "#",
            "pricing": {
              "USD": 90
            }
          },
        ]
      },

    }
  },

};
