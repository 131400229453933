<script>
  export default {
    props: {
      iconPosition: {
        type: String,
        default: 'top',
        required: true
      }
    },

    computed: {
      position () {
        return this.iconPosition.toLowerCase();
      },

      isTop () {
        return this.position === 'top';
      },

      isLeft () {
        return this.position === 'left';
      }
    }
  }
</script>



<style scoped>
  /* Top positioned icon */
  article.top {
    @apply flex-grow flex flex-col items-center text-center;
  }

  article.top figure {
    @apply mb-8 h-24 w-24 flex items-center justify-center;
  }

  article.top h3 {
    @apply text-lg md:text-2xl;
  }

  /* Top-left positioned icon */
  article.top-left {
    @apply flex flex-row items-center text-left pr-16;
    @apply lg:flex-grow lg:flex-col lg:items-start lg:pr-0;
  }

  article.top-left figure {
    @apply mr-8 h-24 w-24 flex items-center justify-center;
    @apply lg:mr-0 mb-8; 
  }

  article.top-left h3 {
    @apply text-xl;
    @apply lg:text-2xl;
  }

  /* Left positioned icon */
  article.left {
    @apply flex flex-row items-center text-left lg:pr-16;
  }

  article.left figure {
    @apply mr-8 h-24 w-24 flex items-center justify-center;
  }

  article.left h3 {
    @apply text-xl;
  }
</style>



<template>
  <article :class="position">
    <figure>
      <x-icon-blob>
        <slot name="icon"></slot>
      </x-icon-blob>
    </figure>

    <section>
      <h3 class="text-white mb-3">
        <slot name="heading"></slot>
      </h3>

      <div class="text-md leading-relaxed">
        <slot></slot>
      </div>
    </section>
  </article>
</template>
