<template>
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 13.0859V8.39844C0 7.31973 0.874414 6.44531 1.95312 6.44531C3.03184 6.44531 3.90625 7.31973 3.90625 8.39844V11.576C5.82803 8.55898 8.38037 5.97861 11.4105 4.01279C15.4572 1.3876 20.1563 0 25 0C31.6777 0 37.9558 2.60039 42.6776 7.32227C47.3995 12.0441 50 18.3224 50 25C50 26.0787 49.1256 26.9531 48.0469 26.9531C46.9682 26.9531 46.0938 26.0787 46.0938 25C46.0938 13.3688 36.6312 3.90625 25 3.90625C17.6108 3.90625 10.7548 7.82773 6.96025 14.0625H9.57031C10.649 14.0625 11.5234 14.9369 11.5234 16.0156C11.5234 17.0943 10.649 17.9688 9.57031 17.9688H4.88281C2.19043 17.9688 0 15.7784 0 13.0859ZM45.1172 32.0312H40.332C39.2534 32.0312 38.3789 32.9058 38.3789 33.9844C38.3789 35.063 39.2533 35.9375 40.332 35.9375H42.9421C39.1477 42.1723 32.2916 46.0938 24.9023 46.0938C13.3251 46.0938 3.90625 36.6312 3.90625 25C3.90625 23.9213 3.03184 23.0469 1.95312 23.0469C0.874414 23.0469 0 23.9213 0 25C0 31.6815 2.58174 37.9582 7.26943 42.6736C11.9663 47.398 18.2284 50 24.9023 50C29.7461 50 34.4452 48.6124 38.4918 45.9872C41.5733 43.9882 44.1612 41.3541 46.0938 38.2711V41.5039C46.0938 42.5826 46.9682 43.457 48.0469 43.457C49.1256 43.457 50 42.5826 50 41.5039V36.9141C50 34.2217 47.8096 32.0312 45.1172 32.0312ZM19.4336 36.9141C19.4321 37.2213 19.6986 37.4037 20.0401 37.4023C20.28 37.4014 20.5333 37.3052 20.7004 37.1388L31.1898 24.881C31.9903 23.9556 31.344 22.4609 30.1155 22.4609H25.5924L30.7604 14.1602H23.8281L17.7336 24.7713C16.9984 26.0821 17.9529 27.7344 19.4641 27.7344H23.3876L19.4936 36.669C19.4525 36.7584 19.434 36.8402 19.4336 36.9141Z" fill="#232634"/>
    <path d="M0 13.0859V8.39844C0 7.31973 0.874414 6.44531 1.95312 6.44531C3.03184 6.44531 3.90625 7.31973 3.90625 8.39844V11.576C5.82803 8.55898 8.38037 5.97861 11.4105 4.01279C15.4572 1.3876 20.1563 0 25 0C31.6777 0 37.9558 2.60039 42.6776 7.32227C47.3995 12.0441 50 18.3224 50 25C50 26.0787 49.1256 26.9531 48.0469 26.9531C46.9682 26.9531 46.0938 26.0787 46.0938 25C46.0938 13.3688 36.6312 3.90625 25 3.90625C17.6108 3.90625 10.7548 7.82773 6.96025 14.0625H9.57031C10.649 14.0625 11.5234 14.9369 11.5234 16.0156C11.5234 17.0943 10.649 17.9688 9.57031 17.9688H4.88281C2.19043 17.9688 0 15.7784 0 13.0859ZM45.1172 32.0312H40.332C39.2534 32.0312 38.3789 32.9058 38.3789 33.9844C38.3789 35.063 39.2533 35.9375 40.332 35.9375H42.9421C39.1477 42.1723 32.2916 46.0938 24.9023 46.0938C13.3251 46.0938 3.90625 36.6312 3.90625 25C3.90625 23.9213 3.03184 23.0469 1.95312 23.0469C0.874414 23.0469 0 23.9213 0 25C0 31.6815 2.58174 37.9582 7.26943 42.6736C11.9663 47.398 18.2284 50 24.9023 50C29.7461 50 34.4452 48.6124 38.4918 45.9872C41.5733 43.9882 44.1612 41.3541 46.0938 38.2711V41.5039C46.0938 42.5826 46.9682 43.457 48.0469 43.457C49.1256 43.457 50 42.5826 50 41.5039V36.9141C50 34.2217 47.8096 32.0312 45.1172 32.0312ZM19.4336 36.9141C19.4321 37.2213 19.6986 37.4037 20.0401 37.4023C20.28 37.4014 20.5333 37.3052 20.7004 37.1388L31.1898 24.881C31.9903 23.9556 31.344 22.4609 30.1155 22.4609H25.5924L30.7604 14.1602H23.8281L17.7336 24.7713C16.9984 26.0821 17.9529 27.7344 19.4641 27.7344H23.3876L19.4936 36.669C19.4525 36.7584 19.434 36.8402 19.4336 36.9141Z" fill="url(#icon_renewable_energy0_linear)"/>
    <defs>
      <linearGradient id="icon_renewable_energy0_linear" x1="25" y1="0" x2="25" y2="50" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>
</template>



<script>
  export default {
    //
  }
</script>
