<script setup>
  import { computed } from 'vue';

  const suffix = Math.random().toString(36).substr(2, 9);
  const generate = (range, callback) => ([...Array(range)].map(callback));

  const gradients = generate(9, (v, k) => `url(#object-storage-${suffix}-${k})`);
  const slugs = generate(9, (v, k) => `object-storage-${suffix}-${k}`);
</script>



<style scoped>
  .object-storage-dark { @apply hidden; }
  .object-storage-light { @apply block; }

  /*
  .object-storage-dark { @apply transition opacity-100; }
  .object-storage-light { @apply transition opacity-0; }

  svg:hover .object-storage-dark { @apply opacity-0; }
  svg:hover .object-storage-light { @apply opacity-100; }
  */

  .object-storage-0 { fill: rgba(0, 0, 0, .15); }
  .object-storage-1 { fill: #242835; }
  .object-storage-2 { fill: #171A23; }
  .object-storage-3 { fill: #20B785; }
  .object-storage-4 { fill: #4B4D59; }
  .object-storage-5 { fill: #323542; }
  .object-storage-6 { fill: #474956; }
  .object-storage-7 { clip-path: v-bind(gradients[2]); }
  .object-storage-8 { fill: #5E6268; }
  .object-storage-9 { fill: #5D6167; }
  .object-storage-10 { fill: #5C6067; }
  .object-storage-11 { fill: #5C5F66; }
  .object-storage-12 { fill: #5B5E65; }
  .object-storage-13 { fill: #5A5D65; }
  .object-storage-14 { fill: #595D64; }
  .object-storage-15 { fill: #585C63; }
  .object-storage-16 { fill: #575B63; }
  .object-storage-17 { fill: #575A62; }
  .object-storage-18 { fill: #565961; }
  .object-storage-19 { fill: #555861; }
  .object-storage-20 { fill: #545760; }
  .object-storage-21 { fill: #535660; }
  .object-storage-22 { fill: #52555F; }
  .object-storage-23 { fill: #52545E; }
  .object-storage-24 { fill: #51535E; }
  .object-storage-25 { fill: #50525D; }
  .object-storage-26 { fill: #4F525C; }
  .object-storage-27 { fill: #4E515C; }
  .object-storage-28 { fill: #4D505B; }
  .object-storage-29 { fill: #4D4F5A; }
  .object-storage-30 { fill: #4C4E5A; }
  .object-storage-31 { fill: #A1B7B2; }
  .object-storage-32 { fill: #46515B; }
  .object-storage-33 { fill: #292E3D; }
  .object-storage-34 { fill: #1F232D; }
  .object-storage-35 { fill: #53DCAB; }
  .object-storage-36 { fill: #25BA81; }
  .object-storage-37 { fill: v-bind(gradients[3]); }
  .object-storage-38 { fill: v-bind(gradients[4]); }
  .object-storage-39 { fill: #28C984; }
  .object-storage-41 { display: inline; }
  .object-storage-42 { display: inline; fill: #363D49; }
  .object-storage-43 { fill: #232835; }
  .object-storage-44 { fill: #A3ABC3; }
  .object-storage-45 { display: inline; fill: #767F91; }
  .object-storage-46 { display: inline; fill: #979FAF; }
  .object-storage-47 { clip-path: v-bind(gradients[6]); }
  .object-storage-48 { fill: #CCCCCC; }
  .object-storage-49 { fill: #CFD1D3; }
  .object-storage-50 { fill: #CDD0D3; }
  .object-storage-51 { fill: #CBCED2; }
  .object-storage-52 { fill: #C9CCD1; }
  .object-storage-53 { fill: #C7CAD0; }
  .object-storage-54 { fill: #C5C9D0; }
  .object-storage-55 { fill: #C3C7CF; }
  .object-storage-56 { fill: #C1C5CE; }
  .object-storage-57 { fill: #BFC3CD; }
  .object-storage-58 { fill: #BDC2CD; }
  .object-storage-59 { fill: #BBC0CC; }
  .object-storage-60 { fill: #B9BECB; }
  .object-storage-61 { fill: #B7BCCA; }
  .object-storage-62 { fill: #B5BBCA; }
  .object-storage-63 { fill: #B3B9C9; }
  .object-storage-64 { fill: #B1B7C8; }
  .object-storage-65 { fill: #AFB5C7; }
  .object-storage-66 { fill: #ADB4C7; }
  .object-storage-67 { fill: #ABB2C6; }
  .object-storage-68 { fill: #A9B0C5; }
  .object-storage-69 { fill: #A7AEC4; }
  .object-storage-70 { fill: #A5ADC4; }
  .object-storage-71 { fill: #86939B; }
  .object-storage-72 { display: inline; fill: #485460; }
  .object-storage-73 { fill: v-bind(gradients[7]); }
  .object-storage-74 { fill: v-bind(gradients[8]); }
</style>

<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 372.43 365.48" style="enable-background:new 0 0 372.43 365.48;" xml:space="preserve">
    <g class="object-storage-dark">
      <g>
        <path class="object-storage-0" d="M169.78,361.56c9.04,5.22,23.83,5.22,32.88,0l153.52-88.63c9.04-5.22,9.04-13.76,0-18.98L202.65,165.3
          c-9.04-5.22-23.84-5.22-32.88,0L16.26,253.94c-9.04,5.22-9.04,13.76,0,18.98L169.78,361.56z"/>
      </g>
      <path class="object-storage-1" d="M365.29,140.18l-161.75,93.39c-9.53,5.5-25.11,5.5-34.64,0v0L7.14,140.18c-4.76-2.75-7.14-6.37-7.14-10v62.37
        c0,3.62,2.38,7.25,7.14,9.99l6.13,3.54l0,0l155.62,89.84v0c9.53,5.5,25.11,5.5,34.64,0l155.62-89.84l0,0l6.13-3.54
        c4.76-2.75,7.14-6.37,7.14-9.99v-62.37C372.43,133.81,370.05,137.43,365.29,140.18z"/>
      <g>
        <path class="object-storage-2" d="M168.89,295.94c9.53,5.5,25.11,5.5,34.64,0l161.75-93.39c9.53-5.5,9.53-14.5,0-20L203.54,89.16
          c-9.53-5.5-25.11-5.5-34.64,0L7.14,182.55c-9.53,5.5-9.53,14.5,0,20L168.89,295.94z"/>
      </g>
      <g>
        <g>
          <g>
            <polygon class="object-storage-3" points="256.68,221.2 256.68,227.17 349.26,173.72 349.26,167.75 				"/>
            <polygon class="object-storage-3" points="256.68,236.61 256.68,242.58 349.26,189.13 349.26,183.15 				"/>
          </g>
          <g>
            <path class="object-storage-3" d="M233.53,232.57c6.04-3.49,10.99-0.63,10.99,6.34c0,6.98-4.94,15.54-10.99,19.03
              c-6.04,3.49-10.99,0.63-10.99-6.34C222.55,244.62,227.49,236.06,233.53,232.57z"/>
          </g>
        </g>
        <g>
          <g>
            <polygon class="object-storage-3" points="152.87,242.74 152.87,248.72 60.29,195.26 60.29,189.29 				"/>
            <polygon class="object-storage-3" points="152.87,258.15 152.87,264.12 60.29,210.67 60.29,204.7 				"/>
          </g>
          <g>
            <path class="object-storage-3" d="M37.14,173.93c-6.04-3.49-10.99-0.63-10.98,6.34c0,6.98,4.94,15.54,10.99,19.03
              c6.04,3.49,10.98,0.64,10.98-6.34C48.13,185.98,43.18,177.42,37.14,173.93z"/>
          </g>
        </g>
      </g>
      <g>
        <path class="object-storage-4" d="M168.89,210.9c9.53,5.5,25.11,5.5,34.64,0l161.75-93.39c9.53-5.5,9.53-14.5,0-20L203.54,4.12
          c-9.53-5.5-25.11-5.5-34.64,0L7.14,97.51c-9.53,5.5-9.53,14.5,0,20L168.89,210.9z"/>
      </g>
      <path class="object-storage-5" d="M365.29,117.51L203.53,210.9c-9.53,5.5-25.11,5.5-34.64,0v0L7.14,117.51c-4.76-2.75-7.14-6.37-7.14-10v22.68
        c0,3.62,2.38,7.25,7.14,9.99l6.13,3.54l0,0l155.62,89.84v0c9.53,5.5,25.11,5.5,34.64,0l155.62-89.84l0,0l6.13-3.54
        c4.76-2.75,7.14-6.37,7.14-9.99v-22.68C372.43,111.14,370.05,114.76,365.29,117.51z"/>
      <path class="object-storage-6" d="M365.29,198.3l-6.13,3.54l-155.62,89.84c-9.53,5.5-25.11,5.5-34.64,0v0L13.28,201.84l-6.13-3.54
        C2.39,195.55,0,191.93,0,188.31v4.25c0,3.62,2.38,7.25,7.14,9.99l6.13,3.54l155.62,89.84v0c9.53,5.5,25.11,5.5,34.64,0
        l155.62-89.84l6.13-3.54c4.76-2.75,7.14-6.37,7.14-9.99v-4.25C372.43,191.93,370.05,195.55,365.29,198.3z"/>
      <path class="object-storage-5" d="M365.29,202.55l-161.75,93.39c-9.53,5.5-25.11,5.5-34.64,0v0L7.14,202.55c-4.76-2.75-7.14-6.37-7.14-10v22.68
        c0,3.62,2.38,7.25,7.14,9.99l6.13,3.54l0,0l155.62,89.84v0c9.53,5.5,25.11,5.5,34.64,0l155.62-89.84l0,0l6.13-3.54
        c4.76-2.75,7.14-6.37,7.14-9.99v-22.68C372.43,196.18,370.05,199.8,365.29,202.55z"/>
      <g>
        <defs>
          <path :id="slugs[1]" d="M203.54,4.12c-9.53-5.5-25.11-5.5-34.64,0L71.26,60.49v47.05h0c0.02,1.02,0.69,2.03,2.02,2.8l108.02,62.36
            c2.7,1.56,7.12,1.56,9.82,0l108.02-62.36c1.33-0.77,2.01-1.78,2.02-2.8h0V60.49L203.54,4.12z"/>
        </defs>
        <clipPath :id="slugs[2]">
          <use :xlink:href="`#${slugs[1]}`"  style="overflow:visible;"/>
        </clipPath>
        <g class="object-storage-7">
          <path class="object-storage-8" d="M203.54,49.48c-9.53-5.5-25.11-5.5-34.64,0l-97.63,56.37v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37v-11.34L203.54,49.48z"/>
          <path class="object-storage-9" d="M203.54,47.51c-9.53-5.5-25.11-5.5-34.64,0l-97.63,56.37v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37v-11.34L203.54,47.51z"/>
          <path class="object-storage-10" d="M203.54,45.53c-9.53-5.5-25.11-5.5-34.64,0L71.26,101.9v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V101.9L203.54,45.53z"/>
          <path class="object-storage-11" d="M203.54,43.56c-9.53-5.5-25.11-5.5-34.64,0L71.26,99.93v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V99.93L203.54,43.56z"/>
          <path class="object-storage-12" d="M203.54,41.59c-9.53-5.5-25.11-5.5-34.64,0L71.26,97.96v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V97.96L203.54,41.59z"/>
          <path class="object-storage-13" d="M203.54,39.62c-9.53-5.5-25.11-5.5-34.64,0L71.26,95.99v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V95.99L203.54,39.62z"/>
          <path class="object-storage-14" d="M203.54,37.65c-9.53-5.5-25.11-5.5-34.64,0L71.26,94.01v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V94.01L203.54,37.65z"/>
          <path class="object-storage-15" d="M203.54,35.68c-9.53-5.5-25.11-5.5-34.64,0L71.26,92.04v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V92.04L203.54,35.68z"/>
          <path class="object-storage-16" d="M203.54,33.7c-9.53-5.5-25.11-5.5-34.64,0L71.26,90.07v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V90.07L203.54,33.7z"/>
          <path class="object-storage-17" d="M203.54,31.73c-9.53-5.5-25.11-5.5-34.64,0L71.26,88.1v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V88.1L203.54,31.73z"/>
          <path class="object-storage-18" d="M203.54,29.76c-9.53-5.5-25.11-5.5-34.64,0L71.26,86.13v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V86.13L203.54,29.76z"/>
          <path class="object-storage-19" d="M203.54,27.79c-9.53-5.5-25.11-5.5-34.64,0L71.26,84.15v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V84.15L203.54,27.79z"/>
          <path class="object-storage-20" d="M203.54,25.82c-9.53-5.5-25.11-5.5-34.64,0L71.26,82.18v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V82.18L203.54,25.82z"/>
          <path class="object-storage-21" d="M203.54,23.84c-9.53-5.5-25.11-5.5-34.64,0L71.26,80.21v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V80.21L203.54,23.84z"/>
          <path class="object-storage-22" d="M203.54,21.87c-9.53-5.5-25.11-5.5-34.64,0L71.26,78.24v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V78.24L203.54,21.87z"/>
          <path class="object-storage-23" d="M203.54,19.9c-9.53-5.5-25.11-5.5-34.64,0L71.26,76.27v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V76.27L203.54,19.9z"/>
          <path class="object-storage-24" d="M203.54,17.93c-9.53-5.5-25.11-5.5-34.64,0L71.26,74.3v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V74.3L203.54,17.93z"/>
          <path class="object-storage-25" d="M203.54,15.96c-9.53-5.5-25.11-5.5-34.64,0L71.26,72.32v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V72.32L203.54,15.96z"/>
          <path class="object-storage-26" d="M203.54,13.98c-9.53-5.5-25.11-5.5-34.64,0L71.26,70.35v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V70.35L203.54,13.98z"/>
          <path class="object-storage-27" d="M203.54,12.01c-9.53-5.5-25.11-5.5-34.64,0L71.26,68.38v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V68.38L203.54,12.01z"/>
          <path class="object-storage-28" d="M203.54,10.04c-9.53-5.5-25.11-5.5-34.64,0L71.26,66.41v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V66.41L203.54,10.04z"/>
          <path class="object-storage-29" d="M203.54,8.07c-9.53-5.5-25.11-5.5-34.64,0L71.26,64.44v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V64.44L203.54,8.07z"/>
          <path class="object-storage-30" d="M203.54,6.1c-9.53-5.5-25.11-5.5-34.64,0L71.26,62.46V73.8l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V62.46L203.54,6.1z"/>
          <path class="object-storage-4" d="M203.54,4.12c-9.53-5.5-25.11-5.5-34.64,0L71.26,60.49v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V60.49L203.54,4.12z"/>
        </g>
      </g>
      <g>
        <g>
          <path class="object-storage-31" d="M340.57,103.68c3.64-2.1,9.61-2.1,13.25,0c3.65,2.11,3.65,5.55,0,7.65c-3.64,2.11-9.61,2.11-13.25,0
            C336.93,109.23,336.93,105.79,340.57,103.68z"/>
        </g>
        <g>
          <path class="object-storage-31" d="M179.59,10.74c3.65-2.1,9.61-2.1,13.26,0c3.64,2.1,3.64,5.55,0,7.65c-3.65,2.1-9.61,2.1-13.26,0
            C175.94,16.29,175.94,12.84,179.59,10.74z"/>
        </g>
        <g>
          <path class="object-storage-31" d="M179.59,196.63c3.64-2.1,9.61-2.1,13.25,0c3.65,2.1,3.65,5.55,0,7.65c-3.65,2.1-9.61,2.1-13.25,0
            C175.94,202.18,175.94,198.73,179.59,196.63z"/>
        </g>
        <g>
          <path class="object-storage-31" d="M18.6,103.68c3.65-2.1,9.61-2.1,13.25,0c3.64,2.1,3.64,5.55,0,7.65c-3.65,2.11-9.61,2.11-13.25,0
            C14.96,109.23,14.96,105.79,18.6,103.68z"/>
        </g>
      </g>
      <g>
        <path class="object-storage-32" d="M181.3,172.71c2.7,1.56,7.12,1.56,9.82,0l108.02-62.36c2.7-1.56,2.7-4.11,0-5.67L191.13,42.31
          c-2.7-1.56-7.12-1.56-9.82,0L73.29,104.68c-2.7,1.56-2.7,4.11,0,5.67L181.3,172.71z"/>
      </g>
      <path class="object-storage-33" d="M181.31,53.65L78.2,113.18l103.11,59.53c2.7,1.56,7.12,1.56,9.82,0l103.11-59.53L191.13,53.65
        C188.42,52.09,184.01,52.09,181.31,53.65z"/>
      <path class="object-storage-34" d="M203.54,89.16c-9.53-5.5-25.11-5.5-34.64,0l-66.15,38.19l78.56,45.35c2.7,1.56,7.12,1.56,9.82,0l78.56-45.35
        L203.54,89.16z"/>
      <g>
        <polygon class="object-storage-35" points="142.13,150.09 120.54,103.72 103.62,127.85 		"/>
        <path class="object-storage-36" d="M181.3,172.71c2.7,1.56,7.12,1.56,9.82,0l11.83-6.83l-39.5-3.47L181.3,172.71z"/>
        <linearGradient :id="slugs[3]" gradientUnits="userSpaceOnUse" x1="120.5355" y1="134.7952" x2="208.3773" y2="134.7952">
          <stop  offset="0" style="stop-color:#23AD7B"/>
          <stop  offset="1" style="stop-color:#4FD3A1"/>
        </linearGradient>
        <polygon class="object-storage-37" points="163.46,162.4 202.96,165.88 208.38,162.75 183.45,109.25 120.54,103.72 142.13,150.09 		"/>
      </g>
      <g>
        <polygon class="object-storage-35" points="208.79,129.51 255.9,125.89 229.2,86.9 182.09,90.53 		"/>
        
          <linearGradient :id="slugs[4]" gradientUnits="userSpaceOnUse" x1="218.1122" y1="110.0759" x2="238.5733" y2="145.5155" gradientTransform="matrix(0.825 -0.5651 0.5651 0.825 -7.8421 108.6898)">
          <stop  offset="0" style="stop-color:#23AD7B"/>
          <stop  offset="1" style="stop-color:#4FD3A1"/>
        </linearGradient>
        <polygon class="object-storage-38" points="229.2,86.9 249.61,44.28 276.31,83.27 255.9,125.89 		"/>
        <polygon class="object-storage-39" points="182.09,90.53 202.5,47.92 249.61,44.28 229.2,86.9 		"/>
      </g>
    </g>
    <g class="object-storage-light">
      <g class="object-storage-41">
        <path class="object-storage-0" d="M169.78,361.56c9.04,5.22,23.83,5.22,32.88,0l153.52-88.63c9.04-5.22,9.04-13.76,0-18.98L202.65,165.3
          c-9.04-5.22-23.84-5.22-32.88,0L16.26,253.94c-9.04,5.22-9.04,13.76,0,18.98L169.78,361.56z"/>
      </g>
      <path class="object-storage-42" d="M365.29,140.18l-161.75,93.39c-9.53,5.5-25.11,5.5-34.64,0v0L7.14,140.18c-4.76-2.75-7.14-6.37-7.14-10v62.37
        c0,3.62,2.38,7.25,7.14,9.99l6.13,3.54l0,0l155.62,89.84v0c9.53,5.5,25.11,5.5,34.64,0l155.62-89.84l0,0l6.13-3.54
        c4.76-2.75,7.14-6.37,7.14-9.99v-62.37C372.43,133.81,370.05,137.43,365.29,140.18z"/>
      <g class="object-storage-41">
        <path class="object-storage-43" d="M168.89,295.94c9.53,5.5,25.11,5.5,34.64,0l161.75-93.39c9.53-5.5,9.53-14.5,0-20L203.54,89.16
          c-9.53-5.5-25.11-5.5-34.64,0L7.14,182.55c-9.53,5.5-9.53,14.5,0,20L168.89,295.94z"/>
      </g>
      <g class="object-storage-41">
        <g>
          <g>
            <polygon class="object-storage-3" points="256.68,221.2 256.68,227.17 349.26,173.72 349.26,167.75 				"/>
            <polygon class="object-storage-3" points="256.68,236.61 256.68,242.58 349.26,189.13 349.26,183.15 				"/>
          </g>
          <g>
            <path class="object-storage-3" d="M233.53,232.57c6.04-3.49,10.99-0.63,10.99,6.34c0,6.98-4.94,15.54-10.99,19.03
              c-6.04,3.49-10.99,0.63-10.99-6.34C222.55,244.62,227.49,236.06,233.53,232.57z"/>
          </g>
        </g>
        <g>
          <g>
            <polygon class="object-storage-3" points="152.87,242.74 152.87,248.72 60.29,195.26 60.29,189.29 				"/>
            <polygon class="object-storage-3" points="152.87,258.15 152.87,264.12 60.29,210.67 60.29,204.7 				"/>
          </g>
          <g>
            <path class="object-storage-3" d="M37.14,173.93c-6.04-3.49-10.99-0.63-10.98,6.34c0,6.98,4.94,15.54,10.99,19.03
              c6.04,3.49,10.98,0.64,10.98-6.34C48.13,185.98,43.18,177.42,37.14,173.93z"/>
          </g>
        </g>
      </g>
      <g class="object-storage-41">
        <path class="object-storage-44" d="M168.89,210.9c9.53,5.5,25.11,5.5,34.64,0l161.75-93.39c9.53-5.5,9.53-14.5,0-20L203.54,4.12
          c-9.53-5.5-25.11-5.5-34.64,0L7.14,97.51c-9.53,5.5-9.53,14.5,0,20L168.89,210.9z"/>
      </g>
      <path class="object-storage-45" d="M365.29,117.51L203.53,210.9c-9.53,5.5-25.11,5.5-34.64,0v0L7.14,117.51c-4.76-2.75-7.14-6.37-7.14-10v22.68
        c0,3.62,2.38,7.25,7.14,9.99l6.13,3.54l0,0l155.62,89.84v0c9.53,5.5,25.11,5.5,34.64,0l155.62-89.84l0,0l6.13-3.54
        c4.76-2.75,7.14-6.37,7.14-9.99v-22.68C372.43,111.14,370.05,114.76,365.29,117.51z"/>
      <path class="object-storage-46" d="M365.29,198.3l-6.13,3.54l-155.62,89.84c-9.53,5.5-25.11,5.5-34.64,0v0L13.28,201.84l-6.13-3.54
        C2.39,195.55,0,191.93,0,188.31v4.25c0,3.62,2.38,7.25,7.14,9.99l6.13,3.54l155.62,89.84v0c9.53,5.5,25.11,5.5,34.64,0
        l155.62-89.84l6.13-3.54c4.76-2.75,7.14-6.37,7.14-9.99v-4.25C372.43,191.93,370.05,195.55,365.29,198.3z"/>
      <path class="object-storage-45" d="M365.29,202.55l-161.75,93.39c-9.53,5.5-25.11,5.5-34.64,0v0L7.14,202.55c-4.76-2.75-7.14-6.37-7.14-10v22.68
        c0,3.62,2.38,7.25,7.14,9.99l6.13,3.54l0,0l155.62,89.84v0c9.53,5.5,25.11,5.5,34.64,0l155.62-89.84l0,0l6.13-3.54
        c4.76-2.75,7.14-6.37,7.14-9.99v-22.68C372.43,196.18,370.05,199.8,365.29,202.55z"/>
      <g class="object-storage-41">
        <defs>
          <path :id="slugs[5]" d="M203.54,4.12c-9.53-5.5-25.11-5.5-34.64,0L71.26,60.49v47.05h0c0.02,1.02,0.69,2.03,2.02,2.8l108.02,62.36
            c2.7,1.56,7.12,1.56,9.82,0l108.02-62.36c1.33-0.77,2.01-1.78,2.02-2.8h0V60.49L203.54,4.12z"/>
        </defs>
        <clipPath :id="slugs[6]">
          <use :xlink:href="`#${slugs[5]}`"  style="overflow:visible;"/>
        </clipPath>
        <g class="object-storage-47">
          <path class="object-storage-48" d="M203.54,49.48c-9.53-5.5-25.11-5.5-34.64,0l-97.63,56.37v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37v-11.34L203.54,49.48z"/>
          <path class="object-storage-49" d="M203.54,47.51c-9.53-5.5-25.11-5.5-34.64,0l-97.63,56.37v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37v-11.34L203.54,47.51z"/>
          <path class="object-storage-50" d="M203.54,45.53c-9.53-5.5-25.11-5.5-34.64,0L71.26,101.9v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V101.9L203.54,45.53z"/>
          <path class="object-storage-51" d="M203.54,43.56c-9.53-5.5-25.11-5.5-34.64,0L71.26,99.93v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V99.93L203.54,43.56z"/>
          <path class="object-storage-52" d="M203.54,41.59c-9.53-5.5-25.11-5.5-34.64,0L71.26,97.96v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V97.96L203.54,41.59z"/>
          <path class="object-storage-53" d="M203.54,39.62c-9.53-5.5-25.11-5.5-34.64,0L71.26,95.99v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V95.99L203.54,39.62z"/>
          <path class="object-storage-54" d="M203.54,37.65c-9.53-5.5-25.11-5.5-34.64,0L71.26,94.01v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V94.01L203.54,37.65z"/>
          <path class="object-storage-55" d="M203.54,35.68c-9.53-5.5-25.11-5.5-34.64,0L71.26,92.04v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V92.04L203.54,35.68z"/>
          <path class="object-storage-56" d="M203.54,33.7c-9.53-5.5-25.11-5.5-34.64,0L71.26,90.07v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V90.07L203.54,33.7z"/>
          <path class="object-storage-57" d="M203.54,31.73c-9.53-5.5-25.11-5.5-34.64,0L71.26,88.1v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V88.1L203.54,31.73z"/>
          <path class="object-storage-58" d="M203.54,29.76c-9.53-5.5-25.11-5.5-34.64,0L71.26,86.13v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V86.13L203.54,29.76z"/>
          <path class="object-storage-59" d="M203.54,27.79c-9.53-5.5-25.11-5.5-34.64,0L71.26,84.15v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V84.15L203.54,27.79z"/>
          <path class="object-storage-60" d="M203.54,25.82c-9.53-5.5-25.11-5.5-34.64,0L71.26,82.18v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V82.18L203.54,25.82z"/>
          <path class="object-storage-61" d="M203.54,23.84c-9.53-5.5-25.11-5.5-34.64,0L71.26,80.21v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V80.21L203.54,23.84z"/>
          <path class="object-storage-62" d="M203.54,21.87c-9.53-5.5-25.11-5.5-34.64,0L71.26,78.24v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V78.24L203.54,21.87z"/>
          <path class="object-storage-63" d="M203.54,19.9c-9.53-5.5-25.11-5.5-34.64,0L71.26,76.27v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V76.27L203.54,19.9z"/>
          <path class="object-storage-64" d="M203.54,17.93c-9.53-5.5-25.11-5.5-34.64,0L71.26,74.3v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V74.3L203.54,17.93z"/>
          <path class="object-storage-65" d="M203.54,15.96c-9.53-5.5-25.11-5.5-34.64,0L71.26,72.32v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V72.32L203.54,15.96z"/>
          <path class="object-storage-66" d="M203.54,13.98c-9.53-5.5-25.11-5.5-34.64,0L71.26,70.35v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V70.35L203.54,13.98z"/>
          <path class="object-storage-67" d="M203.54,12.01c-9.53-5.5-25.11-5.5-34.64,0L71.26,68.38v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V68.38L203.54,12.01z"/>
          <path class="object-storage-68" d="M203.54,10.04c-9.53-5.5-25.11-5.5-34.64,0L71.26,66.41v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V66.41L203.54,10.04z"/>
          <path class="object-storage-69" d="M203.54,8.07c-9.53-5.5-25.11-5.5-34.64,0L71.26,64.44v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V64.44L203.54,8.07z"/>
          <path class="object-storage-70" d="M203.54,6.1c-9.53-5.5-25.11-5.5-34.64,0L71.26,62.46V73.8l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V62.46L203.54,6.1z"/>
          <path class="object-storage-44" d="M203.54,4.12c-9.53-5.5-25.11-5.5-34.64,0L71.26,60.49v11.34l97.63-56.37c9.53-5.5,25.11-5.5,34.64,0
            l97.63,56.37V60.49L203.54,4.12z"/>
        </g>
      </g>
      <g class="object-storage-41">
        <g>
          <path class="object-storage-6" d="M340.57,103.68c3.64-2.1,9.61-2.1,13.25,0c3.65,2.11,3.65,5.55,0,7.65c-3.64,2.11-9.61,2.11-13.25,0
            C336.93,109.23,336.93,105.79,340.57,103.68z"/>
        </g>
        <g>
          <path class="object-storage-6" d="M179.59,10.74c3.65-2.1,9.61-2.1,13.26,0c3.64,2.1,3.64,5.55,0,7.65c-3.65,2.1-9.61,2.1-13.26,0
            C175.94,16.29,175.94,12.84,179.59,10.74z"/>
        </g>
        <g>
          <path class="object-storage-6" d="M179.59,196.63c3.64-2.1,9.61-2.1,13.25,0c3.65,2.1,3.65,5.55,0,7.65c-3.65,2.1-9.61,2.1-13.25,0
            C175.94,202.18,175.94,198.73,179.59,196.63z"/>
        </g>
        <g>
          <path class="object-storage-6" d="M18.6,103.68c3.65-2.1,9.61-2.1,13.25,0c3.64,2.1,3.64,5.55,0,7.65c-3.65,2.11-9.61,2.11-13.25,0
            C14.96,109.23,14.96,105.79,18.6,103.68z"/>
        </g>
      </g>
      <g class="object-storage-41">
        <path class="object-storage-71" d="M181.3,172.71c2.7,1.56,7.12,1.56,9.82,0l108.02-62.36c2.7-1.56,2.7-4.11,0-5.67L191.13,42.31
          c-2.7-1.56-7.12-1.56-9.82,0L73.29,104.68c-2.7,1.56-2.7,4.11,0,5.67L181.3,172.71z"/>
      </g>
      <path class="object-storage-72" d="M181.31,53.65L78.2,113.18l103.11,59.53c2.7,1.56,7.12,1.56,9.82,0l103.11-59.53L191.13,53.65
        C188.42,52.09,184.01,52.09,181.31,53.65z"/>
      <path class="object-storage-42" d="M203.54,89.16c-9.53-5.5-25.11-5.5-34.64,0l-66.15,38.19l78.56,45.35c2.7,1.56,7.12,1.56,9.82,0l78.56-45.35
        L203.54,89.16z"/>
      <g class="object-storage-41">
        <polygon class="object-storage-35" points="142.13,150.09 120.54,103.72 103.62,127.85 		"/>
        <path class="object-storage-36" d="M181.3,172.71c2.7,1.56,7.12,1.56,9.82,0l11.83-6.83l-39.5-3.47L181.3,172.71z"/>
        <linearGradient :id="slugs[7]" gradientUnits="userSpaceOnUse" x1="120.5355" y1="134.7952" x2="208.3773" y2="134.7952">
          <stop  offset="0" style="stop-color:#23AD7B"/>
          <stop  offset="1" style="stop-color:#4FD3A1"/>
        </linearGradient>
        <polygon class="object-storage-73" points="163.46,162.4 202.96,165.88 208.38,162.75 183.45,109.25 120.54,103.72 142.13,150.09 		"/>
      </g>
      <g class="object-storage-41">
        <polygon class="object-storage-35" points="208.79,129.51 255.9,125.89 229.2,86.9 182.09,90.53 		"/>
        
          <linearGradient :id="slugs[8]" gradientUnits="userSpaceOnUse" x1="218.1122" y1="110.0759" x2="238.5733" y2="145.5155" gradientTransform="matrix(0.825 -0.5651 0.5651 0.825 -7.8421 108.6898)">
          <stop  offset="0" style="stop-color:#23AD7B"/>
          <stop  offset="1" style="stop-color:#4FD3A1"/>
        </linearGradient>
        <polygon class="object-storage-74" points="229.2,86.9 249.61,44.28 276.31,83.27 255.9,125.89 		"/>
        <polygon class="object-storage-39" points="182.09,90.53 202.5,47.92 249.61,44.28 229.2,86.9 		"/>
      </g>
    </g>
  </svg>
</template>

<script>

export default {
  //
}

</script>
