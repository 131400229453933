<script>
  import { watch } from 'vue';

  export default {
    props: {
      url: {
        type: [String, Object],
        required: false,
        default: '',
      },
      bordered: Boolean,
      highlighted: Boolean,
      compact: Boolean,
      submenu: String,
      arrow: String,
    },

    data () {
      return {
        hover: false,
        requiresHover: true,
      };
    },

    computed: {
      isExternal () {
        return RegExp('^https?://|^//|^tel:|^mailto:', 'i').test(this.url);
      },

      tag () {
        if (this.hasSubmenu) return 'span';
        
        if (this.isExternal) return 'a';

        return 'router-link';
      },

      attributes () {
        if (this.hasSubmenu) return { role: 'menu' };

        if (this.isExternal) return { href: this.url, role: 'button', 'target': '_blank' };

        return { to: this.url, role: 'button' };
      },

      hasSubmenu () {
        return !! this.$slots?.submenu;
      },

      matchRoute () {
        if (typeof this.url === 'string' || this.url instanceof String) {
          return this.url === this.$route.path
        }

        if (this.url.path === this.$route.path) {
          return true
        }

        return this.url.name === this.$route.name
      }
    },

    methods: {
      checkIfRequiresHover () {
        this.requiresHover = (window.getComputedStyle(this.$el).getPropertyValue('order') === '1');
      },
    },

    created () {
      this.$router.afterEach((to, from) => {
        if (this.hover) {
          this.hover = false;
        }
      });
    },

    mounted () {
      this.checkIfRequiresHover();
      window.addEventListener('resize', this.checkIfRequiresHover);
    }
  }
</script>



<style lang="postcss" scoped>
  [role="menu"] {
    @apply cursor-default;
    @apply border-transparent;
  }

  [role="button"] {
    @apply border-transparent;
    @apply hover:text-brand;

    &.highlighted {
      @apply border-brand-matte text-brand;
      @apply hover:border-brand hover:text-segment-light hover:bg-brand;
    }

    &.bordered {
      @apply border-gray-lighter bg-gray-darkest;
      @apply hover:border-brand-matte hover:text-brand;
    }

    &.active {
      @apply text-brand;
    }
  }

  .submenu {
    @screen lg {
      @apply transition-all absolute z-20 translate-x-[-50%] left-1/2 pt-4;
    }

    .submenu-content {
      @screen lg {
        @apply transition-all rounded-md bg-segment-light ring-2 ring-gray-lighter ml-0;
        @apply shadow-[0_25px_40px_10px_rgba(0,0,0,0.2)];
      }

      & :deep() > ul {
        @apply ml-4 mb-8;

        @screen lg {
          @apply ml-0 mb-0;
        }
      }
    }
  }
</style>



<template>
  <div class="group relative lg:order-1" @mouseover="hover = true" @mouseleave="hover = false">
    <component :is="tag" v-bind="attributes"
      class="transition font-medium inline-flex items-center" 
      :class="{
        'compact': compact,
        'border-2 rounded-md': ! compact,
        'px-4 xl:px-5 py-2 lg:py-1 xl:py-2': ! highlighted && ! bordered && ! compact,
        'ml-4 xl:ml-6 px-6 lg:px-8 xl:px-12 !py-2 lg:!py-1 xl:!py-4': highlighted || bordered,
        'highlighted': highlighted,
        'bordered': bordered,
        'active': ! highlighted && ! bordered && ! compact && matchRoute,
        'group-hover:lg:text-brand': hasSubmenu,
      }">

      <div v-if="$slots?.icon" class="transition text-gray-lighter">
        <slot name="icon"></slot>
      </div>

      <slot></slot>
    </component>
    
    <div v-if="hasSubmenu">
      <transition name="fade">
        <div v-show="! requiresHover || hover" class="submenu" :class="submenu">
          <div class="relative submenu-content">
            <div class="hidden lg:block arrow absolute left-1/2 bottom-full w-3">
              <div class="w-3 rotate-180">
                <svg class="absolute text-segment-light z-10 h-3" :class="arrow" viewBox="0 0 39 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M 19.49998,18.000003 35.6571,0 H 3.34286 Z" fill="currentColor"/>
                </svg>
                <svg class="absolute text-gray-lighter z-0 h-3" :class="arrow" viewBox="0 0 39 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M 39,0 19.5,21 0,0 H 3.34286 L 19.5,17.68421 35.6571,0 Z" fill="currentColor"/>
                </svg>
              </div>
            </div>

            <slot name="submenu"></slot>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
