<template>
  <div class="relative flex items-center justify-center">
    <div class="absolute inset-0 flex items-center justify-center">
      <slot></slot>
    </div>

    <svg :class="icon || 'h-20 w-20 text-brand'" viewBox="0 0 83 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M65.3294 4.73329C71.8621 8.90836 76.4793 16.1288 79.1808 23.7422C81.9314 31.3555 82.7664 39.3619 82.3735 47.6629C81.9806 56.013 80.3596 64.6579 75.3496 70.4539C70.3886 76.2499 62.0384 79.197 54.1303 79.8355C46.2223 80.5232 38.7071 78.9514 31.0446 77.0358C23.3821 75.1693 15.5723 72.9589 10.1201 67.998C4.66798 63.037 1.62263 55.2763 0.492904 47.2699C-0.636822 39.2636 0.0999552 30.9626 3.48913 23.9878C6.92743 17.062 12.9199 11.4625 19.7474 7.38569C26.5257 3.35797 34.1391 0.902041 42.1945 0.214381C50.25 -0.522397 58.7475 0.558209 65.3294 4.73329Z" fill="currentColor"/>
    </svg>
  </div>
</template>



<script>
  export default {
    props: {
      icon: String,
    }
  }
</script>
