export default {
  "app": {
    "title": "Siberfy"
  },
  "timeago": [
    [ "just now", "right now" ],
    [ "%s seconds ago", "in %s seconds" ],
    [ "1 minute ago", "in 1 minute" ],
    [ "%s minutes ago", "in %s minutes" ],
    [ "1 hour ago", "in 1 hour" ],
    [ "%s hours ago", "in %s hours" ],
    [ "1 day ago", "in 1 day" ],
    [ "%s days ago", "in %s days" ],
    [ "1 week ago", "in 1 week" ],
    [ "%s weeks ago", "in %s weeks" ],
    [ "1 month ago", "in 1 month" ],
    [ "%s months ago", "in %s months" ],
    [ "1 year ago", "in 1 year" ],
    [ "%s years ago", "in %s years" ]
  ],
  "header": {
    "banners": [
      // "<a href=\"https://cloud.siberfy.com/coupon/jumpstart\" target=\"_blank\" rel=\"noreferrer noopener\"><strong>Limited offer!</strong> Click here to sign up today and gain a <strong>€100 jumpstart for free!</strong></a>"
    ],
    "navigation": {
      "home": "Home",
      "pricing": "Pricing",
      "features": "Features",
      "company": {
        "heading": "Company",
        "about-us": {
          "heading": "About Us",
          "description": "Learn more about us and how we built our high-performance cloud."
        },
        "contact": {
          "heading": "Contact",
          "description": "Don't hesitate to reach out to us, we're available around the clock."
        },
        "sla": {
          "heading": "Service Level Agreement",
          "description": "Read how we deliver the highest quality of service possible, never seen before in Pakistan."
        },
        "legal": {
          "heading": "Legal Documents",
          "description": "Lorem ipsum dolor asit..."
        }
      },
      "login": "Control Panel"
    },
    "salespitch": {
      "preheading": {
        "prefix": "Welcome to",
        "highlight": "Siberfy",
        "suffix": ""
      },
      "heading": {
        "prefix": "The first high bandwidth, transparently priced IaaS Provider in Pakistan. ",
        "highlight": "",
        "suffix": ""
      },
      "body": "Develop and grow your business with ease on a high-performing all-NVMe 10GbE cloud. Dedicated compute resources without billing surprises."
    }
  },
  "footer": {
    "location": "Based in Pakistan",
    "copyright": "Copyright © Siberfy Private Ltd {year}",
    "links": {
      "pricing": {
        "heading": "Pricing",
        "general-compute": "General Compute VMs",
        "storage-vms": "Storage VMs",
        "high-cpu-compute": "High-CPU Compute VMs"
      },
      "features": {
        "heading": "Features",
        "control-panel": "Control Panel",
        "advanced-networking": "Advanced Networking",
        "operating-systems": "Operating Systems",
      },
      "company": {
        "heading": "Company",
        "about": "About Us",
        "data-centers": "Data Centers",
        "support": "24/7 Support"
      },
      "legal": {
        "heading": "Legal",
        "privacy-policy": "Privacy Policy",
        "terms-of-service": "Terms of Service",
        "acceptable-use-policy": "Acceptable Use Policy",
      }
    },
    "support": {
      "heading": "Not sure where to begin?",
      "message": "We can help! Get in touch today and we’ll help you find the perfect fit for your computing needs.",
      "contact": "Contact Us"
    }
  }
};
  