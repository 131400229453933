<style scoped>
  ul {
    @apply w-[30px] h-[20px];
    @apply ml-[705px] mt-[280px];
  }

  path {
    @apply text-gray-light;
  }
</style>

<template>
  <figure class="relative">
    <ul class="absolute">
      <slot></slot>
    </ul>

    <svg width="960" height="1000" class="stroke-gray-lighter outline-2 outline-segment-light" viewBox="0 0 1000 960" fill="transparent" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
       <path fill="currentColor" stroke-width="5.0" stroke-opacity="1.0" d="M745.2 252.6l2.9-0.6 1.3-0.7 0.7-0.7 0.5-0.6 1-0.7 0.7-0.3 0.5-0.1 2.3-0.3 1.3-0.6 0.5-0.5 0.5-0.6 0.5 0.3 5 2.3 0.8 0.6 0.9 0.8 1.9 2.1 2.4 1.8 0.5 0.8 0 0.8-0.5 0.9-1.6 1.8-0.7 1-2.3 5.5-0.4 0.6-1.9 2-0.7 1-0.2 0.3-0.2 0.2-1.2 0.3-1.3-0.3-1.6-0.9-0.7-0.5-0.5-0.7 0.2-0.9 0.4-0.9 0.1-1.2-0.4-1.4-0.7-1.2-0.7-1-0.8-0.7-1.2-0.4-1 0.1-1.2 0.3-2.3 0.8-2.1 1.2-1 0.9-1.3 0.7-1.4 0.2-1.5-0.2-1.3-1.1-1-1.5-0.8-1.9 0.3-2 1.4-1.8 5.9-3z"></path>
    </svg>
  </figure>
</template>
