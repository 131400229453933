<template>
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M43.5199 17.5858C43.8545 16.175 44.0325 14.7041 44.0325 13.1924V5.86989H42.568C38.5768 5.86989 34.8693 7.10534 31.8058 9.21255C30.9218 5.87575 28.905 2.95555 25.877 0.667321L24.994 0L24.111 0.667224C21.0831 2.95545 19.0664 5.87575 18.1823 9.21245C15.1188 7.10534 11.4112 5.86989 7.42011 5.86989H5.95561V13.1924C5.95561 14.7041 6.13369 16.175 6.46818 17.5858H0V19.0503C0 24.8146 3.71972 29.7258 8.8846 31.5113V33.6953C8.8846 36.1179 10.8555 38.0888 13.2781 38.0888H13.4471L14.9116 50H20.8961L22.8488 47.071H27.1392L29.0919 50H35.0764L36.5409 38.0888H36.7099C39.1325 38.0888 41.1034 36.1179 41.1034 33.6953V31.5113C46.2683 29.7257 49.988 24.8146 49.988 19.0503V17.5858H43.5199ZM26.4585 24.9083C26.4585 16.5189 32.9041 9.60679 41.1035 8.86488V13.1924C41.1035 21.5818 34.658 28.4939 26.4585 29.2358V24.9083ZM24.994 3.72275C27.3645 5.80233 28.8212 8.39927 29.2513 11.3164C27.4429 13.0886 25.9853 15.2172 24.994 17.5878C24.0029 15.2171 22.5452 13.0886 20.7368 11.3164C21.1669 8.39927 22.6236 5.80233 24.994 3.72275ZM8.8846 8.86488C17.084 9.60679 23.5295 16.5189 23.5295 24.9083V29.2358C15.3301 28.4939 8.8846 21.5818 8.8846 13.1924V8.86488ZM3.03326 20.5148H7.42089C8.94191 24.1515 11.5599 27.2188 14.8592 29.3018H13.1804C8.02494 29.3018 3.74608 25.4763 3.03326 20.5148ZM32.4855 47.071H30.6594L28.7067 44.142H21.2813L19.3286 47.071H17.5026L16.3981 38.0888H33.5899L32.4855 47.071ZM38.1745 33.6953C38.1745 34.5028 37.5175 35.1598 36.71 35.1598H13.2781C12.4706 35.1598 11.8136 34.5028 11.8136 33.6953V32.1602C12.263 32.2067 12.7189 32.2308 13.1805 32.2308C16.5403 32.2308 33.4345 32.2308 36.8076 32.2308C37.2691 32.2308 37.7251 32.2067 38.1745 32.1602V33.6953ZM36.8076 29.3018H35.1288C38.428 27.2188 41.0462 24.1515 42.5671 20.5148H46.9547C46.242 25.4763 41.9631 29.3018 36.8076 29.3018Z" fill="#232634"/>
    <path d="M43.5199 17.5858C43.8545 16.175 44.0325 14.7041 44.0325 13.1924V5.86989H42.568C38.5768 5.86989 34.8693 7.10534 31.8058 9.21255C30.9218 5.87575 28.905 2.95555 25.877 0.667321L24.994 0L24.111 0.667224C21.0831 2.95545 19.0664 5.87575 18.1823 9.21245C15.1188 7.10534 11.4112 5.86989 7.42011 5.86989H5.95561V13.1924C5.95561 14.7041 6.13369 16.175 6.46818 17.5858H0V19.0503C0 24.8146 3.71972 29.7258 8.8846 31.5113V33.6953C8.8846 36.1179 10.8555 38.0888 13.2781 38.0888H13.4471L14.9116 50H20.8961L22.8488 47.071H27.1392L29.0919 50H35.0764L36.5409 38.0888H36.7099C39.1325 38.0888 41.1034 36.1179 41.1034 33.6953V31.5113C46.2683 29.7257 49.988 24.8146 49.988 19.0503V17.5858H43.5199ZM26.4585 24.9083C26.4585 16.5189 32.9041 9.60679 41.1035 8.86488V13.1924C41.1035 21.5818 34.658 28.4939 26.4585 29.2358V24.9083ZM24.994 3.72275C27.3645 5.80233 28.8212 8.39927 29.2513 11.3164C27.4429 13.0886 25.9853 15.2172 24.994 17.5878C24.0029 15.2171 22.5452 13.0886 20.7368 11.3164C21.1669 8.39927 22.6236 5.80233 24.994 3.72275ZM8.8846 8.86488C17.084 9.60679 23.5295 16.5189 23.5295 24.9083V29.2358C15.3301 28.4939 8.8846 21.5818 8.8846 13.1924V8.86488ZM3.03326 20.5148H7.42089C8.94191 24.1515 11.5599 27.2188 14.8592 29.3018H13.1804C8.02494 29.3018 3.74608 25.4763 3.03326 20.5148ZM32.4855 47.071H30.6594L28.7067 44.142H21.2813L19.3286 47.071H17.5026L16.3981 38.0888H33.5899L32.4855 47.071ZM38.1745 33.6953C38.1745 34.5028 37.5175 35.1598 36.71 35.1598H13.2781C12.4706 35.1598 11.8136 34.5028 11.8136 33.6953V32.1602C12.263 32.2067 12.7189 32.2308 13.1805 32.2308C16.5403 32.2308 33.4345 32.2308 36.8076 32.2308C37.2691 32.2308 37.7251 32.2067 38.1745 32.1602V33.6953ZM36.8076 29.3018H35.1288C38.428 27.2188 41.0462 24.1515 42.5671 20.5148H46.9547C46.242 25.4763 41.9631 29.3018 36.8076 29.3018Z" fill="url(#icon_plant0_linear)"/>
    <defs>
      <linearGradient id="icon_plant0_linear" x1="24.994" y1="0" x2="24.994" y2="50" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>
</template>



<script>
  export default {
    //
  }
</script>
