<script>
  export default {
    //
  }
</script>



<template>
  <div class="relative">
    <div class="absolute ml-12 bottom-[calc(100%-2.5px)]">
      <div class="h-5">
        <svg class="absolute text-gray-dark z-10 h-5" viewBox="0 0 39 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.5 3L36 21L3 21L19.5 3Z" fill="currentColor"/>
        </svg>
        <svg class="absolute text-gray z-0 h-5" viewBox="0 0 39 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M39 21L19.5 0L0 21H3.34286L19.5 3.31579L35.6571 21H39Z" fill="currentColor"/>
        </svg>
      </div>
    </div>

    <div class="rounded-md bg-gray-dark border-2 border-gray shadow-2xl px-4 md:px-12 py-4 md:py-10">
      <h4 v-if="$slots.heading" class="text-white text-lg md:text-xl mb-4 flex">
        <slot name="heading"></slot>
      </h4>

      <slot></slot>
    </div>
  </div>
</template>
