<script>
  export default {
    //
  }
</script>



<style lang="postcss" scoped>
  path {
    fill: none;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    --length: 24;
    --offset: -38;
    stroke-dasharray: var(--length) var(--total-length);
    stroke-dashoffset: var(--offset);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
  }

  .hamburger svg {
    @apply scale-[250%];
  }

  .hamburger.open svg {
    .top-line,
    .bottom-line {
      --length: 22.627416998;
    }
    .middle-line {
      --length: 0;
    }
  }

  .hamburger {
    @apply bg-transparent stroke-gray-lightest hover:!stroke-brand;

    .top-line,
    .bottom-line {
      --total-length: 111.22813415527344;
      --offset: -50.22813415527344;
    }

    .middle-line {
      --total-length: 99;
    }

    &.open svg {
      path {
        transform: translateX(30px);
      }

      .top-line,
      .bottom-line {
        --offset: -16.9705627485;
      }

      .middle-line {
        --offset: -20;
      }
    }
  }
</style>



<template>
  <button class="hamburger cursor-pointer lg:hidden relative" :class="{ 'open': $parent.navigation === true }">
    <svg class="h-10 w-10" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <path class="top-line" d="M0 70l28-28c2-2 2-2 7-2h64" />
      <path class="middle-line" d="M0 50h99" />
      <path class="bottom-line" d="M0 30l28 28c2 2 2 2 7 2h64" />
    </svg>
  </button>
</template>
