<script>
  export default {
    props: {
      activeSlot: {
        type: String,
        required: true
      }
    }
  }
</script>



<style scoped>
  .fade-enter-active {
    transition: all .3s ease-out;
  }

  .fade-leave-active {
    transition: all .3s ease-in;
  }

  .fade-enter-from,
  .fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }
</style>



<template>
  <div>
    <transition name="fade" mode="out-in">
      <slot :name="activeSlot"></slot>
    </transition>
  </div>
</template>
