<script>
  export default {
    props: {
      // tag: {
      //   type: String,
      //   required: false,
      //   default: 'a'
      // },
      disabled: Boolean,
      url: {
        type: [String, Object],
        required: false,
      },
      type: {
        type: String,
        default: '',
        required: false
      },
      compact: {
        type: Boolean,
        default: false,
        required: false
      },
      small: {
        type: Boolean,
        default: false,
        required: false
      },
      medium: {
        type: Boolean,
        default: false,
        required: false
      },
      big: {
        type: Boolean,
        default: false,
        required: false
      },
      large: {
        type: Boolean,
        default: false,
        required: false
      },
      huge: {
        type: Boolean,
        default: false,
        required: false
      }
    },

    computed: {
      isExternal () {
        return RegExp('^https?://|^//').test(this.url);
      },

      tag () {
        return this.isExternal ? 'a' : 'router-link';
      },

      attributes () {
        return this.isExternal
          ? { href: this.url, role: 'button', 'target': '_blank' }
          : { to: this.url, role: 'button' };
      },

      getClasses () {
        let classes = [];

        if (this.type)    classes.push(this.type);
        if (this.compact) classes.push('compact');
        if (this.small)   classes.push('small');
        if (this.medium)  classes.push('medium');
        if (this.big)     classes.push('big');
        if (this.large)   classes.push('large');
        if (this.huge)    classes.push('huge');

        return classes.join(' ');
      }
    }
  }
</script>



<style scoped>
  a,
  button {
    @apply transition inline-block border-2 rounded-md px-6 py-1 whitespace-nowrap;
    @apply text-gray-lightest border-gray-light;
    @apply hover:text-white hover:border-gray-lighter;
    @apply text-center;

    @apply disabled:border-gray-light disabled:text-gray-lighter disabled:cursor-not-allowed;
    @apply disabled:hover:border-gray-light disabled:hover:text-gray-lighter;
  }

  /* Medium */
  .medium {
    @apply py-4 px-8 text-base font-bold;
  }

  /* Large */
  .large {
    @apply py-6 px-12 text-lg font-medium;
  }

  /* Product */
  .product {
    @apply w-full lg:w-auto;
    @apply hover:!bg-brand hover:!border-brand hover:!text-segment-light;
  }

  /* Bordered */
  .bordered {
    @apply border-gray-lighter;
  }

  /* Attention */
  .attention {
    @apply border-gray-lighter hover:text-brand-matte hover:border-brand-matte;
  }

  /* Highlighted */
  .highlighted {
    @apply border-brand-matte text-brand-matte hover:border-brand hover:bg-brand hover:text-gray-darkest;
  }

  /* Table */
  .table {
    @apply inline-flex items-center justify-center transition -my-3 border-2 rounded-md mx-1 px-6 py-1 whitespace-nowrap;
  }

  /* Compact Table */
  .table.compact {
    @apply rounded px-2 text-sm font-bold;
    @apply text-gray-lighter border-gray-light;
    @apply hover:text-brand;
  }

  /* Small Table */
  .table.small {
    @apply rounded px-3 py-1 text-md font-bold;
    @apply text-gray-lighter border-gray-light;
    @apply hover:text-brand;
  }
</style>



<template>
  <component :is="tag" v-bind="attributes" :disabled="disabled" :class="getClasses" role="button">
    <slot></slot>
  </component>
</template>
