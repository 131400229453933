<script>
  import { useI18n } from 'vue-i18n';
  import Navigation from '@components/UI/Footer/Navigation.vue';
  import Button from '@components/UI/Footer/NavigationButton.vue';
  import Instagram from '@components/UI/Footer/Icons/SocialInstagram.vue';
  import Facebook from '@components/UI/Footer/Icons/SocialFacebook.vue';
  import GitHub from '@components/UI/Footer/Icons/SocialGitHub.vue';
  import Twitter from '@components/UI/Footer/Icons/SocialTwitter.vue';

  export default {
    components: {
      'navigation': Navigation,
      'navigation-button': Button,
      'social-instagram': Instagram,
      'social-facebook': Facebook,
      'social-github': GitHub,
      'social-twitter': Twitter,
    }
  }
</script>



<script setup>
  const { t, tm } = useI18n();
</script>



<template>
  <x-ui-segment-divider class="text-gray-darkest bg-gray-darkest" orientation="slope"></x-ui-segment-divider>

  <footer class="overflow-hidden relative bg-gray-darkest py-24">
    <section class="text-gray-lightest container mx-auto">
      <ul class="list-none flex flex-wrap text-center md:text-left">
        <li class="w-full lg:w-auto flex-grow">
          <x-ui-logo class="w-36 mb-6 mx-auto md:mx-0"></x-ui-logo>

          <p class="my-1 lg:my-2">
            {{ t('footer.location') }}
          </p>

          <p class="my-1 lg:my-2">
            {{ t('footer.copyright', { year: new Date().getFullYear() }) }}
          </p>

          <ul class="list-none flex items-center justify-center md:justify-start mx-auto md:-mx-2 mt-6 mb-16 lg:mb-0">
            <li class="mx-2">
              <a href="https://www.facebook.com/siberfy" target="_blank" class="transition text-gray-lighter hover:text-gray-lightest">
                <social-facebook></social-facebook>
              </a>
            </li>
            <li class="mx-2">
              <a href="https://twitter.com/siberfy" target="_blank" class="transition text-gray-lighter hover:text-gray-lightest">
                <social-twitter></social-twitter>
              </a>
            </li>
          </ul>
        </li>
        <li class="w-1/2 md:w-1/4 lg:w-auto flex-grow mb-12">
          <navigation>
            <template #heading>
              <span v-html="t('footer.links.pricing.heading')"></span>
            </template>

            <navigation-button :url="{ name: 'pricing', params: { group: 'general-compute' } }">{{ t('footer.links.pricing.general-compute') }}</navigation-button>
            <navigation-button :url="{ name: 'pricing', params: { group: 'storage-vms' } }">{{ t('footer.links.pricing.storage-vms') }}</navigation-button>
            <navigation-button :url="{ name: 'pricing', params: { group: 'high-cpu-compute' } }">{{ t('footer.links.pricing.high-cpu-compute') }}</navigation-button>
          </navigation>
        </li>
        <li class="w-1/2 md:w-1/4 lg:w-auto flex-grow mb-12">
          <navigation>
            <template #heading>
              <span v-html="t('footer.links.features.heading')"></span>
            </template>

            <navigation-button :url="{ name: 'features', hash: '#controlpanel' }">{{ t('footer.links.features.control-panel') }}</navigation-button>
            <navigation-button :url="{ name: 'features', hash: '#network' }">{{ t('footer.links.features.advanced-networking') }}</navigation-button>
            <navigation-button :url="{ name: 'features', hash: '#images' }">{{ t('footer.links.features.operating-systems') }}</navigation-button>
          </navigation>
        </li>
        <li class="w-1/2 md:w-1/4 lg:w-auto flex-grow mb-12">
          <navigation>
            <template #heading>
              <span v-html="t('footer.links.company.heading')"></span>
            </template>

            <navigation-button :url="{ name: 'company' }">{{ t('footer.links.company.about') }}</navigation-button>
            <navigation-button :url="{ name: 'features', hash: '#datacenters' }">{{ t('footer.links.company.data-centers') }}</navigation-button>
            <navigation-button url="#">{{ t('footer.links.company.support') }}</navigation-button>
          </navigation>
        </li>
        <li class="w-1/2 md:w-1/4 lg:w-auto flex-grow mb-12">
          <navigation>
            <template #heading>
              <span v-html="t('footer.links.legal.heading')"></span>
            </template>

            <navigation-button :url="{ name: 'legal.privacy' }">{{ t('footer.links.legal.privacy-policy') }}</navigation-button>
            <navigation-button :url="{ name: 'legal.tos' }">{{ t('footer.links.legal.terms-of-service') }}</navigation-button>
            <navigation-button :url="{ name: 'legal.policy' }">{{ t('footer.links.legal.acceptable-use-policy') }}</navigation-button>
          </navigation>
        </li>
      </ul>

      <hr class="mb-12 lg:my-16 border-gray lg:-mx-16">

      <section class="md:flex md:justify-between 2xl:justify-center md:items-center mx-8 md:mx-0 text-center md:text-left">
        <p class="max-w-md mx-auto mb-12 md:mb-0 md:mx-0 lg:max-w-none text-md lg:text-lg 2xl:text-lg text-gray-lighter leading-tight lg:mr-8">
          <strong class="block xl:inline-block text-lg xl:text-base 2xl:text-lg mb-1 lg:mb-0"><span v-html="t('footer.support.heading')"></span></strong>
          {{ t('footer.support.message') }}
        </p>

        <x-ui-button type="attention" medium :url="{ name: 'company.contact' }">
          {{ t('footer.support.contact') }}
        </x-ui-button>
      </section>
    </section>
  </footer>
</template>
