export default {
  "meta": [
    { "name": "description", "content": "Welcome to Siberfy. The first high bandwidth, transparently priced IaaS Provider in Pakistan. " }
  ],

  /**
   * Pricing
   */
  "pricing": {
    "cpu-compute": {
      "heading": {
        "highlight": "Compute",
        "suffix": "VMs"
      },
      "points": {
        "storage": {
          "prefix": "All-Flash enterprise",
          "highlight": "NVMe",
          "suffix": "storage"
        },
        "cpu": {
          "prefix": "",
          "highlight": "AMD & Intel's",
          "suffix": "latest tech"
        },
        "network": {
          "prefix": "",
          "highlight": "10 GbE",
          "suffix": "networking"
        },
        "memory": {
          "prefix": "Up to",
          "highlight": "96GB DDR4 ECC RAM",
          "suffix": "per VM"
        }
      },
      "purchase": "Starting at $5 per month"
    },
    "storage": {
      "heading": {
        "highlight": "Storage",
        "suffix": "VMs"
      },
      "points": {
        "amount": {
          "prefix": "Enterprise",
          "highlight": "SAS/SATA HDDs",
          "suffix": "for mass storage"
        },
        "virtualization": {
          "prefix": "True isolation with",
          "highlight": "KVM",
          "suffix": "virtualization"
        },
        "network": {
          "prefix": "",
          "highlight": "10 GbE",
          "suffix": "networking"
        },
        "storage": {
          "prefix": "Up to",
          "highlight": "10 TB",
          "suffix": "storage per VM"
        }
      },
      "purchase": "Starting at $5 per month"
    }
  },

  /**
   * Custom Solutions
   */
  "custom-solutions": {
    "heading": "Customized Solutions",
    "preamble": "Is your current server bill more than $500 per month? Using AWS/GCP located outside Pakistan, with performance losses to users inside Pakistan? Hosting on-premise with high costs? Contact us today for customized solutions like colocation, private cloud, and hybrid cloud solutions.",
  },

  /**
   * Selling Points
   */
  "selling-points": {
    "cost-effective": {
      "heading": "Cost-effective Computing",
      "message": "We deliver reliable and powerful enterprise grade virtual machines, with 10GbE networking and enterprise all flash NVMe storage. Check out our SLAs. Cost effective does not mean cheap. No corner is cut."
    },
    "location": {
      "heading": "Based in Pakistan",
      "message": "We're a Pakistani company with physical infrastructure located on Pakistani territory. All your data remains inside Pakistan and never leaves the borders, ensuring true safety and privacy, along with performance to your users."
    },
    "developer": {
      "heading": "Optimized for performance",
      "message": "With over a decade of experience, we're the first Pakistani cloud provider that brings an international quality of service to the country - with complete transparency and no hidden charges."
    }
  },

  /**
   * Control Panel
   */
  "control-panel": {
    "heading": "Powerful and Easy Management",
    "preamble": "Spend less time managing your infrastructure with our developer-friendly cloud.",
    "continue": "Learn More",
    "website": "cloud.siberfy.com",
    "deployment": {
      "heading": "Instant Deployment",
      "message": "Easy registration process and instant deployments. Get up and running in 60 seconds!"
    },
    "scaling": {
      "heading": "Scale with Ease",
      "message": "We want you to grow, so we offer you the ability to upgrade directly from our friendly user-interface."
    },
    "billing": {
      "heading": "Simplified Billing",
      "message": "Transparent monthly pricing without surprises. Multiple payment methods. Pay with ease and peace of mind."
    },
    "support": {
      "heading": "24/7 support",
      "message": "We operate around the clock and our dedicated support team is always ready to help."
    }
  },

  /**
   * Locations
   */
  "location": {
    "heading": "Bring your compute to the <span class=\"text-brand\">edge</span>",
    "preamble": "Reduce your latency by ten-fold and save on lost customers. Optimize your infrastructure by deploying your computing closer to the users. Your traffic does not need to cross borders, resulting in a never-before seen faster user experience for Pakistani users."
  }

};
