<script>
  import { h } from 'vue';

  export default {
    props: {
      level: {
        type: Number,
        required: false,
        default: 1
      }
    },

    render() {
      return h('div', {
        class: 'level-' + this.level,
      }, this.$slots.default())
    }
  }
</script>



<style scoped>
  div.level-1, div.level-2, div.level-3, div.level-4, div.level-5, div.level-6 {
    @apply text-gray-lightest;
  }

  div.level-1 {
    @apply max-w-5xl text-base md:text-lg leading-relaxed;
  }

  div.level-2 {
    @apply max-w-4xl text-base md:text-lg leading-relaxed;
  }

  div.level-3 {
    @apply max-w-prose text-base md:text-lg leading-relaxed;
  }

  div :deep() p {
    @apply my-4;
  }
</style>
