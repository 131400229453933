import layout from './layout';
import home from './home';
import datacenters from './datacenters';
import pricing from './pricing';
import features from './features';
import company from './company';
import legal from './legal';

export default {
  ...layout,
  'home': home,
  'data-centers': datacenters,
  'pricing': pricing,
  'features': features,
  'company': company,
  'legal': legal,
};
