<script>
  export default {
    //
  }
</script>



<template>
  <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25 2.67101C23.4274 2.74186 23.4617 2.73608 23.284 2.75062L24.2153 0.150807C24.2153 0.150807 21.3081 1.19614 20.5711 1.38313C18.6356 -0.317327 15.7585 -0.392459 13.7016 0.84322C12.0173 1.85537 11.1132 3.59348 11.4147 5.62504C8.13656 5.1804 5.37453 3.66153 3.19386 1.10106L2.50416 0.291378L1.98879 1.21646C1.33743 2.3854 1.12114 3.72194 1.37959 4.9798C1.48563 5.49585 1.66588 5.98803 1.91555 6.44889L1.32389 6.2248L1.2537 7.19108C1.18256 8.17266 1.51691 9.31847 2.14825 10.2559C2.32601 10.5198 2.55489 10.8098 2.845 11.0957L2.53925 11.0499L2.91233 12.1571C3.40271 13.6118 4.42123 14.7373 5.74303 15.363C4.42295 15.9106 3.35655 16.2599 1.6037 16.8235L0 17.3388L1.48125 18.1304C2.04601 18.4323 4.04167 19.4403 6.01349 19.7427C10.3966 20.4146 15.3315 19.8674 18.6537 16.9428C21.452 14.4795 22.3701 10.9755 22.1794 7.32904C22.1506 6.77702 22.3053 6.25016 22.6151 5.84578C23.2355 5.0361 24.9962 2.67642 25 2.67101ZM21.4437 4.98819C20.9293 5.65954 20.6717 6.51694 20.7178 7.40213C20.9103 11.0803 19.8864 13.9327 17.6744 15.8798C15.0906 18.1545 10.9228 19.0473 6.24027 18.3293C5.39227 18.1994 4.51584 17.9084 3.79047 17.6177C5.26009 17.1235 6.39496 16.683 8.22773 15.8351L10.7862 14.6514L7.96146 14.4747C6.60839 14.39 5.48172 13.7491 4.78916 12.7084C5.1569 12.6875 5.51147 12.6293 5.86452 12.5331L8.55865 11.7997L5.84202 11.1494C4.52194 10.8334 3.76949 10.0614 3.37067 9.4693C3.10879 9.08021 2.9377 8.68162 2.83508 8.31416C3.10745 8.38556 3.42503 8.43646 3.93715 8.48605L6.45161 8.72916L4.45938 7.2101C3.02391 6.11574 2.44865 4.47158 2.87037 2.891C7.35035 7.43289 12.6104 7.09153 13.1399 7.21159C13.0234 6.10511 13.0203 6.1025 12.9898 5.99717C12.3117 3.65426 13.7978 2.46463 14.468 2.06193C15.868 1.22093 18.0902 1.09416 19.6295 2.47973C19.9619 2.77877 20.4115 2.89641 20.8323 2.79424C21.2099 2.70252 21.5199 2.60539 21.8233 2.49968L21.1916 4.26296L21.998 4.26352C21.8458 4.46301 21.6633 4.70183 21.4437 4.98819Z" fill="currentColor"/>
  </svg>
</template>
