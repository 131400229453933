<script>
  export default {
    //
  }
</script>



<template>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.66211 25H11.8164V16.1133H8.88672V13.1836H11.8164V9.52148C11.8164 7.50217 13.4592 5.85938 15.4785 5.85938H19.1406V8.78906H16.2109C15.4032 8.78906 14.7461 9.44614 14.7461 10.2539V13.1836H19.0084L18.5202 16.1133H14.7461V25H21.3379C23.3572 25 25 23.3572 25 21.3379V3.66211C25 1.6428 23.3572 0 21.3379 0H3.66211C1.6428 0 0 1.6428 0 3.66211V21.3379C0 23.3572 1.6428 25 3.66211 25ZM1.46484 3.66211C1.46484 2.45056 2.45056 1.46484 3.66211 1.46484H21.3379C22.5494 1.46484 23.5352 2.45056 23.5352 3.66211V21.3379C23.5352 22.5494 22.5494 23.5352 21.3379 23.5352H16.2109V17.5781H19.7611L20.7376 11.7188H16.2109V10.2539H20.6055V4.39453H15.4785C12.6514 4.39453 10.3516 6.69441 10.3516 9.52148V11.7188H7.42188V17.5781H10.3516V23.5352H3.66211C2.45056 23.5352 1.46484 22.5494 1.46484 21.3379V3.66211Z" fill="currentColor"/>
  </svg>
</template>
