<script>
  export default {
    props: {
      packages: {
        type: Object,
        required: true
      }
    }
  }
</script>



<style scoped>
  ul.tabs-group {
    @apply divide-y divide-gray-lighter xl:divide-y-0 flex-grow bg-gradient-to-b from-gray-darker via-gray-dark to-gray-dark border-2 border-gray rounded-md xl:flex my-4 xl:my-0 md:mx-4 shadow-product text-gray-lightest;
  }

  ul.tabs-group > li {
    @apply relative flex-grow text-center px-6 py-3 xl:py-6 font-medium transition hover:text-brand;
  }

  ul.tabs-group > li.clickable {
    @apply cursor-pointer;
  }

  ul.tabs-group > li.active {
    @apply text-brand-matte hover:text-brand font-bold;
  }

  ul.tabs-group > li .arrow {
    @apply hidden xl:block;
  }
</style>



<template>
  <section class="md:-mx-4 mt-20 xl:mt-24 mb-8 xl:mb-16">
    <div class="md:flex justify-between">
      <ul v-for="(products, group) in packages" :key="`tab-group-${group}`" class="tabs-group">
        <li v-for="(product, identifier) in products" :key="`tab-${group}-${identifier}`"
          class="group clickable"
          :class="{ 'active': $parent.isSelected(group, identifier) }"
          @click="$parent.switchTo(group, identifier)">
          <div class="arrow absolute left-1/2 top-full w-5 -ml-5">
            <x-effect-slide-fade v-show="$parent.isSelected(group, identifier)">
              <div class="w-5">
                <svg class="absolute text-gray-dark z-10 h-5" viewBox="0 0 39 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M 19.49998,18.000003 35.6571,0 H 3.34286 Z" fill="currentColor"/>
                </svg>
                <svg class="absolute text-gray z-0 h-5" viewBox="0 0 39 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M 39,0 19.5,21 0,0 H 3.34286 L 19.5,17.68421 35.6571,0 Z" fill="currentColor"/>
                </svg>
              </div>
            </x-effect-slide-fade>
          </div>
          
          {{ product.heading }}
        </li>
      </ul>
    </div>
  </section>
</template>
