import Logo from '@components/UI/Logo.vue';
import Button from '@components/UI/Button.vue';
import Header from '@components/UI/Header/Hero.vue';
import Footer from '@components/UI/Footer/Footer.vue';

import Segment from '@components/UI/Segment/Segment.vue';
import SegmentDivider from '@components/UI/Segment/Divider.vue';
import SegmentHeading from '@components/UI/Segment/Heading.vue';
import SegmentPreamble from '@components/UI/Segment/Preamble.vue';

import Table from '@components/UI/Table/Table.vue';

import Atlas from '@components/UI/Units/Atlas/Atlas.vue';
import Fumetto from '@components/UI/Units/Fumetto.vue';
import Salespitch from '@components/UI/Units/Salespitch.vue';
import Palette from '@components/UI/Units/Palette.vue';

import ProductPedestal from '@components/UI/Units/Products/Pedestal.vue';
import ProductCarousel from '@components/UI/Units/Products/Carousel.vue';

export default {
  'x-ui-logo': Logo,
  'x-ui-button': Button,
  'x-ui-header': Header,
  'x-ui-footer': Footer,
  
  'x-ui-segment': Segment,
  'x-ui-segment-divider': SegmentDivider,
  'x-ui-segment-heading': SegmentHeading,
  'x-ui-segment-preamble': SegmentPreamble,

  'x-ui-table': Table,

  'x-ui-unit-atlas': Atlas,
  'x-ui-unit-fumetto': Fumetto,
  'x-ui-unit-salespitch': Salespitch,
  'x-ui-unit-palette': Palette,

  'x-ui-unit-product-pedestal': ProductPedestal,
  'x-ui-unit-product-carousel': ProductCarousel,
}
