<template>
  <svg width="40" height="40" class="fill-segment-light" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.4701 11.1258C18.4701 9.83131 17.8504 8.71737 16.726 8.71737C15.6225 8.71737 14.9818 9.76718 14.9818 11.1689C14.9818 12.5824 15.6434 13.5774 16.7364 13.5774C17.8399 13.5774 18.4701 12.5288 18.4701 11.1258Z"/>
    <path d="M18.4701 11.1258C18.4701 9.83131 17.8504 8.71737 16.726 8.71737C15.6225 8.71737 14.9818 9.76718 14.9818 11.1689C14.9818 12.5824 15.6434 13.5774 16.7364 13.5774C17.8399 13.5774 18.4701 12.5288 18.4701 11.1258Z"/>
    <path d="M29.375 0H13.3092C10.9623 0 9.05196 1.91172 9.05196 4.25734V5.04583H1.71551C0.76741 5.04583 0 5.81324 0 6.76126V15.4968C0 16.4449 0.76741 17.2124 1.71551 17.2124H9.05196V35.7427C9.05196 38.091 10.9623 40 13.3092 40H35.23C37.5757 40 39.486 38.0909 39.486 35.7427V10.0758L29.375 0ZM6.38991 14.7554H4.75147V7.54062H6.38991V14.7554ZM7.5261 14.4235L7.8582 13.0755C8.29766 13.3003 8.97223 13.5238 9.66788 13.5238C10.4158 13.5238 10.8134 13.2141 10.8134 12.7432C10.8134 12.2935 10.4707 12.0359 9.6025 11.7259C8.40485 11.309 7.623 10.6446 7.623 9.59603C7.623 8.36427 8.65067 7.42297 10.353 7.42297C11.1664 7.42297 11.7651 7.59421 12.1939 7.78644L11.8304 9.10315C11.5402 8.96443 11.0276 8.76059 10.3189 8.76059C9.61287 8.76059 9.27024 9.08216 9.27024 9.45607C9.27024 9.91643 9.67825 10.1204 10.6092 10.4734C11.8827 10.9443 12.4816 11.6083 12.4816 12.6255C12.4816 13.835 11.5506 14.8628 9.57098 14.8628C8.74732 14.8627 7.93278 14.6483 7.5261 14.4235ZM35.23 37.3928H13.3092C12.4005 37.3928 11.6606 36.6527 11.6606 35.7426V17.2124H23.253C24.201 17.2124 24.9685 16.4449 24.9685 15.4968V6.76126C24.9685 5.81324 24.201 5.04583 23.253 5.04583H11.6606V4.25734C11.6606 3.34989 12.4005 2.60986 13.3092 2.60986L28.3995 2.59426V8.17205C28.3995 9.80128 29.7213 11.1245 31.3519 11.1245L36.8161 11.1089L36.8775 35.7427C36.8776 36.6527 36.1388 37.3928 35.23 37.3928ZM13.2584 11.2017C13.2584 9.03902 14.6391 7.42297 16.7677 7.42297C18.9853 7.42297 20.1934 9.08216 20.1934 11.0723C20.1934 13.4387 18.7604 14.8731 16.6502 14.8731C14.5109 14.8731 13.2584 13.2571 13.2584 11.2017Z" />
    <path d="M29.375 0H13.3092C10.9623 0 9.05196 1.91172 9.05196 4.25734V5.04583H1.71551C0.76741 5.04583 0 5.81324 0 6.76126V15.4968C0 16.4449 0.76741 17.2124 1.71551 17.2124H9.05196V35.7427C9.05196 38.091 10.9623 40 13.3092 40H35.23C37.5757 40 39.486 38.0909 39.486 35.7427V10.0758L29.375 0ZM6.38991 14.7554H4.75147V7.54062H6.38991V14.7554ZM7.5261 14.4235L7.8582 13.0755C8.29766 13.3003 8.97223 13.5238 9.66788 13.5238C10.4158 13.5238 10.8134 13.2141 10.8134 12.7432C10.8134 12.2935 10.4707 12.0359 9.6025 11.7259C8.40485 11.309 7.623 10.6446 7.623 9.59603C7.623 8.36427 8.65067 7.42297 10.353 7.42297C11.1664 7.42297 11.7651 7.59421 12.1939 7.78644L11.8304 9.10315C11.5402 8.96443 11.0276 8.76059 10.3189 8.76059C9.61287 8.76059 9.27024 9.08216 9.27024 9.45607C9.27024 9.91643 9.67825 10.1204 10.6092 10.4734C11.8827 10.9443 12.4816 11.6083 12.4816 12.6255C12.4816 13.835 11.5506 14.8628 9.57098 14.8628C8.74732 14.8627 7.93278 14.6483 7.5261 14.4235ZM35.23 37.3928H13.3092C12.4005 37.3928 11.6606 36.6527 11.6606 35.7426V17.2124H23.253C24.201 17.2124 24.9685 16.4449 24.9685 15.4968V6.76126C24.9685 5.81324 24.201 5.04583 23.253 5.04583H11.6606V4.25734C11.6606 3.34989 12.4005 2.60986 13.3092 2.60986L28.3995 2.59426V8.17205C28.3995 9.80128 29.7213 11.1245 31.3519 11.1245L36.8161 11.1089L36.8775 35.7427C36.8776 36.6527 36.1388 37.3928 35.23 37.3928ZM13.2584 11.2017C13.2584 9.03902 14.6391 7.42297 16.7677 7.42297C18.9853 7.42297 20.1934 9.08216 20.1934 11.0723C20.1934 13.4387 18.7604 14.8731 16.6502 14.8731C14.5109 14.8731 13.2584 13.2571 13.2584 11.2017Z" />
    <path d="M24.2691 18.8274C19.6783 18.8274 15.9557 22.5497 15.9557 27.1404C15.9557 31.7311 19.6783 35.4537 24.2691 35.4537C28.8598 35.4537 32.5823 31.7311 32.5823 27.1404C32.5823 22.5497 28.8598 18.8274 24.2691 18.8274ZM24.2691 34.2077V30.3962C22.4738 30.3962 21.0133 28.9358 21.0133 27.1404C21.0133 27.1392 21.0133 27.1392 21.0133 27.1392H17.2019C17.2019 25.3608 17.8648 23.7395 18.95 22.4986L22.4542 26.0028C22.2462 26.3324 22.1208 26.7219 22.1208 27.1403C22.1208 28.3264 23.0831 29.2886 24.2692 29.2886C24.6313 29.2886 24.9686 29.1905 25.2681 29.0298C25.9479 28.6688 26.4175 27.9642 26.4175 27.1404C26.4175 25.9546 25.4551 24.9921 24.2692 24.9921C23.9069 24.9921 23.5695 25.0903 23.2702 25.251L19.7386 21.7208C20.965 20.6943 22.5445 20.0732 24.2692 20.0732V23.8846C26.0644 23.8846 27.5248 25.3451 27.5248 27.1403C27.5248 28.2648 26.9522 29.2586 26.0827 29.8444L28.7997 32.5601C27.5732 33.5865 25.9937 34.2077 24.2691 34.2077ZM23.2296 27.1404C23.2296 26.5664 23.6951 26.1011 24.2691 26.1011C24.843 26.1011 25.3085 26.5664 25.3085 27.1404C25.3085 27.7145 24.843 28.18 24.2691 28.18C23.6951 28.18 23.2296 27.7145 23.2296 27.1404Z"/>
    <path d="M24.2691 18.8274C19.6783 18.8274 15.9557 22.5497 15.9557 27.1404C15.9557 31.7311 19.6783 35.4537 24.2691 35.4537C28.8598 35.4537 32.5823 31.7311 32.5823 27.1404C32.5823 22.5497 28.8598 18.8274 24.2691 18.8274ZM24.2691 34.2077V30.3962C22.4738 30.3962 21.0133 28.9358 21.0133 27.1404C21.0133 27.1392 21.0133 27.1392 21.0133 27.1392H17.2019C17.2019 25.3608 17.8648 23.7395 18.95 22.4986L22.4542 26.0028C22.2462 26.3324 22.1208 26.7219 22.1208 27.1403C22.1208 28.3264 23.0831 29.2886 24.2692 29.2886C24.6313 29.2886 24.9686 29.1905 25.2681 29.0298C25.9479 28.6688 26.4175 27.9642 26.4175 27.1404C26.4175 25.9546 25.4551 24.9921 24.2692 24.9921C23.9069 24.9921 23.5695 25.0903 23.2702 25.251L19.7386 21.7208C20.965 20.6943 22.5445 20.0732 24.2692 20.0732V23.8846C26.0644 23.8846 27.5248 25.3451 27.5248 27.1403C27.5248 28.2648 26.9522 29.2586 26.0827 29.8444L28.7997 32.5601C27.5732 33.5865 25.9937 34.2077 24.2691 34.2077ZM23.2296 27.1404C23.2296 26.5664 23.6951 26.1011 24.2691 26.1011C24.843 26.1011 25.3085 26.5664 25.3085 27.1404C25.3085 27.7145 24.843 28.18 24.2691 28.18C23.6951 28.18 23.2296 27.7145 23.2296 27.1404Z"/>
  </svg>
</template>



<script>
  export default {
    //
  }
</script>
