import { createApp, computed } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { createI18n } from 'vue-i18n';
import { createHead, useHead } from '@vueuse/head';
import { Debug, Effects, Icons, UI } from '@components/Components.js';
import EnglishLocale from '@locale/en';
import UrduLocale from '@locale/ur';
import * as timeago from 'timeago.js';

const LOCALE_DEFAULT = 'en';
const LOCALE_ALLOWED = ['ur', LOCALE_DEFAULT];

const routes = [
  { name: 'home', path: '/', component: () => import('./Pages/Home.vue') },
  { name: 'features', path: '/features/:type?', component: () => import('./Pages/Features.vue') },
  { name: 'pricing', path: '/pricing/:group?/:subgroup?', component: () => import('./Pages/Pricing.vue') },
  { name: 'company', path: '/company', component: () => import('./Pages/Company.vue') },
  { name: 'company.contact', path: '/company/contact', component: () => import('./Pages/Company/Contact.vue') },
  { name: 'company.sla', path: '/company/sla', component: () => import('./Pages/Company/SLA.vue') },
  { name: 'company.legal', path: '/company/legal', component: () => import('./Pages/Company/Legal.vue') },
  { name: 'legal.tos', path: '/terms-of-service', component: () => import('./Pages/Legal/TermsOfService.vue') },
  { name: 'legal.policy', path: '/acceptable-use-policy', component: () => import('./Pages/Legal/AcceptableUsePolicy.vue') },
  { name: 'legal.privacy', path: '/privacy-policy', component: () => import('./Pages/Legal/PrivacyPolicy.vue') },
];

const i18n = createI18n({
  locale: process.env.MIX_SIBERFY_LOCALE || LOCALE_DEFAULT,
  legacy: false,
  fallbackLocale: LOCALE_DEFAULT,
  warnHtmlInMessage: "off",
  messages: {
    en: EnglishLocale,
    ur: UrduLocale,
  }
});

timeago.register(LOCALE_DEFAULT, (number, index, totalSec) => {
  return i18n.global.tm('timeago')[index];
});

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.name === 'pricing' && to.params?.group) {
      return { el: '#pricing-wrapper', behavior: 'smooth' };
    }

    if (from.name !== 'features' && to.name === 'features' && to.params?.type) {
      return { el: '#features-wrapper', behavior: 'smooth' };
    }

    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    }

    if (to.name !== from.name) {
      return { top: 0 };
    }

    return { };
  }
});

const head = createHead();

const app = createApp({
  setup () {
    useHead({
      title: computed(() => i18n.global.t('app.title')),
      meta: computed(() => i18n.global.tm('home.meta')),
    });
  },

  computed: {
    development () {
      return process.env.NODE_ENV !== 'production';
    },

    production () {
      return process.env.NODE_ENV === 'production';
    }
  },

  methods: {
    timeago (datetime) {
      return timeago.format(datetime, LOCALE_DEFAULT);
    }
  }
});

const registerComponents = (module) => {
  Object.keys(module).forEach((key) => {
    app.component(key, module[key]);
  });
}

app.use(router);
app.use(i18n);
app.use(head);

registerComponents(Debug);
registerComponents(Effects);
registerComponents(Icons);
registerComponents(UI);

router.isReady().then(() => app.mount('#app'));
