export default {
  "city": "City",
  "country": "Country",
  "status": "Status",
  "looking-glass": "Looking Glass",
  "test-files": "Test Files",
  "locations": [
    {
      "city": "Lahore",
      "country": "Pakistan",
      "dataCenter": "Online",
      "lookingGlass": "",
      "lookingGlassDomain": "LG.ACC",
      "testFiles": [
        {
          "url": "http://acc.mirror.siberfy.com/1gb",
          "name": "1 GB"
        },
        {
          "url": "http://acc.mirror.siberfy.com/10gb",
          "name": "10 GB"
        }
      ]
    },
    {
      "city": "Karachi",
      "country": "Pakistan",
      "comingSoon": "Coming Q1 2023"
    },
    {
      "city": "Islamabad",
      "country": "Pakistan",
      "comingSoon": "Coming Q2 2023"
    }
  ]
};
