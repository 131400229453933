<script>
  export default {
    props: {
      url: {
        type: [String, Object],
        required: true,
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      }
    }
  }
</script>



<style scoped>
  article {
    @apply bg-gradient-to-b from-gray-darker via-gray-dark to-gray-dark border-2 border-gray text-gray-lightest;
  }

  article h1 {
    @apply text-gray-lightish;
  }

  article h1 :deep() mark {
    @apply font-medium;
    @apply text-brand bg-transparent;
  }

  article ul :deep() mark {
    @apply font-bold;
    @apply text-brand bg-transparent;
  }
</style>



<template>
  <article class="group transition-all hover:lg:-mt-4 hover:lg:mb-4 flex-grow my-8 lg:my-0 lg:mx-4 px-4 md:px-10 lg:px-5 py-4 md:py-8 lg:py-5 rounded-md border text-center shadow-product lg:w-1/3">
    <form method="GET">
      <div class="md:flex items-center lg:block">
        <div class="flex justify-center items-center lg:-mt-16 order-2 md:order-1 md:ml-4 md:mr-16 lg:mx-0">
          <figure class="h-28 sm:h-32 md:h-44 lg:h-56 w-28 sm:w-32 md:w-44 lg:w-56">
            <slot name="icon"></slot>
          </figure>
        </div>

        <div class="flex-grow text-center md:text-left lg:text-center order-1 md:order-2">
          <h1 class="transition-all text-xl md:text-2xl xl:text-3xl mt-2 mb-4">
            <slot name="heading"></slot>
          </h1>

          <ul class="flex flex-wrap lg:block text-base xl:text-base leading-relaxed mb-6">
            <slot name="points"></slot>
          </ul>
        </div>
      </div>

      <div class="mt-12 md:mt-6 lg:mt-12 mb-3 sm:mb-0 lg:mb-6">
        <x-ui-button :url="url" :disabled="disabled" type="attention product" medium>
          <slot name="purchase"></slot>
        </x-ui-button>
      </div>
    </form>
  </article>
</template>
