<template>
  <svg width="38" height="45" viewBox="0 0 38 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7611 7.15176C10.7611 5.16186 9.142 3.54275 7.15176 3.54275C5.16186 3.54275 3.54275 5.16186 3.54275 7.15176C3.54275 9.142 5.16186 10.7608 7.15176 10.7608C9.142 10.7611 10.7611 9.142 10.7611 7.15176ZM5.30056 7.15176C5.30056 6.13106 6.13106 5.30056 7.15176 5.30056C8.1728 5.30056 9.0033 6.13106 9.0033 7.15176C9.0033 8.17245 8.1728 9.00295 7.15176 9.00295C6.13106 9.0033 5.30056 8.17245 5.30056 7.15176Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7611 7.15176C10.7611 5.16186 9.142 3.54275 7.15176 3.54275C5.16186 3.54275 3.54275 5.16186 3.54275 7.15176C3.54275 9.142 5.16186 10.7608 7.15176 10.7608C9.142 10.7611 10.7611 9.142 10.7611 7.15176ZM5.30056 7.15176C5.30056 6.13106 6.13106 5.30056 7.15176 5.30056C8.1728 5.30056 9.0033 6.13106 9.0033 7.15176C9.0033 8.17245 8.1728 9.00295 7.15176 9.00295C6.13106 9.0033 5.30056 8.17245 5.30056 7.15176Z" fill="#232634"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7611 7.15176C10.7611 5.16186 9.142 3.54275 7.15176 3.54275C5.16186 3.54275 3.54275 5.16186 3.54275 7.15176C3.54275 9.142 5.16186 10.7608 7.15176 10.7608C9.142 10.7611 10.7611 9.142 10.7611 7.15176ZM5.30056 7.15176C5.30056 6.13106 6.13106 5.30056 7.15176 5.30056C8.1728 5.30056 9.0033 6.13106 9.0033 7.15176C9.0033 8.17245 8.1728 9.00295 7.15176 9.00295C6.13106 9.0033 5.30056 8.17245 5.30056 7.15176Z" fill="url(#icon_bgprouting0_linear)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M31.4089 23.0277C29.144 23.0277 27.219 23.5921 26.0555 24.4889L22.9034 23.7871C22.4306 23.6817 21.9603 23.9801 21.8546 24.4538C21.7492 24.9276 22.0475 25.3973 22.5216 25.5027L24.9376 26.0407C24.8926 26.2161 24.8686 26.3967 24.8686 26.5811C24.8686 27.7752 25.846 28.7955 27.4116 29.4327L26.0043 30.8805C25.6661 31.2286 25.674 31.7852 26.0221 32.1233C26.1928 32.2895 26.4139 32.3719 26.6346 32.3719C26.8636 32.3719 27.0926 32.283 27.265 32.1058L29.3441 29.9666C29.9882 30.0754 30.6807 30.1345 31.4089 30.1345C35.1374 30.1345 37.9492 28.6067 37.9492 26.5811C37.9492 24.5555 35.1374 23.0277 31.4089 23.0277ZM31.4089 28.3767C28.5339 28.3767 26.6264 27.2959 26.6264 26.5811C26.6264 26.4314 26.7109 26.2659 26.8691 26.0977C26.8962 26.074 26.922 26.0489 26.9457 26.0215C27.6018 25.4086 29.2257 24.7855 31.4089 24.7855C34.2839 24.7855 36.1914 25.8663 36.1914 26.5811C36.1914 27.2959 34.2839 28.3767 31.4089 28.3767Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M31.4089 23.0277C29.144 23.0277 27.219 23.5921 26.0555 24.4889L22.9034 23.7871C22.4306 23.6817 21.9603 23.9801 21.8546 24.4538C21.7492 24.9276 22.0475 25.3973 22.5216 25.5027L24.9376 26.0407C24.8926 26.2161 24.8686 26.3967 24.8686 26.5811C24.8686 27.7752 25.846 28.7955 27.4116 29.4327L26.0043 30.8805C25.6661 31.2286 25.674 31.7852 26.0221 32.1233C26.1928 32.2895 26.4139 32.3719 26.6346 32.3719C26.8636 32.3719 27.0926 32.283 27.265 32.1058L29.3441 29.9666C29.9882 30.0754 30.6807 30.1345 31.4089 30.1345C35.1374 30.1345 37.9492 28.6067 37.9492 26.5811C37.9492 24.5555 35.1374 23.0277 31.4089 23.0277ZM31.4089 28.3767C28.5339 28.3767 26.6264 27.2959 26.6264 26.5811C26.6264 26.4314 26.7109 26.2659 26.8691 26.0977C26.8962 26.074 26.922 26.0489 26.9457 26.0215C27.6018 25.4086 29.2257 24.7855 31.4089 24.7855C34.2839 24.7855 36.1914 25.8663 36.1914 26.5811C36.1914 27.2959 34.2839 28.3767 31.4089 28.3767Z" fill="#232634"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M31.4089 23.0277C29.144 23.0277 27.219 23.5921 26.0555 24.4889L22.9034 23.7871C22.4306 23.6817 21.9603 23.9801 21.8546 24.4538C21.7492 24.9276 22.0475 25.3973 22.5216 25.5027L24.9376 26.0407C24.8926 26.2161 24.8686 26.3967 24.8686 26.5811C24.8686 27.7752 25.846 28.7955 27.4116 29.4327L26.0043 30.8805C25.6661 31.2286 25.674 31.7852 26.0221 32.1233C26.1928 32.2895 26.4139 32.3719 26.6346 32.3719C26.8636 32.3719 27.0926 32.283 27.265 32.1058L29.3441 29.9666C29.9882 30.0754 30.6807 30.1345 31.4089 30.1345C35.1374 30.1345 37.9492 28.6067 37.9492 26.5811C37.9492 24.5555 35.1374 23.0277 31.4089 23.0277ZM31.4089 28.3767C28.5339 28.3767 26.6264 27.2959 26.6264 26.5811C26.6264 26.4314 26.7109 26.2659 26.8691 26.0977C26.8962 26.074 26.922 26.0489 26.9457 26.0215C27.6018 25.4086 29.2257 24.7855 31.4089 24.7855C34.2839 24.7855 36.1914 25.8663 36.1914 26.5811C36.1914 27.2959 34.2839 28.3767 31.4089 28.3767Z" fill="url(#icon_bgprouting1_linear)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.9575 38.5953L22.3647 37.1472C22.7029 36.799 22.695 36.2429 22.3469 35.9044C21.9991 35.5662 21.4426 35.5741 21.104 35.9222L19.0249 38.0611C18.3808 37.9523 17.6883 37.8932 16.9601 37.8932C13.2317 37.8932 10.4198 39.421 10.4198 41.4466C10.4198 43.4722 13.2317 45 16.9601 45C20.6886 45 23.5004 43.4722 23.5004 41.4466C23.5004 40.2525 22.523 39.2322 20.9575 38.5953ZM16.9601 43.2422C14.0852 43.2422 12.1777 42.1614 12.1777 41.4466C12.1777 40.7318 14.0852 39.651 16.9601 39.651C19.8351 39.651 21.7426 40.7318 21.7426 41.4466C21.7426 42.1614 19.8351 43.2422 16.9601 43.2422Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.9575 38.5953L22.3647 37.1472C22.7029 36.799 22.695 36.2429 22.3469 35.9044C21.9991 35.5662 21.4426 35.5741 21.104 35.9222L19.0249 38.0611C18.3808 37.9523 17.6883 37.8932 16.9601 37.8932C13.2317 37.8932 10.4198 39.421 10.4198 41.4466C10.4198 43.4722 13.2317 45 16.9601 45C20.6886 45 23.5004 43.4722 23.5004 41.4466C23.5004 40.2525 22.523 39.2322 20.9575 38.5953ZM16.9601 43.2422C14.0852 43.2422 12.1777 42.1614 12.1777 41.4466C12.1777 40.7318 14.0852 39.651 16.9601 39.651C19.8351 39.651 21.7426 40.7318 21.7426 41.4466C21.7426 42.1614 19.8351 43.2422 16.9601 43.2422Z" fill="#232634"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.9575 38.5953L22.3647 37.1472C22.7029 36.799 22.695 36.2429 22.3469 35.9044C21.9991 35.5662 21.4426 35.5741 21.104 35.9222L19.0249 38.0611C18.3808 37.9523 17.6883 37.8932 16.9601 37.8932C13.2317 37.8932 10.4198 39.421 10.4198 41.4466C10.4198 43.4722 13.2317 45 16.9601 45C20.6886 45 23.5004 43.4722 23.5004 41.4466C23.5004 40.2525 22.523 39.2322 20.9575 38.5953ZM16.9601 43.2422C14.0852 43.2422 12.1777 42.1614 12.1777 41.4466C12.1777 40.7318 14.0852 39.651 16.9601 39.651C19.8351 39.651 21.7426 40.7318 21.7426 41.4466C21.7426 42.1614 19.8351 43.2422 16.9601 43.2422Z" fill="url(#icon_bgprouting2_linear)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6572 23.9742C15.7218 23.9886 15.7856 23.9955 15.8492 23.9955C16.2519 23.9955 16.6151 23.7167 16.7061 23.3075C16.8115 22.8337 16.5131 22.364 16.0394 22.2586L13.623 21.7203C13.6684 21.5449 13.6921 21.3646 13.6921 21.1799C13.6921 19.533 11.8601 18.4206 9.7617 17.9204L13.6835 10.0655C13.6897 10.0528 13.6958 10.0398 13.7017 10.0267C14.101 9.11797 14.3035 8.15083 14.3035 7.15176C14.3035 3.20835 11.0955 0 7.15176 0C3.20835 0 0 3.20835 0 7.15176C0 8.15048 0.20256 9.11762 0.602188 10.0267C0.607681 10.0398 0.613861 10.0528 0.620384 10.0655L4.54594 17.9283C2.44205 18.4406 0.611458 19.5533 0.611458 21.1803C0.611458 23.2059 3.42327 24.7333 7.15176 24.7333C9.41666 24.7333 11.342 24.1689 12.5055 23.2725L15.6572 23.9742ZM1.75781 7.15176C1.75781 4.17755 4.17755 1.75781 7.15176 1.75781C10.126 1.75781 12.5457 4.17755 12.5457 7.15176C12.5457 7.89883 12.396 8.62152 12.1008 9.30027L7.15176 19.2127L2.2031 9.30027C1.9075 8.62118 1.75781 7.89848 1.75781 7.15176ZM2.36927 21.1803C2.36927 20.7185 3.51391 19.9158 5.35515 19.5491L6.36555 21.5727C6.51421 21.8707 6.81873 22.0588 7.15176 22.0588C7.48478 22.0588 7.78931 21.8707 7.93831 21.5727L8.95111 19.5436C10.8133 19.8969 11.9342 20.6976 11.9342 21.1799C11.9342 21.33 11.8491 21.4961 11.6901 21.665C11.6634 21.6887 11.638 21.7134 11.6143 21.7402C10.9582 22.3527 9.33426 22.9755 7.15176 22.9755C4.27677 22.9755 2.36927 21.8951 2.36927 21.1803Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6572 23.9742C15.7218 23.9886 15.7856 23.9955 15.8492 23.9955C16.2519 23.9955 16.6151 23.7167 16.7061 23.3075C16.8115 22.8337 16.5131 22.364 16.0394 22.2586L13.623 21.7203C13.6684 21.5449 13.6921 21.3646 13.6921 21.1799C13.6921 19.533 11.8601 18.4206 9.7617 17.9204L13.6835 10.0655C13.6897 10.0528 13.6958 10.0398 13.7017 10.0267C14.101 9.11797 14.3035 8.15083 14.3035 7.15176C14.3035 3.20835 11.0955 0 7.15176 0C3.20835 0 0 3.20835 0 7.15176C0 8.15048 0.20256 9.11762 0.602188 10.0267C0.607681 10.0398 0.613861 10.0528 0.620384 10.0655L4.54594 17.9283C2.44205 18.4406 0.611458 19.5533 0.611458 21.1803C0.611458 23.2059 3.42327 24.7333 7.15176 24.7333C9.41666 24.7333 11.342 24.1689 12.5055 23.2725L15.6572 23.9742ZM1.75781 7.15176C1.75781 4.17755 4.17755 1.75781 7.15176 1.75781C10.126 1.75781 12.5457 4.17755 12.5457 7.15176C12.5457 7.89883 12.396 8.62152 12.1008 9.30027L7.15176 19.2127L2.2031 9.30027C1.9075 8.62118 1.75781 7.89848 1.75781 7.15176ZM2.36927 21.1803C2.36927 20.7185 3.51391 19.9158 5.35515 19.5491L6.36555 21.5727C6.51421 21.8707 6.81873 22.0588 7.15176 22.0588C7.48478 22.0588 7.78931 21.8707 7.93831 21.5727L8.95111 19.5436C10.8133 19.8969 11.9342 20.6976 11.9342 21.1799C11.9342 21.33 11.8491 21.4961 11.6901 21.665C11.6634 21.6887 11.638 21.7134 11.6143 21.7402C10.9582 22.3527 9.33426 22.9755 7.15176 22.9755C4.27677 22.9755 2.36927 21.8951 2.36927 21.1803Z" fill="#232634"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6572 23.9742C15.7218 23.9886 15.7856 23.9955 15.8492 23.9955C16.2519 23.9955 16.6151 23.7167 16.7061 23.3075C16.8115 22.8337 16.5131 22.364 16.0394 22.2586L13.623 21.7203C13.6684 21.5449 13.6921 21.3646 13.6921 21.1799C13.6921 19.533 11.8601 18.4206 9.7617 17.9204L13.6835 10.0655C13.6897 10.0528 13.6958 10.0398 13.7017 10.0267C14.101 9.11797 14.3035 8.15083 14.3035 7.15176C14.3035 3.20835 11.0955 0 7.15176 0C3.20835 0 0 3.20835 0 7.15176C0 8.15048 0.20256 9.11762 0.602188 10.0267C0.607681 10.0398 0.613861 10.0528 0.620384 10.0655L4.54594 17.9283C2.44205 18.4406 0.611458 19.5533 0.611458 21.1803C0.611458 23.2059 3.42327 24.7333 7.15176 24.7333C9.41666 24.7333 11.342 24.1689 12.5055 23.2725L15.6572 23.9742ZM1.75781 7.15176C1.75781 4.17755 4.17755 1.75781 7.15176 1.75781C10.126 1.75781 12.5457 4.17755 12.5457 7.15176C12.5457 7.89883 12.396 8.62152 12.1008 9.30027L7.15176 19.2127L2.2031 9.30027C1.9075 8.62118 1.75781 7.89848 1.75781 7.15176ZM2.36927 21.1803C2.36927 20.7185 3.51391 19.9158 5.35515 19.5491L6.36555 21.5727C6.51421 21.8707 6.81873 22.0588 7.15176 22.0588C7.48478 22.0588 7.78931 21.8707 7.93831 21.5727L8.95111 19.5436C10.8133 19.8969 11.9342 20.6976 11.9342 21.1799C11.9342 21.33 11.8491 21.4961 11.6901 21.665C11.6634 21.6887 11.638 21.7134 11.6143 21.7402C10.9582 22.3527 9.33426 22.9755 7.15176 22.9755C4.27677 22.9755 2.36927 21.8951 2.36927 21.1803Z" fill="url(#icon_bgprouting3_linear)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.1843 33.1351C23.7003 33.1351 23.3054 33.5296 23.3054 34.014C23.3054 34.4981 23.7003 34.8929 24.1843 34.8929C24.6684 34.8929 25.0632 34.4981 25.0632 34.014C25.0632 33.5296 24.6684 33.1351 24.1843 33.1351Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.1843 33.1351C23.7003 33.1351 23.3054 33.5296 23.3054 34.014C23.3054 34.4981 23.7003 34.8929 24.1843 34.8929C24.6684 34.8929 25.0632 34.4981 25.0632 34.014C25.0632 33.5296 24.6684 33.1351 24.1843 33.1351Z" fill="#232634"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.1843 33.1351C23.7003 33.1351 23.3054 33.5296 23.3054 34.014C23.3054 34.4981 23.7003 34.8929 24.1843 34.8929C24.6684 34.8929 25.0632 34.4981 25.0632 34.014C25.0632 33.5296 24.6684 33.1351 24.1843 33.1351Z" fill="url(#icon_bgprouting4_linear)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2803 24.7594C19.7644 24.7594 20.1592 24.3646 20.1592 23.8805C20.1592 23.3964 19.7644 23.0016 19.2803 23.0016C18.7962 23.0016 18.4014 23.3964 18.4014 23.8805C18.4014 24.3646 18.7962 24.7594 19.2803 24.7594Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2803 24.7594C19.7644 24.7594 20.1592 24.3646 20.1592 23.8805C20.1592 23.3964 19.7644 23.0016 19.2803 23.0016C18.7962 23.0016 18.4014 23.3964 18.4014 23.8805C18.4014 24.3646 18.7962 24.7594 19.2803 24.7594Z" fill="#232634"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2803 24.7594C19.7644 24.7594 20.1592 24.3646 20.1592 23.8805C20.1592 23.3964 19.7644 23.0016 19.2803 23.0016C18.7962 23.0016 18.4014 23.3964 18.4014 23.8805C18.4014 24.3646 18.7962 24.7594 19.2803 24.7594Z" fill="url(#icon_bgprouting5_linear)"/>
    <defs>
      <linearGradient id="icon_bgprouting0_linear" x1="18.9746" y1="0" x2="18.9746" y2="45" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="icon_bgprouting1_linear" x1="18.9746" y1="0" x2="18.9746" y2="45" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="icon_bgprouting2_linear" x1="18.9746" y1="0" x2="18.9746" y2="45" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="icon_bgprouting3_linear" x1="18.9746" y1="0" x2="18.9746" y2="45" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="icon_bgprouting4_linear" x1="18.9746" y1="0" x2="18.9746" y2="45" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="icon_bgprouting5_linear" x1="18.9746" y1="0" x2="18.9746" y2="45" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>
</template>



<script>
  export default {
    //
  }
</script>
