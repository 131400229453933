import IconBlob from './Blob.vue';

import BareMetal from './Products/BareMetal.vue';
import CpuCompute from './Products/CpuCompute.vue';
import DedicatedCpu from './Products/DedicatedCpu.vue';
import MemoryOptimized from './Products/MemoryOptimized.vue';
import GpuCompute from './Products/GpuCompute.vue';
import ObjectStorage from './Products/ObjectStorage.vue';
import SnapshotsBackups from './Products/SnapshotsBackups.vue';

import FlaticonAiCloud from './Flaticon/AiCloud.vue';
import FlaticonChip from './Flaticon/Chip.vue';
import FlaticonCpu from './Flaticon/Cpu.vue';
import FlaticonEdgeLocations from './Flaticon/EdgeLocations.vue';
import FlaticonElk from './Flaticon/Elk.vue';
import FlaticonFailover from './Flaticon/Failover.vue';
import FlaticonFastCloud from './Flaticon/FastCloud.vue';
import FlaticonFirewall from './Flaticon/Firewall.vue';
import FlaticonHeating from './Flaticon/Heating.vue';
import FlaticonHelpdesk from './Flaticon/Helpdesk.vue';
import FlaticonHot from './Flaticon/Hot.vue';
import FlaticonRenewable from './Flaticon/Renewable.vue';
import FlaticonRocket from './Flaticon/Rocket.vue';
import FlaticonWfh from './Flaticon/Wfh.vue';

import GenericCloud from './Generic/Cloud.vue';
import GenericMoose from './Generic/Moose.vue';
import GenericRocket from './Generic/Rocket.vue';
import GenericSnapshots from './Generic/Snapshots.vue';
import GenericDDoSProtection from './Generic/DDoSProtection.vue';
import GenericOneClickApps from './Generic/OneClickApps.vue';
import GenericAnsible from './Generic/Ansible.vue';
import GenericBGPRouting from './Generic/BGPRouting.vue';
import GenericPrivateNetworking from './Generic/PrivateNetworking.vue';
import GenericCustomISO from './Generic/CustomISO.vue';
import GenericWindturbine from './Generic/Windturbine.vue';
import GenericPlant from './Generic/Plant.vue';
import GenericRenewableEnergy from './Generic/RenewableEnergy.vue';
import GenericCompany from './Generic/Company.vue';
import GenericFlexibleSchedule from './Generic/FlexibleSchedule.vue';
import GenericAtmosphere from './Generic/Atmosphere.vue';
import GenericPersonalGrowth from './Generic/PersonalGrowth.vue';

export default {
  'x-icon-blob': IconBlob,

  'x-icon-product-bare-metal': BareMetal,
  'x-icon-product-cpu-compute': CpuCompute,
  'x-icon-product-dedicated-cpu': DedicatedCpu,
  'x-icon-product-memory-optimized': MemoryOptimized,
  'x-icon-product-gpu-compute': GpuCompute,
  'x-icon-product-object-storage': ObjectStorage,
  'x-icon-product-snapshots-backups': SnapshotsBackups,

  'x-icon-flaticon-ai-cloud': FlaticonAiCloud,
  'x-icon-flaticon-chip': FlaticonChip,
  'x-icon-flaticon-cpu': FlaticonCpu,
  'x-icon-flaticon-edge-locations': FlaticonEdgeLocations,
  'x-icon-flaticon-elk': FlaticonElk,
  'x-icon-flaticon-failover': FlaticonFailover,
  'x-icon-flaticon-fast-cloud': FlaticonFastCloud,
  'x-icon-flaticon-firewall': FlaticonFirewall,
  'x-icon-flaticon-heating': FlaticonHeating,
  'x-icon-flaticon-helpdesk': FlaticonHelpdesk,
  'x-icon-flaticon-hot': FlaticonHot,
  'x-icon-flaticon-renewable': FlaticonRenewable,
  'x-icon-flaticon-rocket': FlaticonRocket,
  'x-icon-flaticon-wfh': FlaticonWfh,

  'x-icon-rocket': GenericRocket,
  'x-icon-moose': GenericMoose,
  'x-icon-cloud': GenericCloud,
  'x-icon-snapshots': GenericSnapshots,
  'x-icon-ddos-protection': GenericDDoSProtection,
  'x-icon-oneclick-apps': GenericOneClickApps,
  'x-icon-ansible': GenericAnsible,
  'x-icon-bgp-routing': GenericBGPRouting,
  'x-icon-private-networking': GenericPrivateNetworking,
  'x-icon-custom-iso': GenericCustomISO,
  'x-icon-windturbine': GenericWindturbine,
  'x-icon-plant': GenericPlant,
  'x-icon-renewable-energy': GenericRenewableEnergy,
  'x-icon-company': GenericCompany,
  'x-icon-flexible-schedule': GenericFlexibleSchedule,
  'x-icon-atmosphere': GenericAtmosphere,
  'x-icon-personal-growth': GenericPersonalGrowth,
}
