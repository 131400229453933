import layout from '../en/layout';
import home from '../en/home';
import datacenters from '../en/datacenters';
import pricing from '../en/pricing';
import features from '../en/features';
import company from '../en/company';
import legal from '../en/legal';

export default {
  ...layout,
  'home': home,
  'data-centers': datacenters,
  'pricing': pricing,
  'features': features,
  'company': company,
  'legal': legal,
};
