<script>
  export default {
    //
  }
</script>



<template>
  <nav>
    <h3 class="font-bold uppercase text-gray-lighter mb-3">
      <slot name="heading"></slot>
    </h3>

    <ul class="list-none">
      <slot></slot>
    </ul>
  </nav>
</template>
