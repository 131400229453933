<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M39.0372 22.7711C38.6135 22.6923 38.2052 22.9721 38.1263 23.3963C36.5055 32.1121 28.8841 38.4375 20.0037 38.4375H20.002C9.83539 38.4366 1.56344 30.1647 1.5625 19.9981C1.56203 15.0733 3.47945 10.4435 6.96148 6.96141C10.4433 3.47969 15.072 1.5625 19.9965 1.5625H19.9983C21.9912 1.56266 23.9374 1.87562 25.8012 2.49414C24.8334 3.43461 24.2358 4.78852 24.2358 6.31656C24.2358 11.583 31.4631 15.0829 31.7708 15.2293C31.8758 15.2792 31.9905 15.3051 32.1065 15.3051C32.1143 15.3051 32.1222 15.305 32.1301 15.3048L32.1616 15.3038C32.2702 15.3005 32.377 15.2746 32.4749 15.2278C32.6399 15.149 34.8041 14.0971 36.7658 12.3203C37.3909 13.6822 37.8463 15.1142 38.1225 16.5896C38.1927 16.9652 38.5209 17.2273 38.8895 17.2273C38.9371 17.2273 38.9855 17.2229 39.0341 17.2137C39.4583 17.1344 39.7377 16.7262 39.6583 16.3021C39.3222 14.5066 38.7417 12.7705 37.931 11.1344C39.0927 9.79102 39.9768 8.16227 39.9768 6.31656C39.9768 3.41062 37.8155 1.13422 35.0563 1.13422C33.9467 1.13422 32.9399 1.49484 32.1061 2.18437C31.4305 1.62664 30.4637 1.13422 29.1562 1.13422C28.5573 1.13422 27.9866 1.24148 27.4601 1.43914C25.0882 0.484375 22.5789 0.000234375 19.9984 0H19.9964C14.6549 0 9.63336 2.07992 5.85664 5.85656C2.07945 9.63383 -0.000468671 14.6561 7.9214e-08 19.9983C0.000468829 25.3404 2.08133 30.363 5.85914 34.1409C9.63695 37.9187 14.6596 39.9995 20.0018 40H20.0037C29.6358 40 37.9041 33.137 39.6624 23.682C39.7413 23.2578 39.4614 22.85 39.0372 22.7711ZM31.5184 3.78617C31.6682 3.95727 31.8877 4.05289 32.1148 4.04992C32.3392 4.04828 32.5544 3.94727 32.7013 3.77789C32.708 3.77102 32.7145 3.76398 32.7209 3.7568C33.3485 3.05344 34.1342 2.6968 35.0562 2.6968C36.9392 2.6968 38.4142 4.2868 38.4142 6.31664C38.4142 10.0472 33.4418 12.9487 32.1201 13.6538C30.7899 12.9491 25.7982 10.0543 25.7982 6.31664C25.7982 4.2868 27.2732 2.6968 29.1562 2.6968C30.0911 2.6968 30.8859 3.06328 31.5184 3.78617Z" fill="#232634"/>
    <path d="M39.0372 22.7711C38.6135 22.6923 38.2052 22.9721 38.1263 23.3963C36.5055 32.1121 28.8841 38.4375 20.0037 38.4375H20.002C9.83539 38.4366 1.56344 30.1647 1.5625 19.9981C1.56203 15.0733 3.47945 10.4435 6.96148 6.96141C10.4433 3.47969 15.072 1.5625 19.9965 1.5625H19.9983C21.9912 1.56266 23.9374 1.87562 25.8012 2.49414C24.8334 3.43461 24.2358 4.78852 24.2358 6.31656C24.2358 11.583 31.4631 15.0829 31.7708 15.2293C31.8758 15.2792 31.9905 15.3051 32.1065 15.3051C32.1143 15.3051 32.1222 15.305 32.1301 15.3048L32.1616 15.3038C32.2702 15.3005 32.377 15.2746 32.4749 15.2278C32.6399 15.149 34.8041 14.0971 36.7658 12.3203C37.3909 13.6822 37.8463 15.1142 38.1225 16.5896C38.1927 16.9652 38.5209 17.2273 38.8895 17.2273C38.9371 17.2273 38.9855 17.2229 39.0341 17.2137C39.4583 17.1344 39.7377 16.7262 39.6583 16.3021C39.3222 14.5066 38.7417 12.7705 37.931 11.1344C39.0927 9.79102 39.9768 8.16227 39.9768 6.31656C39.9768 3.41062 37.8155 1.13422 35.0563 1.13422C33.9467 1.13422 32.9399 1.49484 32.1061 2.18437C31.4305 1.62664 30.4637 1.13422 29.1562 1.13422C28.5573 1.13422 27.9866 1.24148 27.4601 1.43914C25.0882 0.484375 22.5789 0.000234375 19.9984 0H19.9964C14.6549 0 9.63336 2.07992 5.85664 5.85656C2.07945 9.63383 -0.000468671 14.6561 7.9214e-08 19.9983C0.000468829 25.3404 2.08133 30.363 5.85914 34.1409C9.63695 37.9187 14.6596 39.9995 20.0018 40H20.0037C29.6358 40 37.9041 33.137 39.6624 23.682C39.7413 23.2578 39.4614 22.85 39.0372 22.7711ZM31.5184 3.78617C31.6682 3.95727 31.8877 4.05289 32.1148 4.04992C32.3392 4.04828 32.5544 3.94727 32.7013 3.77789C32.708 3.77102 32.7145 3.76398 32.7209 3.7568C33.3485 3.05344 34.1342 2.6968 35.0562 2.6968C36.9392 2.6968 38.4142 4.2868 38.4142 6.31664C38.4142 10.0472 33.4418 12.9487 32.1201 13.6538C30.7899 12.9491 25.7982 10.0543 25.7982 6.31664C25.7982 4.2868 27.2732 2.6968 29.1562 2.6968C30.0911 2.6968 30.8859 3.06328 31.5184 3.78617Z" fill="url(#icon_atmosphere0_linear)"/>
    <path d="M39.7805 19.7977C39.6526 19.4906 39.3357 19.2934 39.0033 19.3174C38.6787 19.3409 38.3988 19.5705 38.3087 19.8824C38.2185 20.1948 38.3394 20.5433 38.6046 20.7317C38.868 20.9188 39.2237 20.9241 39.493 20.7462C39.8012 20.5425 39.9197 20.1373 39.7805 19.7977Z" fill="#232634"/>
    <path d="M39.7805 19.7977C39.6526 19.4906 39.3357 19.2934 39.0033 19.3174C38.6787 19.3409 38.3988 19.5705 38.3087 19.8824C38.2185 20.1948 38.3394 20.5433 38.6046 20.7317C38.868 20.9188 39.2237 20.9241 39.493 20.7462C39.8012 20.5425 39.9197 20.1373 39.7805 19.7977Z" fill="url(#icon_atmosphere1_linear)"/>
    <path d="M24.8768 17.6136L26.2323 16.2581L27.5877 17.6136C27.8927 17.9187 28.3873 17.9187 28.6926 17.6136C28.9977 17.3085 28.9977 16.8138 28.6926 16.5087L26.7848 14.6009C26.6383 14.4543 26.4395 14.372 26.2323 14.372C26.0252 14.372 25.8265 14.4544 25.6799 14.6009L23.772 16.5087C23.467 16.8138 23.467 17.3085 23.772 17.6137C23.9246 17.7663 24.1245 17.8425 24.3245 17.8425C24.5243 17.8423 24.7241 17.7661 24.8768 17.6136Z" fill="#232634"/>
    <path d="M24.8768 17.6136L26.2323 16.2581L27.5877 17.6136C27.8927 17.9187 28.3873 17.9187 28.6926 17.6136C28.9977 17.3085 28.9977 16.8138 28.6926 16.5087L26.7848 14.6009C26.6383 14.4543 26.4395 14.372 26.2323 14.372C26.0252 14.372 25.8265 14.4544 25.6799 14.6009L23.772 16.5087C23.467 16.8138 23.467 17.3085 23.772 17.6137C23.9246 17.7663 24.1245 17.8425 24.3245 17.8425C24.5243 17.8423 24.7241 17.7661 24.8768 17.6136Z" fill="url(#icon_atmosphere2_linear)"/>
    <path d="M12.3659 17.6136L13.7213 16.2581L15.0768 17.6136C15.2294 17.7662 15.4293 17.8424 15.6292 17.8424C15.8291 17.8424 16.0291 17.7662 16.1816 17.6136C16.4867 17.3085 16.4867 16.8138 16.1816 16.5087L14.2737 14.6008C13.9687 14.2957 13.474 14.2957 13.1689 14.6008L11.261 16.5087C10.9559 16.8138 10.9559 17.3084 11.261 17.6136C11.5661 17.9187 12.0608 17.9187 12.3659 17.6136Z" fill="#232634"/>
    <path d="M12.3659 17.6136L13.7213 16.2581L15.0768 17.6136C15.2294 17.7662 15.4293 17.8424 15.6292 17.8424C15.8291 17.8424 16.0291 17.7662 16.1816 17.6136C16.4867 17.3085 16.4867 16.8138 16.1816 16.5087L14.2737 14.6008C13.9687 14.2957 13.474 14.2957 13.1689 14.6008L11.261 16.5087C10.9559 16.8138 10.9559 17.3084 11.261 17.6136C11.5661 17.9187 12.0608 17.9187 12.3659 17.6136Z" fill="url(#icon_atmosphere3_linear)"/>
    <path d="M8.3018 21.1252V22.0603C8.3018 23.2181 8.47094 24.3619 8.80469 25.4598C8.80664 25.4663 8.80875 25.4727 8.81086 25.4791C9.70008 28.3868 11.6587 30.8015 14.328 32.28C14.3286 32.2804 14.3293 32.2808 14.3299 32.2811C16.0477 33.2325 18.0003 33.7353 19.9767 33.7353C21.9531 33.7353 23.9058 33.2324 25.6235 32.2811C25.6238 32.2809 25.6241 32.2808 25.6244 32.2805C26.0977 32.0186 26.5576 31.7202 26.9917 31.3934C28.9541 29.916 30.4274 27.8163 31.1423 25.4795C31.1445 25.4729 31.1466 25.4663 31.1487 25.4597C31.4824 24.3619 31.6516 23.2181 31.6516 22.0602V21.1251C31.6516 20.6936 31.3019 20.3438 30.8704 20.3438H9.08305C8.65156 20.344 8.3018 20.6938 8.3018 21.1252ZM19.9767 32.173C18.5653 32.173 17.1685 31.8773 15.8886 31.3122C16.9109 30.1376 18.3857 29.457 19.9767 29.457C21.4262 29.457 22.7898 30.0218 23.8159 31.0471C23.9017 31.133 23.9845 31.2215 24.0641 31.3125C22.7845 31.8773 21.3877 32.173 19.9767 32.173ZM26.052 30.1452C25.8618 30.2884 25.6659 30.4253 25.4655 30.555C25.2966 30.3409 25.1145 30.1361 24.9205 29.9421C23.5991 28.6217 21.8434 27.8945 19.9766 27.8945C17.8082 27.8945 15.8073 28.8762 14.4878 30.5545C12.7988 29.4573 11.4566 27.859 10.6678 26.0143H29.2856C28.5926 27.6366 27.4696 29.078 26.052 30.1452ZM9.8643 21.9065H30.0891V22.0603C30.0891 22.8705 29.9937 23.6724 29.8049 24.4513H10.1485C9.95976 23.6723 9.8643 22.8705 9.8643 22.0603V21.9065Z" fill="#232634"/>
    <path d="M8.3018 21.1252V22.0603C8.3018 23.2181 8.47094 24.3619 8.80469 25.4598C8.80664 25.4663 8.80875 25.4727 8.81086 25.4791C9.70008 28.3868 11.6587 30.8015 14.328 32.28C14.3286 32.2804 14.3293 32.2808 14.3299 32.2811C16.0477 33.2325 18.0003 33.7353 19.9767 33.7353C21.9531 33.7353 23.9058 33.2324 25.6235 32.2811C25.6238 32.2809 25.6241 32.2808 25.6244 32.2805C26.0977 32.0186 26.5576 31.7202 26.9917 31.3934C28.9541 29.916 30.4274 27.8163 31.1423 25.4795C31.1445 25.4729 31.1466 25.4663 31.1487 25.4597C31.4824 24.3619 31.6516 23.2181 31.6516 22.0602V21.1251C31.6516 20.6936 31.3019 20.3438 30.8704 20.3438H9.08305C8.65156 20.344 8.3018 20.6938 8.3018 21.1252ZM19.9767 32.173C18.5653 32.173 17.1685 31.8773 15.8886 31.3122C16.9109 30.1376 18.3857 29.457 19.9767 29.457C21.4262 29.457 22.7898 30.0218 23.8159 31.0471C23.9017 31.133 23.9845 31.2215 24.0641 31.3125C22.7845 31.8773 21.3877 32.173 19.9767 32.173ZM26.052 30.1452C25.8618 30.2884 25.6659 30.4253 25.4655 30.555C25.2966 30.3409 25.1145 30.1361 24.9205 29.9421C23.5991 28.6217 21.8434 27.8945 19.9766 27.8945C17.8082 27.8945 15.8073 28.8762 14.4878 30.5545C12.7988 29.4573 11.4566 27.859 10.6678 26.0143H29.2856C28.5926 27.6366 27.4696 29.078 26.052 30.1452ZM9.8643 21.9065H30.0891V22.0603C30.0891 22.8705 29.9937 23.6724 29.8049 24.4513H10.1485C9.95976 23.6723 9.8643 22.8705 9.8643 22.0603V21.9065Z" fill="url(#icon_atmosphere4_linear)"/>
    <defs>
      <linearGradient id="icon_atmosphere0_linear" x1="19.9884" y1="0" x2="19.9884" y2="40" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="icon_atmosphere1_linear" x1="19.9884" y1="0" x2="19.9884" y2="40" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="icon_atmosphere2_linear" x1="19.9884" y1="0" x2="19.9884" y2="40" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="icon_atmosphere3_linear" x1="19.9884" y1="0" x2="19.9884" y2="40" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="icon_atmosphere4_linear" x1="19.9884" y1="0" x2="19.9884" y2="40" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>
</template>



<script>
  export default {
    //
  }
</script>
