<script setup>
  import { computed } from 'vue';

  const suffix = Math.random().toString(36).substr(2, 9);
  const generate = (range, callback) => ([...Array(range)].map(callback));

  const gradients = generate(12, (v, k) => `url(#bare-metal-gradient-${suffix}-${k})`);
  const slugs = generate(12, (v, k) => `bare-metal-gradient-${suffix}-${k}`);
</script>



<style scoped>
  .bare-metal-dark { @apply hidden; }
  .bare-metal-light { @apply block; }

  /*
  .bare-metal-dark { @apply transition opacity-100; }
  .bare-metal-light { @apply transition opacity-0; }

  svg:hover .bare-metal-dark { @apply opacity-0; }
  svg:hover .bare-metal-light { @apply opacity-100; }
  */

  .bare-metal-1 { fill: rgba(0, 0, 0, .15); }
  .bare-metal-2 { fill: #20b785; }
  .bare-metal-3 { fill: #53dcab; }
  .bare-metal-4 { fill: #1faa78; }
  .bare-metal-5 { fill: #767f91; }
  .bare-metal-6 { fill: #6d7789; }
  .bare-metal-7 { fill: v-bind(gradients[1]); }
  .bare-metal-8 { fill: #abb3c6; }
  .bare-metal-9 { fill: #2b303d; }
  .bare-metal-10 { fill: #4d5470; }
  .bare-metal-11 { fill: v-bind(gradients[2]); }
  .bare-metal-12 { fill: v-bind(gradients[3]); }
  .bare-metal-13 { fill: v-bind(gradients[4]); }
  .bare-metal-14 { fill: v-bind(gradients[5]); }
  .bare-metal-15 { fill: v-bind(gradients[6]); }
  .bare-metal-16 { fill: v-bind(gradients[7]); }
  .bare-metal-17 { fill: #394051; }
  .bare-metal-18 { fill: #4fd3a1; }
  .bare-metal-19 { fill: v-bind(gradients[8]); }
  .bare-metal-20 { fill: #232634; }
  .bare-metal-21 { fill: #5c5f6b; }
  .bare-metal-22 { fill: v-bind(gradients[9]); }
  .bare-metal-23 { fill: #7e89a3; }
  .bare-metal-24 { fill: #8691ad; }
  .bare-metal-25 { fill: v-bind(gradients[10]); }
  .bare-metal-26 { fill: #5f6772; }
  .bare-metal-27 { fill: #3b3e49; }
  .bare-metal-28 { fill: #5c646d; }
  .bare-metal-29 { fill: #242835; }
  .bare-metal-30 { fill: #282d38; }
  .bare-metal-31 { fill: #363f4c; }
  .bare-metal-32 { fill: #217f62; }
  .bare-metal-33 { fill: #161924; }
  .bare-metal-34 { fill: #363c4c; }
  .bare-metal-35 { fill: v-bind(gradients[11]); }
</style>



<template>
  <svg id="Both" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 372.43 388.15">
    <defs>
      <linearGradient :id="slugs[1]" x1="818.9" y1="524.99" x2="853.54" y2="524.99" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#767f91"/>
        <stop offset="1" stop-color="#6d7789"/>
      </linearGradient>
      <linearGradient :id="slugs[2]" x1="246.03" y1="228.86" x2="257.37" y2="222.31" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#4fd3a1"/>
          <stop offset="0.21" stop-color="#4fd3a1"/>
          <stop offset="0.54" stop-color="#3ac896"/>
          <stop offset="0.76" stop-color="#31c391"/>
          <stop offset="1" stop-color="#23ad7b"/>
        </linearGradient>
        <linearGradient :id="slugs[3]" x1="258.13" y1="221.88" x2="269.46" y2="215.33" :xlink:href="`#${slugs[2]}`"/>
        <linearGradient :id="slugs[4]" x1="270.22" y1="214.89" x2="281.56" y2="208.35" :xlink:href="`#${slugs[2]}`"/>
        <linearGradient :id="slugs[5]" x1="282.32" y1="207.91" x2="293.65" y2="201.36" :xlink:href="`#${slugs[2]}`"/>
        <linearGradient :id="slugs[6]" x1="294.41" y1="200.93" x2="305.75" y2="194.38" :xlink:href="`#${slugs[2]}`"/>
        <linearGradient :id="slugs[7]" x1="306.51" y1="193.94" x2="317.84" y2="187.4" :xlink:href="`#${slugs[2]}`"/>
        <linearGradient :id="slugs[8]" x1="41.44" y1="224.1" x2="122.78" y2="224.1" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#20b785"/>
          <stop offset="0.27" stop-color="#53dcab"/>
          <stop offset="0.63" stop-color="#4fd3a1"/>
          <stop offset="0.76" stop-color="#44ca98"/>
          <stop offset="0.97" stop-color="#27b17f"/>
          <stop offset="1" stop-color="#23ad7b"/>
        </linearGradient>
        <linearGradient :id="slugs[9]" x1="41.44" y1="196.18" x2="122.78" y2="196.18" :xlink:href="`#${slugs[8]}`"/>
        <linearGradient :id="slugs[10]" x1="831.31" y1="327.43" x2="841.13" y2="327.43" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#7e89a3"/>
          <stop offset="1" stop-color="#8691ad"/>
        </linearGradient>
        <linearGradient :id="slugs[11]" x1="129.61" y1="147.26" x2="200.03" y2="147.26" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#f7cd66"/>
          <stop offset="0.61" stop-color="#f0c964"/>
          <stop offset="1" stop-color="#e8c562"/>
        </linearGradient>
      </defs>
    <path class="bare-metal-1" d="M819.78,650.91c9,5.22,23.84,5.22,32.88,0l153.52-88.63c9-5.22,9-13.77,0-19L852.66,454.65c-9-5.23-23.84-5.23-32.88,0L666.26,543.29c-9,5.22-9,13.77,0,19Z" transform="translate(-650 -266.67)"/>
    <path class="bare-metal-2" d="M1006.19,518.34l-5.82,3.36v9.9l5.82-3.36c4.52-2.61,6.78-6,6.79-9.49h0v-9.9C1013,512.29,1010.71,515.73,1006.19,518.34Z" transform="translate(-650 -266.67)"/>
    <path class="bare-metal-2" d="M666.25,518.34l5.82,3.36v9.9l-5.82-3.36c-4.52-2.61-6.78-6-6.79-9.49h0v-9.9C659.47,512.29,661.73,515.73,666.25,518.34Z" transform="translate(-650 -266.67)"/>
    <polygon class="bare-metal-2" points="202.66 340.31 202.66 350.21 356.19 261.56 356.19 251.67 202.66 340.31"/>
    <polygon class="bare-metal-2" points="169.78 340.31 169.78 350.21 16.24 261.56 16.24 251.67 169.78 340.31"/>
    <path class="bare-metal-2" d="M819.78,607v9.9c9,5.22,23.84,5.22,32.88,0V607C843.62,612.2,828.82,612.2,819.78,607Z" transform="translate(-650 -266.67)"/>
    <path class="bare-metal-3" d="M1006.19,499.36,852.66,410.72c-9-5.22-23.84-5.22-32.88,0L666.25,499.36c-9,5.22-9,13.76,0,19L819.78,607c9,5.22,23.84,5.22,32.88,0l153.53-88.64C1015.24,513.12,1015.24,504.58,1006.19,499.36ZM992,513.85,844.88,598.77c-4.76,2.75-12.56,2.75-17.32,0L680.47,513.85c-4.76-2.75-4.76-7.25,0-10l147.09-84.92c4.76-2.75,12.56-2.75,17.32,0L992,503.85C996.73,506.6,996.73,511.1,992,513.85Z" transform="translate(-650 -266.67)"/>
    <path class="bare-metal-4" d="M680.41,513.81l.06,0,147.09-84.92c4.76-2.75,12.56-2.75,17.32,0L992,513.77l.06,0c4.7-2.76,4.68-7.23-.06-10L844.88,418.93c-4.76-2.75-12.56-2.75-17.32,0L680.47,503.85C675.73,506.58,675.71,511.05,680.41,513.81Z" transform="translate(-650 -266.67)"/>
    <path class="bare-metal-5" d="M1015.29,384.18,853.54,477.57c-9.53,5.5-25.11,5.5-34.64,0h0L657.15,384.18c-4.76-2.75-7.14-6.37-7.14-10V464.9c0,3.62,2.38,7.25,7.14,10l6.14,3.55h0L818.9,568.28h0c9.53,5.5,25.11,5.5,34.64,0l155.62-89.84h0l6.13-3.55c4.76-2.74,7.14-6.37,7.15-10V374.19C1022.43,377.81,1020.05,381.43,1015.29,384.18Z" transform="translate(-650 -266.67)"/>
    <path class="bare-metal-6" d="M1015.29,384.18l-6.13,3.54v90.72l6.13-3.55c4.76-2.74,7.14-6.37,7.15-10h0V374.19C1022.43,377.81,1020.05,381.43,1015.29,384.18Z" transform="translate(-650 -266.67)"/>
    <path class="bare-metal-5" d="M657.15,384.18l6.14,3.54v90.72l-6.14-3.55c-4.76-2.74-7.14-6.37-7.14-10h0V374.19C650,377.81,652.39,381.43,657.15,384.18Z" transform="translate(-650 -266.67)"/>
    <polygon class="bare-metal-6" points="203.53 210.89 203.53 301.61 365.29 208.22 365.29 117.51 203.53 210.89"/>
    <polygon class="bare-metal-5" points="168.9 210.89 168.9 301.61 7.15 208.22 7.15 117.51 168.9 210.89"/>
    <path class="bare-metal-7" d="M818.9,477.57v90.71c9.53,5.5,25.11,5.5,34.64,0V477.57C844,483.07,828.43,483.07,818.9,477.57Z" transform="translate(-650 -266.67)"/>
    <path class="bare-metal-8" d="M818.9,477.57c9.53,5.5,25.11,5.5,34.64,0l161.75-93.39c9.53-5.5,9.53-14.5,0-20L853.54,270.8c-9.53-5.5-25.11-5.5-34.64,0L657.15,364.18c-9.53,5.5-9.53,14.5,0,20Z" transform="translate(-650 -266.67)"/>
    <polygon class="bare-metal-9" points="225.49 220.89 225.49 266.25 225.49 260.58 338.42 195.38 343.33 198.22 343.33 152.86 225.49 220.89"/>
    <polygon class="bare-metal-5" points="343.33 198.22 225.49 266.25 225.49 260.58 338.42 195.38 343.33 198.22"/>
    <polygon class="bare-metal-10" points="343.33 152.86 343.33 198.22 338.42 195.38 338.42 155.7 343.33 152.86"/>
    <polygon class="bare-metal-11" points="247.92 223.4 247.92 232.13 255.48 227.77 255.48 219.04 247.92 223.4"/>
    <polygon class="bare-metal-12" points="260.02 216.42 260.02 225.15 267.57 220.78 267.57 212.06 260.02 216.42"/>
    <polygon class="bare-metal-13" points="272.11 209.44 272.11 218.17 279.67 213.8 279.67 205.07 272.11 209.44"/>
    <polygon class="bare-metal-14" points="284.21 202.45 284.21 211.18 291.76 206.82 291.76 198.09 284.21 202.45"/>
    <polygon class="bare-metal-15" points="296.3 195.47 296.3 204.2 303.86 199.84 303.86 191.11 296.3 195.47"/>
    <polygon class="bare-metal-16" points="308.4 188.49 308.4 197.22 315.95 192.85 315.95 184.13 308.4 188.49"/>
    <polygon class="bare-metal-9" points="9.46 130.19 9.46 198.22 166.57 288.93 166.57 220.89 9.46 130.19"/>
    <polygon class="bare-metal-17" points="21.28 179.68 150.29 254.16 150.29 272.77 21.28 198.3 21.28 179.68"/>
    <path class="bare-metal-18" d="M680.47,461c0-1.84,1.31-2.6,2.91-1.68a6.48,6.48,0,0,1,2.91,5c0,1.85-1.31,2.61-2.91,1.68A6.45,6.45,0,0,1,680.47,461Z" transform="translate(-650 -266.67)"/>
    <polygon class="bare-metal-2" points="134.16 251.83 134.16 256.48 138.19 258.81 138.19 254.16 134.16 251.83"/>
    <polygon class="bare-metal-2" points="127.21 247.82 127.21 252.47 131.24 254.8 131.24 250.14 127.21 247.82"/>
    <polygon class="bare-metal-19" points="122.78 246.19 41.44 199.23 41.44 202.02 122.78 248.98 122.78 246.19"/>
    <polygon class="bare-metal-20" points="150.29 254.16 150.29 272.77 152.3 271.61 152.3 252.99 150.29 254.16"/>
    <polygon class="bare-metal-21" points="21.28 179.68 23.3 178.51 152.3 252.99 150.29 254.16 21.28 179.68"/>
    <polygon class="bare-metal-17" points="21.28 151.75 150.29 226.23 150.29 244.85 21.28 170.37 21.28 151.75"/>
    <path class="bare-metal-18" d="M680.47,433c0-1.85,1.31-2.61,2.91-1.68a6.44,6.44,0,0,1,2.91,5c0,1.85-1.31,2.61-2.91,1.68A6.44,6.44,0,0,1,680.47,433Z" transform="translate(-650 -266.67)"/>
    <polygon class="bare-metal-2" points="134.16 223.9 134.16 228.56 138.19 230.88 138.19 226.23 134.16 223.9"/>
    <polygon class="bare-metal-2" points="127.21 219.89 127.21 224.54 131.24 226.87 131.24 222.22 127.21 219.89"/>
    <polygon class="bare-metal-22" points="122.78 218.26 41.44 171.3 41.44 174.09 122.78 221.05 122.78 218.26"/>
    <polygon class="bare-metal-20" points="150.29 226.23 150.29 244.85 152.3 243.68 152.3 225.06 150.29 226.23"/>
    <polygon class="bare-metal-21" points="21.28 151.75 23.3 150.59 152.3 225.06 150.29 226.23 21.28 151.75"/>
    <path class="bare-metal-23" d="M831.31,439.38a10.89,10.89,0,0,0,9.82,0l108-62.36c2.7-1.56,2.7-4.11,0-5.67L841.13,309a10.89,10.89,0,0,0-9.82,0l-108,62.36c-2.7,1.56-2.7,4.11,0,5.67Z" transform="translate(-650 -266.67)"/>
    <path class="bare-metal-24" d="M905.31,402.32,949.15,377c2.7-1.56,2.7-4.11,0-5.67L841.13,309v93.33Z" transform="translate(-650 -266.67)"/>
    <path class="bare-metal-25" d="M841.13,347.05V309a10.89,10.89,0,0,0-9.82,0v38.06Z" transform="translate(-650 -266.67)"/>
    <path class="bare-metal-5" d="M831.06,470,670.8,377.45a3.92,3.92,0,0,1-2.27-3.27h0a3.91,3.91,0,0,1,2.27-3.26h0l160.26-92.53a10.48,10.48,0,0,1,5.16-1.24h0a10.5,10.5,0,0,1,5.16,1.24h0l160.26,92.53a3.91,3.91,0,0,1,2.27,3.26h0a3.94,3.94,0,0,1-2.27,3.27h0L841.38,470a10.5,10.5,0,0,1-5.16,1.24h0a10.48,10.48,0,0,1-5.16-1.24Zm9.82-.86,160.26-92.53a3,3,0,0,0,1.77-2.4h0a3,3,0,0,0-1.77-2.4h0L840.88,279.25a9.64,9.64,0,0,0-4.66-1.1h0a9.58,9.58,0,0,0-4.66,1.1h0L671.3,371.78l-.25-.43.25.43a3,3,0,0,0-1.78,2.4h0a3,3,0,0,0,1.78,2.4h0l160.26,92.53a9.61,9.61,0,0,0,4.66,1.1h0a9.64,9.64,0,0,0,4.66-1.1Z" transform="translate(-650 -266.67)"/>
    <path class="bare-metal-26" d="M826.85,347.22,749.26,392l82,47.37a10.89,10.89,0,0,0,9.82,0l82-47.37-77.59-44.79C840.44,344.24,832,344.24,826.85,347.22Z" transform="translate(-650 -266.67)"/>
    <path class="bare-metal-27" d="M839.8,350.79a7.92,7.92,0,0,0-7.16,0c-2,1.14-2,3,0,4.14a7.92,7.92,0,0,0,7.16,0C841.78,353.79,841.78,351.93,839.8,350.79Z" transform="translate(-650 -266.67)"/>
    <path class="bare-metal-28" d="M881.19,385.27a6.74,6.74,0,0,0-6.09,0L847,401.49c-1.67,1-1.67,2.55,0,3.51l26.83,15.49L908,400.76Z" transform="translate(-650 -266.67)"/>
    <path class="bare-metal-29" d="M906.34,398.35l-24.43,14.1v3.38l25.21-14.55v-4A1.32,1.32,0,0,1,906.34,398.35Zm-57.16-1.09v6a1.32,1.32,0,0,0,.78,1.08l24.43,14.1v-6h0L850,398.35A1.32,1.32,0,0,1,849.18,397.26Z" transform="translate(-650 -266.67)"/>
    <polygon class="bare-metal-30" points="224.39 151.76 227.41 151.76 231.91 149.16 231.91 145.78 224.39 145.78 224.39 151.76"/>
    <path class="bare-metal-31" d="M906.34,396.18,880,381a4.21,4.21,0,0,0-3.76,0L850,396.18a1.14,1.14,0,0,0,0,2.17l24.43,14.1h7.52l24.43-14.11A1.14,1.14,0,0,0,906.34,396.18Z" transform="translate(-650 -266.67)"/>
    <path class="bare-metal-3" d="M876.77,389.7a3.11,3.11,0,0,0,2.76,0h0a.84.84,0,0,0,0-1.59h0a3.05,3.05,0,0,0-2.76,0A.84.84,0,0,0,876.77,389.7Zm9.66,5.57a3,3,0,0,0,2.75,0h0a.84.84,0,0,0,0-1.59,3,3,0,0,0-2.75,0A.84.84,0,0,0,886.43,395.27Zm7.57,1.2a3,3,0,0,0-2.75,0,.84.84,0,0,0,0,1.58,3,3,0,0,0,2.75,0h0A.83.83,0,0,0,894,396.47Zm-17.23-1.2a3.05,3.05,0,0,0,2.76,0h0a.84.84,0,0,0,0-1.59h0a3.05,3.05,0,0,0-2.76,0A.84.84,0,0,0,876.77,395.27Zm7.58,1.2h0a3,3,0,0,0-2.75,0,.84.84,0,0,0,0,1.59h0a3.09,3.09,0,0,0,2.75,0A.84.84,0,0,0,884.35,396.47Zm4.83,2.78h0a3.09,3.09,0,0,0-2.75,0,.84.84,0,0,0,0,1.59h0a3,3,0,0,0,2.75,0A.84.84,0,0,0,889.18,399.25Zm-22.06-4a3,3,0,0,0,2.75,0h0a.84.84,0,0,0,0-1.59,3,3,0,0,0-2.75,0A.84.84,0,0,0,867.12,395.27Zm7.58,1.2h0a3,3,0,0,0-2.75,0,.84.84,0,0,0,0,1.59,3.09,3.09,0,0,0,2.75,0A.84.84,0,0,0,874.7,396.47Zm4.83,2.78h0a3.11,3.11,0,0,0-2.76,0,.84.84,0,0,0,0,1.59,3.05,3.05,0,0,0,2.76,0A.84.84,0,0,0,879.53,399.25Zm-14.48-2.78a3.05,3.05,0,0,0-2.76,0,.84.84,0,0,0,0,1.58,3,3,0,0,0,2.75,0h0A.84.84,0,0,0,865.05,396.47Zm4.82,2.78h0a3.09,3.09,0,0,0-2.75,0,.84.84,0,0,0,0,1.59,3,3,0,0,0,2.75,0A.84.84,0,0,0,869.87,399.25Z" transform="translate(-650 -266.67)"/>
    <path class="bare-metal-2" d="M881.6,392.48h0a3,3,0,0,0,2.75,0h0a.84.84,0,0,0,0-1.59,3.09,3.09,0,0,0-2.75,0A.84.84,0,0,0,881.6,392.48Zm-9.65,0a3,3,0,0,0,2.75,0,.84.84,0,0,0,0-1.59h0a3.09,3.09,0,0,0-2.75,0A.84.84,0,0,0,872,392.48Zm12.4,9.56h0a3,3,0,0,0-2.75,0,.84.84,0,0,0,0,1.59,3.09,3.09,0,0,0,2.75,0A.84.84,0,0,0,884.35,402Zm-9.65,0h0a3,3,0,0,0-2.75,0,.84.84,0,0,0,0,1.59,3.09,3.09,0,0,0,2.75,0A.84.84,0,0,0,874.7,402Zm4.83,2.79h0a3,3,0,0,0-2.75,0,.84.84,0,0,0,0,1.59,3.09,3.09,0,0,0,2.75,0A.84.84,0,0,0,879.53,404.83Z" transform="translate(-650 -266.67)"/>
    <path class="bare-metal-28" d="M870.31,379l-28.09-16.22a6.8,6.8,0,0,0-6.09,0L808,379c-1.68,1-1.68,2.55,0,3.52l27,15.6h8.22l27-15.6C872,381.53,872,380,870.31,379Z" transform="translate(-650 -266.67)"/>
    <path class="bare-metal-29" d="M867.37,375.84,842.93,390h0v6l24.44-14.1a1.33,1.33,0,0,0,.77-1.09v-6A1.31,1.31,0,0,1,867.37,375.84Z" transform="translate(-650 -266.67)"/>
    <path class="bare-metal-29" d="M835.41,390h0L811,375.84a1.29,1.29,0,0,1-.77-1.08v6a1.31,1.31,0,0,0,.77,1.09l24.43,14.1Z" transform="translate(-650 -266.67)"/>
    <rect class="bare-metal-30" x="185.41" y="123.28" width="7.52" height="5.98"/>
    <path class="bare-metal-31" d="M867.37,373.67l-26.32-15.19a4.15,4.15,0,0,0-3.76,0L811,373.67a1.15,1.15,0,0,0,0,2.17L835.42,390h7.51l24.44-14.11A1.15,1.15,0,0,0,867.37,373.67Z" transform="translate(-650 -266.67)"/>
    <path class="bare-metal-3" d="M837.8,367.19a3,3,0,0,0,2.75,0h0a.84.84,0,0,0,0-1.58h0a3,3,0,0,0-2.75,0A.83.83,0,0,0,837.8,367.19Zm9.65,5.58a3.09,3.09,0,0,0,2.75,0h0a.84.84,0,0,0,0-1.59,3,3,0,0,0-2.75,0A.84.84,0,0,0,847.45,372.77ZM855,374a3.09,3.09,0,0,0-2.75,0,.84.84,0,0,0,0,1.59,3,3,0,0,0,2.75,0h0A.84.84,0,0,0,855,374Zm-17.23-1.19a3.09,3.09,0,0,0,2.75,0h0a.84.84,0,0,0,0-1.59h0a3,3,0,0,0-2.75,0A.84.84,0,0,0,837.8,372.77Zm7.58,1.2h0a3.05,3.05,0,0,0-2.76,0,.84.84,0,0,0,0,1.58h0a3.05,3.05,0,0,0,2.76,0A.84.84,0,0,0,845.38,374Zm4.82,2.78h0a3,3,0,0,0-2.75,0,.84.84,0,0,0,0,1.59h0a3,3,0,0,0,2.75,0A.84.84,0,0,0,850.2,376.75Zm-22.06-4a3.09,3.09,0,0,0,2.75,0h0a.84.84,0,0,0,0-1.59,3.05,3.05,0,0,0-2.76,0A.84.84,0,0,0,828.14,372.77Zm7.58,1.2h0a3.06,3.06,0,0,0-2.75,0,.83.83,0,0,0,0,1.58,3,3,0,0,0,2.75,0A.83.83,0,0,0,835.72,374Zm4.83,2.78h0a3,3,0,0,0-2.75,0,.84.84,0,0,0,0,1.59,3,3,0,0,0,2.75,0A.84.84,0,0,0,840.55,376.75ZM826.07,374a3.09,3.09,0,0,0-2.75,0,.84.84,0,0,0,0,1.59,3,3,0,0,0,2.75,0h0A.84.84,0,0,0,826.07,374Zm4.83,2.79h0a3.05,3.05,0,0,0-2.76,0,.84.84,0,0,0,0,1.59,3.05,3.05,0,0,0,2.76,0A.84.84,0,0,0,830.9,376.75Z" transform="translate(-650 -266.67)"/>
    <path class="bare-metal-2" d="M842.62,370h0a3.05,3.05,0,0,0,2.76,0h0a.84.84,0,0,0,0-1.59,3.05,3.05,0,0,0-2.76,0A.84.84,0,0,0,842.62,370ZM833,370a3,3,0,0,0,2.75,0,.84.84,0,0,0,0-1.59h0a3.09,3.09,0,0,0-2.75,0A.84.84,0,0,0,833,370Zm12.41,9.56h0a3.05,3.05,0,0,0-2.76,0,.84.84,0,0,0,0,1.59,3.11,3.11,0,0,0,2.76,0A.84.84,0,0,0,845.38,379.54Zm-9.66,0h0a3,3,0,0,0-2.75,0,.84.84,0,0,0,0,1.59,3.09,3.09,0,0,0,2.75,0A.84.84,0,0,0,835.72,379.54Zm4.83,2.78h0a3.09,3.09,0,0,0-2.75,0,.84.84,0,0,0,0,1.59,3,3,0,0,0,2.75,0A.84.84,0,0,0,840.55,382.32Z" transform="translate(-650 -266.67)"/>
    <polygon class="bare-metal-2" points="125.8 126.25 198.69 168.33 203.62 165.49 203.62 145.5 125.79 100.57 125.8 126.25"/>
    <polygon class="bare-metal-3" points="127.65 99.5 125.79 100.57 203.62 145.5 205.48 144.43 127.65 99.5"/>
    <polygon class="bare-metal-32" points="203.62 165.49 205.48 164.42 205.48 144.43 203.62 145.5 203.62 165.49"/>
    <polygon class="bare-metal-29" points="128.74 122.41 128.74 107.43 139.86 113.85 139.86 128.82 128.74 122.41"/>
    <polygon class="bare-metal-33" points="140.73 113.34 139.86 113.85 139.86 128.82 140.73 128.32 140.73 113.34"/>
    <polygon class="bare-metal-34" points="129.61 106.92 128.74 107.43 139.86 113.85 140.73 113.34 129.61 106.92"/>
    <polygon class="bare-metal-29" points="143.57 130.96 143.56 115.98 154.69 122.41 154.69 137.38 143.57 130.96"/>
    <polygon class="bare-metal-33" points="155.55 121.9 154.69 122.41 154.69 137.38 155.55 136.88 155.55 121.9"/>
    <polygon class="bare-metal-34" points="144.44 115.48 143.56 115.98 154.69 122.41 155.55 121.9 144.44 115.48"/>
    <polygon class="bare-metal-29" points="158.39 139.52 158.39 124.55 169.51 130.97 169.51 145.94 158.39 139.52"/>
    <polygon class="bare-metal-33" points="170.38 130.46 169.51 130.97 169.51 145.94 170.38 145.44 170.38 130.46"/>
    <polygon class="bare-metal-34" points="159.26 124.04 158.39 124.55 169.51 130.97 170.38 130.46 159.26 124.04"/>
    <polygon class="bare-metal-29" points="173.22 148.08 173.22 133.1 184.34 139.52 184.34 154.5 173.22 148.08"/>
    <polygon class="bare-metal-33" points="185.2 139.02 184.34 139.52 184.34 154.5 185.2 154 185.2 139.02"/>
    <polygon class="bare-metal-34" points="174.09 132.6 173.22 133.1 184.34 139.52 185.2 139.02 174.09 132.6"/>
    <polygon class="bare-metal-29" points="188.04 156.64 188.04 141.66 199.16 148.08 199.16 163.06 188.04 156.64"/>
    <polygon class="bare-metal-33" points="200.03 147.58 199.16 148.08 199.16 163.06 200.03 162.56 200.03 147.58"/>
    <polygon class="bare-metal-34" points="188.91 141.16 188.04 141.66 199.16 148.08 200.03 147.58 188.91 141.16"/>
    <polygon class="bare-metal-35" points="129.61 128.32 198.7 168.33 200.03 167.56 200.03 166.84 129.61 126.18 129.61 128.32"/>
    <path class="bare-metal-2" d="M760.87,398.71l70.44,40.67a11,11,0,0,0,7.39.9V420.8l-77.83-44.93Z" transform="translate(-650 -266.67)"/>
    <polygon class="bare-metal-3" points="112.71 108.12 110.86 109.19 188.69 154.13 190.54 153.06 112.71 108.12"/>
    <path class="bare-metal-32" d="M838.7,440.28a9.16,9.16,0,0,0,1.85-.63V419.73l-1.85,1.07Z" transform="translate(-650 -266.67)"/>
    <polygon class="bare-metal-29" points="113.81 131.03 113.81 116.05 124.92 122.47 124.92 137.45 113.81 131.03"/>
    <polygon class="bare-metal-33" points="125.79 121.97 124.92 122.47 124.92 137.45 125.79 136.94 125.79 121.97"/>
    <polygon class="bare-metal-34" points="114.67 115.55 113.81 116.05 124.92 122.47 125.79 121.97 114.67 115.55"/>
    <polygon class="bare-metal-29" points="128.63 139.59 128.63 124.61 139.75 131.03 139.75 146.01 128.63 139.59"/>
    <polygon class="bare-metal-33" points="140.62 130.53 139.75 131.03 139.75 146.01 140.62 145.5 140.62 130.53"/>
    <polygon class="bare-metal-34" points="129.5 124.11 128.63 124.61 139.75 131.03 140.62 130.53 129.5 124.11"/>
    <polygon class="bare-metal-29" points="143.46 148.15 143.46 133.17 154.57 139.59 154.57 154.56 143.46 148.15"/>
    <polygon class="bare-metal-33" points="155.44 139.09 154.57 139.59 154.57 154.56 155.44 154.06 155.44 139.09"/>
    <polygon class="bare-metal-34" points="144.32 132.67 143.46 133.17 154.57 139.59 155.44 139.09 144.32 132.67"/>
    <polygon class="bare-metal-29" points="158.28 156.71 158.28 141.73 169.4 148.15 169.4 163.12 158.28 156.71"/>
    <polygon class="bare-metal-33" points="170.27 147.64 169.4 148.15 169.4 163.12 170.27 162.62 170.27 147.64"/>
    <polygon class="bare-metal-34" points="159.15 141.23 158.28 141.73 169.4 148.15 170.27 147.64 159.15 141.23"/>
    <polygon class="bare-metal-29" points="173.11 165.27 173.11 150.29 184.23 156.71 184.23 171.68 173.11 165.27"/>
    <polygon class="bare-metal-33" points="185.09 156.21 184.23 156.71 184.23 171.68 185.09 171.18 185.09 156.21"/>
    <polygon class="bare-metal-34" points="173.98 149.78 173.11 150.29 184.23 156.71 185.09 156.21 173.98 149.78"/>
  </svg>
</template>
