<script>
  import Pin from '@components/UI/Units/Atlas/Pin.vue';

  import Balochistan from '@components/UI/Units/Atlas/Regions/Balochistan.vue';
  import AzadKashmir from '@components/UI/Units/Atlas/Regions/AzadKashmir.vue';
  import Islamabad from '@components/UI/Units/Atlas/Regions/Islamabad.vue';
  import GilgitBaltistan from '@components/UI/Units/Atlas/Regions/GilgitBaltistan.vue';
  import FATribalAreas from '@components/UI/Units/Atlas/Regions/FATribalAreas.vue';
  import KhyberPakhtunkhwa from '@components/UI/Units/Atlas/Regions/KhyberPakhtunkhwa.vue';
  import Punjab from '@components/UI/Units/Atlas/Regions/Punjab.vue';
  import Sindh from '@components/UI/Units/Atlas/Regions/Sindh.vue';
  
  export default {
    components: {
      'pin': Pin,

      'balochistan': Balochistan,
      'azad-kashmir': AzadKashmir,
      'islamabad': Islamabad,
      'gilgit-baltistan': GilgitBaltistan,
      'fa-tribal-areas': FATribalAreas,
      'khyber-pakhtunkhwa': KhyberPakhtunkhwa,
      'punjab': Punjab,
      'sindh': Sindh,
    }
  }
</script>



<style scoped>
  section > ul {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    display: grid;
  }

  section > ul > * {
    grid-area: 1 / 1 / 1 / 1;
  }
</style>



<template>
  <section class="transform origin-left
    scale-50 -mt-52 -mb-52
    md:scale-[65%] md:-mt-32 md:-mb-48 md:ml-10
    lg:scale-[65%] lg:-mt-64 lg:-ml-48
    2xl:-ml-32">
    <ul class="relative">
      <balochistan>
        <!--
        <pin :x="340" :y="125" disabled>
          Quetta
        </pin>
        -->
      </balochistan>
      <azad-kashmir></azad-kashmir>
      <gilgit-baltistan></gilgit-baltistan>
      <fa-tribal-areas></fa-tribal-areas>
      <khyber-pakhtunkhwa></khyber-pakhtunkhwa>
      <sindh>
        <pin :x="15" :y="225" disabled>
          Karachi
        </pin>
      </sindh>
      <punjab>
        <pin :x="280" :y="150">
          Lahore
        </pin>
      </punjab>
      <islamabad>
        <pin :x="19" :y="-11" disabled>
          Islamabad
        </pin>
      </islamabad>
    </ul>
  </section>
</template>
