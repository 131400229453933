<script>
  export default {
    props: {
      compact: {
        type: Boolean,
        default: false,
        required: false
      }
    }
  }
</script>



<style scoped>
  table {
    @apply table-auto border-collapse w-full text-left my-8 text-lg whitespace-nowrap;
  }

  div.caption {
    @apply text-left mb-4;
  }

  table :deep() thead tr th,
  table :deep() tfoot tr th {
    @apply text-base border-gray-light px-4 py-5 uppercase text-gray-lighter;
  }

  table :deep() thead tr th {
    @apply border-b-2;
  }

  table :deep() tfoot tr th {
    @apply border-t-2;
  }

  table :deep() tbody tr td,
  table :deep() tbody tr th {
    @apply border-b border-gray-light px-4 py-5;
  }

  table :deep() tbody tr:hover td,
  table :deep() tbody tr:hover th {
    @apply bg-gray-light bg-opacity-20;
  }

  table :deep() tbody tr th {
    @apply text-left font-bold;
  }

  /* Compact Table */
  table.compact :deep() thead tr th,
  table.compact :deep() tfoot tr th {
    @apply px-4 py-3 text-md;
  }

  table.compact :deep() tbody tr th,
  table.compact :deep() tbody tr td {
    @apply px-4 py-3 text-md;
  }
</style>



<template>
  <article>
    <div class="caption" v-if="$slots.caption">
      <slot name="caption"></slot>
    </div>

    <div class="overflow-x-auto">
      <table :class="{ 'compact': compact }">
        <thead v-if="$slots.header">
          <slot name="header"></slot>
        </thead>

        <tbody>
          <slot></slot>
        </tbody>

        <tfoot v-if="$slots.footer">
          <slot name="footer"></slot>
        </tfoot>
      </table>
    </div>
  </article>
</template>
