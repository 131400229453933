<script>
  import { useI18n } from 'vue-i18n';
  import Navigation from '@components/UI/Header/Navigation.vue';
  import Button from '@components/UI/Header/NavigationButton.vue';
  import HamburgerMenu from '@components/UI/Header/HamburgerMenu.vue';

  export default {
    components: {
      'navigation': Navigation,
      'navigation-button': Button,
      'hamburger-menu': HamburgerMenu,
    },

    props: {
      extended: {
        type: Boolean,
        default: false,
        required: false,
      },
    },

    data () {
      return {
        navigation: false,
        animateNavigation: true,
      };
    },

    created () {
      this.$router.beforeEach((to, from) => {
        this.animateNavigation = false;
        this.navigation = false;

        const body = document.documentElement.querySelector('body');
        body.classList.remove('noscroll');
      });

      this.$router.afterEach((to, from) => {
        this.animateNavigation = true;
      });
    },

    methods: {
      toggleNavigation () {
        const body = document.documentElement.querySelector('body');

        this.navigation = ! this.navigation;

        this.navigation ? body.classList.add('noscroll') : body.classList.remove('noscroll');
      }
    }
  }
</script>



<script setup>
  const { t, tm } = useI18n();
</script>



<style scoped>
  header {
    @apply bg-gray-darkest bg-cover;
  }

  header svg#logo {
    @apply text-brand;
  }
</style>



<template>
  <section class="bg-brand/80 space-y-0.5">
    <div v-for="(banner, index) in tm('header.banners')" :key="`banner-${index}`"
      class="h-20 lg:h-12 flex items-center bg-brand">
      <div class="container mx-auto py-2 text-gray-darker flex items-center justify-center">
        <div v-html="banner"></div>
      </div>
    </div>
  </section>

  <header class="flex-shrink-0 relative" :class="{
    'h-[400px] lg:h-[600px] xl:h-[650px]': extended,
    'h-32 lg:h-auto': ! extended,
  }"
  :style="`background-image: url('/images/backgrounds/hero.jpg');`">
    <section class="relative z-20 h-32 lg:h-auto">
      <ul class="pt-8 lg:pt-12 pb-12 lg:pb-24 container mx-auto lg:flex justify-between items-center">
        <li class="lg:w-1/5 flex items-center justify-between">
          <router-link :to="{ name: 'home' }" role="button">
            <x-ui-logo @click="toggleNavigation" id="logo" class="w-32 md:w-40 lg:w-44 2xl:w-48"></x-ui-logo>
          </router-link>

          <hamburger-menu @click="toggleNavigation" />
        </li>
        <li class="lg:w-3/5">
          <navigation />
        </li>
        <li class="lg:w-1/5 hidden lg:block text-right">
          <navigation-button url="https://cloud.siberfy.com" highlighted>
            {{ t('header.navigation.login') }}
          </navigation-button>
        </li>
      </ul>

      <div v-if="extended" class="container mt-6 md:mt-12 mx-auto text-center text-white">
        <h1 class="text-2xl md:text-3xl lg:text-4xl mb-4">
          <slot name="preheading"></slot>
        </h1>

        <h1 class="text-lg md:text-xl lg:text-2xl mb-4">
          <slot name="heading"></slot>
        </h1>

        <div class="max-w-md md:max-w-xl lg:max-w-2xl mx-auto text-base md:text-lg text-gray-lightest leading-normal md:leading-relaxed">
          <slot name="message"></slot>
        </div>
      </div>
    </section>
  </header>
</template>
