<script>
  export default {
    data () {
      return {
        dark: {
          foreground: 'text-segment-dark',
          background: 'bg-segment-dark',
        },
        light: {
          foreground: 'text-segment-light',
          background: 'bg-segment-light',
        }
      }
    },

    props: {
      type: {
        type: String,
        required: true,
        default: 'light',
        validator: (value) => {
          const types = ['light', 'dark'];

          return types.indexOf(value.toLowerCase()) !== -1;
        },
      },
      orientation: {
        type: String,
        required: true,
        default: 'slope',
      },
      image: String,
      segmentClasses: {
        type: [String, Object],
        default: 'py-12 md:py-32',
      }
    },

    computed: {
      getForegroundColor () {
        return this[this.type].foreground
      },

      getBackgroundColor () {
        return this[this.type].background
      },

      getBackgroundImage () {
        if (! this.image) {
          return;
        }

        return `background-image: url(${this.image});`;
      },

      getColors () {
        return `${this.getForegroundColor} ${this.getBackgroundColor}`
      }
    }
  }
</script>



<template>
  <section class="bg-cover bg-no-repeat bg-right" :class="getColors" :style="getBackgroundImage">
    <x-ui-segment-divider :orientation="orientation" overlap>
      <div :class="segmentClasses">
        <div class="relative z-[10] text-gray-lightest container mx-auto">
          <slot></slot>
        </div>
      </div>
    </x-ui-segment-divider>
  </section>
</template>
