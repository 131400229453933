<template>
    <svg id="thermal-pump" class="fill-segment-light" enable-background="new 0 0 300 300" height="56" viewBox="0 0 300 300" width="56" xmlns="http://www.w3.org/2000/svg">
        <g>
            <circle cx="170" cy="61.394" r="4"/>
            <path d="m170 129.394c0 6.617 5.383 12 12 12s12-5.383 12-12-5.383-12-12-12-12 5.382-12 12zm12-4c2.205 0 4 1.795 4 4s-1.795 4-4 4-4-1.795-4-4 1.795-4 4-4z"/>
            <path d="m53.516 57.878c.781.781 1.805 1.172 2.828 1.172s2.047-.391 2.828-1.172l2.828-2.828 2.828 2.828c.781.781 1.805 1.172 2.828 1.172s2.047-.391 2.828-1.172c1.562-1.562 1.562-4.094 0-5.656l-2.828-2.828 2.828-2.828c1.562-1.562 1.562-4.094 0-5.656s-4.094-1.562-5.656 0l-2.828 2.827-2.828-2.828c-1.562-1.562-4.094-1.562-5.656 0s-1.562 4.094 0 5.656l2.828 2.828-2.828 2.828c-1.563 1.563-1.563 4.094 0 5.657z"/>
            <path d="m106 85.394c-6.617 0-12 5.383-12 12s5.383 12 12 12 12-5.383 12-12-5.383-12-12-12zm0 16c-2.205 0-4-1.795-4-4s1.795-4 4-4 4 1.795 4 4-1.795 4-4 4z"/>
            <path d="m191.172 92.222c.781.781 1.805 1.172 2.828 1.172s2.047-.391 2.828-1.172l6.828-6.828h8.688l-5.172 5.172c-1.562 1.562-1.562 4.094 0 5.656.781.781 1.805 1.172 2.828 1.172s2.047-.391 2.828-1.172l5.172-5.172v8.688l-6.828 6.828c-1.562 1.562-1.562 4.094 0 5.656s4.094 1.562 5.656 0l5.172-5.172 5.172 5.172c.781.781 1.805 1.172 2.828 1.172s2.047-.391 2.828-1.172c1.562-1.562 1.562-4.094 0-5.656l-6.828-6.829v-8.687l5.172 5.172c.781.781 1.805 1.172 2.828 1.172s2.047-.391 2.828-1.172c1.562-1.562 1.562-4.094 0-5.656l-5.172-5.172h8.688l6.828 6.828c.781.781 1.805 1.172 2.828 1.172s2.047-.391 2.828-1.172c1.562-1.562 1.562-4.094 0-5.656l-5.172-5.172 5.172-5.172c1.562-1.562 1.562-4.094 0-5.656s-4.094-1.562-5.656 0l-6.828 6.828h-8.688l5.172-5.172c1.562-1.562 1.562-4.094 0-5.656s-4.094-1.562-5.656 0l-5.172 5.171v-8.687l6.828-6.828c1.562-1.562 1.562-4.094 0-5.656s-4.094-1.562-5.656 0l-5.172 5.171-5.172-5.172c-1.562-1.562-4.094-1.562-5.656 0s-1.562 4.094 0 5.656l6.828 6.829v8.688l-5.172-5.172c-1.562-1.562-4.094-1.562-5.656 0s-1.562 4.094 0 5.656l5.172 5.172h-8.688l-6.828-6.828c-1.562-1.562-4.094-1.562-5.656 0s-1.562 4.094 0 5.656l5.172 5.172-5.172 5.172c-1.563 1.562-1.563 4.093 0 5.656z"/>
            <path d="m266 161.394h-116v-48h12c1.645 0 3.121-1.008 3.723-2.537.602-1.531.205-3.275-1-4.395l-56-52c-1.535-1.426-3.91-1.426-5.445 0l-56 52c-1.205 1.119-1.602 2.863-1 4.395.602 1.529 2.078 2.537 3.723 2.537h12v48h-28.001c-2.209 0-4 1.791-4 4v16c0 2.209 1.791 4 4 4h32c2.209 0 4-1.791 4-4s-1.791-4-4-4h-28v-8h40v66.148c0 7.639 6.213 13.852 13.852 13.852h18.945c2.209 0 4-1.791 4-4s-1.791-4-4-4h-18.945c-3.227 0-5.852-2.625-5.852-5.852v-66.148h40v26.148c0 7.639 6.213 13.852 13.852 13.852h35.774l-4.311 3.904c-1.637 1.482-1.762 4.014-.279 5.65.789.871 1.875 1.314 2.967 1.314.957 0 1.918-.342 2.684-1.035l12-10.869c.811-.736 1.287-1.771 1.312-2.865.027-1.096-.395-2.154-1.17-2.928l-12-12c-1.562-1.562-4.094-1.562-5.656 0s-1.562 4.094 0 5.656l5.171 5.172h-36.491c-3.227 0-5.852-2.625-5.852-5.852v-26.148h128v8h-115.536c-2.209 0-4 1.791-4 4s1.791 4 4 4h119.535c2.209 0 4-1.791 4-4v-16c0-2.208-1.791-3.999-4-3.999zm-156 0h-8v-28h8zm24 0v-20c0-2.209-1.791-4-4-4s-4 1.791-4 4v20h-8v-32c0-2.209-1.791-4-4-4h-16c-2.209 0-4 1.791-4 4v32h-8v-20c0-2.209-1.791-4-4-4s-4 1.791-4 4v20h-8v-52c0-2.209-1.791-4-4-4h-5.814l45.814-42.541 45.814 42.541h-5.814c-2.209 0-4 1.791-4 4v52z"/>
            <path d="m240.148 201.394h-38.148c-2.209 0-4 1.791-4 4s1.791 4 4 4h38.148c3.227 0 5.852 2.625 5.852 5.852v20.297c0 3.227-2.625 5.852-5.852 5.852h-104.491l5.171-5.172c1.562-1.562 1.562-4.094 0-5.656s-4.094-1.562-5.656 0l-12 12c-.775.773-1.197 1.832-1.17 2.928.025 1.094.502 2.129 1.312 2.865l12 10.869c.766.693 1.727 1.035 2.684 1.035 1.092 0 2.178-.443 2.967-1.314 1.482-1.637 1.357-4.168-.279-5.65l-4.311-3.904h103.774c7.639 0 13.852-6.213 13.852-13.852v-20.297c-.001-7.641-6.214-13.853-13.853-13.853z"/>
        </g>
    </svg>
</template>

<script>
  export default {
    //
  }
</script>
