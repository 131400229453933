<script>
  import { useI18n } from 'vue-i18n';
  import Button from '@components/UI/Header/NavigationButton.vue';
  import HamburgerMenu from '@components/UI/Header/HamburgerMenu.vue';

  export default {
    components: {
      'hamburger-menu': HamburgerMenu,
      'navigation-button': Button,
    },

    data () {
      return {
        topMargin: null,
      };
    },

    created () {
      this.calculateTopMargin();
    },

    methods: {
      /**
       * @see https://tailwindcss.com/docs/height
       */
      calculateTopMargin () {
        const banners = this.$tm('header.banners')?.length || 0;
        const bannersHeight = banners * 5; // height in rem for each banner
        const bannerSpacing = banners > 0 ? (banners - 1) * 0.125 : 0; // height in rem for each space between the banners

        this.topMargin = 'calc((8rem + ' + bannersHeight + 'rem + ' + bannerSpacing + 'rem) - 25px)';
      }
    }
  }
</script>



<script setup>
  const { t, tm } = useI18n();
</script>



<style lang="postcss" scoped>
  .navigation {
    @apply fixed top-[v-bind(topMargin)] left-0 right-0 bottom-0 z-50 overflow-hidden;
    @apply max-h-[0] w-full h-screen;
    @apply text-white;
    @apply flex flex-col;

    &.animated {
      @apply transition-[max-height] duration-500;
    }

    &.open {
      @apply max-h-[calc(100vh-8rem)];
    }

    @screen lg {
      @apply static z-0 w-full h-auto max-h-[none] overflow-visible;
      @apply bg-transparent;
      @apply flex flex-row items-center justify-center;
    }

    & > ul {
      @apply w-full text-lg mt-16;

      @screen lg {
        @apply flex flex-row justify-end items-center mt-0;
      }
    }
  }

  .navigation-content {
    @apply w-full bg-segment-light flex-grow;

    @screen lg {
      @apply w-auto bg-transparent grow-0;
    }
  }

  .navigation-list {
    @apply container mx-auto;

    @screen lg {
      @apply flex justify-end;
    }
  }
</style>



<template>
  <nav class="navigation" :class="{
    'animated': $parent.animateNavigation === true,
    'open': $parent.navigation === true,
    'lg:flex': $parent.navigation === false,
  }">
    <x-ui-segment-divider class="lg:hidden shrink-0 w-full text-segment-light bg-segment-light mt-[25px]" orientation="hill"></x-ui-segment-divider>

    <div class="navigation-content">
      <ul class="navigation-list">
        <li>
          <navigation-button :url="{ name: 'home' }">
            {{ t('header.navigation.home') }}
          </navigation-button>
        </li>
        <li>
          <navigation-button :url="{ name: 'features' }">
            {{ t('header.navigation.features') }}
          </navigation-button>
        </li>
        <li>
          <navigation-button :url="{ name: 'pricing' }">
            {{ t('header.navigation.pricing') }}
          </navigation-button>
        </li>
        <li>
          <navigation-button submenu="lg:-ml-24" arrow="lg:-ml-24">
            {{ t('header.navigation.company.heading') }}

            <template #submenu>
              <div class="ml-6 lg:ml-0 mb-6 lg:my-0 lg:w-[40rem] lg:flex">
                <ul class="lg:bg-segment-dark lg:w-3/5 lg:flex lg:flex-col lg:justify-between lg:space-y-10 lg:px-12 lg:pt-8 lg:pb-10 lg:rounded-l-md">
                  <li class="lg:flex lg:items-center">
                    <x-icon-blob class="hidden lg:block" icon="text-brand h-16 w-16 mt-1">
                      <svg class="w-8 h-8 text-segment-light" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path>
                      </svg>
                    </x-icon-blob>

                    <div class="lg:ml-8">
                      <navigation-button :url="{ name: 'company' }" class="p-2 lg:p-0" compact>
                        <template #icon>
                          <svg class="lg:hidden w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
                          </svg>
                        </template>

                        {{ t('header.navigation.company.about-us.heading') }}
                      </navigation-button>

                      <p class="hidden lg:block mt-1 text-sm font-medium text-gray-lightest">
                        {{ t('header.navigation.company.about-us.description') }}
                      </p>
                    </div>
                  </li>
                  <li class="lg:flex lg:items-center">
                    <x-icon-blob class="hidden lg:block" icon="text-brand h-16 w-16 mt-1">
                      <svg class="w-8 h-8 text-segment-light" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path>
                      </svg>
                    </x-icon-blob>

                    <div class="lg:ml-8">
                      <navigation-button :url="{ name: 'company.contact' }" class="p-2 lg:p-0" compact>
                        <template #icon>
                          <svg class="lg:hidden w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
                          </svg>
                        </template>

                        {{ t('header.navigation.company.contact.heading') }}
                      </navigation-button>

                      <p class="hidden lg:block mt-1 text-sm font-medium text-gray-lightest">
                        {{ t('header.navigation.company.contact.description') }}
                      </p>
                    </div>
                  </li>
                </ul>

                <ul class="lg:w-2/5 lg:flex lg:flex-col lg:justify-around lg:border-l-2 lg:border-gray-darker lg:space-y-6 lg:py-4 lg:px-6">
                  <li class="lg:flex lg:items-center">
                    <div>
                      <navigation-button :url="{ name: 'company.sla' }" class="p-2 lg:p-0" compact>
                        <template #icon>
                          <svg class="lg:hidden w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
                          </svg>
                        </template>

                        {{ t('header.navigation.company.sla.heading') }}
                      </navigation-button>

                      <p class="hidden lg:block mt-1 text-sm font-medium text-gray-lightest">
                        {{ t('header.navigation.company.sla.description') }}
                      </p>
                    </div>
                  </li>
                  <li class="lg:flex lg:items-center">
                    <div>
                      <navigation-button :url="{ name: 'company.legal' }" class="p-2 lg:p-0" compact>
                        <template #icon>
                          <svg class="lg:hidden w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
                          </svg>
                        </template>

                        {{ t('header.navigation.company.legal.heading') }}
                      </navigation-button>

                      <ul class="list-disc ml-5 hidden lg:block mt-1 text-sm font-medium text-gray-lighter">
                        <li>
                          <router-link class="transition text-gray-lightest hover:text-brand" :to="{ name: 'legal.privacy' }">{{ t('footer.links.legal.privacy-policy') }}</router-link>
                        </li>
                        <li>
                          <router-link class="transition text-gray-lightest hover:text-brand" :to="{ name: 'legal.tos' }">{{ t('footer.links.legal.terms-of-service') }}</router-link>
                        </li>
                        <li>
                          <router-link class="transition text-gray-lightest hover:text-brand" :to="{ name: 'legal.policy' }">{{ t('footer.links.legal.acceptable-use-policy') }}</router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </template>
          </navigation-button>
        </li>
        <li class="lg:hidden">
          <navigation-button url="https://cloud.siberfy.com" highlighted>
            {{ t('header.navigation.login') }}
          </navigation-button>
        </li>
      </ul>
    </div>
  </nav>
</template>
