export default {

  /**
   * Terms of Agreement
   */
  "tos": {
    "heading": "Terms of Service",
    "meta": {
      "description": "SEO description of the ToS"
    },
    "lastmodified": {
      "heading": "Last Modified:",
      "date": "22 February, 2022"
    },
    "preamble": [
      "These terms of use (the “<strong>Terms</strong>”) are a legal contract between Siberfy Private Limited, and its affiliates (“<strong>Siberfy</strong>”, “we” or “us”) and “you” (“<strong>Customer</strong>”, “<strong>your</strong>,” or “<strong>User</strong>”). The Terms explain how you are permitted to use the services provided by and through our platform and website(s) (main url located at <a href=\"https://siberfy.com\">www.siberfy.com</a>) as well as all of our associated internet and online properties (either linked by Siberfy and/or affiliated companies) and any software that Siberfy provides to you for download or use, including in your mobile devices (collectively, our “<strong>App(s)</strong>”) (all of these virtual properties, software and mobile applications, collectively, the “<strong>Site</strong>”). These Terms also govern your use of all the text, data, information, software, graphics, proprietary content and more (all of which we refer to as “<strong>Materials</strong>”) that we and/or our affiliates may make available to you, as well as any services we may provide through this Site). Collectively, the Site, the Materials, and the services provided therein are referred to as the “<strong>Services</strong>”.",
      "USING THE SERVICES INDICATES THAT YOU HAVE BOTH READ AND ACCEPT THESE TERMS. IF YOU DO NOT AGREE WITH ANY OF THESE TERMS, DO NOT ACCESS OR OTHERWISE USE THE SERVICES.",
      "IMPORTANT: THESE TERMS CONTAIN A <a href=\"#\">DISPUTE RESOLUTION AND ARBITRATION PROVISION</a>, INCLUDING A CLASS ACTION WAIVER THAT AFFECTS YOUR RIGHTS. DEPENDING ON YOUR JURISDICTION, THIS SECTION MAY NOT APPLY TO YOU."
    ],
    "subterms": {
      "heading": "Relevant Policies",
      "preamble": [
        "These two documents are relevant to the terms of service that follow. Please read them in full and understand that they are part of your agreement when using Siberfy's services."
      ],
      "items": [
        {
          "heading": "Acceptable Use Policy",
          "route": "legal.policy",
          "description": "Pellentesque ligula est, finibus vel urna ac, dictum suscipit leo. Sed vel erat id lectus tincidunt rutrum ac vel lorem. Mauris non cursus ligula."
        },
        {
          "heading": "Privacy Policy",
          "route": "legal.privacy",
          "description": "Pellentesque ligula est, finibus vel urna ac, dictum suscipit leo. Sed vel erat id lectus tincidunt rutrum ac vel lorem. Mauris non cursus ligula."
        }
      ]
    },
    "terms": {
      "heading": "Full Terms of Service",
      "sections": [
        {
          "heading": "YOUR ACCEPTANCE AND CONTRACTUAL RELATIONSHIP",
          "paragraphs": [
            "By using the Services, you represent and warrant that you are of age under the laws of your jurisdiction and/or lawfully able to enter into contracts. If you are not legally able to enter into contracts, you may not use the Services at any time or in any manner, or submit any information to Siberfy or the Services.",
            "If you are entering into these Terms on behalf of a business entity or organization, you represent and warrant that you have the legal authority and capacity to bind such business entity or organization. If you are not authorized nor deemed by law to have such authority, you assume sole personal liability for the obligations set out in these Terms."
          ]
        },
        {
          "heading": "PRIVACY",
          "paragraphs": [
            "Please review our privacy policy (the “<a href=\"#\">Privacy Policy</a>”) which explains how we use information that you submit to Siberfy. The Privacy Policy is hereby incorporated by reference.",
            "We follow the GDPR standard for users outside of Pakistan. However law of the land (Pakistan) will always take precedence.",
          ]
        },
        {
          "heading": "MODIFICATIONS AND ADDITIONAL TERMS",
          "paragraphs": [
            "(a) <u>These Terms</u>. Siberfy can change, update, add or remove provisions of these Terms at any time by posting the updated Terms on the Site and by providing a notice on the Services. We will ask for your express consent to the updated Terms where we are legally required to do so, and our notice to you will explain how you can accept or reject the changes. If you do not agree with any of the updated Terms, you must stop using the Services. Unless otherwise required by law, the updated Terms are effective as of the day of posting.",
            "(b) <u>The Services</u>. Siberfy may make changes to the Services at any time, without notice to you. If you object to any changes to the Services, your sole recourse will be to cease using the Services. Continued use of the Services following posting of any such changes will indicate your acknowledgement of such changes and satisfaction with the Services as modified. We also reserve the right to discontinue the Services, or any component of it, at any time without notice to you. We will not be liable to you or any third party should we exercise our right to modify or discontinue the Services.",
            "(c) <u>Paid subscriptions</u>. Please note that if you are under paid-subscription services with us, in the event we change the price for the services which you have previously agreed to pay or if we substantially change the services you are paying for, we will also notify you of such changes as contemplated in Section 3(a) above. Any changes will become effective after your then-current subscription expires or terminates. If you do not agree with such changes and you communicate this to us in accordance with the notification, we will not automatically renew your subscription even if you previously agreed to automatic renewal for payment.",
            "(d) <u>Additional Terms</u>. In addition, certain features of the Services may be subject to additional terms of use (“<strong>Additional Terms</strong>”), which shall be provided to you at the moment you choose to use such features or services. By using such features, or any part thereof, you agree to be bound by the Additional Terms applicable to such features. In the event that any of the Additional Terms governing such features conflict with these Terms, the Additional Terms will govern.",
            "(e) <u>Executed Contract(s)</u>. If You have entered into a separate executed (i.e. signed) agreement for services with Siberfy (collectively and individually, “Contract”), such Contract shall supersede these Terms. In the event of any conflict between these Terms and the Contract, the Contract will prevail. In cases where the Contract does not address specific provisions included in these Terms, these Terms will apply, supplementing the Contract."
          ]
        },
        {
          "heading": "USERS",
          "paragraphs": [
            "(a) <u>Visitors</u>. Visitors may browse the Site in accordance with these Terms but will not have full access to the Services without first becoming “Registered Users.”",
            "(b) <u>Registered Users and Accounts</u>. In order to access certain features of the Services you will be required to become a Registered User. A “Registered User” is a User who has registered an account with us (your “Account”). As a Registered User you choose what information you may make public or showcase. By registering as a User you represent that you are not barred from using the Services under these Terms, the laws of the United States of America, your place of residence or any other applicable jurisdiction.",
            "(c) <u>Registration Data</u>. In registering for the Services, you agree to (i) provide true, accurate, current and complete information about yourself as prompted by the Services’ registration form (the “Registration Data”); and (ii) maintain and promptly update the Registration Data to keep it true, accurate, current and complete. You agree that all notices and communications between Us will be sent to the email address You provide. If you provide any information that is untrue, inaccurate, not current or incomplete, or Siberfy has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Siberfy has the right to suspend or terminate your Account and refuse any and all current or future use of the Services (or any portion thereof) for breach. You agree not to create an Account using a false identity or information. You agree that you shall not have more than one Account at any given time. You agree not to register for an Account on behalf of an individual other than Yourself, or register for an Account on behalf of any group or entity unless you are authorized to bind such person, group, organization, or entity to these Terms. By registering another person, group, organization, or entity you hereby represent that you are authorized to do so. You agree not to create an Account or use the Services if you have been previously removed by Siberfy, or if you have been previously banned from any of the Siberfy properties.",
            "(d) <u>Account Management</u>. When You register for the Services, You will choose a login (email) and password. You may use the Services or modify Your Content and data only through such login and password. You are entirely responsible for maintaining the confidentiality of Your password and for any and all activities which occur using Your credentials and/or under your Account. Siberfy reserves the right to establish an Account verification process to verify the account and/or any information provided. You agree to immediately notify Us of any unauthorized use of Your Account or any other breach of security known to (or reasonably suspected by) You at <a href=\"mailto:support@siberfy.com\">support@siberfy.com</a>. Siberfy shall be entitled to monitor your username and password and, at its discretion, require you to change it. If you use a username and password that Siberfy considers insecure or inappropriate, Siberfy will be entitled to require this to be changed and/or terminate your Account. Siberfy reserves the right to remove or reclaim any usernames at any time and for any reason, including but not limited to, claims by a third party that a username violates the third party’s rights. It is also Your responsibility to make sure that Our domain(s) (including “<a href=\"#\">siberfy.com</a>”) are not included in any spam block list used by You or Your mail provider. You are required to perform all necessary security configuration and management tasks (1) for Your site(s) and all domains, web pages or IP addresses which You are hosting through the Services, and (2) to protect Your Content (defined below) and data, including that of Your customers and/or end users hosted through the Services. You are responsible for management of updates and security patches, any application software or utilities installed, any files accessible via Your site(s), and the configuration of any Siberfy-provided security utilities."
          ]
        },
        {
          "heading": "SERVICES",
          "paragraphs": [
            "At the time of initial registration, You will select from the list of available Services the service plan(s) to which You wish to subscribe (each a “Subscription”). All Subscriptions to Services are subject to formal acceptance by Siberfy. Your Subscription to the Services will be deemed accepted by Siberfy when Siberfy delivers a confirmation of the Subscription to You. Siberfy reserves the right to refuse to provide You with any Service for any reason. Notwithstanding Our <a href=\"#\">Uptime Guarantee</a>, Siberfy also reserves the right to interrupt access to the Services to perform regular and emergency maintenance as needed. You may order additional Services at any time, provided that You agree to pay the then-current fees for such additional Services. All additional services shall be considered “Services” hereunder. All Services provided are subject to availability and to these Terms.",
            "For as long as you agree to these Terms and abide by them, you may use the Services. These Terms apply to all Users of the Service, including Visitors and Registered Users, who are also contributors of User Content. The Services are licensed, not sold, to you.",
            "(a) <u>Grant of a Limited License</u>. The Services are protected by copyright laws throughout the world. Subject to your agreement, and continuing compliance with these Terms, and any other relevant Siberfy policies, Siberfy grants you a non-exclusive, non-transferable, non-sublicensable, revocable limited license subject to the limitations below to use the Services and/or download, and use a copy of any Apps or client-software on a device or computer that you own or control and to run such copy solely for your own internal business or individual purposes only. You agree not to use the Services for any other purpose.",
            "(b) <u>Restrictions</u>. You may not: (i) remove any copyright, trademark or other proprietary notices from any portion of the Services; (ii) reproduce, modify, prepare derivative works based upon, distribute, license, lease, sell, resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Services except as expressly permitted by Siberfy; (iii) decompile, reverse engineer or disassemble the Services except as may be permitted by applicable law; (iv) link to, mirror or frame any portion of the Services except as expressly permitted by Siberfy; (v) cause or launch any programs or scripts for the purpose of scraping, indexing, surveying, or otherwise data mining any portion of the Services or unduly burdening or hindering the operation and/or functionality of any aspect of the Services; or (vi) attempt to gain unauthorized access to or impair any aspect of the Services or its related systems or networks."
          ]
        },
        {
          "heading": "SUBSCRIPTIONS AND CANCELLATIONS",
          "paragraphs": [
            "(a) <u>Subscriptions</u>. Your Subscription or commencement of the Services shall begin upon confirmation to You and receipt of lawful funds, whichever comes earlier. The Subscription initial term's length is chosen by You and shall be indicated when You subscribe to Our Services. The Subscription may not be terminated by You during the Initial Term (and any renewals thereof) except in the event of a breach by Siberfy. After the Initial Term, the Subscription shall automatically renew for successive terms, equal in length to the Initial Term, unless terminated or canceled by either party as provided herein.",
            "(b) <u>Pricing</u>. Subject to Section 3(c), Siberfy reserves the right to change prices for paid Subscriptions at any time and does not provide price protection or refunds in the event of promotions or price decreases. Any pricing changes will become effective after your then-current Subscription expires or terminates. You understand that if We agree to provide Services to You in the future after your Subscription terminates for any reason, the amount You paid under any prior term or time period is not determinative of the amount You will pay should We provide Services to You again. It is Your responsibility to check Our Site for plan or price changes should You wish to take advantage of plan or price changes which may have occurred. Siberfy does not automatically update Your Subscription plan. All upgrades or downgrades will be performed at Your request and may include modification of Fees (defined in Section 7(a)) or require reinitiating service with Us.",
            "(c) <u>AUTOMATIC RENEWAL TERMS</u>: To facilitate continuity of the Services to you, each paid Subscription contains automatic renewal terms. Siberfy will automatically renew your paid Subscription as per the Subscription period of your choosing (each a “Subscription Term”), on the anniversary of that date that Siberfy first charges your Account for the first Subscription fee, and, as authorized by you during the Subscription sign-up process, Siberfy will charge your Account with the applicable Subscription fee and any sales or similar taxes that may be imposed on your Subscription fee payment (unless you cancel prior to the anniversary date). Each Subscription renewal period is for the same Subscription Term as the prior one, unless otherwise agreed between you and Siberfy. IF YOU CANCEL YOUR SUBSCRIPTION, YOUR ACCESS TO AND USE OF THE SERVICES WILL BE SHUT OFF ONCE YOUR THEN CURRENT SUBSCRIPTION TERM EXPIRES.",
            "Siberfy reserves the right to modify pricing at any time (but not the price in effect for your then-current Subscription Term), upon advance notice to you. If you have not cancelled your Subscription or turned off the auto-renew function within the specified time after receiving notice of a price change, your Subscription will auto-renew at the price indicated in your notice.",
            "(d) <u>CANCELLATION TERMS</u>: YOU MAY CANCEL YOUR SUBSCRIPTION AT ANY TIME, AND SUCH CANCELLATION SHALL BECOME EFFECTIVE UPON EXPIRATION OF YOUR THEN-CURRENT SUBSCRIPTION TERM. You agree and understand that you will be charged Subscription fees until the expiration of your then-current Subscription Term and SUBSCRIPTION FEES WILL NOT BE REFUNDED, IN WHOLE OR IN PART, SUBJECT TO APPLICABLE LAW. You will not be eligible for a pro-rated refund of any portion of the Subscription fees paid for any unused days of the then-current Subscription Term. If you have any problems or concerns with your cancellation please contact us at <a href=\"mailto:support@siberfy.com\">support@siberfy.com</a> for assistance."
          ]
        },
        {
          "heading": "PAYMENTS",
          "paragraphs": [
            "(a) If you purchase any Services that we offer for a fee, either on a one-time or on a Subscription basis (collectively “Fees”), you agree and consent to Siberfy’s use of third-party payment providers for billing and processing online payments (see below), and you agree to pay the applicable Fees for the Services (including, without limitation, periodic fees for Subscriptions) as they become due, plus all related taxes, and to reimburse us for all collection costs and interest for any overdue amounts. Unless separately negotiated by You and Us, and confirmed by separate written agreement, the initial and recurring Fees for the Services selected by You shall be as provided in the initial on-line order form. All set-up fees and special programming fees are non-refundable. Unless otherwise indicated, we may invoice Fees for Services in advance. Failure to make payment of Fees for Services when due may result in the suspension or termination of Services.",
            "(b) Services are billed on an automatic, auto-renewal and recurring basis unless and until you follow Siberfy's cancellation procedure set forth in this Agreement. Your obligation to pay fees continues through the end of the Subscription Term (defined in Section 6(c)). Our Subscriptions may be subject to usage limits. You agree and understand that we verify use of the Service periodically. If as part of a routine verification process, we determine that Usage exceeds the current amount of usage limits purchased, we reserve the right to invoice you for the additional use. We may contact you to discuss your current plan and other options available to you. You agree to upgrade to a higher usage plan if necessary.",
            "(c) At the time of registration, You must select a payment method. By providing a payment method, You expressly authorize Siberfy to charge said payment method at regular intervals subject to Your particular Subscription. Siberfy reserves the right to contract with a third party to process all payments. Such third party may impose additional terms and conditions governing payment processing. If You do not pay all Fees when due, Your account will be deemed past due. For any past due Fees, Siberfy reserves the right to charge You interest at one and one-half percent (1.5%) or the highest rate allowed by applicable law, whichever is lower, per month of the unpaid amount, until paid.",
            "(d) You agree to pay any and all taxes, including personal property, value added, or sales taxes, resulting from Your use of the Services. Siberfy is not responsible for any bank fees incurred by You due to Your use of check cards, automatic payment services, insufficient funds, and any and all other fees your financial institution may impose due to Your use of the Services. If Siberfy should receive less than full payment of the Fees due to taxes, bank charges, transfer fees, or the like, Siberfy will invoice You for the difference between payment received and the Fees due. You also agree to pay all attorney and collection fees arising from Siberfy's efforts to collect any past-due Fees.",
            "(e) If you cancel any Service prior to the expiration of the pre-paid Fees, You understand and agree that Siberfy, at its sole discretion, may not issue You any refund whatsoever, including but not limited to any remaining pre-paid Fees, set up Fees, and/or special programming Fees. Any refunds, if any, will be refunded to the original method of payment only.",
            "(f) If you do not pay on time or if Siberfy cannot charge your payment method for any reason, Siberfy reserves the right to either suspend or terminate your Subscription, access to the Services, and/or Account and terminate these Terms.",
            "(g) <u>Coupons and Discount Codes</u>. From time to time, Siberfy may offer coupons or other discount codes which may be used when signing up for Services with Us. Coupons and discount codes are for first-time customers of Siberfy only and must be used at the time of Your initial purchase with Us – they may not be applied after commencement of the Services. Unless expressly provided, such coupons and discount codes may not be used toward upgrades to Your account. Any account We deem to be attempting unauthorized coupon or discount code use may be subject to Termination for Cause (see Section 9)."
          ]
        },
        {
          "heading": "REFERRAL PROGRAM",
          "paragraphs": [
            "We may offer programs allowing our users to earn service credits and rewards (“<strong>Rewards</strong>”) on their Accounts. Please refer to our <a href=\"#\">Referral Program Terms</a> as these may be amended from time to time, before participating in our Rewards’ program. The <a href=\"#\">Referral Program Terms</a> are hereby incorporated by reference."
          ]
        },
        {
          "heading": "TERMINATION",
          "paragraphs": [
            "(a) Siberfy may terminate your Subscription in its sole discretion at any time for any reason or no reason (“Termination without Cause”). In such case, Siberfy will provide You with thirty (30) days written notice before the discontinuation of Services. If Siberfy cancels your Subscription pursuant to any of the terms outlined in these Terms, with the exception of Termination without Cause, Siberfy shall not refund to You any fees paid or prepaid in advance of such cancellation and You shall be obligated to pay all fees and charges accrued prior to the effectiveness of such cancellation. In the event that Siberfy terminates the Agreement for cause, all prepaid Fees will be forfeited and are not refundable. The termination of your Subscription does not relieve You of Your obligation to pay any Fees accrued or payable to Siberfy prior to the effective date of termination of your Subscription.",
            "(b) In addition to Siberfy's right to terminate your Subscription provided elsewhere in these Terms, Siberfy may terminate your Subscription effective immediately if, based on Siberfy's sole judgment, it determines that You or any of Your end users: (i) have breached the <a href=\"#\">Acceptable Use Policy</a> (“AUP”) or <a href=\"#\">Anti-Spam Policy</a>, (ii) have infringed or violated any intellectual property right or privacy or publicity right of a third party, (iii) have not complied with any applicable law, statute or regulation, or (iv) have uploaded, published or disseminated any images, text, graphics, code or video which Siberfy considers illegal or high risk, in its discretion, or (v) breached these Terms. Nothing contained in these Terms is intended to, or shall, impose any duty or obligation upon Siberfy to monitor or review Your Content (defined in Section 12) or the content of Your end users at any time. You remain solely responsible for Your Content, and any liability generated therefrom.",
            "(c) The termination of your Subscription will end Your access to the Services and Your license to the Materials. Siberfy shall not be liable to You or to any third party for termination of the Services permitted under these Terms. Upon termination of your Subscription, Siberfy reserves the right to maintain copies of Your data files and records for archival purposes but does not undertake any obligation to do so. Siberfy reserves the right to impose an early termination charge for all Services terminated prior to the last day of the billing cycle.",
            "(d) If either party cancels or terminates your Subscription for any reason, You shall be solely responsible for making all necessary arrangements for securing a replacement service provider and timely moving all electronic data, graphics, images, video or text to the new service provider.",
            "(e) Siberfy will not have any liability whatsoever to you for any suspension or termination, including for deletion of Content. All provisions of the Terms, which by their nature should survive, shall survive termination of the Service, including without limitation, ownership provisions, warranty disclaimers, and limitation of liability. You may close your Account by using the feature provided in the Services or, in the alternative, by emailing us at <a href=\"mailto:support@siberfy.com\">support@siberfy.com</a>. We will proceed to close your Account and send you an email confirmation."
          ]
        },
        {
          "heading": "OUR ACCEPTABLE USE AND ANTI-SPAM POLICIES AND OUR COMMUNITY",
          "paragraphs": [
            "To use our Services, you agree to and warrant that you will comply at all times with our <a href=\"#\">Acceptable Use Policy</a> (“AUP”) and our <a href=\"#\">Anti-Spam Policy</a> (“Policies”) as these may be amended from time to time, which regulate prohibited and/or acceptable practices relating to the use of our Services, including limitations on User Content, prohibited activities, system abuse and security. If you do not agree with our Policies, you must discontinue use of the Service. The Policies are hereby incorporated by reference."
          ]
        },
        {
          "heading": "INTELLECTUAL PROPERTY INFRINGEMENT AND DMCA NOTIFICATIONS",
          "paragraphs": [
            "Siberfy does not permit copyright-infringing activities and infringement of intellectual property rights on the Services, and pursuant to the safe-harbor provisions provided by the Digital Millennium Copyright Act of 1998 (“DMCA”), Siberfy will remove User Content if properly notified that such User Content infringes on another's intellectual property rights (please refer to our <a href=\"#\">Copyright Policy</a>). Siberfy reserves the right to remove User Content without prior notice."
          ]
        },
        {
          "heading": "USER CONTENT",
          "paragraphs": [
            "12.1 <u>Content</u>.",
            "(a) You are responsible for the information, text, opinions, messages, comments, audio visual works, motion pictures, photographs, animation, videos, graphics, sounds, music, software, Apps, and any other content or material that You or your end users submit, upload, post, host, store, or otherwise make available (“Make Available”) on or through the Services (collectively, “Your Content,” “Content” or “User Content”). You may not Make Available on the Services any material protected by copyright, trademark, or any other proprietary right without the express permission of the owner of such copyright, trademark or other proprietary right owned by a third party, and the burden of determining whether any such right protects any material is on you. You shall be solely liable for any damage resulting from any infringement of copyrights, trademarks, proprietary rights, violation of contract, privacy or publicity rights or any other harm resulting from any User Content that you make or submit. As between you and us, you own your User Content and you have full responsibility for all User Content you make or submit, including its legality, reliability and appropriateness, while using the Services. You hereby grant to Siberfy a non-exclusive, perpetual, irrevocable, royalty-free, fully paid-up, worldwide license (including the right to sublicense through multiple tiers) to use, reproduce, process, adapt, publicly perform, publicly display, modify, prepare derivative works, publish, transmit and distribute each of your User Content, or any portion thereof, in any form, medium or distribution method now known or hereafter existing, known or developed, and otherwise use and commercialize the User Content in any way that Siberfy deems appropriate, without any further consent, notice and/or compensation to you or to any third parties, for purposes of providing the Services to you.",
            "(b) Siberfy will not actively monitor Content being hosted by Siberfy, although Siberfy, at its sole discretion, may elect to electronically monitor its network and may disclose any content or records concerning Your Account as necessary to satisfy any law, regulation, or other governmental request or to properly operate Our network and protect any of Our customers. Siberfy will investigate complaints of a violation of a third-party right or of the <a href=\"#\">AUP</a>. Siberfy will cooperate with those attempting to minimize Internet abuse and reserves the right to institute “filters” or other mechanisms for that purpose. <strong>Siberfy will cooperate with law enforcement authorities and reserves the right to notify such authorities if it suspects that You or any of Your end users are engaged in illegal activities</strong>. Nothing contained in this Section, or anywhere in these Terms, is intended to bestow any rights on any third party, and no third parties shall be entitled to enforce any terms of these Terms between us, the parties.",
            "(c) You acknowledge and expressly agree that Siberfy will not be liable to You or any of Your end users for any action Siberfy takes to remove or restrict access to the Services for any alleged violation of the <a href=\"#\">AUP</a>, or exercising its rights as a Good Samaritan under the Telecommunications Act of 1996 (47 U.S.C. § 230(d)) or under the DMCA.",
            "(d) Siberfy may, at its sole discretion, immediately terminate Your Account and access to the Services, and terminate these Terms for cause, if Your conduct violates our Policies (See Section 10), or if any of Your end users' or downstream customers' conduct violate such Policies. We respect the intellectual property rights of all parties and have adopted a policy regarding termination of repeat copyright infringers under the DMCA (See Section 11).",
            "(e) Child Pornography. Siberfy takes the issue of <strong>child pornography</strong> very seriously, and any potential harm to minors using our Services is strictly prohibited. Content that is or may be perceived to be child pornography will be immediately removed from public access upon notification or detection by Us. Additionally, Siberfy reserves the right to terminate any Account whose website(s) hosts or links to child pornography immediately and without notice to You. If the account is a Siberfy reseller account, the account will be suspended, and the reseller will be directed to terminate the responsible account. You agree to cooperate in any such efforts. Content or communications seeking to solicit, lure or entice minors into sexual activity or lewd behavior is also strictly prohibited, and will be treated the same as, or similar to, child pornography, consistent with applicable law. You agree to cooperate with Siberfy in any effort to investigate, disable or remove such Content originating with Your end users. Consistent with federal law, <strong>Siberfy will cooperate with law enforcement authorities and will notify such authorities if it suspects that You or any of Your downstream customers or end users are engaged in any such illegal activities</strong>.",
            "In accordance with the reporting requirements of 18 U.S.C. § 2258A, We will report to the CyberTipline (<a href=\"https://www.cybertipline.com\" target=\"_blank\" rel=\"noreferrer noopener\">www.cybertipline.com</a>) any actual knowledge of apparent violations of 18 U.S.C §§ 2251, 2251A, 2252, 2252A, 2252B, 2260, or 1466A. If You suspect any instances of child pornography appearing on sites hosted by Siberfy, We encourage You to send such reports to Our abuse address at <a href=\"mailto:abuse@siberfy.com\">abuse@siberfy.com</a>, and include the file name and/or URL (or other location on the Customer's site), victim (if known), date of birth, date of production, and any other information about the suspect image(s). <strong>Do not send the image(s) in question</strong>. Alternately, You may use the CyberTipline to report suspected child pornography. Reports involving sites not hosted by Siberfy should be directed to law enforcement or to a cooperating child pornography organization such as: <a href=\"https://www.asacp.org/index.php?content=report\" target=\"_blank\" rel=\"noreferrer noopener\">https://www.asacp.org/index.php?content=report</a>.",
            "(f) Content appropriate for Minors and Section 230 Notice: You acknowledge Your responsibility to prevent minors under Your care from accessing harmful or inappropriate material on Your site and services. You agree not to allow minors to view any such materials and agree to take responsible measures to prevent them from doing so. Numerous commercial online safety filters are available which may help users limit minors' access to harmful or inappropriate material. Pursuant to 47 U.S.C. §230(d), you are hereby informed that you can research such services at websites such as <a href=\"https://www.asacp.org\" target=\"_blank\" rel=\"noreferrer noopener\">www.asacp.org</a>. Please note that the Service makes no representation or warranty regarding any of the products or services referenced on such sites and recommends that the user conduct appropriate due diligence before purchasing or installing any online filter. You agree to take particular steps to prevent minors from viewing these kind of materials if Your computer can be accessed by a minor. Finally, You agree that if You are a parent or guardian of a minor child, it is Your responsibility, not Ours, to keep any age-restricted content on Our Site from being displayed or accessed by Your children or wards.",
            "(g) Pursuant to the <u>Communications Decency Act</u> (“CDA”), 47 U.S.C. § 230(c)(1), and court decisions interpreting the scope of the CDA, You acknowledge and understand that Siberfy operates as the provider of an interactive computer service. Thus, We are immune from, and cannot be held responsible for, claims arising from the publication of Your Content (including third-party content published on Your web site(s)). We do not create such content, and We are not responsible for the publication of remarks or communications of You or third-parties that may arguably rise to the level of being actionable under federal or state laws including, but not limited to, the publication of material that might be considered defamatory, or violative of privacy or publicity rights. Note, that federal law allows Siberfy to remove any content found to be offensive, defamatory, obscene or otherwise violative of Our policies, without impacting Our immunity status as an interactive computer service. Nothing contained in this Section is intended to limit or alter the immunity from claims provided by Section 230 of the Communications Decency Act. In the event that any court finds that any third-party communication or third-party content hosted by us falls outside of the realm of the immunity provided by the CDA, this shall not be deemed to be a waiver of any legal protections provided by Section 230 for any and all other content posted on our Site or hosted via our Services."
          ]
        }
      ]
    }
  },

  /**
   * Privacy Policy
   */
  "privacy": {
    "heading": "Privacy Policy",
    "meta": {
      "description": "SEO description of the Privacy Policy"
    },
    "lastmodified": {
      "heading": "Last Modified:",
      "date": "21 September, 2022"
    },
    "preamble": [
      "Thank you for visiting Siberfy. The Siberfy website and its associated services (collectively \"Services\") are owned and operated by Siberfy Private Limited. (\"Siberfy\"), which is a Private Limited Company located in Pakistan. Siberfy has adopted this privacy policy (\"Privacy Policy\") because it believes that you should always know what kind of data it collects from you, how it collects and uses that data, and how you can have control over that data. Siberfy strives to be transparent in its data collection efforts and to provide you with the clear and concise information that you need to make decisions about Siberfy's collection of data from you.",
    ],
    "sections": [
      {
        "heading": "Data Collected",
        "paragraphs": [
          "Siberfy collects data from you when you share it with us and when you use the Siberfy Services. When you provide your Personal Data (defined below) to Siberfy, Siberfy acts as a controller of Personal Data. When you use the Services to store or process Personal Data that you collect, you appoint Siberfy as a data processor to process data on your behalf and in accordance with your instructions or as otherwise necessary to provide the Services to you.",
        ]
      },
      {
        "heading": "Data You Share With Us",
        "paragraphs": [
          "Siberfy may collect the following information from you when you voluntarily share it with us (\"Personally Identifiable Information\"):",
          [
            "Name;",
            "Company name;",
            "Address;",
            "Telephone Number;",
            "Email Address;",
            "Security questions and answers;",
            "Credit Card and debit card information;",
            "Passport number; and",
            "Driver's License.",
          ],
          "<br>",
          "This Personally Identifiable Information is used for the following reasons:",
          [
            "To provide you with the Services;",
            "For billing and contracting purposes;",
            "To process your payment;",
            "To authenticate your account;",
            "To send you marketing e-mails concerning our Services;",
            "To communicate with you;",
            "To provide you with customer service or support;",
            "To keep records of communications through our Services;",
            "To comply with regulations; and",
            "To pursue or defend against legal claims.",
          ],
        ]
      },
      {
        "heading": "Data We Receive From You",
        "paragraphs": [
          "Siberfy may also collect personal data from you automatically when you use the Services (\"Personal Data\"). This Personal Data may include the following:",
          [
            "Location data, such as by collecting and processing your IP address and device settings;",
            "Technical data, such as your IP address, your device IDs, server location, server type, server position, IP assignments, and MAC addresses;",
            "Usage and interaction data, including the manner in which you interact with the Siberfy Services; and",
            "Marketing data, such as your IP address, your advertising or creative ID, your geographical location, pages visited, referring web page, search terms, cookie data, and advertising IDs.",
          ],
          "<br>",
          "This Personal Data may be used for the following reasons:",
          [
            "To provide location-based Services or to tailor the Services to your geographic location;",
            "To respond to law enforcement requests;",
            "To provide content that is relevant to your geographic location;",
            "To analyze your use of the Services, to administer and secure our Services, to determine how Siberfy should allocate server resources, to understand the technical load on Siberfy's servers, and to detect and report fraud;",
            "To better tailor our services or to understand how we can better improve our user interfaces; and",
            "To provide advertisements to you, to determine the relevance of advertisements, and to help make advertisements more relevant to your preferences.",
            "Use of Cookies",
          ],
          "<br>",
          "Siberfy may collect data from you using cookies and other technologies. Siberfy may use cookies to:",
          [
            "Ensure the functionality of the Services, including by allowing you to add services to your cart and to retain information for a session when you use the Services;",
            "To authenticate users and to prevent fraud and abuse;",
            "To understand how you interact with the Service so that we may improve it;",
            "To personalize the Service to your wants and needs; and",
            "To provide you with advertisements.",
            "Siberfy may use session or persistent cookies. Session cookies are only stored on your computer or mobile device during your use of the Services and are automatically deleted when you close your web browser. Persistent cookies are stored as a file on your computer or mobile device that remain on your computer or mobile device even after you close your web browser. Persistent cookies can be read by the website that created the cookie when you revisit it again. Siberfy may use persistent cookies when it uses Google Analytics or other analytics providers.",
          ],
        ]
      },
      {
        "heading": "Marketing Communications",
        "paragraphs": [
          "Siberfy may send you marketing communications if you have asked us for information concerning our services or if you have agreed to, and have not opted out from, receiving marketing communications. You may ask us to stop sending you marketing messages at any time by following the opt-out link in any marketing message set to you.",
        ]
      },
      {
        "heading": "Disclosure of Personally Identifiable Information and Personal Data",
        "paragraphs": [
          "Siberfy will only share your Personally Identifiable Information or Personal Data under limited circumstances. Siberfy will disclose your Personally Identifiable Information and Personal Data:",
          [
            "Upon your consent or at your direction;",
            "To its subsidiaries and affiliates;",
            "To contractors, business partners, and service providers that Siberfy uses to support the Service;",
            "In the event Siberfy sells or transfers all or a part of its business or assets;",
            "To Siberfy's accountants, auditors, insurers, or attorneys;",
            "In response to lawful requests from public authorities, including upon receipt of an exigent circumstances request, upon receipt of a duly authorized subpoena or court order, or where doing so is necessary to protect our users, our employees, our contractors, third parties, or property.",
          ],
          "We will ensure that the Personal Data transferred will be used solely for the support of our service and we will maintain controls, contracts, and/or non-disclosure agreements with third parties, to ensure the correct use of information disclosure.",
        ]
      },
      {
        "heading": "Legal Basis for Collection and Use of Personally Identifiable Information and Personal Data",
        "paragraphs": [
          "Siberfy collects and processes the above-described Personally Identifiable Information and Personal Data consistent with the European General Data Protection Regulation (EU GDPR), the UK Data Protection Act 2018, and the UK GDPR. Siberfy collects this data to comply with its contractual obligations, to perform the Services, to communicate with you, and to administer its Services and business. Siberfy's lawful ground for processing this Personally Identifiable Information and Personal Data is the following:",
          [
            "Your consent, when you voluntarily submit Personally Identifiable Information and Personal Data to Siberfy;",
            "Siberfy's legitimate interest in providing its Services, maintaining its business records, communicating with its users, performing its contractual obligations, and growing its business through marketing efforts; and",
            "Compliance with a legal obligation to which Siberfy is subject.",
          ],
        ]
      },
      {
        "heading": "Access, Protection, and Retention of Personally Identifiable Information and Personal Data",
        "paragraphs": [
          "If you have registered an account with Siberfy and are a citizen of the European Union, Siberfy provides you with account settings that let you modify, correct, or delete the personal data that you have provided to us that is associated with your account. You may access these accounts settings at https://cloud.siberfy.com/. Whether you are located in the European Union or elsewhere, to stop our collection and use of your personal data, you need only to request the deletion of your account.",
          "If you have not registered an account with Siberfy, we only collect technical data from you. To stop our collection and use of your technical data, you need only cease using our Services.",
          "Siberfy is located in Pakistan and we may transfer your Personally Identifiable Information and Personal Data to countries outside of the European Union. Many of our third-party service providers are located within the United States and their processing of Personally Identifiable Information and Personal Data will involve a transfer of data outside of the European Union. Whenever personal data is transferred outside of the European Union, Siberfy does its best to ensure a similar degree of security and data privacy by requiring that its service providers and its foreign parents or subsidiaries adopt standard contractual clauses covering the transfer of this data.",
          "Additionally, Siberfy takes measures that supplement transfer tools, such as standard contractual clauses, to ensure compliance with the level of protection of personal data in the European Union. For example, when Siberfy receives an expedited circumstances request, a subpoena, a warrant, or a court order to disclose personal data from law enforcement or regulators in the United States, Siberfy carefully validates these requests, including by reviewing the legality of such request, subpoena, warrant, or court order and challenging them if there are grounds under the law to do so before any personal data is disclosed.",
        ]
      },
      {
        "heading": "Your Rights",
        "paragraphs": [
          "Rights of European Citizens",
          "If you are a European citizen, you are entitled to certain rights regarding the protection of your Personally Identifiable Information and Personal Data, which are subject to limitations set forth in the EU GDPR and its applicable case law. These rights are:",
          [
            "The right to access and correct the information that Siberfy processes about you;",
            "The right to transfer all or a part of the information collected about you to another data controller, where it is technically feasible;",
            "The right to the erasure of data concerning you, subject to Siberfy's rights of retention under the law;",
            "The right to object to the processing of Personally Identifiable Information and Personal Data where you dispute the accuracy of the data, the processing is not lawful, Siberfy no longer needs the information for the purposes of processing, or you have raised an objection for personal reasons;",
            "The right to revoke your consent to data processing;",
            "The right to object to the processing of your Personally Identifiable Information and Personal Data for marketing purposes;",
            "The right to object to the processing of Personally Identifiable Information and Personal Data for direct marketing or for personal reasons that arise from your particular situation; and",
            "The right to file a complaint with a data protection authority.",
          ],
          "To exercise these rights, you may either edit and update your Personally Identifiable Information or Personal Data at https://cloud.siberfy.com/, opt out of receiving communications from Siberfy by unsubscribing to its e-mails, or by sending an e-mail to privacy@siberfy.com. You do not need to pay a fee to exercise these rights, however, Siberfy reserves the right to charge a reasonable fee if your request is unreasonable or excessive. To confirm your request, we may need to request specific information from you as a security measure to ensure that personal data is not disclosed to an unauthorized third party. We will attempt to respond to all legitimate requests within thirty (30) days",
          "Upon a verifiable consumer request, Siberfy will delete your Personal Information from its records, and direct all of its service providers who possess your Personal Information, if any, to also delete such Personal Information, provided that Siberfy shall not comply with a deletion request if Siberfy needs the Personal Information for any of the following reasons: (i) providing goods or services to the consumer, (ii) detecting and resolving issues relating to security or functionality, (iii) complying with legal obligations, (iv) conducting research in public interest, (v) exercising free speech or ensuring another's exercise of free speech or (vi) using the information for internal purposes that the consumer might expect.",
          "Siberfy shall not \"sell\" any of your Personal Information. Siberfy does not collect information on children under the age of 13.",
        ]
      },
      {
        "heading": "Changes to Policy",
        "paragraphs": [
          "This Privacy Policy may be amended or modified from time to time as needed due to regulatory changes, to better serve the needs of our market, and/or as a result of an internal review by Siberfy that reflects the necessity of amending or modifying such policies.",
          "Please refer to the \"Last Updated\" date found on this page to determine the date of our last Privacy Policy Update. Any and all changes to our Privacy Policy will be effective upon the date it is published to the Privacy Policy page(s) on our website.",
        ]
      },
    ],
  },

  /**
   * Acceptable Use Policy
   */
  "policy": {
    "heading": "Acceptable Use Policy",
    "meta": {
      "description": "SEO description of the AUP"
    },
    "lastmodified": {
      "heading": "Last Modified:",
      "date": "23 February, 2022"
    },
    "preamble": [
      "This Acceptable Use Policy (the “<strong>AUP</strong>”) describes prohibited uses and acceptable practices relating to the use of Siberfy Private Limited and its affiliates (“<strong>Siberfy</strong>”, “<strong>us</strong>”, “<strong>our</strong>”)’s network of internet properties (including online platforms, websites and applications), products, materials and online services (collectively, the “<strong>Services</strong>”) by Siberfy’s customers and users that have gained access to the Services by visiting, purchasing Services and/or registering accounts (collectively, “<strong>You</strong>,” “<strong>Customers</strong>,” or “<strong>Users</strong>”). The Services must be used in a manner that is consistent with the intended purpose of the Services and the terms of the applicable agreement with Siberfy, including our <a href=\"#\">Terms of Service</a> (the “<strong>Terms</strong>”) to which this AUP is incorporated by reference.",
      "<strong>The examples described in this AUP are not exhaustive.</strong> We may modify this AUP at any time by posting a revised version on the Services. By using the Services, You consent to be bound by the terms of the latest version this AUP. Terms not defined herein are defined in our <a href=\"#\">Terms of Service</a>. You agree to comply with this AUP. You also agree to require your end Users to comply with this AUP. <strong>If you violate the AUP or authorize or help others to do so (including your end Users), we may suspend or terminate your use of the Services and delete your Account.</strong>"
    ],
    "sections": [
      {
        "heading": "No Illegal, Harmful, or Offensive User Content, or Use.",
        "paragraphs": [
          "You may not use, or encourage, promote, facilitate or instruct others to use, the Services for any illegal, criminal, unlawful, harmful, fraudulent, infringing or offensive use or enterprise, or to transmit, store, display, distribute or otherwise make available information or content (including User Content) that is illegal, unlawful, harmful, fraudulent, infringing or offensive. Prohibited activities or content include:",
          [
            "<strong>Illegal, Harmful or Fraudulent Activities.</strong> Any activities that are illegal, tortious, that violate the rights of others, or that may be harmful to others, our operations or reputation, including disseminating, promoting or facilitating child pornography, offering or disseminating fraudulent goods, services, schemes, or promotions, make-money-fast schemes, ponzi and pyramid schemes, phishing, or pharming.",
            "<strong>Infringing Content and/or Use.</strong> Content that infringes or misappropriates the intellectual property or proprietary rights of others, including unauthorized disclosures of private information, or taking information directly from Siberfy and/or our Users, and using it for your own personal or business purposes without permission or proper attribution. You agree NOT to attempt to resell Siberfy’s products and/or access to the Services without our written permission.",
            "<strong>Offensive Content.</strong> Content that is harmful to minors in any way, defamatory, libelous, obscene, abusive, threatening, discriminatory, harassing, invasive of privacy, false, intentionally misleading, patently offensive, or otherwise objectionable, including content that constitutes child pornography, relates to bestiality, depicts non-consensual sex acts or that promotes racism, bigotry, hatred, religious intolerance, misogyny, or physical harm of any kind against any group, individual or animal.",
            "<strong>Harmful Content.</strong> Content or other computer technology that may damage, interfere with, surreptitiously intercept, or expropriate any system, program, or data, including viruses, Trojan horses, worms, time bombs, or cancelbots.",
            "<strong>False Content.</strong> Submitting any content or information that falsely states, impersonates or otherwise misrepresents your identity, including but not limited to misrepresenting your current or previous qualifications, or your affiliations with a person or entity, past or present, or assuming another’s identity."
          ]
        ]
      },
      {
        "heading": "No SPAM, E-Mail or Other Message Abuse.",
        "paragraphs": [
          "You agree to comply at all times with our <a href=\"#\">Anti-Spam Policy</a>. You will not distribute, publish, send, or facilitate the sending of unsolicited mass e-mail or other messages, promotions, advertising, or solicitations (like “SPAM”), including commercial advertising and informational announcements. You will not alter or obscure mail headers or assume a sender’s identity without the sender’s explicit permission. You will not collect replies to messages sent from another internet service provider if those messages violate this AUP or the acceptable use policy of that provider. You will not use ANY of our communication tools (forums, messaging, feeds) to market other businesses or opportunities not related to (or approved by) the Service."
        ]
      },
      {
        "heading": "No Security Violations.",
        "paragraphs": [
          "You may not use the Services to violate the security or integrity of any network, computer or communications system, software application, or network or computing device (each, a “System”). Prohibited activities include:",
          [
            "<strong>Unauthorized Access.</strong> Accessing or using any System without permission, including attempting to probe, scan, or test the vulnerability of a System or to breach any security or authentication measures used by a System.",
            "<strong>Interception.</strong> Monitoring of data or traffic on a System without permission.",
            "<strong>Falsification of Origin.</strong> Forging TCP-IP packet headers, e-mail headers, or any part of a message describing its origin or route."
          ]
        ]
      },
      {
        "heading": "No Network Abuse.",
        "paragraphs": [
          "You may not make network connections to any Users, hosts, or networks unless you have permission to communicate with them. Prohibited activities include:",
          [
            "<strong>Monitoring or Crawling.</strong> Monitoring or crawling of a System that impairs or disrupts the System being monitored or crawled.",
            "<strong>Denial of Services (DoS).</strong> Inundating a target with communications requests so the target either cannot respond to legitimate traffic or responds so slowly that it becomes ineffective.",
            "<strong>Intentional Interference.</strong> Interfering with the proper functioning of any System, including any deliberate attempt to overload a system by mail bombing, news bombing, broadcast attacks, or flooding techniques.",
            "<strong>Operation of Certain Network Services.</strong> Operating network services like open proxies, open mail relays, or open recursive domain name servers.",
            "<strong>Avoiding System Restrictions.</strong> Using manual or electronic means to avoid any use limitations placed on a System, such as access and storage restrictions.",
            "<strong>Excessive Use.</strong> In the event any instance uses excessive CPU from activities such as cryptocurrency CPU mining, Siberfy reserves the right to limit the CPU available to your instances in order to maintain a consistent level of performance on all our nodes."
          ]
        ]
      },
      {
        "heading": "Authorized Use.",
        "paragraphs": [
          "You agree to (i) comply with this AUP, (ii) comply with all laws and regulations applicable to you, including, without limitation, criminal laws, privacy laws, intellectual property laws, export control laws, tax laws, and regulatory requirements within your field; and (iii) use the Services in a professional manner."
        ]
      },
      {
        "heading": "Our Monitoring and Enforcement",
        "paragraphs": [
          "We <strong>reserve the right, but do not assume the obligation</strong>, to investigate any violation of this AUP or misuse of the Services. We may:",
          [
            "investigate violations of this AUP or misuse of the Services;",
            "review any User Content, and remove, disable access to, or modify any content or resource (including User Content) that violates this AUP or any other agreement we have with you for use of the Services;",
            "monitor all prohibited actions, investigate, and/or take appropriate action at our sole discretion against you."
          ],
          "If you violate this AUP or any other provision of the <a href=\"#\">Terms of Service</a> or otherwise create liability for us or any other person, including reputational harm, we may (without limitation) and in addition to the above, <strong>terminate or suspend your Account</strong>, your access to any or all Services and the related services or any portion thereof at any time, with or without notice, for violating this AUP. We may also take legal action to enforce our rights, and/or report you to appropriate law enforcement authorities, regulators, or other appropriate third parties. <strong>PLEASE BE AWARE THAT WE COOPERATE WITH LAW ENFORCEMENT AND REPORT SUSPICIOUS AND/OR ILLEGAL ACTIVITY.</strong>",
          "Our reporting may include disclosing appropriate customer information. We also may cooperate with appropriate law enforcement agencies, regulators, or other appropriate third parties to help with the investigation and prosecution of illegal conduct by providing information related to alleged violations of this AUP.",
          "In accordance with our <a href=\"#\">Privacy Policy</a>, we also may share and/or use your information to protect the safety, rights, property, or security of Siberfy, our services, any third party, or the general public; to detect, prevent, or otherwise address fraud, security, cybersecurity, cybercrimes or technical issues; to prevent or stop any activity that Siberfy, in its sole discretion, may consider to be, or to pose a risk of being, illegal, unethical, or legally actionable; to use as evidence in litigation; to conduct audits; and/or to enforce this Acceptable Use Policy, and our <a href=\"#\">Terms of Service</a>.",
          "Siberfy does not control or endorse the Content, messages or information found in the Services and, therefore, Siberfy specifically disclaims any liability with regard to the Services and any actions resulting from your participation in any Service. Siberfy shall have no obligation to maintain any Content after termination of the Services."
        ]
      },
      {
        "heading": "Sample list of Prohibited Activities",
        "paragraphs": [
          "The Services are designed to enable you and your end users to communicate with others via the Internet. You agree to use the Services (and to require your users to use the Services) only to post, send and receive messages and material that is proper and, when applicable, related to the particular Service. By way of example, and not as a limitation, you agree that when you (or your end users are) using a Service, you (and your end users) will not:",
          [
            "Use the Service in connection with unlawful contests, lotteries, or gambling; pyramid schemes, chain letters, junk email, spamming or any duplicative or unsolicited messages (commercial or otherwise).",
            "Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others.",
            "Publish, post, upload, distribute, traffic or disseminate any defamatory, obscene, or otherwise unlawful content, such as child pornography or virtual child pornography.",
            "Publish, post, upload, distribute or disseminate any topic, name, material or information that incites discrimination, hate or violence towards one person or a group because of their belonging to a race, a religion or a nation.",
            "Upload, or otherwise make available files that contain images, photographs, software or other material protected by intellectual property laws, including, by way of example, and not as limitation, copyright or trademark laws (or by rights of privacy or publicity) unless you own or control the rights thereto or have received all necessary consents to do the same.",
            "Use any material or information, including images or photographs, which are made available through the Services in any manner that infringes any copyright, trademark, patent, trade secret, or other proprietary right of any party.",
            "Upload files that contain viruses, Trojan horses, worms, time bombs, cancelbots, corrupted files, or any other similar software or programs that may damage the operation of another's property.",
            "Download any file posted by another user of a Service that you know, or reasonably should know, cannot be legally distributed in such manner.",
            "Falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded.",
            "Restrict or inhibit any other user from using and enjoying the Services.",
            "Violate any code of conduct or other guidelines which may be applicable for any particular Service.",
            "Harvest or otherwise collect information about others, including e-mail addresses, except as needed to operate your site and as permitted in your site's privacy policy (if any).",
            "Violate any applicable laws or regulations.",
            "Create a false identity for the purpose of misleading others.",
            "Host TOR exit nodes.",
            "Use, download or otherwise copy, or provide (whether or not for a fee) to a person or entity any directory of users of the Services or other user or usage information or any portion thereof.",
            "Network probing or port scanning tools are only permitted if explicitly authorized by the destination host and/or network. Unauthorized port scanning, for any reason, is strictly prohibited.",
            "Utilizing bots for the purpose of repeatedly and/or automatically acquiring merchandise and anything similar that violates 3rd party ToS that could result in the blocking of Siberfy IP space or ASN.",
            "Utilizing autodialers or other VOIP based robocalling software.",
            "Siberfy customers are NOT permitted to utilize and deploy our services to conduct and deliver DOS/DDOS mitigation as a service.",
            "Any VoIP or VPN service that requires special licensing from PTA, without prior approval and agreement",
            "Anything that goes against the law of the land of the country where the server is physically hosted",
          ]
        ]
      },
      {
        "heading": "Reporting of Violations of this AUP",
        "paragraphs": [
          "If you become aware of any violation of this AUP, you will immediately notify us and provide us with assistance, as requested, to stop or remedy the violation. To report any violation of this AUP, please contact us at <a href=\"mailto:abuse@siberfy.com\">abuse@siberfy.com</a>."
        ]
      }
    ]
  }

};
