<template>
  <svg width="46" height="60" viewBox="0 0 46 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.2892 55.6313H27.1315L23.827 26.5461C25.0415 25.931 25.5256 24.4491 24.9087 23.2363C24.2936 22.0236 22.8117 21.5395 21.599 22.1529C20.3862 22.7698 19.9021 24.2516 20.5155 25.4644C20.7549 25.931 21.1324 26.3084 21.599 26.5461L18.2892 55.6313Z" fill="#232634"/>
    <path d="M18.2892 55.6313H27.1315L23.827 26.5461C25.0415 25.931 25.5256 24.4491 24.9087 23.2363C24.2936 22.0236 22.8117 21.5395 21.599 22.1529C20.3862 22.7698 19.9021 24.2516 20.5155 25.4644C20.7549 25.931 21.1324 26.3084 21.599 26.5461L18.2892 55.6313Z" fill="url(#icon_windturbine0_linear)"/>
    <path d="M17.7877 60H27.6278L27.3202 57.2809H18.0935L17.7877 60Z" fill="#232634"/>
    <path d="M17.7877 60H27.6278L27.3202 57.2809H18.0935L17.7877 60Z" fill="url(#icon_windturbine1_linear)"/>
    <path d="M24.9087 27.2923C27.806 29.3001 36.6972 34.8816 44.9646 34.2018L45.0013 34.1127C39.9179 27.5474 29.7929 24.7811 26.3556 24C26.3626 24.1206 26.3626 24.2412 26.3556 24.3617C26.3609 25.5116 25.8244 26.5968 24.9087 27.2923Z" fill="#232634"/>
    <path d="M24.9087 27.2923C27.806 29.3001 36.6972 34.8816 44.9646 34.2018L45.0013 34.1127C39.9179 27.5474 29.7929 24.7811 26.3556 24C26.3626 24.1206 26.3626 24.2412 26.3556 24.3617C26.3609 25.5116 25.8244 26.5968 24.9087 27.2923Z" fill="url(#icon_windturbine2_linear)"/>
    <path d="M20.3844 27.2206C19.3901 26.415 18.8921 25.1429 19.0826 23.8777C15.6156 24.5085 5.38226 26.8467 0 33.1865L0.0349498 33.2757C8.28484 34.284 17.405 29.0799 20.3844 27.2206Z" fill="#232634"/>
    <path d="M20.3844 27.2206C19.3901 26.415 18.8921 25.1429 19.0826 23.8777C15.6156 24.5085 5.38226 26.8467 0 33.1865L0.0349498 33.2757C8.28484 34.284 17.405 29.0799 20.3844 27.2206Z" fill="url(#icon_windturbine3_linear)"/>
    <path d="M22.7086 20.6972C23.3377 20.6972 23.9546 20.8598 24.4998 21.1691C25.1691 17.7108 26.7296 7.32722 22.7523 0H22.6579C18.7016 7.31499 20.2412 17.7003 20.9087 21.1691C21.4574 20.8598 22.076 20.6972 22.7086 20.6972Z" fill="#232634"/>
    <path d="M22.7086 20.6972C23.3377 20.6972 23.9546 20.8598 24.4998 21.1691C25.1691 17.7108 26.7296 7.32722 22.7523 0H22.6579C18.7016 7.31499 20.2412 17.7003 20.9087 21.1691C21.4574 20.8598 22.076 20.6972 22.7086 20.6972Z" fill="url(#icon_windturbine4_linear)"/>
    <defs>
      <linearGradient id="icon_windturbine0_linear" x1="22.5007" y1="0" x2="22.5007" y2="60" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="icon_windturbine1_linear" x1="22.5007" y1="0" x2="22.5007" y2="60" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="icon_windturbine2_linear" x1="22.5007" y1="0" x2="22.5007" y2="60" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="icon_windturbine3_linear" x1="22.5007" y1="0" x2="22.5007" y2="60" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="icon_windturbine4_linear" x1="22.5007" y1="0" x2="22.5007" y2="60" gradientUnits="userSpaceOnUse">
        <stop stop-color="#41ED90"/>
        <stop offset="1" stop-color="#0075FF" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>
</template>



<script>
  export default {
    //
  }
</script>
