<template>
  <svg version="1.1" id="Layer_1" height="44" class="fill-segment-light" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;" xml:space="preserve">
    <g>
      <g>
        <path d="M452.602,188.772c-4.24-4.017-10.934-3.833-14.951,0.408c-0.356,0.375-0.719,0.75-1.088,1.119
          c-4.13,4.13-4.13,10.827,0,14.958c2.066,2.065,4.772,3.098,7.479,3.098c2.707,0,5.414-1.033,7.479-3.098
          c0.507-0.507,1.003-1.017,1.491-1.534C457.027,199.481,456.844,192.787,452.602,188.772z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M196.976,236.899c-5.841,0-10.576,4.736-10.576,10.576v5.101c0,5.84,4.735,10.576,10.576,10.576
          s10.576-4.736,10.576-10.576v-5.101C207.552,241.634,202.817,236.899,196.976,236.899z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M241.02,367.803l-3.451-5.363c-3.161-4.913-9.706-6.331-14.618-3.171c-4.913,3.16-6.332,9.705-3.172,14.616l3.451,5.363
          c2.022,3.142,5.427,4.855,8.904,4.855c1.96,0,3.943-0.545,5.713-1.684C242.761,379.26,244.18,372.715,241.02,367.803z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M303.11,125.152c-0.008-0.004-0.017-0.01-0.025-0.014C303.092,125.141,303.1,125.147,303.11,125.152
          C303.109,125.151,303.11,125.152,303.11,125.152z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M477.083,4.832c-1.145-0.41-28.492-9.897-64.434-1.116c-31.149,7.613-74.85,30.88-109.748,97.817
          c-6.847-2.537-13.633-3.558-19.943-3.826l6.74-6.74c4.13-4.13,4.13-10.827,0-14.958c-4.13-4.129-10.827-4.129-14.958,0
          l-8.149,8.149V71.735c0-5.84-4.735-10.576-10.576-10.576c-5.841,0-10.576,4.736-10.576,10.576v12.424l-8.149-8.149
          c-4.13-4.129-10.827-4.129-14.958,0c-4.13,4.13-4.13,10.827,0,14.958l6.74,6.74c-6.31,0.268-13.096,1.287-19.943,3.824
          C174.231,34.596,130.53,11.329,99.381,3.717C63.439-5.068,36.093,4.422,34.947,4.832c-4.653,1.656-7.57,6.31-6.985,11.214
          c0.586,4.905,4.457,8.748,9.368,9.262c0.167,0.02,17.01,2.126,39.66,16.485c20.207,12.81,49.142,38.184,74.307,86.778
          c-9.008-6.216-19.525-10.851-30.861-13.4c-16.302-3.673-32.848-2.553-46.593,3.153c-14.447,5.998-24.945,16.588-29.564,29.822
          c-0.006,0.02-0.013,0.04-0.019,0.059c-0.017,0.049-0.032,0.098-0.048,0.148c-0.928,2.702-1.616,5.536-2.041,8.445
          c-2.231,15.419,3.111,31.383,15.043,44.948c11.316,12.865,27.39,22.165,45.258,26.185c6.317,1.422,12.639,2.123,18.813,2.123
          c9.194,0,18.051-1.555,26.034-4.591c0.127,1.315,0.254,2.63,0.406,3.953c0.002,0.016,0.003,0.032,0.005,0.047
          c0.002,0.02,0.004,0.039,0.006,0.059c2.28,19.711,7.479,39.886,14.637,56.806c3.099,7.333,6.168,14.064,8.876,20.004
          c10.403,22.818,15.23,34.276,11.325,45.274c-9.589,27.02,2.996,44.887,15.192,56.498c0.857,15.611,7.162,63.948,51.418,101.394
          c1.971,1.668,4.402,2.502,6.831,2.502c2.429,0,4.86-0.834,6.831-2.502c44.255-37.447,50.561-85.782,51.418-101.393
          c12.191-11.606,24.781-29.474,15.191-56.5c-3.904-10.996,0.923-22.454,11.325-45.273c2.708-5.94,5.777-12.672,8.876-20.006
          c7.148-16.927,12.347-37.099,14.634-56.795c0.003-0.022,0.004-0.044,0.007-0.067c0.002-0.017,0.003-0.033,0.005-0.05
          c0.153-1.321,0.28-2.636,0.407-3.949c7.979,3.035,16.834,4.589,26.03,4.589c6.171,0,12.499-0.7,18.814-2.123
          c5.44-1.224,10.779-2.958,15.871-5.153c5.364-2.312,7.838-8.534,5.526-13.899c-2.312-5.362-8.536-7.835-13.899-5.526
          c-3.891,1.677-7.977,3.004-12.144,3.942c-10.477,2.361-20.79,2.11-29.607-0.552c12.142-18.436,35.004-47.144,56.319-51.503
          c6.188-1.268,11.656-0.337,16.978,2.911c0.118,0.555,0.242,1.109,0.325,1.675c0.485,3.358,0.319,6.846-0.493,10.367
          c-1.315,5.692,2.235,11.371,7.926,12.684c0.801,0.185,1.601,0.274,2.389,0.274c4.813,0,9.167-3.308,10.296-8.199
          c1.405-6.085,1.68-12.192,0.815-18.171c-0.423-2.895-1.113-5.728-2.041-8.436c-0.015-0.045-0.029-0.091-0.043-0.136
          c-0.007-0.022-0.014-0.045-0.022-0.068c-4.617-13.231-15.116-23.82-29.563-29.818c-13.746-5.706-30.292-6.826-46.589-3.154
          c-11.339,2.55-21.857,7.186-30.865,13.401c25.165-48.595,54.101-73.968,74.307-86.778c22.651-14.359,39.493-16.465,39.597-16.479
          c4.913-0.514,8.814-4.36,9.4-9.264C484.623,11.145,481.736,6.489,477.083,4.832z M107.119,207.294
          c-26.711-6.009-46.865-27.746-44.014-47.452c0.085-0.573,0.208-1.132,0.328-1.691c5.317-3.245,10.779-4.178,16.962-2.914
          c21.258,4.335,44.155,33.063,56.323,51.507C127.899,209.404,117.59,209.652,107.119,207.294z M147.801,185.656
          c-2.622-3.62-5.568-7.495-8.795-11.437c-17.115-20.909-33.925-33.856-50.042-38.6c8.053-1.895,17.317-1.956,26.828,0.188
          c16.052,3.61,30.199,12.932,38.046,24.793C151.034,168.318,149.018,176.69,147.801,185.656z M86.353,22.619
          c2.599,0.435,5.269,0.977,8.006,1.647c37.618,9.193,70.345,38.64,95.149,85.418l-20.404,7.285
          C142.338,65.986,111.33,37.96,86.353,22.619z M256.015,487.148c-20.991-20.454-30.16-43.312-34.158-59.959
          c4.885,3.639,9.414,7.17,13.064,10.773c1.98,1.955,4.649,3.05,7.43,3.05h27.329c2.782,0,5.451-1.096,7.43-3.05
          c3.649-3.602,8.178-7.134,13.064-10.773C286.175,443.836,277.006,466.693,256.015,487.148z M282.937,250.545
          c1.586,0.848,3.292,1.25,4.974,1.25c3.778,0,7.434-2.03,9.338-5.595c7.448-13.943,18.227-22.555,28.836-23.038
          c6.355-0.302,12.06,2.465,16.671,8.001c-2.268,16.438-6.684,32.958-12.586,46.931c-2.984,7.061-5.858,13.366-8.638,19.463
          c-11.003,24.136-18.953,41.576-12.013,61.124c4.985,14.044,1.431,24.332-12.669,36.682c-4.587,4.011-9.895,7.917-15.03,11.695
          c-5.556,4.089-11.267,8.292-16.318,12.802h-18.978c-5.05-4.511-10.761-8.713-16.318-12.802
          c-5.134-3.777-10.443-7.685-15.023-11.689c-14.107-12.356-17.66-22.643-12.677-36.687c6.94-19.549-1.01-36.989-12.013-61.125
          c-2.779-6.097-5.654-12.403-8.639-19.467c-5.908-13.965-10.325-30.481-12.586-46.926c4.61-5.539,10.31-8.317,16.674-8.002
          c10.609,0.483,21.388,9.097,28.836,23.038c1.905,3.565,5.559,5.595,9.338,5.595c1.681,0,3.388-0.402,4.974-1.25
          c5.153-2.752,7.098-9.159,4.346-14.312c-10.997-20.589-28.392-33.376-46.529-34.203c-6.808-0.306-13.361,1.115-19.336,4.043
          c0.098-16.221,2.689-30.442,7.723-42.28c0.039-0.092,0.077-0.185,0.113-0.278c0.399-0.933,0.767-1.748,1.122-2.49
          c0.04-0.085,0.07-0.171,0.108-0.257c0.088-0.197,0.173-0.394,0.25-0.596c0.051-0.134,0.096-0.272,0.141-0.409
          c0.062-0.189,0.123-0.38,0.174-0.573c0.041-0.155,0.077-0.312,0.112-0.47c0.038-0.174,0.075-0.35,0.105-0.527
          c0.03-0.178,0.053-0.357,0.073-0.537c0.018-0.159,0.035-0.316,0.045-0.476c0.014-0.199,0.019-0.4,0.021-0.601
          c0.001-0.144,0.003-0.287-0.001-0.432c-0.006-0.215-0.023-0.43-0.042-0.646c-0.008-0.093-0.007-0.184-0.018-0.277l-2.112-18.04
          l32.52-11.611c0.164-0.059,0.326-0.135,0.489-0.203c0.116-0.049,0.233-0.092,0.347-0.144c0.059-0.026,0.122-0.049,0.179-0.076
          h0.001c0.008-0.004,0.017-0.01,0.025-0.014c0.317-0.153,0.625-0.319,0.92-0.5c0.026-0.016,0.051-0.035,0.076-0.052
          c0.012-0.007,0.023-0.012,0.035-0.019c11.569-7.271,24.257-6.076,35.46-4.997v12.898c0,5.84,4.735,10.576,10.576,10.576
          c5.841,0,10.576-4.736,10.576-10.576v-12.898c11.206-1.079,23.892-2.275,35.46,4.997c0.012,0.007,0.023,0.012,0.035,0.019
          c0.026,0.016,0.05,0.035,0.076,0.052c0.296,0.181,0.603,0.347,0.92,0.5c0.008,0.004,0.017,0.01,0.026,0.014
          c0.058,0.027,0.122,0.05,0.18,0.077c0.115,0.053,0.232,0.096,0.347,0.144c0.018,0.007,0.036,0.015,0.054,0.022
          c0.143,0.058,0.285,0.126,0.428,0.178c0.002,0,0.003,0.001,0.005,0.002l31.827,11.362l-2.877,14.271
          c-0.514,2.548-0.06,5.064,1.1,7.176c0.9,1.71,1.744,3.488,2.518,5.317c0.024,0.059,0.05,0.117,0.075,0.177
          c5.01,11.817,7.59,26.002,7.689,42.195c-5.975-2.928-12.528-4.349-19.336-4.041c-18.137,0.827-35.531,13.614-46.529,34.203
          C275.839,241.386,277.785,247.794,282.937,250.545z M396.243,135.806c9.51-2.143,18.772-2.081,26.823-0.188
          c-16.117,4.746-32.927,17.691-50.042,38.6c-3.227,3.942-6.172,7.817-8.794,11.437c-1.217-8.971-3.232-17.344-6.034-25.054
          C366.038,148.739,380.182,139.417,396.243,135.806z M342.926,116.967l-20.416-7.288c24.747-46.88,56.348-75.507,94.055-85.137
          c3.112-0.795,6.146-1.424,9.092-1.911C400.686,37.973,369.685,65.998,342.926,116.967z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M315.053,236.899c-5.841,0-10.576,4.736-10.576,10.576v5.101c0,5.84,4.735,10.576,10.576,10.576
          s10.576-4.736,10.576-10.576v-5.101C325.63,241.634,320.895,236.899,315.053,236.899z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M289.078,359.269c-4.914-3.161-11.456-1.742-14.618,3.171l-3.451,5.363c-3.16,4.913-1.741,11.456,3.172,14.616
          c1.771,1.139,3.753,1.684,5.713,1.684c3.477,0,6.882-1.712,8.904-4.855l3.451-5.363C295.41,368.973,293.99,362.43,289.078,359.269
          z"/>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    //
  }
</script>
