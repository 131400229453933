<script>
  export default {
    //
  }
</script>



<template>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.66211 25H21.3379C23.3572 25 25 23.3572 25 21.3379V3.66211C25 1.6428 23.3572 0 21.3379 0H3.66211C1.6428 0 0 1.6428 0 3.66211V21.3379C0 23.3572 1.6428 25 3.66211 25ZM1.46484 3.66211C1.46484 2.45056 2.45056 1.46484 3.66211 1.46484H21.3379C22.5494 1.46484 23.5352 2.45056 23.5352 3.66211V21.3379C23.5352 22.5494 22.5494 23.5352 21.3379 23.5352H3.66211C2.45056 23.5352 1.46484 22.5494 1.46484 21.3379V3.66211Z" fill="currentColor"/>
    <path d="M12.5 19.0918C16.1346 19.0918 19.0918 16.1346 19.0918 12.5C19.0918 8.86536 16.1346 5.9082 12.5 5.9082C8.86536 5.9082 5.9082 8.86536 5.9082 12.5C5.9082 16.1346 8.86536 19.0918 12.5 19.0918ZM12.5 7.37305C15.3271 7.37305 17.627 9.67293 17.627 12.5C17.627 15.3271 15.3271 17.627 12.5 17.627C9.67293 17.627 7.37305 15.3271 7.37305 12.5C7.37305 9.67293 9.67293 7.37305 12.5 7.37305Z" fill="currentColor"/>
    <path d="M19.8242 7.37305C21.0358 7.37305 22.0215 6.38733 22.0215 5.17578C22.0215 3.96423 21.0358 2.97852 19.8242 2.97852C18.6127 2.97852 17.627 3.96423 17.627 5.17578C17.627 6.38733 18.6127 7.37305 19.8242 7.37305ZM19.8242 4.44336C20.228 4.44336 20.5566 4.772 20.5566 5.17578C20.5566 5.57957 20.228 5.9082 19.8242 5.9082C19.4204 5.9082 19.0918 5.57957 19.0918 5.17578C19.0918 4.772 19.4204 4.44336 19.8242 4.44336Z" fill="currentColor"/>
  </svg>
</template>
