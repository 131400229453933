<style scoped>
  .gpu-compute-dark { @apply hidden; }
  .gpu-compute-light { @apply block; }
  
  /*
  .gpu-compute-dark { @apply transition opacity-100; }
  .gpu-compute-light { @apply transition opacity-0; }

  svg:hover .gpu-compute-dark { @apply opacity-0; }
  svg:hover .gpu-compute-light { @apply opacity-100; }
  */

  .gpu-compute-0 { fill: rgba(0, 0, 0, .15); }
  .gpu-compute-1 { fill: #20B785; }
  .gpu-compute-2 { fill: #53DCAB; }
  .gpu-compute-3 { fill: #4D5470; }
  .gpu-compute-4 { fill: #323542; }
  .gpu-compute-5 { fill: #474956; }
  .gpu-compute-6 { fill: #191D26; }
  .gpu-compute-7 { fill: #A1B7B2; }
  .gpu-compute-8 { fill: #31DD8B; }
  .gpu-compute-9 { fill: #72FCB0; }
  .gpu-compute-10 { fill: #363F4C; }
  .gpu-compute-12 { display: inline; }
  .gpu-compute-13 { display: inline; fill: #767F91; }
  .gpu-compute-14 { fill: #A3ABC3; }
  .gpu-compute-15 { fill: #282D38; }
  .gpu-compute-16 { fill: #979FAF; }
</style>

<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 379.01 338.55" style="enable-background:new 0 0 379.01 338.55;" xml:space="preserve">
  <g class="gpu-compute-dark">
    <g>
      <path class="gpu-compute-0" d="M173.06,334.64c9.04,5.22,23.84,5.22,32.88,0L359.48,246c9.04-5.22,9.04-13.76,0-18.98l-153.53-88.64
        c-9.04-5.22-23.84-5.22-32.88,0L19.53,227.01c-9.04,5.22-9.04,13.76,0,18.98L173.06,334.64z"/>
    </g>
    <g>
      <g>
        <g>
          <path class="gpu-compute-1" d="M189.51,84.85c5.96,0,11.92,1.31,16.44,3.92l153.53,88.64c9.04,5.22,9.04,13.76,0,18.98l-153.53,88.64
            c-4.52,2.61-10.48,3.92-16.44,3.92c-5.96,0-11.92-1.31-16.44-3.92L19.53,196.39c-9.04-5.22-9.04-13.76,0-18.98l153.53-88.64
            C177.59,86.16,183.55,84.85,189.51,84.85 M189.51,92.85c-4.78,0-9.31,1.04-12.44,2.84L23.53,184.34
            c-2.19,1.26-2.75,2.35-2.78,2.58c0.03,0.2,0.6,1.29,2.78,2.55l153.53,88.64c3.13,1.81,7.66,2.84,12.44,2.84
            c4.78,0,9.31-1.04,12.44-2.84l153.53-88.64c2.19-1.26,2.75-2.35,2.78-2.58c-0.03-0.2-0.6-1.29-2.78-2.55L201.95,95.7
            C198.82,93.89,194.28,92.85,189.51,92.85L189.51,92.85z"/>
        </g>
      </g>
      <g>
        
          <ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 123.25 518.3612)" class="gpu-compute-2" cx="366.26" cy="186.75" rx="12.76" ry="12.76"/>
        
          <ellipse transform="matrix(0.9999 -0.013 0.013 0.9999 -2.4357 0.1823)" class="gpu-compute-2" cx="12.76" cy="186.75" rx="12.76" ry="12.76"/>
        <path class="gpu-compute-2" d="M203.68,286.11c0,7.83-6.35,14.17-14.17,14.17c-7.83,0-14.17-6.34-14.17-14.17
          c0-7.83,6.35-14.17,14.17-14.17C197.33,271.94,203.68,278.28,203.68,286.11z"/>
      </g>
    </g>
    <g>
      <path class="gpu-compute-3" d="M172.18,239.24c9.53,5.5,25.11,5.5,34.64,0l161.75-93.38c9.53-5.5,9.53-14.5,0-20L206.83,32.47
        c-9.53-5.5-25.11-5.5-34.64,0L10.44,125.86c-9.53,5.5-9.53,14.5,0,20L172.18,239.24z"/>
    </g>
    <path class="gpu-compute-4" d="M368.58,117.51L206.82,210.9c-9.53,5.5-25.11,5.5-34.64,0v0L10.44,117.51c-4.76-2.75-7.14-6.37-7.14-10v28.35
      c0,3.62,2.38,7.25,7.14,9.99l6.13,3.54l0,0l155.62,89.84v0c9.53,5.5,25.11,5.5,34.64,0l155.62-89.84l0,0l6.13-3.54
      c4.76-2.75,7.14-6.37,7.14-9.99v-28.35C375.72,111.14,373.34,114.76,368.58,117.51z"/>
    <g>
      <path class="gpu-compute-5" d="M172.18,210.9c9.53,5.5,25.11,5.5,34.64,0l161.75-93.39c9.53-5.5,9.53-14.5,0-20L206.83,4.12
        c-9.53-5.5-25.11-5.5-34.64,0L10.44,97.51c-9.53,5.5-9.53,14.5,0,20L172.18,210.9z"/>
    </g>
    <g>
      <path class="gpu-compute-6" d="M112.24,152.12c42.49,24.53,112.03,24.53,154.52,0c42.5-24.53,42.5-64.68,0-89.22
        c-42.5-24.53-112.03-24.53-154.53,0C69.75,87.44,69.75,127.58,112.24,152.12z"/>
    </g>
    <g>
      <g>
        <path class="gpu-compute-7" d="M343.86,103.68c3.64-2.1,9.61-2.1,13.25,0c3.65,2.11,3.65,5.55,0,7.65c-3.64,2.11-9.61,2.11-13.25,0
          C340.22,109.23,340.22,105.79,343.86,103.68z"/>
      </g>
      <g>
        <path class="gpu-compute-7" d="M182.88,10.74c3.65-2.1,9.61-2.1,13.26,0c3.64,2.1,3.64,5.55,0,7.65c-3.65,2.1-9.61,2.1-13.26,0
          C179.23,16.29,179.23,12.84,182.88,10.74z"/>
      </g>
      <g>
        <path class="gpu-compute-7" d="M182.88,196.63c3.65-2.1,9.61-2.1,13.25,0c3.65,2.1,3.65,5.55,0,7.65c-3.65,2.1-9.61,2.1-13.25,0
          C179.23,202.18,179.23,198.73,182.88,196.63z"/>
      </g>
      <g>
        <path class="gpu-compute-7" d="M21.89,103.68c3.64-2.1,9.61-2.1,13.25,0c3.64,2.1,3.64,5.55,0,7.65c-3.65,2.11-9.61,2.11-13.25,0
          C18.25,109.23,18.25,105.79,21.89,103.68z"/>
      </g>
    </g>
    <g>
      <g>
        <polygon class="gpu-compute-5" points="135.67,199.84 135.67,209.56 131.64,207.23 131.64,197.51 			"/>
        <polygon class="gpu-compute-5" points="123.57,192.85 123.57,202.57 119.54,200.24 119.54,190.52 			"/>
        <polygon class="gpu-compute-5" points="111.48,185.87 111.48,195.58 107.44,193.26 107.44,183.54 			"/>
        <polygon class="gpu-compute-5" points="99.38,178.88 99.38,188.6 95.34,186.27 95.34,176.55 			"/>
        <polygon class="gpu-compute-5" points="87.28,171.9 87.28,181.61 83.24,179.29 83.24,169.57 			"/>
        <polygon class="gpu-compute-5" points="75.18,164.91 75.18,174.63 71.14,172.3 71.14,162.58 			"/>
        <polygon class="gpu-compute-5" points="63.08,157.92 63.08,167.64 59.04,165.32 59.04,155.59 			"/>
        <polygon class="gpu-compute-5" points="50.98,150.94 50.98,160.66 46.94,158.33 46.94,148.61 			"/>
      </g>
      <g>
        <polygon class="gpu-compute-5" points="243.34,199.84 243.34,209.56 247.37,207.23 247.37,197.51 			"/>
        <polygon class="gpu-compute-5" points="255.44,192.85 255.44,202.57 259.47,200.24 259.47,190.52 			"/>
        <polygon class="gpu-compute-5" points="267.54,185.87 267.54,195.58 271.57,193.26 271.57,183.54 			"/>
        <polygon class="gpu-compute-5" points="279.64,178.88 279.64,188.6 283.67,186.27 283.67,176.55 			"/>
        <polygon class="gpu-compute-5" points="291.73,171.9 291.73,181.61 295.77,179.29 295.77,169.57 			"/>
        <polygon class="gpu-compute-5" points="303.83,164.91 303.83,174.63 307.87,172.3 307.87,162.58 			"/>
        <polygon class="gpu-compute-5" points="315.93,157.92 315.93,167.64 319.97,165.32 319.97,155.59 			"/>
        <polygon class="gpu-compute-5" points="328.03,150.94 328.03,160.66 332.07,158.33 332.07,148.61 			"/>
      </g>
    </g>
    <g>
      <g>
        <g>
          <g>
            <path class="gpu-compute-1" d="M122.86,80.63c-0.65-0.17-1.04-0.84-0.87-1.49l0,0c0.77-2.9,3.02-5.53,6.52-7.55l0,0
              c3.92-2.25,8.82-3.46,13.83-3.7l0,0c0.67-0.03,1.24,0.49,1.27,1.16l0,0c0.03,0.67-0.49,1.24-1.16,1.27l0,0
              c-4.69,0.22-9.26,1.37-12.73,3.37l0,0c-3.12,1.8-4.84,3.98-5.39,6.07l0,0c-0.14,0.55-0.64,0.91-1.18,0.91l0,0
              C123.07,80.67,122.96,80.66,122.86,80.63L122.86,80.63z"/>
          </g>
          <path class="gpu-compute-1" d="M175.39,82.89c-0.21-0.64,0.15-1.33,0.79-1.53l0,0c1.28-0.41,2.48-0.93,3.53-1.54l0,0
            c3.11-1.81,4.42-4,4.41-5.98l0,0c0-1.98-1.3-4.16-4.41-5.97l0,0c-0.58-0.34-0.78-1.08-0.45-1.66l0,0
            c0.33-0.58,1.08-0.78,1.66-0.44l0,0c3.58,2.05,5.62,4.96,5.63,8.08l0,0c0,3.11-2.05,6.03-5.63,8.08l0,0
            c-1.23,0.71-2.58,1.29-4.01,1.75l0,0v0c-0.12,0.04-0.25,0.06-0.37,0.06l0,0C176.04,83.73,175.56,83.4,175.39,82.89L175.39,82.89
            z"/>
          <path class="gpu-compute-1" d="M118.1,113.37c-0.34-0.58-0.14-1.33,0.44-1.66l0,0c3.57-2.05,8.12-3.03,12.67-3.03l0,0
            c2.29,0,4.59,0.25,6.75,0.76l0,0c0.65,0.15,1.06,0.81,0.9,1.46l0,0c-0.15,0.65-0.81,1.06-1.46,0.91l0,0
            c-1.97-0.46-4.08-0.7-6.19-0.7l0,0c-4.19,0-8.38,0.93-11.45,2.71l0,0c-0.19,0.11-0.4,0.16-0.61,0.16l0,0
            C118.74,113.97,118.33,113.75,118.1,113.37L118.1,113.37z"/>
          <path class="gpu-compute-1" d="M118.55,127.73c-3.55-2.04-5.58-4.92-5.58-8.01l0,0c0-3.09,2.03-5.98,5.58-8.02l0,0
            c0.89-0.52,1.85-0.96,2.85-1.34l0,0c-0.42-0.92-0.64-1.88-0.64-2.85l0,0c0-0.97,0.22-1.94,0.64-2.86l0,0
            c-1-0.38-1.96-0.82-2.85-1.34l0,0c-3.55-2.04-5.58-4.92-5.58-8.02l0,0c0-3.09,2.03-5.98,5.58-8.02l0,0
            c0.58-0.34,1.33-0.14,1.66,0.45l0,0c0.34,0.58,0.14,1.33-0.44,1.66l0,0c-3.08,1.79-4.37,3.95-4.36,5.91l0,0
            c0,1.95,1.28,4.11,4.36,5.91l0,0c1.13,0.65,2.41,1.19,3.79,1.61l0,0c0.38,0.11,0.67,0.4,0.8,0.78l0,0
            c0.13,0.37,0.06,0.78-0.17,1.1l0,0c-0.68,0.95-0.99,1.89-0.99,2.81l0,0c0,0.92,0.3,1.86,0.99,2.81l0,0
            c0.23,0.32,0.29,0.73,0.17,1.1l0,0c-0.12,0.38-0.42,0.66-0.8,0.78l0,0c-1.38,0.42-2.66,0.96-3.79,1.61l0,0
            c-3.08,1.79-4.37,3.95-4.36,5.91l0,0c0,1.96,1.28,4.11,4.36,5.91l0,0c0.58,0.34,0.78,1.08,0.44,1.66l0,0
            c-0.23,0.39-0.63,0.61-1.06,0.61l0,0C118.95,127.89,118.74,127.84,118.55,127.73L118.55,127.73z"/>
          <path class="gpu-compute-1" d="M195.34,70.4c-1.85-0.45-3.84-0.68-5.84-0.68l0,0c-1.99,0-3.98,0.23-5.83,0.67l0,0
            c-0.43,0.11-0.89-0.03-1.19-0.36l0,0c-0.69-0.76-1.6-1.49-2.77-2.17l0,0c-3.1-1.79-7.33-2.74-11.57-2.74l0,0
            c-4.24,0-8.47,0.94-11.56,2.74l0,0c-0.58,0.34-1.33,0.14-1.66-0.45l0,0c-0.33-0.58-0.14-1.33,0.45-1.66l0,0
            c3.6-2.07,8.19-3.06,12.78-3.06l0,0c4.59,0,9.18,0.99,12.78,3.06l0,0c1.1,0.64,2.06,1.35,2.85,2.12l0,0
            c1.85-0.39,3.78-0.59,5.72-0.59l0,0c1.94,0,3.87,0.2,5.72,0.59l0,0c0.79-0.77,1.75-1.48,2.85-2.12l0,0
            c3.6-2.07,8.19-3.06,12.78-3.06l0,0c4.59,0,9.18,0.99,12.78,3.06l0,0c0.58,0.34,0.78,1.08,0.44,1.66l0,0
            c-0.34,0.58-1.08,0.78-1.66,0.45l0,0c-3.1-1.79-7.33-2.74-11.57-2.74l0,0c-4.23,0-8.47,0.94-11.56,2.74l0,0
            c-1.16,0.67-2.08,1.41-2.77,2.17l0,0c-0.23,0.26-0.56,0.4-0.9,0.4l0,0C195.53,70.43,195.43,70.42,195.34,70.4L195.34,70.4z"/>
          <g>
            <path class="gpu-compute-1" d="M122.5,104.98c-0.55-0.39-0.68-1.14-0.3-1.69l0,0c0.89-1.27,2.18-2.4,3.83-3.36l0,0
              c1.49-0.86,3.18-1.52,4.96-1.98l0,0c0.65-0.17,1.31,0.22,1.48,0.87l0,0c0.17,0.65-0.22,1.31-0.88,1.48l0,0
              c-1.6,0.41-3.08,0.99-4.35,1.73l0,0c-1.41,0.81-2.41,1.73-3.05,2.64l0,0c-0.24,0.34-0.61,0.52-1,0.52l0,0
              C122.96,105.2,122.71,105.13,122.5,104.98L122.5,104.98z"/>
          </g>
          <path class="gpu-compute-1" d="M202.09,83.67c-1.43-0.46-2.78-1.04-4.01-1.75l0,0c-3.58-2.06-5.62-4.97-5.63-8.08l0,0
            c0-3.12,2.05-6.03,5.63-8.08l0,0c0.58-0.34,1.33-0.13,1.66,0.45l0,0c0.33,0.58,0.14,1.33-0.45,1.66l0,0
            c-3.11,1.81-4.41,3.99-4.41,5.97l0,0c0,1.98,1.3,4.16,4.41,5.98l0,0c1.06,0.61,2.26,1.13,3.54,1.54l0,0
            c0.64,0.21,0.99,0.89,0.79,1.53l0,0c-0.17,0.52-0.64,0.84-1.16,0.84l0,0C202.33,83.73,202.21,83.71,202.09,83.67L202.09,83.67z"
            />
        </g>
        <g>
          <g>
            <path class="gpu-compute-1" d="M234.49,145.97c-0.02-0.67,0.52-1.23,1.19-1.25l0,0c4.99-0.11,9.92-1.28,13.59-3.41l0,0
              c3.4-1.96,5.13-4.36,5.51-6.62l0,0c0.11-0.67,0.74-1.11,1.4-1l0,0c0.66,0.11,1.11,0.74,1,1.4l0,0c-0.54,3.2-2.88,6.13-6.7,8.33
              l0,0c-4.16,2.39-9.43,3.6-14.75,3.73l0,0c-0.01,0-0.02,0-0.03,0l0,0C235.05,147.16,234.51,146.63,234.49,145.97L234.49,145.97z
              "/>
          </g>
          <path class="gpu-compute-1" d="M198.08,149.24c-3.58-2.05-5.62-4.97-5.63-8.08l0,0c0-3.12,2.05-6.03,5.63-8.08l0,0
            c1.23-0.71,2.58-1.29,4.01-1.75l0,0c0.64-0.2,1.33,0.15,1.53,0.79l0,0c0.2,0.64-0.15,1.33-0.79,1.53l0,0
            c-1.28,0.41-2.47,0.93-3.54,1.54l0,0c-3.11,1.81-4.41,3.99-4.41,5.98l0,0c0,1.98,1.3,4.17,4.41,5.97l0,0v0
            c0.58,0.33,0.78,1.08,0.44,1.66l0,0c-0.23,0.39-0.63,0.61-1.05,0.61l0,0C198.48,149.41,198.27,149.35,198.08,149.24
            L198.08,149.24z"/>
          <path class="gpu-compute-1" d="M179.27,148.8c-0.34-0.58-0.14-1.33,0.44-1.66l0,0c3.11-1.81,4.41-3.99,4.41-5.97l0,0
            c0-1.98-1.3-4.17-4.41-5.98l0,0c-1.06-0.61-2.26-1.13-3.54-1.54l0,0c-0.64-0.21-0.99-0.89-0.79-1.53l0,0
            c0.21-0.64,0.89-0.99,1.53-0.79l0,0c1.43,0.46,2.78,1.04,4.01,1.75l0,0c3.58,2.06,5.62,4.97,5.63,8.08l0,0
            c0,3.11-2.05,6.03-5.63,8.08l0,0v0c-0.19,0.11-0.4,0.16-0.61,0.16l0,0C179.91,149.41,179.5,149.19,179.27,148.8L179.27,148.8z"
            />
          <path class="gpu-compute-1" d="M246.62,116.09c-0.17-0.65,0.22-1.32,0.87-1.49l0,0c1.6-0.41,3.08-0.99,4.35-1.73l0,0
            c1.41-0.81,2.41-1.73,3.05-2.65l0,0v0c0.39-0.55,1.14-0.69,1.7-0.3l0,0c0.55,0.38,0.69,1.14,0.3,1.69l0,0
            c-0.89,1.27-2.18,2.41-3.83,3.36l0,0c-1.5,0.86-3.17,1.51-4.96,1.98l0,0c-0.1,0.03-0.21,0.04-0.31,0.04l0,0
            C247.25,117,246.76,116.64,246.62,116.09L246.62,116.09z"/>
          <path class="gpu-compute-1" d="M241.1,105.6c-0.66-0.15-1.06-0.81-0.91-1.46l0,0c0.15-0.66,0.8-1.06,1.46-0.91l0,0
            c1.95,0.45,4.04,0.69,6.13,0.69l0,0c4.2,0.01,8.39-0.93,11.46-2.71l0,0h0c0.58-0.34,1.33-0.14,1.66,0.45l0,0
            c0.33,0.58,0.13,1.33-0.45,1.66l0,0c-3.57,2.05-8.13,3.03-12.68,3.03l0,0C245.51,106.35,243.24,106.1,241.1,105.6L241.1,105.6z"
            />
          <path class="gpu-compute-1" d="M198.08,149.24c-1.1-0.64-2.06-1.35-2.85-2.12l0,0c-1.85,0.39-3.79,0.59-5.72,0.59l0,0
            c-1.94,0-3.87-0.19-5.72-0.59l0,0c-0.79,0.77-1.75,1.48-2.85,2.12l0,0c-3.6,2.07-8.19,3.06-12.78,3.06l0,0
            c-4.59-0.01-9.18-0.99-12.78-3.06l0,0c-0.58-0.33-0.78-1.08-0.45-1.66l0,0c0.34-0.58,1.08-0.78,1.66-0.45l0,0
            c3.1,1.79,7.33,2.74,11.57,2.73l0,0c4.24,0,8.47-0.94,11.56-2.73l0,0c1.16-0.67,2.08-1.41,2.77-2.17l0,0
            c0.3-0.33,0.75-0.47,1.19-0.37l0,0c1.85,0.45,3.84,0.67,5.83,0.67l0,0c1.99,0,3.98-0.23,5.84-0.67l0,0
            c0.43-0.11,0.89,0.03,1.19,0.37l0,0c0.68,0.76,1.6,1.49,2.77,2.17l0,0c3.1,1.79,7.33,2.74,11.56,2.73l0,0
            c4.23,0,8.46-0.94,11.57-2.73l0,0c0.58-0.34,1.33-0.13,1.66,0.45l0,0c0.33,0.58,0.14,1.33-0.45,1.66l0,0
            c-3.6,2.07-8.19,3.06-12.78,3.06l0,0C206.27,152.3,201.68,151.31,198.08,149.24L198.08,149.24z"/>
          <path class="gpu-compute-1" d="M258.8,127.29c-0.34-0.58-0.14-1.32,0.45-1.66l0,0c3.08-1.79,4.37-3.95,4.36-5.9l0,0
            c0-1.96-1.28-4.12-4.36-5.91l0,0c-1.13-0.65-2.41-1.19-3.79-1.61l0,0c-0.38-0.12-0.67-0.4-0.8-0.78l0,0
            c-0.13-0.38-0.06-0.78,0.17-1.1l0,0c0.68-0.95,0.99-1.89,0.99-2.81l0,0c0-0.92-0.3-1.86-0.99-2.81l0,0
            c-0.23-0.32-0.29-0.73-0.17-1.1l0,0c0.12-0.37,0.42-0.66,0.8-0.78l0,0c1.38-0.42,2.66-0.96,3.79-1.61l0,0
            c3.08-1.79,4.36-3.95,4.36-5.91l0,0c0-1.96-1.28-4.12-4.36-5.91l0,0c-0.58-0.34-0.78-1.08-0.44-1.66l0,0
            c0.33-0.58,1.08-0.78,1.66-0.45l0,0c3.55,2.04,5.57,4.93,5.58,8.02l0,0c0,3.09-2.03,5.98-5.58,8.02l0,0
            c-0.9,0.52-1.85,0.96-2.85,1.34l0,0c0.42,0.92,0.64,1.88,0.64,2.86l0,0c0,0.97-0.22,1.94-0.64,2.85l0,0
            c1,0.38,1.96,0.82,2.85,1.34l0,0c3.55,2.04,5.58,4.92,5.58,8.02l0,0c0,3.09-2.03,5.98-5.58,8.02l0,0h0
            c-0.19,0.11-0.4,0.16-0.61,0.16l0,0C259.43,127.89,259.02,127.68,258.8,127.29L258.8,127.29z"/>
        </g>
        <g>
          <g>
            <g>
              <path class="gpu-compute-8" d="M250.55,114.2c1.51,0.87,1.51,2.3,0,3.18c-1.51,0.87-3.99,0.87-5.5,0c-1.51-0.87-1.51-2.3,0-3.18
                C246.56,113.33,249.03,113.33,250.55,114.2z"/>
            </g>
            <path class="gpu-compute-8" d="M245.75,115.79c0,1.13,0.92,2.04,2.04,2.04s2.04-0.91,2.04-2.04c0-1.13-0.92-2.04-2.04-2.04
              S245.75,114.66,245.75,115.79z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-8" d="M205.21,130.9c1.51,0.87,1.51,2.3,0,3.18c-1.51,0.87-3.99,0.87-5.5,0c-1.51-0.87-1.51-2.3,0-3.18
                C201.22,130.03,203.69,130.03,205.21,130.9z"/>
            </g>
            <path class="gpu-compute-8" d="M200.41,132.49c0,1.13,0.92,2.04,2.04,2.04c1.13,0,2.04-0.91,2.04-2.04c0-1.13-0.91-2.04-2.04-2.04
              C201.33,130.45,200.41,131.36,200.41,132.49z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-8" d="M179.31,130.9c1.51,0.87,1.51,2.3,0,3.18c-1.51,0.87-3.99,0.87-5.5,0c-1.51-0.87-1.51-2.3,0-3.18
                C175.32,130.03,177.79,130.03,179.31,130.9z"/>
            </g>
            <path class="gpu-compute-8" d="M174.51,132.49c0,1.13,0.91,2.04,2.04,2.04c1.13,0,2.04-0.91,2.04-2.04c0-1.13-0.91-2.04-2.04-2.04
              C175.42,130.45,174.51,131.36,174.51,132.49z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-8" d="M244.13,102.82c1.51,0.87,1.51,2.3,0,3.18c-1.51,0.87-3.99,0.87-5.5,0c-1.51-0.87-1.51-2.3,0-3.18
                C240.14,101.95,242.61,101.95,244.13,102.82z"/>
            </g>
            <path class="gpu-compute-8" d="M239.33,104.41c0,1.13,0.91,2.04,2.04,2.04s2.04-0.91,2.04-2.04c0-1.13-0.91-2.04-2.04-2.04
              S239.33,103.28,239.33,104.41z"/>
          </g>
        </g>
        <g>
          <g>
            <g>
              <ellipse class="gpu-compute-8" cx="131.3" cy="99.12" rx="3.89" ry="2.25"/>
            </g>
          </g>
          <g>
            <g>
              <path class="gpu-compute-8" d="M179.3,80.93c1.51,0.87,1.51,2.3,0,3.18c-1.51,0.87-3.99,0.87-5.5,0c-1.51-0.87-1.51-2.3,0-3.18
                C175.31,80.05,177.79,80.05,179.3,80.93z"/>
            </g>
            <path class="gpu-compute-8" d="M174.51,82.51c0,1.13,0.91,2.04,2.04,2.04c1.13,0,2.04-0.91,2.04-2.04c0-1.13-0.91-2.04-2.04-2.04
              C175.42,80.47,174.51,81.39,174.51,82.51z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-8" d="M199.71,80.93c-1.51,0.87-1.51,2.3,0,3.18c1.51,0.87,3.99,0.87,5.5,0c1.51-0.87,1.51-2.3,0-3.18
                C203.7,80.05,201.22,80.05,199.71,80.93z"/>
            </g>
            <path class="gpu-compute-8" d="M204.5,82.51c0,1.13-0.91,2.04-2.04,2.04c-1.13,0-2.04-0.91-2.04-2.04c0-1.13,0.92-2.04,2.04-2.04
              C203.59,80.47,204.5,81.39,204.5,82.51z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-8" d="M140.44,109.03c1.51,0.87,1.51,2.3,0,3.18c-1.51,0.87-3.99,0.87-5.5,0c-1.51-0.88-1.51-2.3,0-3.18
                C136.45,108.15,138.93,108.15,140.44,109.03z"/>
            </g>
            <path class="gpu-compute-8" d="M135.65,110.61c0,1.13,0.91,2.04,2.04,2.04c1.13,0,2.04-0.91,2.04-2.04c0-1.13-0.91-2.04-2.04-2.04
              C136.56,108.57,135.65,109.49,135.65,110.61z"/>
          </g>
        </g>
        <g>
          <path class="gpu-compute-2" d="M207.88,96.9c-0.34-0.58-0.14-1.33,0.45-1.66l0,0l16.58-9.57c0.58-0.34,1.33-0.14,1.66,0.44l0,0
            c0.34,0.58,0.14,1.33-0.45,1.66l0,0l-16.58,9.57c-0.19,0.11-0.4,0.16-0.61,0.16l0,0C208.51,97.51,208.11,97.29,207.88,96.9
            L207.88,96.9z"/>
          <path class="gpu-compute-2" d="M154.23,127.87c-0.34-0.58-0.14-1.33,0.45-1.66l0,0l14.79-8.54c0.58-0.33,1.33-0.14,1.66,0.45l0,0
            c0.34,0.58,0.14,1.33-0.45,1.66l0,0l-14.79,8.54c-0.19,0.11-0.4,0.16-0.61,0.16l0,0C154.87,128.48,154.46,128.26,154.23,127.87
            L154.23,127.87z"/>
          <path class="gpu-compute-2" d="M214.35,100.64c-0.33-0.58-0.13-1.33,0.45-1.66l0,0l6.76-3.9h12.96l19.15-11.05
            c0.58-0.34,1.33-0.14,1.66,0.44l0,0c0.33,0.58,0.13,1.33-0.45,1.66l0,0L235.17,97.5h-12.96l-6.19,3.58
            c-0.19,0.11-0.4,0.16-0.61,0.16l0,0C214.99,101.25,214.58,101.03,214.35,100.64L214.35,100.64z"/>
          <path class="gpu-compute-2" d="M201.4,93.16c-0.34-0.58-0.14-1.33,0.45-1.66l0,0l12.34-7.13v-7.48l13.56-7.83
            c0.58-0.34,1.33-0.14,1.67,0.45l0,0c0.33,0.58,0.13,1.33-0.45,1.66l0,0l-12.34,7.13v7.48l-13.56,7.83
            c-0.19,0.11-0.4,0.16-0.61,0.16l0,0C202.04,93.77,201.63,93.55,201.4,93.16L201.4,93.16z"/>
          <path class="gpu-compute-2" d="M149.59,145.51c-0.34-0.58-0.14-1.33,0.45-1.66l0,0l12.34-7.13v-7.48l13.56-7.83h0
            c0.58-0.33,1.33-0.13,1.66,0.45l0,0c0.33,0.58,0.14,1.33-0.45,1.66l0,0l-12.35,7.13v7.48l-13.56,7.83
            c-0.19,0.11-0.4,0.16-0.61,0.16l0,0C150.22,146.12,149.81,145.9,149.59,145.51L149.59,145.51z"/>
          <path class="gpu-compute-2" d="M123.68,130.55c-0.34-0.58-0.14-1.33,0.45-1.66l0,0l19.71-11.38l12.95,0l6.19-3.58v0
            c0.58-0.34,1.33-0.14,1.66,0.44l0,0c0.34,0.58,0.14,1.33-0.44,1.67l0,0l-6.76,3.9l-12.95,0L125.34,131
            c-0.19,0.11-0.4,0.16-0.61,0.16l0,0C124.32,131.16,123.91,130.94,123.68,130.55L123.68,130.55z"/>
          <path class="gpu-compute-2" d="M169.47,97.34l-25.91-14.96c-0.58-0.34-0.78-1.08-0.45-1.66l0,0c0.34-0.58,1.08-0.78,1.66-0.44l0,0
            l25.91,14.96c0.58,0.34,0.78,1.08,0.44,1.66l0,0c-0.23,0.39-0.63,0.61-1.05,0.61l0,0C169.87,97.5,169.66,97.45,169.47,97.34
            L169.47,97.34z"/>
          <path class="gpu-compute-2" d="M234.23,134.74l-25.91-14.96c-0.58-0.34-0.78-1.08-0.44-1.66l0,0c0.34-0.58,1.08-0.78,1.66-0.45l0,0
            l25.91,14.96c0.58,0.34,0.78,1.08,0.45,1.66l0,0c-0.23,0.39-0.63,0.61-1.06,0.61l0,0C234.64,134.9,234.43,134.85,234.23,134.74
            L234.23,134.74z"/>
          <path class="gpu-compute-2" d="M162.99,101.08l-6.19-3.58h-12.96l-19.71-11.38c-0.58-0.34-0.78-1.08-0.45-1.66l0,0
            c0.34-0.58,1.08-0.78,1.66-0.45l0,0l19.14,11.06h12.96l6.76,3.9c0.58,0.34,0.78,1.08,0.45,1.66l0,0
            c-0.23,0.39-0.64,0.61-1.06,0.61l0,0C163.39,101.25,163.18,101.19,162.99,101.08L162.99,101.08z"/>
          <path class="gpu-compute-2" d="M175.94,93.6l-13.56-7.83l0-7.48l-12.34-7.13c-0.58-0.34-0.78-1.08-0.45-1.66l0,0
            c0.33-0.58,1.08-0.78,1.66-0.45l0,0l13.56,7.83v7.48l12.34,7.13v0c0.58,0.33,0.78,1.08,0.45,1.66l0,0
            c-0.23,0.39-0.64,0.61-1.06,0.61l0,0C176.34,93.77,176.13,93.71,175.94,93.6L175.94,93.6z"/>
          <path class="gpu-compute-2" d="M227.76,145.96l-13.56-7.83l0-7.48l-12.34-7.12c-0.58-0.34-0.78-1.08-0.45-1.66l0,0
            c0.34-0.58,1.08-0.78,1.66-0.45l0,0l13.56,7.83v7.48l12.35,7.13c0.58,0.34,0.78,1.08,0.45,1.66l0,0
            c-0.23,0.39-0.64,0.61-1.06,0.61l0,0C228.16,146.12,227.95,146.06,227.76,145.96L227.76,145.96z"/>
          <path class="gpu-compute-2" d="M253.66,131l-19.15-11.06h-12.95l-6.76-3.9c-0.58-0.34-0.78-1.08-0.45-1.66l0,0
            c0.34-0.58,1.08-0.78,1.66-0.45l0,0l6.19,3.58h12.95l19.71,11.38c0.58,0.33,0.78,1.08,0.45,1.66l0,0
            c-0.23,0.39-0.64,0.61-1.06,0.61l0,0C254.06,131.16,253.85,131.11,253.66,131L253.66,131z"/>
        </g>
        <g>
          <g>
            <g>
              <path class="gpu-compute-9" d="M152.42,125.61c-1.57,0.91-1.57,2.4,0,3.31c1.58,0.91,4.16,0.91,5.73,0c1.58-0.91,1.58-2.4,0-3.31
                C156.57,124.7,154,124.7,152.42,125.61z"/>
            </g>
            <path class="gpu-compute-9" d="M157.41,127.26c0,1.18-0.95,2.13-2.13,2.13c-1.17,0-2.13-0.95-2.13-2.13c0-1.17,0.95-2.13,2.13-2.13
              C156.46,125.14,157.41,126.09,157.41,127.26z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-9" d="M121.87,128.29c-1.58,0.91-1.58,2.4,0,3.31c1.58,0.91,4.15,0.91,5.73,0c1.57-0.91,1.57-2.4,0-3.31
                C126.02,127.38,123.45,127.38,121.87,128.29z"/>
            </g>
            <path class="gpu-compute-9" d="M126.86,129.94c0,1.17-0.95,2.13-2.13,2.13c-1.17,0-2.13-0.95-2.13-2.13c0-1.18,0.95-2.13,2.13-2.13
              C125.91,127.82,126.86,128.77,126.86,129.94z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-9" d="M147.78,143.24c-1.57,0.91-1.57,2.4,0,3.31c1.58,0.91,4.15,0.91,5.73,0c1.58-0.91,1.58-2.4,0-3.31
                C151.93,142.34,149.35,142.34,147.78,143.24z"/>
            </g>
            <path class="gpu-compute-9" d="M152.77,144.9c0,1.17-0.95,2.13-2.13,2.13c-1.18,0-2.13-0.95-2.13-2.13c0-1.18,0.95-2.13,2.13-2.13
              C151.82,142.77,152.77,143.72,152.77,144.9z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-9" d="M251.41,83.42c-1.58,0.91-1.58,2.4,0,3.31c1.58,0.91,4.16,0.91,5.73,0c1.58-0.91,1.58-2.4,0-3.31
                C255.56,82.51,252.98,82.51,251.41,83.42z"/>
            </g>
            <path class="gpu-compute-9" d="M256.4,85.07c0,1.18-0.95,2.13-2.13,2.13c-1.18,0-2.13-0.95-2.13-2.13s0.95-2.13,2.13-2.13
              C255.45,82.94,256.4,83.9,256.4,85.07z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-9" d="M222.65,85.06c-1.57,0.91-1.57,2.4,0,3.31c1.58,0.91,4.16,0.91,5.73,0c1.57-0.91,1.57-2.4,0-3.31
                C226.8,84.15,224.23,84.15,222.65,85.06z"/>
            </g>
            <path class="gpu-compute-9" d="M227.64,86.72c0,1.17-0.95,2.13-2.13,2.13c-1.17,0-2.12-0.95-2.12-2.13c0-1.18,0.95-2.13,2.12-2.13
              C226.69,84.59,227.64,85.54,227.64,86.72z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-9" d="M225.5,68.46c-1.58,0.91-1.58,2.4,0,3.31c1.58,0.91,4.15,0.91,5.73,0c1.58-0.91,1.58-2.4,0-3.31
                C229.65,67.55,227.08,67.55,225.5,68.46z"/>
            </g>
            <path class="gpu-compute-9" d="M230.49,70.11c0,1.18-0.95,2.13-2.13,2.13c-1.18,0-2.13-0.95-2.13-2.13s0.95-2.13,2.13-2.13
              C229.54,67.99,230.49,68.94,230.49,70.11z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-9" d="M237.71,132.03c1.58,0.91,1.58,2.4,0,3.31c-1.58,0.91-4.15,0.91-5.73,0c-1.57-0.91-1.57-2.4,0-3.31
                C233.56,131.12,236.13,131.12,237.71,132.03z"/>
            </g>
            <path class="gpu-compute-9" d="M232.72,133.68c0,1.17,0.95,2.12,2.13,2.12c1.18,0,2.13-0.95,2.13-2.12c0-1.18-0.95-2.13-2.13-2.13
              C233.67,131.56,232.72,132.51,232.72,133.68z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-9" d="M257.14,128.29c1.58,0.91,1.58,2.4,0,3.31c-1.58,0.91-4.16,0.91-5.73,0c-1.58-0.91-1.58-2.4,0-3.31
                C252.98,127.38,255.56,127.38,257.14,128.29z"/>
            </g>
            <path class="gpu-compute-9" d="M252.15,129.94c0,1.17,0.95,2.13,2.13,2.13c1.18,0,2.13-0.95,2.13-2.13c0-1.18-0.95-2.13-2.13-2.13
              C253.1,127.82,252.15,128.77,252.15,129.94z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-9" d="M231.23,143.24c1.58,0.91,1.58,2.4,0,3.31c-1.58,0.91-4.15,0.91-5.73,0c-1.58-0.91-1.58-2.4,0-3.31
                C227.08,142.34,229.65,142.34,231.23,143.24z"/>
            </g>
            <path class="gpu-compute-9" d="M226.24,144.9c0,1.17,0.95,2.13,2.13,2.13c1.18,0,2.13-0.95,2.13-2.13c0-1.18-0.95-2.13-2.13-2.13
              C227.19,142.77,226.24,143.72,226.24,144.9z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-9" d="M127.6,83.42c1.58,0.91,1.58,2.4,0,3.31c-1.58,0.91-4.15,0.91-5.73,0c-1.58-0.91-1.58-2.4,0-3.31
                C123.45,82.51,126.03,82.51,127.6,83.42z"/>
            </g>
            <path class="gpu-compute-9" d="M122.61,85.07c0,1.18,0.95,2.13,2.13,2.13c1.18,0,2.13-0.95,2.13-2.13s-0.95-2.13-2.13-2.13
              C123.56,82.94,122.61,83.9,122.61,85.07z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-9" d="M147.03,79.68c1.58,0.91,1.58,2.4,0,3.31c-1.58,0.91-4.15,0.91-5.73,0c-1.57-0.91-1.57-2.4,0-3.31
                C142.88,78.77,145.46,78.77,147.03,79.68z"/>
            </g>
            <path class="gpu-compute-9" d="M142.04,81.33c0,1.18,0.95,2.13,2.13,2.13c1.17,0,2.13-0.95,2.13-2.13c0-1.18-0.95-2.13-2.13-2.13
              C142.99,79.2,142.04,80.16,142.04,81.33z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-9" d="M153.51,68.46c1.58,0.91,1.58,2.4,0,3.31c-1.58,0.91-4.15,0.91-5.73,0c-1.58-0.91-1.58-2.4,0-3.31
                C149.36,67.55,151.93,67.55,153.51,68.46z"/>
            </g>
            <path class="gpu-compute-9" d="M148.52,70.11c0,1.18,0.95,2.13,2.13,2.13c1.18,0,2.13-0.95,2.13-2.13s-0.95-2.13-2.13-2.13
              C149.47,67.99,148.52,68.94,148.52,70.11z"/>
          </g>
        </g>
        <g>
          <path class="gpu-compute-9" d="M187.08,122c1.33,0.77,3.52,0.77,4.86,0l22.67-13.09c1.33-0.77,1.33-2.03,0-2.8l-22.67-13.09
            c-1.33-0.77-3.52-0.77-4.86,0l-22.67,13.09c-1.34,0.77-1.34,2.03,0,2.8L187.08,122z"/>
        </g>
      </g>
      <g>
        <path class="gpu-compute-10" d="M193.24,102.64l-7.17,8.96l-2.27-1.31l1.67-2.01l-4.13-2.38l-3.42,1l-2.21-1.28l15.33-4.25L193.24,102.64z
          M183.88,105.15l2.82,1.63l2.77-3.26L183.88,105.15"/>
        <polygon class="gpu-compute-10" points="201.64,107.49 190.34,114.05 188.25,112.85 199.55,106.28 			"/>
      </g>
    </g>
  </g>
  <g class="gpu-compute-light">
    <g class="gpu-compute-12">
      <path class="gpu-compute-0" d="M173.06,334.64c9.04,5.22,23.84,5.22,32.88,0L359.48,246c9.04-5.22,9.04-13.76,0-18.98l-153.53-88.64
        c-9.04-5.22-23.84-5.22-32.88,0L19.53,227.01c-9.04,5.22-9.04,13.76,0,18.98L173.06,334.64z"/>
    </g>
    <g class="gpu-compute-12">
      <g>
        <g>
          <path class="gpu-compute-1" d="M189.51,84.85c5.96,0,11.92,1.31,16.44,3.92l153.53,88.64c9.04,5.22,9.04,13.76,0,18.98l-153.53,88.64
            c-4.52,2.61-10.48,3.92-16.44,3.92c-5.96,0-11.92-1.31-16.44-3.92L19.53,196.39c-9.04-5.22-9.04-13.76,0-18.98l153.53-88.64
            C177.59,86.16,183.55,84.85,189.51,84.85 M189.51,92.85c-4.78,0-9.31,1.04-12.44,2.84L23.53,184.34
            c-2.19,1.26-2.75,2.35-2.78,2.58c0.03,0.2,0.6,1.29,2.78,2.55l153.53,88.64c3.13,1.81,7.66,2.84,12.44,2.84
            c4.78,0,9.31-1.04,12.44-2.84l153.53-88.64c2.19-1.26,2.75-2.35,2.78-2.58c-0.03-0.2-0.6-1.29-2.78-2.55L201.95,95.7
            C198.82,93.89,194.28,92.85,189.51,92.85L189.51,92.85z"/>
        </g>
      </g>
      <g>
        
          <ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 123.25 518.3612)" class="gpu-compute-2" cx="366.26" cy="186.75" rx="12.76" ry="12.76"/>
        
          <ellipse transform="matrix(0.9999 -0.013 0.013 0.9999 -2.4357 0.1823)" class="gpu-compute-2" cx="12.76" cy="186.75" rx="12.76" ry="12.76"/>
        <path class="gpu-compute-2" d="M203.68,286.11c0,7.83-6.35,14.17-14.17,14.17c-7.83,0-14.17-6.34-14.17-14.17
          c0-7.83,6.35-14.17,14.17-14.17C197.33,271.94,203.68,278.28,203.68,286.11z"/>
      </g>
    </g>
    <g class="gpu-compute-12">
      <path class="gpu-compute-3" d="M172.18,239.24c9.53,5.5,25.11,5.5,34.64,0l161.75-93.38c9.53-5.5,9.53-14.5,0-20L206.83,32.47
        c-9.53-5.5-25.11-5.5-34.64,0L10.44,125.86c-9.53,5.5-9.53,14.5,0,20L172.18,239.24z"/>
    </g>
    <path class="gpu-compute-13" d="M368.58,117.51L206.82,210.9c-9.53,5.5-25.11,5.5-34.64,0v0L10.44,117.51c-4.76-2.75-7.14-6.37-7.14-10v28.35
      c0,3.62,2.38,7.25,7.14,9.99l6.13,3.54l0,0l155.62,89.84v0c9.53,5.5,25.11,5.5,34.64,0l155.62-89.84l0,0l6.13-3.54
      c4.76-2.75,7.14-6.37,7.14-9.99v-28.35C375.72,111.14,373.34,114.76,368.58,117.51z"/>
    <g class="gpu-compute-12">
      <path class="gpu-compute-14" d="M172.18,210.9c9.53,5.5,25.11,5.5,34.64,0l161.75-93.39c9.53-5.5,9.53-14.5,0-20L206.83,4.12
        c-9.53-5.5-25.11-5.5-34.64,0L10.44,97.51c-9.53,5.5-9.53,14.5,0,20L172.18,210.9z"/>
    </g>
    <g class="gpu-compute-12">
      <path class="gpu-compute-15" d="M112.24,152.12c42.49,24.53,112.03,24.53,154.52,0c42.5-24.53,42.5-64.68,0-89.22
        c-42.5-24.53-112.03-24.53-154.53,0C69.75,87.44,69.75,127.58,112.24,152.12z"/>
    </g>
    <g class="gpu-compute-12">
      <g>
        <path class="gpu-compute-5" d="M343.86,103.68c3.64-2.1,9.61-2.1,13.25,0c3.65,2.11,3.65,5.55,0,7.65c-3.64,2.11-9.61,2.11-13.25,0
          C340.22,109.23,340.22,105.79,343.86,103.68z"/>
      </g>
      <g>
        <path class="gpu-compute-5" d="M182.88,10.74c3.65-2.1,9.61-2.1,13.26,0c3.64,2.1,3.64,5.55,0,7.65c-3.65,2.1-9.61,2.1-13.26,0
          C179.23,16.29,179.23,12.84,182.88,10.74z"/>
      </g>
      <g>
        <path class="gpu-compute-5" d="M182.88,196.63c3.65-2.1,9.61-2.1,13.25,0c3.65,2.1,3.65,5.55,0,7.65c-3.65,2.1-9.61,2.1-13.25,0
          C179.23,202.18,179.23,198.73,182.88,196.63z"/>
      </g>
      <g>
        <path class="gpu-compute-5" d="M21.89,103.68c3.64-2.1,9.61-2.1,13.25,0c3.64,2.1,3.64,5.55,0,7.65c-3.65,2.11-9.61,2.11-13.25,0
          C18.25,109.23,18.25,105.79,21.89,103.68z"/>
      </g>
    </g>
    <g class="gpu-compute-12">
      <g>
        <polygon class="gpu-compute-16" points="135.67,199.84 135.67,209.56 131.64,207.23 131.64,197.51 			"/>
        <polygon class="gpu-compute-16" points="123.57,192.85 123.57,202.57 119.54,200.24 119.54,190.52 			"/>
        <polygon class="gpu-compute-16" points="111.48,185.87 111.48,195.58 107.44,193.26 107.44,183.54 			"/>
        <polygon class="gpu-compute-16" points="99.38,178.88 99.38,188.6 95.34,186.27 95.34,176.55 			"/>
        <polygon class="gpu-compute-16" points="87.28,171.9 87.28,181.61 83.24,179.29 83.24,169.57 			"/>
        <polygon class="gpu-compute-16" points="75.18,164.91 75.18,174.63 71.14,172.3 71.14,162.58 			"/>
        <polygon class="gpu-compute-16" points="63.08,157.92 63.08,167.64 59.04,165.32 59.04,155.59 			"/>
        <polygon class="gpu-compute-16" points="50.98,150.94 50.98,160.66 46.94,158.33 46.94,148.61 			"/>
      </g>
      <g>
        <polygon class="gpu-compute-16" points="243.34,199.84 243.34,209.56 247.37,207.23 247.37,197.51 			"/>
        <polygon class="gpu-compute-16" points="255.44,192.85 255.44,202.57 259.47,200.24 259.47,190.52 			"/>
        <polygon class="gpu-compute-16" points="267.54,185.87 267.54,195.58 271.57,193.26 271.57,183.54 			"/>
        <polygon class="gpu-compute-16" points="279.64,178.88 279.64,188.6 283.67,186.27 283.67,176.55 			"/>
        <polygon class="gpu-compute-16" points="291.73,171.9 291.73,181.61 295.77,179.29 295.77,169.57 			"/>
        <polygon class="gpu-compute-16" points="303.83,164.91 303.83,174.63 307.87,172.3 307.87,162.58 			"/>
        <polygon class="gpu-compute-16" points="315.93,157.92 315.93,167.64 319.97,165.32 319.97,155.59 			"/>
        <polygon class="gpu-compute-16" points="328.03,150.94 328.03,160.66 332.07,158.33 332.07,148.61 			"/>
      </g>
    </g>
    <g class="gpu-compute-12">
      <g>
        <g>
          <g>
            <path class="gpu-compute-1" d="M122.86,80.63c-0.65-0.17-1.04-0.84-0.87-1.49l0,0c0.77-2.9,3.02-5.53,6.52-7.55l0,0
              c3.92-2.25,8.82-3.46,13.83-3.7l0,0c0.67-0.03,1.24,0.49,1.27,1.16l0,0c0.03,0.67-0.49,1.24-1.16,1.27l0,0
              c-4.69,0.22-9.26,1.37-12.73,3.37l0,0c-3.12,1.8-4.84,3.98-5.39,6.07l0,0c-0.14,0.55-0.64,0.91-1.18,0.91l0,0
              C123.07,80.67,122.96,80.66,122.86,80.63L122.86,80.63z"/>
          </g>
          <path class="gpu-compute-1" d="M175.39,82.89c-0.21-0.64,0.15-1.33,0.79-1.53l0,0c1.28-0.41,2.48-0.93,3.53-1.54l0,0
            c3.11-1.81,4.42-4,4.41-5.98l0,0c0-1.98-1.3-4.16-4.41-5.97l0,0c-0.58-0.34-0.78-1.08-0.45-1.66l0,0
            c0.33-0.58,1.08-0.78,1.66-0.44l0,0c3.58,2.05,5.62,4.96,5.63,8.08l0,0c0,3.11-2.05,6.03-5.63,8.08l0,0
            c-1.23,0.71-2.58,1.29-4.01,1.75l0,0v0c-0.12,0.04-0.25,0.06-0.37,0.06l0,0C176.04,83.73,175.56,83.4,175.39,82.89L175.39,82.89
            z"/>
          <path class="gpu-compute-1" d="M118.1,113.37c-0.34-0.58-0.14-1.33,0.44-1.66l0,0c3.57-2.05,8.12-3.03,12.67-3.03l0,0
            c2.29,0,4.59,0.25,6.75,0.76l0,0c0.65,0.15,1.06,0.81,0.9,1.46l0,0c-0.15,0.65-0.81,1.06-1.46,0.91l0,0
            c-1.97-0.46-4.08-0.7-6.19-0.7l0,0c-4.19,0-8.38,0.93-11.45,2.71l0,0c-0.19,0.11-0.4,0.16-0.61,0.16l0,0
            C118.74,113.97,118.33,113.75,118.1,113.37L118.1,113.37z"/>
          <path class="gpu-compute-1" d="M118.55,127.73c-3.55-2.04-5.58-4.92-5.58-8.01l0,0c0-3.09,2.03-5.98,5.58-8.02l0,0
            c0.89-0.52,1.85-0.96,2.85-1.34l0,0c-0.42-0.92-0.64-1.88-0.64-2.85l0,0c0-0.97,0.22-1.94,0.64-2.86l0,0
            c-1-0.38-1.96-0.82-2.85-1.34l0,0c-3.55-2.04-5.58-4.92-5.58-8.02l0,0c0-3.09,2.03-5.98,5.58-8.02l0,0
            c0.58-0.34,1.33-0.14,1.66,0.45l0,0c0.34,0.58,0.14,1.33-0.44,1.66l0,0c-3.08,1.79-4.37,3.95-4.36,5.91l0,0
            c0,1.95,1.28,4.11,4.36,5.91l0,0c1.13,0.65,2.41,1.19,3.79,1.61l0,0c0.38,0.11,0.67,0.4,0.8,0.78l0,0
            c0.13,0.37,0.06,0.78-0.17,1.1l0,0c-0.68,0.95-0.99,1.89-0.99,2.81l0,0c0,0.92,0.3,1.86,0.99,2.81l0,0
            c0.23,0.32,0.29,0.73,0.17,1.1l0,0c-0.12,0.38-0.42,0.66-0.8,0.78l0,0c-1.38,0.42-2.66,0.96-3.79,1.61l0,0
            c-3.08,1.79-4.37,3.95-4.36,5.91l0,0c0,1.96,1.28,4.11,4.36,5.91l0,0c0.58,0.34,0.78,1.08,0.44,1.66l0,0
            c-0.23,0.39-0.63,0.61-1.06,0.61l0,0C118.95,127.89,118.74,127.84,118.55,127.73L118.55,127.73z"/>
          <path class="gpu-compute-1" d="M195.34,70.4c-1.85-0.45-3.84-0.68-5.84-0.68l0,0c-1.99,0-3.98,0.23-5.83,0.67l0,0
            c-0.43,0.11-0.89-0.03-1.19-0.36l0,0c-0.69-0.76-1.6-1.49-2.77-2.17l0,0c-3.1-1.79-7.33-2.74-11.57-2.74l0,0
            c-4.24,0-8.47,0.94-11.56,2.74l0,0c-0.58,0.34-1.33,0.14-1.66-0.45l0,0c-0.33-0.58-0.14-1.33,0.45-1.66l0,0
            c3.6-2.07,8.19-3.06,12.78-3.06l0,0c4.59,0,9.18,0.99,12.78,3.06l0,0c1.1,0.64,2.06,1.35,2.85,2.12l0,0
            c1.85-0.39,3.78-0.59,5.72-0.59l0,0c1.94,0,3.87,0.2,5.72,0.59l0,0c0.79-0.77,1.75-1.48,2.85-2.12l0,0
            c3.6-2.07,8.19-3.06,12.78-3.06l0,0c4.59,0,9.18,0.99,12.78,3.06l0,0c0.58,0.34,0.78,1.08,0.44,1.66l0,0
            c-0.34,0.58-1.08,0.78-1.66,0.45l0,0c-3.1-1.79-7.33-2.74-11.57-2.74l0,0c-4.23,0-8.47,0.94-11.56,2.74l0,0
            c-1.16,0.67-2.08,1.41-2.77,2.17l0,0c-0.23,0.26-0.56,0.4-0.9,0.4l0,0C195.53,70.43,195.43,70.42,195.34,70.4L195.34,70.4z"/>
          <g>
            <path class="gpu-compute-1" d="M122.5,104.98c-0.55-0.39-0.68-1.14-0.3-1.69l0,0c0.89-1.27,2.18-2.4,3.83-3.36l0,0
              c1.49-0.86,3.18-1.52,4.96-1.98l0,0c0.65-0.17,1.31,0.22,1.48,0.87l0,0c0.17,0.65-0.22,1.31-0.88,1.48l0,0
              c-1.6,0.41-3.08,0.99-4.35,1.73l0,0c-1.41,0.81-2.41,1.73-3.05,2.64l0,0c-0.24,0.34-0.61,0.52-1,0.52l0,0
              C122.96,105.2,122.71,105.13,122.5,104.98L122.5,104.98z"/>
          </g>
          <path class="gpu-compute-1" d="M202.09,83.67c-1.43-0.46-2.78-1.04-4.01-1.75l0,0c-3.58-2.06-5.62-4.97-5.63-8.08l0,0
            c0-3.12,2.05-6.03,5.63-8.08l0,0c0.58-0.34,1.33-0.13,1.66,0.45l0,0c0.33,0.58,0.14,1.33-0.45,1.66l0,0
            c-3.11,1.81-4.41,3.99-4.41,5.97l0,0c0,1.98,1.3,4.16,4.41,5.98l0,0c1.06,0.61,2.26,1.13,3.54,1.54l0,0
            c0.64,0.21,0.99,0.89,0.79,1.53l0,0c-0.17,0.52-0.64,0.84-1.16,0.84l0,0C202.33,83.73,202.21,83.71,202.09,83.67L202.09,83.67z"
            />
        </g>
        <g>
          <g>
            <path class="gpu-compute-1" d="M234.49,145.97c-0.02-0.67,0.52-1.23,1.19-1.25l0,0c4.99-0.11,9.92-1.28,13.59-3.41l0,0
              c3.4-1.96,5.13-4.36,5.51-6.62l0,0c0.11-0.67,0.74-1.11,1.4-1l0,0c0.66,0.11,1.11,0.74,1,1.4l0,0c-0.54,3.2-2.88,6.13-6.7,8.33
              l0,0c-4.16,2.39-9.43,3.6-14.75,3.73l0,0c-0.01,0-0.02,0-0.03,0l0,0C235.05,147.16,234.51,146.63,234.49,145.97L234.49,145.97z
              "/>
          </g>
          <path class="gpu-compute-1" d="M198.08,149.24c-3.58-2.05-5.62-4.97-5.63-8.08l0,0c0-3.12,2.05-6.03,5.63-8.08l0,0
            c1.23-0.71,2.58-1.29,4.01-1.75l0,0c0.64-0.2,1.33,0.15,1.53,0.79l0,0c0.2,0.64-0.15,1.33-0.79,1.53l0,0
            c-1.28,0.41-2.47,0.93-3.54,1.54l0,0c-3.11,1.81-4.41,3.99-4.41,5.98l0,0c0,1.98,1.3,4.17,4.41,5.97l0,0v0
            c0.58,0.33,0.78,1.08,0.44,1.66l0,0c-0.23,0.39-0.63,0.61-1.05,0.61l0,0C198.48,149.41,198.27,149.35,198.08,149.24
            L198.08,149.24z"/>
          <path class="gpu-compute-1" d="M179.27,148.8c-0.34-0.58-0.14-1.33,0.44-1.66l0,0c3.11-1.81,4.41-3.99,4.41-5.97l0,0
            c0-1.98-1.3-4.17-4.41-5.98l0,0c-1.06-0.61-2.26-1.13-3.54-1.54l0,0c-0.64-0.21-0.99-0.89-0.79-1.53l0,0
            c0.21-0.64,0.89-0.99,1.53-0.79l0,0c1.43,0.46,2.78,1.04,4.01,1.75l0,0c3.58,2.06,5.62,4.97,5.63,8.08l0,0
            c0,3.11-2.05,6.03-5.63,8.08l0,0v0c-0.19,0.11-0.4,0.16-0.61,0.16l0,0C179.91,149.41,179.5,149.19,179.27,148.8L179.27,148.8z"
            />
          <path class="gpu-compute-1" d="M246.62,116.09c-0.17-0.65,0.22-1.32,0.87-1.49l0,0c1.6-0.41,3.08-0.99,4.35-1.73l0,0
            c1.41-0.81,2.41-1.73,3.05-2.65l0,0v0c0.39-0.55,1.14-0.69,1.7-0.3l0,0c0.55,0.38,0.69,1.14,0.3,1.69l0,0
            c-0.89,1.27-2.18,2.41-3.83,3.36l0,0c-1.5,0.86-3.17,1.51-4.96,1.98l0,0c-0.1,0.03-0.21,0.04-0.31,0.04l0,0
            C247.25,117,246.76,116.64,246.62,116.09L246.62,116.09z"/>
          <path class="gpu-compute-1" d="M241.1,105.6c-0.66-0.15-1.06-0.81-0.91-1.46l0,0c0.15-0.66,0.8-1.06,1.46-0.91l0,0
            c1.95,0.45,4.04,0.69,6.13,0.69l0,0c4.2,0.01,8.39-0.93,11.46-2.71l0,0h0c0.58-0.34,1.33-0.14,1.66,0.45l0,0
            c0.33,0.58,0.13,1.33-0.45,1.66l0,0c-3.57,2.05-8.13,3.03-12.68,3.03l0,0C245.51,106.35,243.24,106.1,241.1,105.6L241.1,105.6z"
            />
          <path class="gpu-compute-1" d="M198.08,149.24c-1.1-0.64-2.06-1.35-2.85-2.12l0,0c-1.85,0.39-3.79,0.59-5.72,0.59l0,0
            c-1.94,0-3.87-0.19-5.72-0.59l0,0c-0.79,0.77-1.75,1.48-2.85,2.12l0,0c-3.6,2.07-8.19,3.06-12.78,3.06l0,0
            c-4.59-0.01-9.18-0.99-12.78-3.06l0,0c-0.58-0.33-0.78-1.08-0.45-1.66l0,0c0.34-0.58,1.08-0.78,1.66-0.45l0,0
            c3.1,1.79,7.33,2.74,11.57,2.73l0,0c4.24,0,8.47-0.94,11.56-2.73l0,0c1.16-0.67,2.08-1.41,2.77-2.17l0,0
            c0.3-0.33,0.75-0.47,1.19-0.37l0,0c1.85,0.45,3.84,0.67,5.83,0.67l0,0c1.99,0,3.98-0.23,5.84-0.67l0,0
            c0.43-0.11,0.89,0.03,1.19,0.37l0,0c0.68,0.76,1.6,1.49,2.77,2.17l0,0c3.1,1.79,7.33,2.74,11.56,2.73l0,0
            c4.23,0,8.46-0.94,11.57-2.73l0,0c0.58-0.34,1.33-0.13,1.66,0.45l0,0c0.33,0.58,0.14,1.33-0.45,1.66l0,0
            c-3.6,2.07-8.19,3.06-12.78,3.06l0,0C206.27,152.3,201.68,151.31,198.08,149.24L198.08,149.24z"/>
          <path class="gpu-compute-1" d="M258.8,127.29c-0.34-0.58-0.14-1.32,0.45-1.66l0,0c3.08-1.79,4.37-3.95,4.36-5.9l0,0
            c0-1.96-1.28-4.12-4.36-5.91l0,0c-1.13-0.65-2.41-1.19-3.79-1.61l0,0c-0.38-0.12-0.67-0.4-0.8-0.78l0,0
            c-0.13-0.38-0.06-0.78,0.17-1.1l0,0c0.68-0.95,0.99-1.89,0.99-2.81l0,0c0-0.92-0.3-1.86-0.99-2.81l0,0
            c-0.23-0.32-0.29-0.73-0.17-1.1l0,0c0.12-0.37,0.42-0.66,0.8-0.78l0,0c1.38-0.42,2.66-0.96,3.79-1.61l0,0
            c3.08-1.79,4.36-3.95,4.36-5.91l0,0c0-1.96-1.28-4.12-4.36-5.91l0,0c-0.58-0.34-0.78-1.08-0.44-1.66l0,0
            c0.33-0.58,1.08-0.78,1.66-0.45l0,0c3.55,2.04,5.57,4.93,5.58,8.02l0,0c0,3.09-2.03,5.98-5.58,8.02l0,0
            c-0.9,0.52-1.85,0.96-2.85,1.34l0,0c0.42,0.92,0.64,1.88,0.64,2.86l0,0c0,0.97-0.22,1.94-0.64,2.85l0,0
            c1,0.38,1.96,0.82,2.85,1.34l0,0c3.55,2.04,5.58,4.92,5.58,8.02l0,0c0,3.09-2.03,5.98-5.58,8.02l0,0h0
            c-0.19,0.11-0.4,0.16-0.61,0.16l0,0C259.43,127.89,259.02,127.68,258.8,127.29L258.8,127.29z"/>
        </g>
        <g>
          <g>
            <g>
              <path class="gpu-compute-8" d="M250.55,114.2c1.51,0.87,1.51,2.3,0,3.18c-1.51,0.87-3.99,0.87-5.5,0c-1.51-0.87-1.51-2.3,0-3.18
                C246.56,113.33,249.03,113.33,250.55,114.2z"/>
            </g>
            <path class="gpu-compute-8" d="M245.75,115.79c0,1.13,0.92,2.04,2.04,2.04s2.04-0.91,2.04-2.04c0-1.13-0.92-2.04-2.04-2.04
              S245.75,114.66,245.75,115.79z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-8" d="M205.21,130.9c1.51,0.87,1.51,2.3,0,3.18c-1.51,0.87-3.99,0.87-5.5,0c-1.51-0.87-1.51-2.3,0-3.18
                C201.22,130.03,203.69,130.03,205.21,130.9z"/>
            </g>
            <path class="gpu-compute-8" d="M200.41,132.49c0,1.13,0.92,2.04,2.04,2.04c1.13,0,2.04-0.91,2.04-2.04c0-1.13-0.91-2.04-2.04-2.04
              C201.33,130.45,200.41,131.36,200.41,132.49z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-8" d="M179.31,130.9c1.51,0.87,1.51,2.3,0,3.18c-1.51,0.87-3.99,0.87-5.5,0c-1.51-0.87-1.51-2.3,0-3.18
                C175.32,130.03,177.79,130.03,179.31,130.9z"/>
            </g>
            <path class="gpu-compute-8" d="M174.51,132.49c0,1.13,0.91,2.04,2.04,2.04c1.13,0,2.04-0.91,2.04-2.04c0-1.13-0.91-2.04-2.04-2.04
              C175.42,130.45,174.51,131.36,174.51,132.49z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-8" d="M244.13,102.82c1.51,0.87,1.51,2.3,0,3.18c-1.51,0.87-3.99,0.87-5.5,0c-1.51-0.87-1.51-2.3,0-3.18
                C240.14,101.95,242.61,101.95,244.13,102.82z"/>
            </g>
            <path class="gpu-compute-8" d="M239.33,104.41c0,1.13,0.91,2.04,2.04,2.04s2.04-0.91,2.04-2.04c0-1.13-0.91-2.04-2.04-2.04
              S239.33,103.28,239.33,104.41z"/>
          </g>
        </g>
        <g>
          <g>
            <g>
              <ellipse class="gpu-compute-8" cx="131.3" cy="99.12" rx="3.89" ry="2.25"/>
            </g>
          </g>
          <g>
            <g>
              <path class="gpu-compute-8" d="M179.3,80.93c1.51,0.87,1.51,2.3,0,3.18c-1.51,0.87-3.99,0.87-5.5,0c-1.51-0.87-1.51-2.3,0-3.18
                C175.31,80.05,177.79,80.05,179.3,80.93z"/>
            </g>
            <path class="gpu-compute-8" d="M174.51,82.51c0,1.13,0.91,2.04,2.04,2.04c1.13,0,2.04-0.91,2.04-2.04c0-1.13-0.91-2.04-2.04-2.04
              C175.42,80.47,174.51,81.39,174.51,82.51z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-8" d="M199.71,80.93c-1.51,0.87-1.51,2.3,0,3.18c1.51,0.87,3.99,0.87,5.5,0c1.51-0.87,1.51-2.3,0-3.18
                C203.7,80.05,201.22,80.05,199.71,80.93z"/>
            </g>
            <path class="gpu-compute-8" d="M204.5,82.51c0,1.13-0.91,2.04-2.04,2.04c-1.13,0-2.04-0.91-2.04-2.04c0-1.13,0.92-2.04,2.04-2.04
              C203.59,80.47,204.5,81.39,204.5,82.51z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-8" d="M140.44,109.03c1.51,0.87,1.51,2.3,0,3.18c-1.51,0.87-3.99,0.87-5.5,0c-1.51-0.88-1.51-2.3,0-3.18
                C136.45,108.15,138.93,108.15,140.44,109.03z"/>
            </g>
            <path class="gpu-compute-8" d="M135.65,110.61c0,1.13,0.91,2.04,2.04,2.04c1.13,0,2.04-0.91,2.04-2.04c0-1.13-0.91-2.04-2.04-2.04
              C136.56,108.57,135.65,109.49,135.65,110.61z"/>
          </g>
        </g>
        <g>
          <path class="gpu-compute-2" d="M207.88,96.9c-0.34-0.58-0.14-1.33,0.45-1.66l0,0l16.58-9.57c0.58-0.34,1.33-0.14,1.66,0.44l0,0
            c0.34,0.58,0.14,1.33-0.45,1.66l0,0l-16.58,9.57c-0.19,0.11-0.4,0.16-0.61,0.16l0,0C208.51,97.51,208.11,97.29,207.88,96.9
            L207.88,96.9z"/>
          <path class="gpu-compute-2" d="M154.23,127.87c-0.34-0.58-0.14-1.33,0.45-1.66l0,0l14.79-8.54c0.58-0.33,1.33-0.14,1.66,0.45l0,0
            c0.34,0.58,0.14,1.33-0.45,1.66l0,0l-14.79,8.54c-0.19,0.11-0.4,0.16-0.61,0.16l0,0C154.87,128.48,154.46,128.26,154.23,127.87
            L154.23,127.87z"/>
          <path class="gpu-compute-2" d="M214.35,100.64c-0.33-0.58-0.13-1.33,0.45-1.66l0,0l6.76-3.9h12.96l19.15-11.05
            c0.58-0.34,1.33-0.14,1.66,0.44l0,0c0.33,0.58,0.13,1.33-0.45,1.66l0,0L235.17,97.5h-12.96l-6.19,3.58
            c-0.19,0.11-0.4,0.16-0.61,0.16l0,0C214.99,101.25,214.58,101.03,214.35,100.64L214.35,100.64z"/>
          <path class="gpu-compute-2" d="M201.4,93.16c-0.34-0.58-0.14-1.33,0.45-1.66l0,0l12.34-7.13v-7.48l13.56-7.83
            c0.58-0.34,1.33-0.14,1.67,0.45l0,0c0.33,0.58,0.13,1.33-0.45,1.66l0,0l-12.34,7.13v7.48l-13.56,7.83
            c-0.19,0.11-0.4,0.16-0.61,0.16l0,0C202.04,93.77,201.63,93.55,201.4,93.16L201.4,93.16z"/>
          <path class="gpu-compute-2" d="M149.59,145.51c-0.34-0.58-0.14-1.33,0.45-1.66l0,0l12.34-7.13v-7.48l13.56-7.83h0
            c0.58-0.33,1.33-0.13,1.66,0.45l0,0c0.33,0.58,0.14,1.33-0.45,1.66l0,0l-12.35,7.13v7.48l-13.56,7.83
            c-0.19,0.11-0.4,0.16-0.61,0.16l0,0C150.22,146.12,149.81,145.9,149.59,145.51L149.59,145.51z"/>
          <path class="gpu-compute-2" d="M123.68,130.55c-0.34-0.58-0.14-1.33,0.45-1.66l0,0l19.71-11.38l12.95,0l6.19-3.58v0
            c0.58-0.34,1.33-0.14,1.66,0.44l0,0c0.34,0.58,0.14,1.33-0.44,1.67l0,0l-6.76,3.9l-12.95,0L125.34,131
            c-0.19,0.11-0.4,0.16-0.61,0.16l0,0C124.32,131.16,123.91,130.94,123.68,130.55L123.68,130.55z"/>
          <path class="gpu-compute-2" d="M169.47,97.34l-25.91-14.96c-0.58-0.34-0.78-1.08-0.45-1.66l0,0c0.34-0.58,1.08-0.78,1.66-0.44l0,0
            l25.91,14.96c0.58,0.34,0.78,1.08,0.44,1.66l0,0c-0.23,0.39-0.63,0.61-1.05,0.61l0,0C169.87,97.5,169.66,97.45,169.47,97.34
            L169.47,97.34z"/>
          <path class="gpu-compute-2" d="M234.23,134.74l-25.91-14.96c-0.58-0.34-0.78-1.08-0.44-1.66l0,0c0.34-0.58,1.08-0.78,1.66-0.45l0,0
            l25.91,14.96c0.58,0.34,0.78,1.08,0.45,1.66l0,0c-0.23,0.39-0.63,0.61-1.06,0.61l0,0C234.64,134.9,234.43,134.85,234.23,134.74
            L234.23,134.74z"/>
          <path class="gpu-compute-2" d="M162.99,101.08l-6.19-3.58h-12.96l-19.71-11.38c-0.58-0.34-0.78-1.08-0.45-1.66l0,0
            c0.34-0.58,1.08-0.78,1.66-0.45l0,0l19.14,11.06h12.96l6.76,3.9c0.58,0.34,0.78,1.08,0.45,1.66l0,0
            c-0.23,0.39-0.64,0.61-1.06,0.61l0,0C163.39,101.25,163.18,101.19,162.99,101.08L162.99,101.08z"/>
          <path class="gpu-compute-2" d="M175.94,93.6l-13.56-7.83l0-7.48l-12.34-7.13c-0.58-0.34-0.78-1.08-0.45-1.66l0,0
            c0.33-0.58,1.08-0.78,1.66-0.45l0,0l13.56,7.83v7.48l12.34,7.13v0c0.58,0.33,0.78,1.08,0.45,1.66l0,0
            c-0.23,0.39-0.64,0.61-1.06,0.61l0,0C176.34,93.77,176.13,93.71,175.94,93.6L175.94,93.6z"/>
          <path class="gpu-compute-2" d="M227.76,145.96l-13.56-7.83l0-7.48l-12.34-7.12c-0.58-0.34-0.78-1.08-0.45-1.66l0,0
            c0.34-0.58,1.08-0.78,1.66-0.45l0,0l13.56,7.83v7.48l12.35,7.13c0.58,0.34,0.78,1.08,0.45,1.66l0,0
            c-0.23,0.39-0.64,0.61-1.06,0.61l0,0C228.16,146.12,227.95,146.06,227.76,145.96L227.76,145.96z"/>
          <path class="gpu-compute-2" d="M253.66,131l-19.15-11.06h-12.95l-6.76-3.9c-0.58-0.34-0.78-1.08-0.45-1.66l0,0
            c0.34-0.58,1.08-0.78,1.66-0.45l0,0l6.19,3.58h12.95l19.71,11.38c0.58,0.33,0.78,1.08,0.45,1.66l0,0
            c-0.23,0.39-0.64,0.61-1.06,0.61l0,0C254.06,131.16,253.85,131.11,253.66,131L253.66,131z"/>
        </g>
        <g>
          <g>
            <g>
              <path class="gpu-compute-9" d="M152.42,125.61c-1.57,0.91-1.57,2.4,0,3.31c1.58,0.91,4.16,0.91,5.73,0c1.58-0.91,1.58-2.4,0-3.31
                C156.57,124.7,154,124.7,152.42,125.61z"/>
            </g>
            <path class="gpu-compute-9" d="M157.41,127.26c0,1.18-0.95,2.13-2.13,2.13c-1.17,0-2.13-0.95-2.13-2.13c0-1.17,0.95-2.13,2.13-2.13
              C156.46,125.14,157.41,126.09,157.41,127.26z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-9" d="M121.87,128.29c-1.58,0.91-1.58,2.4,0,3.31c1.58,0.91,4.15,0.91,5.73,0c1.57-0.91,1.57-2.4,0-3.31
                C126.02,127.38,123.45,127.38,121.87,128.29z"/>
            </g>
            <path class="gpu-compute-9" d="M126.86,129.94c0,1.17-0.95,2.13-2.13,2.13c-1.17,0-2.13-0.95-2.13-2.13c0-1.18,0.95-2.13,2.13-2.13
              C125.91,127.82,126.86,128.77,126.86,129.94z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-9" d="M147.78,143.24c-1.57,0.91-1.57,2.4,0,3.31c1.58,0.91,4.15,0.91,5.73,0c1.58-0.91,1.58-2.4,0-3.31
                C151.93,142.34,149.35,142.34,147.78,143.24z"/>
            </g>
            <path class="gpu-compute-9" d="M152.77,144.9c0,1.17-0.95,2.13-2.13,2.13c-1.18,0-2.13-0.95-2.13-2.13c0-1.18,0.95-2.13,2.13-2.13
              C151.82,142.77,152.77,143.72,152.77,144.9z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-9" d="M251.41,83.42c-1.58,0.91-1.58,2.4,0,3.31c1.58,0.91,4.16,0.91,5.73,0c1.58-0.91,1.58-2.4,0-3.31
                C255.56,82.51,252.98,82.51,251.41,83.42z"/>
            </g>
            <path class="gpu-compute-9" d="M256.4,85.07c0,1.18-0.95,2.13-2.13,2.13c-1.18,0-2.13-0.95-2.13-2.13s0.95-2.13,2.13-2.13
              C255.45,82.94,256.4,83.9,256.4,85.07z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-9" d="M222.65,85.06c-1.57,0.91-1.57,2.4,0,3.31c1.58,0.91,4.16,0.91,5.73,0c1.57-0.91,1.57-2.4,0-3.31
                C226.8,84.15,224.23,84.15,222.65,85.06z"/>
            </g>
            <path class="gpu-compute-9" d="M227.64,86.72c0,1.17-0.95,2.13-2.13,2.13c-1.17,0-2.12-0.95-2.12-2.13c0-1.18,0.95-2.13,2.12-2.13
              C226.69,84.59,227.64,85.54,227.64,86.72z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-9" d="M225.5,68.46c-1.58,0.91-1.58,2.4,0,3.31c1.58,0.91,4.15,0.91,5.73,0c1.58-0.91,1.58-2.4,0-3.31
                C229.65,67.55,227.08,67.55,225.5,68.46z"/>
            </g>
            <path class="gpu-compute-9" d="M230.49,70.11c0,1.18-0.95,2.13-2.13,2.13c-1.18,0-2.13-0.95-2.13-2.13s0.95-2.13,2.13-2.13
              C229.54,67.99,230.49,68.94,230.49,70.11z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-9" d="M237.71,132.03c1.58,0.91,1.58,2.4,0,3.31c-1.58,0.91-4.15,0.91-5.73,0c-1.57-0.91-1.57-2.4,0-3.31
                C233.56,131.12,236.13,131.12,237.71,132.03z"/>
            </g>
            <path class="gpu-compute-9" d="M232.72,133.68c0,1.17,0.95,2.12,2.13,2.12c1.18,0,2.13-0.95,2.13-2.12c0-1.18-0.95-2.13-2.13-2.13
              C233.67,131.56,232.72,132.51,232.72,133.68z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-9" d="M257.14,128.29c1.58,0.91,1.58,2.4,0,3.31c-1.58,0.91-4.16,0.91-5.73,0c-1.58-0.91-1.58-2.4,0-3.31
                C252.98,127.38,255.56,127.38,257.14,128.29z"/>
            </g>
            <path class="gpu-compute-9" d="M252.15,129.94c0,1.17,0.95,2.13,2.13,2.13c1.18,0,2.13-0.95,2.13-2.13c0-1.18-0.95-2.13-2.13-2.13
              C253.1,127.82,252.15,128.77,252.15,129.94z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-9" d="M231.23,143.24c1.58,0.91,1.58,2.4,0,3.31c-1.58,0.91-4.15,0.91-5.73,0c-1.58-0.91-1.58-2.4,0-3.31
                C227.08,142.34,229.65,142.34,231.23,143.24z"/>
            </g>
            <path class="gpu-compute-9" d="M226.24,144.9c0,1.17,0.95,2.13,2.13,2.13c1.18,0,2.13-0.95,2.13-2.13c0-1.18-0.95-2.13-2.13-2.13
              C227.19,142.77,226.24,143.72,226.24,144.9z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-9" d="M127.6,83.42c1.58,0.91,1.58,2.4,0,3.31c-1.58,0.91-4.15,0.91-5.73,0c-1.58-0.91-1.58-2.4,0-3.31
                C123.45,82.51,126.03,82.51,127.6,83.42z"/>
            </g>
            <path class="gpu-compute-9" d="M122.61,85.07c0,1.18,0.95,2.13,2.13,2.13c1.18,0,2.13-0.95,2.13-2.13s-0.95-2.13-2.13-2.13
              C123.56,82.94,122.61,83.9,122.61,85.07z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-9" d="M147.03,79.68c1.58,0.91,1.58,2.4,0,3.31c-1.58,0.91-4.15,0.91-5.73,0c-1.57-0.91-1.57-2.4,0-3.31
                C142.88,78.77,145.46,78.77,147.03,79.68z"/>
            </g>
            <path class="gpu-compute-9" d="M142.04,81.33c0,1.18,0.95,2.13,2.13,2.13c1.17,0,2.13-0.95,2.13-2.13c0-1.18-0.95-2.13-2.13-2.13
              C142.99,79.2,142.04,80.16,142.04,81.33z"/>
          </g>
          <g>
            <g>
              <path class="gpu-compute-9" d="M153.51,68.46c1.58,0.91,1.58,2.4,0,3.31c-1.58,0.91-4.15,0.91-5.73,0c-1.58-0.91-1.58-2.4,0-3.31
                C149.36,67.55,151.93,67.55,153.51,68.46z"/>
            </g>
            <path class="gpu-compute-9" d="M148.52,70.11c0,1.18,0.95,2.13,2.13,2.13c1.18,0,2.13-0.95,2.13-2.13s-0.95-2.13-2.13-2.13
              C149.47,67.99,148.52,68.94,148.52,70.11z"/>
          </g>
        </g>
        <g>
          <path class="gpu-compute-9" d="M187.08,122c1.33,0.77,3.52,0.77,4.86,0l22.67-13.09c1.33-0.77,1.33-2.03,0-2.8l-22.67-13.09
            c-1.33-0.77-3.52-0.77-4.86,0l-22.67,13.09c-1.34,0.77-1.34,2.03,0,2.8L187.08,122z"/>
        </g>
      </g>
      <g>
        <path class="gpu-compute-10" d="M193.24,102.64l-7.17,8.96l-2.27-1.31l1.67-2.01l-4.13-2.38l-3.42,1l-2.21-1.28l15.33-4.25L193.24,102.64z
          M183.88,105.15l2.82,1.63l2.77-3.26L183.88,105.15"/>
        <polygon class="gpu-compute-10" points="201.64,107.49 190.34,114.05 188.25,112.85 199.55,106.28 			"/>
      </g>
    </g>
  </g>
  </svg>

</template>

<script>

export default {
  //
}

</script>
