<template>
  <div class="w-full flex bg-gray-darkest text-gray-lighter text-center font-bold border-b-2 border-gray-light divide-x-2 divide-gray-light flex-shrink-0">
    <div class="flex-grow px-6 py-2 text-brand bg-gray-darker sm:text-gray-lighter sm:bg-transparent">xs</div>
    <div class="flex-grow px-6 py-2 sm:text-brand sm:bg-gray-darker md:text-gray-lighter md:bg-transparent">sm</div>
    <div class="flex-grow px-6 py-2 md:text-brand md:bg-gray-darker lg:text-gray-lighter lg:bg-transparent">md</div>
    <div class="flex-grow px-6 py-2 lg:text-brand lg:bg-gray-darker xl:text-gray-lighter xl:bg-transparent">lg</div>
    <div class="flex-grow px-6 py-2 xl:text-brand xl:bg-gray-darker 2xl:text-gray-lighter 2xl:bg-transparent">xl</div>
    <div class="flex-grow px-6 py-2 2xl:text-brand 2xl:bg-gray-darker">2xl</div>
  </div>
</template>
