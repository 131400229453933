<style scoped>
  .dedicated-compute-dark { @apply hidden; }
  .dedicated-compute-light { @apply block; }

  /*
  .dedicated-compute-dark { @apply transition opacity-100; }
  .dedicated-compute-light { @apply transition opacity-0; }

  svg:hover .dedicated-compute-dark { @apply opacity-0; }
  svg:hover .dedicated-compute-light { @apply opacity-100; }
  */
  
  .dedicated-compute-0 { fill: rgba(0, 0, 0, .15); }
  .dedicated-compute-1 { fill: #349172; }
  .dedicated-compute-2 { fill: #53DCAB; }
  .dedicated-compute-3 { fill: #4D5470; }
  .dedicated-compute-4 { fill: #323542; }
  .dedicated-compute-5 { fill: #4B4D59; }
  .dedicated-compute-6 { fill: #A1B7B2; }
  .dedicated-compute-7 { fill: #474956; }
  .dedicated-compute-8 { fill: #161924; }
  .dedicated-compute-9 { fill: #242835; }
  .dedicated-compute-10 { fill: #191D26; }
  .dedicated-compute-11 { fill: #20B785; }
  .dedicated-compute-13 { display: inline; }
  .dedicated-compute-14 { display: inline; fill: #767F91; }
  .dedicated-compute-15 { fill: #A3ABC3; }
  .dedicated-compute-16 { display: inline; fill: #979FAF; }
  .dedicated-compute-17 { display: inline; fill: #242835; }
  .dedicated-compute-18 { fill: #363F4C; }
  .dedicated-compute-19 { fill: #979FAF; }
  .dedicated-compute-20 { display: inline; fill: #282D38; }
</style>

<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 372.43 338.55" style="enable-background:new 0 0 372.43 338.55;" xml:space="preserve">
    <g class="dedicated-compute-dark">
      <g>
        <path class="dedicated-compute-0" d="M169.77,334.64c9.04,5.22,23.84,5.22,32.88,0L356.19,246c9.04-5.22,9.04-13.76,0-18.98l-153.53-88.64
          c-9.04-5.22-23.84-5.22-32.88,0L16.24,227.01c-9.04,5.22-9.04,13.76,0,18.98L169.77,334.64z"/>
      </g>
      <path class="dedicated-compute-1" d="M356.19,192.14l-153.53,88.64c-9.04,5.22-23.84,5.22-32.88,0v0L16.24,192.14c-4.52-2.61-6.78-6.05-6.78-9.49
        v9.9c0,3.44,2.26,6.88,6.78,9.49l153.53,88.64v0c9.04,5.22,23.84,5.22,32.88,0l153.53-88.64c4.52-2.61,6.78-6.05,6.78-9.49v-9.9
        C362.97,186.09,360.71,189.53,356.19,192.14z"/>
      <path class="dedicated-compute-2" d="M356.19,173.16L202.66,84.52c-9.04-5.22-23.84-5.22-32.88,0L16.24,173.16c-9.04,5.22-9.04,13.76,0,18.98
        l153.53,88.64c9.04,5.22,23.84,5.22,32.88,0l153.53-88.64C365.23,186.92,365.23,178.38,356.19,173.16z M341.97,187.65
        l-147.09,84.92c-4.76,2.75-12.56,2.75-17.32,0L30.47,187.65c-4.76-2.75-4.76-7.25,0-10l147.09-84.92c4.76-2.75,12.56-2.75,17.32,0
        l147.09,84.92C346.73,180.4,346.73,184.9,341.97,187.65z"/>
      <path class="dedicated-compute-1" d="M30.4,187.61c0.02-0.01,0.04-0.03,0.06-0.04l147.09-84.92c4.76-2.75,12.56-2.75,17.32,0l147.09,84.92
        c0.02,0.01,0.04,0.03,0.06,0.04c4.7-2.75,4.68-7.22-0.06-9.96L194.88,92.73c-4.76-2.75-12.56-2.75-17.32,0L30.47,177.65
        C25.72,180.39,25.71,184.86,30.4,187.61z"/>
      <g>
        <path class="dedicated-compute-3" d="M168.89,239.24c9.53,5.5,25.11,5.5,34.64,0l161.75-93.38c9.53-5.5,9.53-14.5,0-20L203.54,32.47
          c-9.53-5.5-25.11-5.5-34.64,0L7.14,125.86c-9.53,5.5-9.53,14.5,0,20L168.89,239.24z"/>
      </g>
      <path class="dedicated-compute-4" d="M365.29,117.51L203.53,210.9c-9.53,5.5-25.11,5.5-34.64,0v0L7.14,117.51c-4.76-2.75-7.14-6.37-7.14-10v28.35
        c0,3.62,2.38,7.25,7.14,9.99l6.13,3.54l0,0l155.62,89.84v0c9.53,5.5,25.11,5.5,34.64,0l155.62-89.84l0,0l6.13-3.54
        c4.76-2.75,7.14-6.37,7.14-9.99v-28.35C372.43,111.14,370.05,114.76,365.29,117.51z"/>
      <g>
        <path class="dedicated-compute-5" d="M168.89,210.9c9.53,5.5,25.11,5.5,34.64,0l161.75-93.39c9.53-5.5,9.53-14.5,0-20L203.54,4.12
          c-9.53-5.5-25.11-5.5-34.64,0L7.14,97.51c-9.53,5.5-9.53,14.5,0,20L168.89,210.9z"/>
      </g>
      <g>
        <g>
          <path class="dedicated-compute-6" d="M340.57,103.68c3.64-2.1,9.61-2.1,13.25,0c3.65,2.11,3.65,5.55,0,7.65c-3.64,2.11-9.61,2.11-13.25,0
            C336.93,109.23,336.93,105.79,340.57,103.68z"/>
        </g>
        <g>
          <path class="dedicated-compute-6" d="M179.59,10.74c3.65-2.1,9.61-2.1,13.26,0c3.64,2.1,3.64,5.55,0,7.65c-3.65,2.1-9.61,2.1-13.26,0
            C175.94,16.29,175.94,12.84,179.59,10.74z"/>
        </g>
        <g>
          <path class="dedicated-compute-6" d="M179.59,196.63c3.64-2.1,9.61-2.1,13.25,0c3.65,2.1,3.65,5.55,0,7.65c-3.65,2.1-9.61,2.1-13.25,0
            C175.94,202.18,175.94,198.73,179.59,196.63z"/>
        </g>
        <g>
          <path class="dedicated-compute-6" d="M18.6,103.68c3.65-2.1,9.61-2.1,13.25,0c3.64,2.1,3.64,5.55,0,7.65c-3.65,2.11-9.61,2.11-13.25,0
            C14.96,109.23,14.96,105.79,18.6,103.68z"/>
        </g>
      </g>
      <path class="dedicated-compute-7" d="M314.3,103L201.38,37.8c-8.34-4.81-21.98-4.81-30.32,0L58.13,103c-8.34,4.81-8.34,12.69,0,17.5l112.92,65.19
        h30.32L314.3,120.5C322.64,115.69,322.64,107.81,314.3,103z"/>
      <path class="dedicated-compute-8" d="M300.27,101.13L300.27,101.13L300.27,101.13l-100.56,58.06v18.42l99.13-57.23l1.43-0.83
        c3.71-2.14,5.57-4.97,5.57-7.79V93.34C305.84,96.16,303.99,98.99,300.27,101.13z"/>
      <path class="dedicated-compute-8" d="M172.72,159.19L72.16,101.13v0l0,0c-3.71-2.14-5.57-4.97-5.57-7.8v18.41c0,2.82,1.86,5.65,5.57,7.79l2.75,1.59
        l97.81,56.47V159.19z"/>
      <g>
        <path class="dedicated-compute-9" d="M172.71,159.19c7.43,4.29,19.57,4.29,27,0l100.56-58.06c7.43-4.29,7.43-11.3,0-15.59L199.72,27.49
          c-7.42-4.29-19.57-4.29-27,0L72.15,85.54c-7.42,4.29-7.42,11.3,0,15.59L172.71,159.19z"/>
      </g>
      <g>
        <g>
          <polygon class="dedicated-compute-7" points="132.38,199.84 132.38,209.56 128.35,207.23 128.35,197.51      "/>
          <polygon class="dedicated-compute-7" points="120.28,192.85 120.28,202.57 116.25,200.24 116.25,190.52      "/>
          <polygon class="dedicated-compute-7" points="108.18,185.87 108.18,195.58 104.15,193.26 104.15,183.54      "/>
          <polygon class="dedicated-compute-7" points="96.09,178.88 96.09,188.6 92.05,186.27 92.05,176.55       "/>
          <polygon class="dedicated-compute-7" points="83.99,171.9 83.99,181.61 79.95,179.29 79.95,169.57       "/>
          <polygon class="dedicated-compute-7" points="71.89,164.91 71.89,174.63 67.85,172.3 67.85,162.58       "/>
          <polygon class="dedicated-compute-7" points="59.79,157.92 59.79,167.64 55.75,165.32 55.75,155.59      "/>
          <polygon class="dedicated-compute-7" points="47.69,150.94 47.69,160.66 43.65,158.33 43.65,148.61      "/>
        </g>
        <g>
          <polygon class="dedicated-compute-7" points="240.05,199.84 240.05,209.56 244.08,207.23 244.08,197.51      "/>
          <polygon class="dedicated-compute-7" points="252.15,192.85 252.15,202.57 256.18,200.24 256.18,190.52      "/>
          <polygon class="dedicated-compute-7" points="264.25,185.87 264.25,195.58 268.28,193.26 268.28,183.54      "/>
          <polygon class="dedicated-compute-7" points="276.35,178.88 276.35,188.6 280.38,186.27 280.38,176.55       "/>
          <polygon class="dedicated-compute-7" points="288.44,171.9 288.44,181.61 292.48,179.29 292.48,169.57       "/>
          <polygon class="dedicated-compute-7" points="300.54,164.91 300.54,174.63 304.58,172.3 304.58,162.58       "/>
          <polygon class="dedicated-compute-7" points="312.64,157.92 312.64,167.64 316.68,165.32 316.68,155.59      "/>
          <polygon class="dedicated-compute-7" points="324.74,150.94 324.74,160.66 328.78,158.33 328.78,148.61      "/>
        </g>
      </g>
      <rect x="172.72" y="159.19" class="dedicated-compute-10" width="27" height="18.42"/>
      <g>
        <g>
          <path class="dedicated-compute-2" d="M179.43,48.18c3.73-2.16,9.84-2.16,13.58,0v0c3.73,2.15,3.73,5.68,0,7.83v0c-3.74,2.15-9.84,2.15-13.58,0
            l0,0C175.7,53.86,175.7,50.33,179.43,48.18z"/>
        </g>
        <g>
          <path class="dedicated-compute-11" d="M203.24,61.93c3.74-2.16,9.84-2.16,13.57,0l0,0c3.73,2.15,3.73,5.68,0,7.83v0c-3.73,2.16-9.84,2.16-13.57,0
            h0C199.51,67.6,199.51,64.08,203.24,61.93z"/>
        </g>
        <g>
          <path class="dedicated-compute-2" d="M227.05,75.67c3.73-2.15,9.84-2.15,13.57,0l0,0c3.74,2.15,3.74,5.68,0,7.84v0c-3.73,2.15-9.84,2.15-13.57,0
            l0,0C223.31,81.35,223.31,77.83,227.05,75.67z"/>
        </g>
        <g>
          <path class="dedicated-compute-2" d="M250.86,89.42c3.73-2.15,9.84-2.15,13.57,0l0,0c3.74,2.15,3.74,5.68,0,7.84v0c-3.73,2.15-9.84,2.15-13.57,0
            l0,0C247.13,95.1,247.13,91.57,250.86,89.42z"/>
        </g>
        <path class="dedicated-compute-11" d="M155.62,61.93c3.73-2.15,9.84-2.15,13.57,0v0c3.73,2.15,3.73,5.68,0,7.83l0,0c-3.73,2.16-9.84,2.16-13.57,0
          C151.89,67.61,151.89,64.08,155.62,61.93z"/>
        <g>
          <path class="dedicated-compute-2" d="M179.43,75.67c3.73-2.15,9.84-2.15,13.57,0v0c3.73,2.15,3.73,5.68,0,7.83v0c-3.74,2.15-9.84,2.15-13.57,0
            C175.7,81.35,175.7,77.83,179.43,75.67z"/>
        </g>
        <g>
          <path class="dedicated-compute-2" d="M203.24,89.42c3.74-2.16,9.84-2.16,13.57,0v0c3.73,2.15,3.73,5.68,0,7.84l0,0c-3.73,2.15-9.84,2.15-13.57,0
            h0C199.51,95.1,199.51,91.58,203.24,89.42z"/>
        </g>
        <g>
          <path class="dedicated-compute-2" d="M227.05,103.17c3.74-2.16,9.84-2.16,13.57,0v0c3.73,2.15,3.73,5.68,0,7.84l0,0c-3.73,2.16-9.84,2.16-13.57,0
            h0C223.32,108.84,223.32,105.32,227.05,103.17z"/>
        </g>
        <g>
          <path class="dedicated-compute-2" d="M131.81,75.67c3.73-2.15,9.84-2.15,13.57,0l0,0c3.73,2.15,3.73,5.68,0,7.84l0,0
            c-3.73,2.15-9.84,2.15-13.57,0l0,0C128.07,81.35,128.07,77.83,131.81,75.67z"/>
        </g>
        <g>
          <path class="dedicated-compute-2" d="M155.61,89.42c3.73-2.16,9.84-2.16,13.57,0v0c3.73,2.15,3.73,5.68,0,7.84l0,0c-3.73,2.15-9.84,2.15-13.57,0
            l0,0C151.89,95.1,151.89,91.58,155.61,89.42z"/>
        </g>
        <g>
          <path class="dedicated-compute-2" d="M179.43,103.17c3.73-2.15,9.84-2.15,13.57,0v0c3.73,2.16,3.73,5.68,0,7.84l0,0c-3.74,2.15-9.84,2.15-13.57,0
            l0,0C175.7,108.85,175.7,105.32,179.43,103.17z"/>
        </g>
        <g>
          <path class="dedicated-compute-11" d="M203.24,116.91c3.73-2.15,9.84-2.15,13.57,0v0c3.73,2.16,3.73,5.68,0,7.84l0,0
            c-3.74,2.15-9.84,2.15-13.57,0l0,0C199.51,122.59,199.51,119.07,203.24,116.91z"/>
        </g>
        <g>
          <path class="dedicated-compute-2" d="M108,89.42c3.73-2.15,9.84-2.15,13.57,0l0,0c3.73,2.15,3.73,5.68,0,7.84l0,0c-3.73,2.15-9.84,2.15-13.57,0
            l0,0C104.27,95.1,104.27,91.57,108,89.42z"/>
        </g>
        <g>
          <path class="dedicated-compute-2" d="M131.81,103.17c3.73-2.16,9.84-2.16,13.57,0v0c3.73,2.15,3.73,5.68,0,7.84l0,0c-3.73,2.16-9.84,2.16-13.57,0
            l0,0C128.08,108.84,128.08,105.32,131.81,103.17z"/>
        </g>
        <g>
          <path class="dedicated-compute-11" d="M155.62,116.91c3.73-2.16,9.84-2.16,13.57,0v0c3.73,2.16,3.73,5.68,0,7.84l0,0
            c-3.74,2.15-9.84,2.15-13.57,0l0,0C151.89,122.59,151.89,119.07,155.62,116.91z"/>
        </g>
        <g>
          <path class="dedicated-compute-11" d="M179.43,130.66c3.73-2.16,9.84-2.16,13.57,0v0c3.73,2.15,3.73,5.68,0,7.84l0,0
            c-3.74,2.15-9.84,2.15-13.57,0l0,0C175.7,136.34,175.7,132.81,179.43,130.66z"/>
        </g>
      </g>
    </g>
    <g class="dedicated-compute-light">
      <g class="dedicated-compute-13">
        <path class="dedicated-compute-0" d="M169.77,334.64c9.04,5.22,23.84,5.22,32.88,0L356.19,246c9.04-5.22,9.04-13.76,0-18.98l-153.53-88.64
          c-9.04-5.22-23.84-5.22-32.88,0L16.24,227.01c-9.04,5.22-9.04,13.76,0,18.98L169.77,334.64z"/>
      </g>
      <g class="dedicated-compute-13">
        <path class="dedicated-compute-1" d="M356.19,192.14l-153.53,88.64c-9.04,5.22-23.84,5.22-32.88,0v0L16.24,192.14c-4.52-2.61-6.78-6.05-6.78-9.49
          v9.9c0,3.44,2.26,6.88,6.78,9.49l153.53,88.64v0c9.04,5.22,23.84,5.22,32.88,0l153.53-88.64c4.52-2.61,6.78-6.05,6.78-9.49v-9.9
          C362.97,186.09,360.71,189.53,356.19,192.14z"/>
        <path class="dedicated-compute-2" d="M356.19,173.16L202.66,84.52c-9.04-5.22-23.84-5.22-32.88,0L16.24,173.16c-9.04,5.22-9.04,13.76,0,18.98
          l153.53,88.64c9.04,5.22,23.84,5.22,32.88,0l153.53-88.64C365.23,186.92,365.23,178.38,356.19,173.16z M341.97,187.65
          l-147.09,84.92c-4.76,2.75-12.56,2.75-17.32,0L30.47,187.65c-4.76-2.75-4.76-7.25,0-10l147.09-84.92c4.76-2.75,12.56-2.75,17.32,0
          l147.09,84.92C346.73,180.4,346.73,184.9,341.97,187.65z"/>
        <path class="dedicated-compute-1" d="M30.4,187.61c0.02-0.01,0.04-0.03,0.06-0.04l147.09-84.92c4.76-2.75,12.56-2.75,17.32,0l147.09,84.92
          c0.02,0.01,0.04,0.03,0.06,0.04c4.7-2.75,4.68-7.22-0.06-9.96L194.88,92.73c-4.76-2.75-12.56-2.75-17.32,0L30.47,177.65
          C25.72,180.39,25.71,184.86,30.4,187.61z"/>
      </g>
      <g class="dedicated-compute-13">
        <path class="dedicated-compute-3" d="M168.89,239.24c9.53,5.5,25.11,5.5,34.64,0l161.75-93.38c9.53-5.5,9.53-14.5,0-20L203.54,32.47
          c-9.53-5.5-25.11-5.5-34.64,0L7.14,125.86c-9.53,5.5-9.53,14.5,0,20L168.89,239.24z"/>
      </g>
      <path class="dedicated-compute-14" d="M365.29,117.51L203.53,210.9c-9.53,5.5-25.11,5.5-34.64,0v0L7.14,117.51c-4.76-2.75-7.14-6.37-7.14-10v28.35
        c0,3.62,2.38,7.25,7.14,9.99l6.13,3.54l0,0l155.62,89.84v0c9.53,5.5,25.11,5.5,34.64,0l155.62-89.84l0,0l6.13-3.54
        c4.76-2.75,7.14-6.37,7.14-9.99v-28.35C372.43,111.14,370.05,114.76,365.29,117.51z"/>
      <g class="dedicated-compute-13">
        <path class="dedicated-compute-15" d="M168.89,210.9c9.53,5.5,25.11,5.5,34.64,0l161.75-93.39c9.53-5.5,9.53-14.5,0-20L203.54,4.12
          c-9.53-5.5-25.11-5.5-34.64,0L7.14,97.51c-9.53,5.5-9.53,14.5,0,20L168.89,210.9z"/>
      </g>
      <g class="dedicated-compute-13">
        <g>
          <path class="dedicated-compute-7" d="M340.57,103.68c3.64-2.1,9.61-2.1,13.25,0c3.65,2.11,3.65,5.55,0,7.65c-3.64,2.11-9.61,2.11-13.25,0
            C336.93,109.23,336.93,105.79,340.57,103.68z"/>
        </g>
        <g>
          <path class="dedicated-compute-7" d="M179.59,10.74c3.65-2.1,9.61-2.1,13.26,0c3.64,2.1,3.64,5.55,0,7.65c-3.65,2.1-9.61,2.1-13.26,0
            C175.94,16.29,175.94,12.84,179.59,10.74z"/>
        </g>
        <g>
          <path class="dedicated-compute-7" d="M179.59,196.63c3.64-2.1,9.61-2.1,13.25,0c3.65,2.1,3.65,5.55,0,7.65c-3.65,2.1-9.61,2.1-13.25,0
            C175.94,202.18,175.94,198.73,179.59,196.63z"/>
        </g>
        <g>
          <path class="dedicated-compute-7" d="M18.6,103.68c3.65-2.1,9.61-2.1,13.25,0c3.64,2.1,3.64,5.55,0,7.65c-3.65,2.11-9.61,2.11-13.25,0
            C14.96,109.23,14.96,105.79,18.6,103.68z"/>
        </g>
      </g>
      <path class="dedicated-compute-16" d="M314.3,103L201.38,37.8c-8.34-4.81-21.98-4.81-30.32,0L58.13,103c-8.34,4.81-8.34,12.69,0,17.5l112.92,65.19
        h30.32L314.3,120.5C322.64,115.69,322.64,107.81,314.3,103z"/>
      <path class="dedicated-compute-17" d="M300.27,101.13L300.27,101.13L300.27,101.13l-100.56,58.06v18.42l99.13-57.23l1.43-0.83
        c3.71-2.14,5.57-4.97,5.57-7.79V93.34C305.84,96.16,303.99,98.99,300.27,101.13z"/>
      <path class="dedicated-compute-17" d="M172.72,159.19L72.16,101.13v0l0,0c-3.71-2.14-5.57-4.97-5.57-7.8v18.41c0,2.82,1.86,5.65,5.57,7.79
        l2.75,1.59l97.81,56.47V159.19z"/>
      <g class="dedicated-compute-13">
        <path class="dedicated-compute-18" d="M172.71,159.19c7.43,4.29,19.57,4.29,27,0l100.56-58.06c7.43-4.29,7.43-11.3,0-15.59L199.72,27.49
          c-7.42-4.29-19.57-4.29-27,0L72.15,85.54c-7.42,4.29-7.42,11.3,0,15.59L172.71,159.19z"/>
      </g>
      <g class="dedicated-compute-13">
        <g>
          <polygon class="dedicated-compute-19" points="132.38,199.84 132.38,209.56 128.35,207.23 128.35,197.51       "/>
          <polygon class="dedicated-compute-19" points="120.28,192.85 120.28,202.57 116.25,200.24 116.25,190.52       "/>
          <polygon class="dedicated-compute-19" points="108.18,185.87 108.18,195.58 104.15,193.26 104.15,183.54       "/>
          <polygon class="dedicated-compute-19" points="96.09,178.88 96.09,188.6 92.05,186.27 92.05,176.55      "/>
          <polygon class="dedicated-compute-19" points="83.99,171.9 83.99,181.61 79.95,179.29 79.95,169.57      "/>
          <polygon class="dedicated-compute-19" points="71.89,164.91 71.89,174.63 67.85,172.3 67.85,162.58      "/>
          <polygon class="dedicated-compute-19" points="59.79,157.92 59.79,167.64 55.75,165.32 55.75,155.59       "/>
          <polygon class="dedicated-compute-19" points="47.69,150.94 47.69,160.66 43.65,158.33 43.65,148.61       "/>
        </g>
        <g>
          <polygon class="dedicated-compute-19" points="240.05,199.84 240.05,209.56 244.08,207.23 244.08,197.51       "/>
          <polygon class="dedicated-compute-19" points="252.15,192.85 252.15,202.57 256.18,200.24 256.18,190.52       "/>
          <polygon class="dedicated-compute-19" points="264.25,185.87 264.25,195.58 268.28,193.26 268.28,183.54       "/>
          <polygon class="dedicated-compute-19" points="276.35,178.88 276.35,188.6 280.38,186.27 280.38,176.55      "/>
          <polygon class="dedicated-compute-19" points="288.44,171.9 288.44,181.61 292.48,179.29 292.48,169.57      "/>
          <polygon class="dedicated-compute-19" points="300.54,164.91 300.54,174.63 304.58,172.3 304.58,162.58      "/>
          <polygon class="dedicated-compute-19" points="312.64,157.92 312.64,167.64 316.68,165.32 316.68,155.59       "/>
          <polygon class="dedicated-compute-19" points="324.74,150.94 324.74,160.66 328.78,158.33 328.78,148.61       "/>
        </g>
      </g>
      <rect x="172.72" y="159.19" class="dedicated-compute-20" width="27" height="18.42"/>
      <g class="dedicated-compute-13">
        <g>
          <path class="dedicated-compute-2" d="M179.43,48.18c3.73-2.16,9.84-2.16,13.58,0v0c3.73,2.15,3.73,5.68,0,7.83v0c-3.74,2.15-9.84,2.15-13.58,0
            l0,0C175.7,53.86,175.7,50.33,179.43,48.18z"/>
        </g>
        <g>
          <path class="dedicated-compute-11" d="M203.24,61.93c3.74-2.16,9.84-2.16,13.57,0l0,0c3.73,2.15,3.73,5.68,0,7.83v0c-3.73,2.16-9.84,2.16-13.57,0
            h0C199.51,67.6,199.51,64.08,203.24,61.93z"/>
        </g>
        <g>
          <path class="dedicated-compute-2" d="M227.05,75.67c3.73-2.15,9.84-2.15,13.57,0l0,0c3.74,2.15,3.74,5.68,0,7.84v0c-3.73,2.15-9.84,2.15-13.57,0
            l0,0C223.31,81.35,223.31,77.83,227.05,75.67z"/>
        </g>
        <g>
          <path class="dedicated-compute-2" d="M250.86,89.42c3.73-2.15,9.84-2.15,13.57,0l0,0c3.74,2.15,3.74,5.68,0,7.84v0c-3.73,2.15-9.84,2.15-13.57,0
            l0,0C247.13,95.1,247.13,91.57,250.86,89.42z"/>
        </g>
        <path class="dedicated-compute-11" d="M155.62,61.93c3.73-2.15,9.84-2.15,13.57,0v0c3.73,2.15,3.73,5.68,0,7.83l0,0c-3.73,2.16-9.84,2.16-13.57,0
          C151.89,67.61,151.89,64.08,155.62,61.93z"/>
        <g>
          <path class="dedicated-compute-2" d="M179.43,75.67c3.73-2.15,9.84-2.15,13.57,0v0c3.73,2.15,3.73,5.68,0,7.83v0c-3.74,2.15-9.84,2.15-13.57,0
            C175.7,81.35,175.7,77.83,179.43,75.67z"/>
        </g>
        <g>
          <path class="dedicated-compute-2" d="M203.24,89.42c3.74-2.16,9.84-2.16,13.57,0v0c3.73,2.15,3.73,5.68,0,7.84l0,0c-3.73,2.15-9.84,2.15-13.57,0
            h0C199.51,95.1,199.51,91.58,203.24,89.42z"/>
        </g>
        <g>
          <path class="dedicated-compute-2" d="M227.05,103.17c3.74-2.16,9.84-2.16,13.57,0v0c3.73,2.15,3.73,5.68,0,7.84l0,0c-3.73,2.16-9.84,2.16-13.57,0
            h0C223.32,108.84,223.32,105.32,227.05,103.17z"/>
        </g>
        <g>
          <path class="dedicated-compute-2" d="M131.81,75.67c3.73-2.15,9.84-2.15,13.57,0l0,0c3.73,2.15,3.73,5.68,0,7.84l0,0
            c-3.73,2.15-9.84,2.15-13.57,0l0,0C128.07,81.35,128.07,77.83,131.81,75.67z"/>
        </g>
        <g>
          <path class="dedicated-compute-2" d="M155.61,89.42c3.73-2.16,9.84-2.16,13.57,0v0c3.73,2.15,3.73,5.68,0,7.84l0,0c-3.73,2.15-9.84,2.15-13.57,0
            l0,0C151.89,95.1,151.89,91.58,155.61,89.42z"/>
        </g>
        <g>
          <path class="dedicated-compute-2" d="M179.43,103.17c3.73-2.15,9.84-2.15,13.57,0v0c3.73,2.16,3.73,5.68,0,7.84l0,0c-3.74,2.15-9.84,2.15-13.57,0
            l0,0C175.7,108.85,175.7,105.32,179.43,103.17z"/>
        </g>
        <g>
          <path class="dedicated-compute-11" d="M203.24,116.91c3.73-2.15,9.84-2.15,13.57,0v0c3.73,2.16,3.73,5.68,0,7.84l0,0
            c-3.74,2.15-9.84,2.15-13.57,0l0,0C199.51,122.59,199.51,119.07,203.24,116.91z"/>
        </g>
        <g>
          <path class="dedicated-compute-2" d="M108,89.42c3.73-2.15,9.84-2.15,13.57,0l0,0c3.73,2.15,3.73,5.68,0,7.84l0,0c-3.73,2.15-9.84,2.15-13.57,0
            l0,0C104.27,95.1,104.27,91.57,108,89.42z"/>
        </g>
        <g>
          <path class="dedicated-compute-2" d="M131.81,103.17c3.73-2.16,9.84-2.16,13.57,0v0c3.73,2.15,3.73,5.68,0,7.84l0,0c-3.73,2.16-9.84,2.16-13.57,0
            l0,0C128.08,108.84,128.08,105.32,131.81,103.17z"/>
        </g>
        <g>
          <path class="dedicated-compute-11" d="M155.62,116.91c3.73-2.16,9.84-2.16,13.57,0v0c3.73,2.16,3.73,5.68,0,7.84l0,0
            c-3.74,2.15-9.84,2.15-13.57,0l0,0C151.89,122.59,151.89,119.07,155.62,116.91z"/>
        </g>
        <g>
          <path class="dedicated-compute-11" d="M179.43,130.66c3.73-2.16,9.84-2.16,13.57,0v0c3.73,2.15,3.73,5.68,0,7.84l0,0
            c-3.74,2.15-9.84,2.15-13.57,0l0,0C175.7,136.34,175.7,132.81,179.43,130.66z"/>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>

export default {
  //
}

</script>
