<script>
  export default {
    props: {
      foreground: String,
      background: String,
      download: String,
      darkOnLight: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      lightOnDark () {
        return ! this.darkOnLight;
      },

      foregroundPalette () {
        return `background-color: ${this.foreground};`;
      },

      backgroundPalette () {
        return `background-color: ${this.background};`;
      },

      foregroundStyle () {
        return `color: ${this.foreground};`;
      },

      backgroundStyle () {
        return this.backgroundPalette;
      }
    }
  }
</script>



<template>
  <article>
    <figure class="px-6 py-12 flex flex-col items-center justify-center rounded-lg bg-gray-darkest bg-repeat bg-left bg-palette heropattern-topography-palette"
      :style="backgroundStyle">
      <x-ui-logo class="w-48 mb-6 mx-auto md:mx-0" :style="foregroundStyle"></x-ui-logo>

      <ul class="w-full list-reset flex items-center justify-around mt-4 whitespace-nowrap font-bold"
        :class="{
          'text-gray-lightest': darkOnLight,
          'text-gray-darkest': lightOnDark
        }">
        <li class="flex items-center">
          <span class="block mr-2 h-6 w-6 border-2 border-gray-lighter rounded" 
            :style="foregroundPalette"></span>

          {{ foreground }}
        </li>
        <li class="flex items-center">
          <span class="block mr-2 h-6 w-6 border-2 border-gray-lighter rounded" 
            :style="backgroundPalette"></span>

          {{ background }}
        </li>
      </ul>
    </figure>

    <section class="px-2 py-8 flex justify-between items-center">
      <div>
        <h4 class="text-gray-lightest font-bold text-xl mb-1">
          <slot name="heading"></slot>
        </h4>

        <p class="text-sm font-medium text-gray-lightish">
          <slot></slot>
        </p>
      </div>

      <div>
        <x-ui-button v-if="download" type="table" class="h-12 w-12 !rounded-full" small :url="download" target="_blank">
          <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.16209 13.7557C5.29452 13.9185 5.45994 14 5.65839 14C5.85699 14 6.02221 13.9185 6.15468 13.7557L11.0959 7.9909C11.3238 7.74216 11.3753 7.44186 11.2503 7.09014C11.1255 6.75568 10.9085 6.58842 10.5996 6.58824H7.77599V0.823558C7.77599 0.600554 7.70603 0.40752 7.56657 0.244548C7.42673 0.081531 7.26135 0 7.07028 0H4.24649C4.05542 0 3.88997 0.081531 3.7502 0.244548C3.61055 0.40752 3.54066 0.600509 3.54066 0.823558V6.58829H0.71715C0.408178 6.58829 0.19138 6.75572 0.0663699 7.09018C-0.0585632 7.44177 -0.00714544 7.74221 0.220856 7.99095L5.16209 13.7557Z" fill="currentColor" />
          </svg>
        </x-ui-button>
      </div>
    </section>
  </article>
</template>
